import React, { ReactNode } from "react";
import { OrganizationContext } from "@/contexts/organization-context";
import useOrganizationData from "@/hooks/use-organization-data";

export function OrganizationProvider({
  children,
  userId,
}: {
  children: ReactNode;
  userId: string;
}) {
  const orgData = useOrganizationData(userId);

  return (
    <OrganizationContext.Provider value={orgData}>
      {children}
    </OrganizationContext.Provider>
  );
}
