import { OrganizationContext } from "@/contexts/organization-context";
import { useContext } from "react";

export function useOrganization() {
  const context = useContext(OrganizationContext);
  if (!context) {
    throw new Error(
      "useOrganization must be used within an OrganizationProvider",
    );
  }
  return context;
}
