import { useState, useEffect, useCallback } from "react";
import {
  useGetUserOrganizationsByUserIdQuery,
  OrganizationFragment,
  useGetOrganizationByIdForWebAppQuery,
} from "@/generated/graphql";

export interface OrganizationDataResult {
  organization: OrganizationFragment | null;
  loading: boolean;
  error: Error | null;
  refresh: () => void;
}

export default function useOrganizationData(
  userId: string,
): OrganizationDataResult {
  const [organization, setOrganization] = useState<OrganizationFragment | null>(
    null,
  );

  const {
    data: userOrganizationsData,
    loading: userOrganizationLoading,
    error: userOrganizationError,
    refetch: refetchUserOrganizations,
  } = useGetUserOrganizationsByUserIdQuery({
    variables: { userId },
    skip: !userId,
  });

  const organizationId =
    userOrganizationsData?.user_organizations[0]?.organizationId;

  const {
    data: organizationQueryResult,
    loading: organizationLoading,
    error: organizationError,
    refetch: refetchOrganization,
  } = useGetOrganizationByIdForWebAppQuery({
    variables: { id: organizationId },
    skip: !organizationId,
  });

  const updateOrganization = useCallback(() => {
    if (organizationQueryResult?.organization) {
      setOrganization(organizationQueryResult.organization);
    }
  }, [organizationQueryResult]);

  useEffect(() => {
    updateOrganization();
  }, [updateOrganization]);

  const refresh = useCallback(() => {
    refetchUserOrganizations();
    if (organizationId) {
      refetchOrganization();
    }
  }, [refetchUserOrganizations, refetchOrganization, organizationId]);

  const loading = userOrganizationLoading || organizationLoading;
  const error = userOrganizationError || organizationError;

  return {
    organization,
    loading,
    error: error ? new Error(error.message) : null,
    refresh,
  };
}
