import { useState, useCallback } from "react";
import { User } from "@nhost/nextjs";

export interface UserDetails {
  id: string;
  email: string | undefined;
  displayName: string;
  avatarUrl: string;
}

export default function useUserDetails() {
  const [userDetails, setUserDetails] = useState<UserDetails | null>(null);

  const fetchUserDetails = useCallback((user: User | null) => {
    if (user) {
      setUserDetails({
        id: user.id,
        displayName: user.displayName,
        email: user.email,
        avatarUrl: user.avatarUrl,
      });
    }
  }, []);

  return { userDetails, fetchUserDetails };
}
