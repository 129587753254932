"use client";

import { FC } from "react";
import Lottie, { LottieRefCurrentProps } from "lottie-react";
import playgroundAnimation from "../../../../public/assets/playground_dev.json";
import { Small } from "@/components/ui";

interface PlaygroundLogoProps {
  lottieRef: React.RefObject<LottieRefCurrentProps>;
}

export const PlaygroundLogo: FC<PlaygroundLogoProps> = ({ lottieRef }) => (
  <div className="flex items-center">
    <div
      onMouseEnter={() => lottieRef.current?.pause()}
      onMouseLeave={() => lottieRef.current?.play()}
    >
      <Lottie
        lottieRef={lottieRef}
        animationData={playgroundAnimation}
        loop={true}
        autoplay={true}
      />
    </div>
    <Small className="font-mono">developer playground</Small>
  </div>
);
