import { PlayIcon, KeyIcon } from "lucide-react";

export const sideNavigationLinks = [
  {
    href: "/",
    label: "dashboard",
    icon: PlayIcon,
  },
  {
    href: "/keys",
    label: "keys",
    icon: KeyIcon,
  },
] as const;

export type SideNavigationLink = (typeof sideNavigationLinks)[number];
