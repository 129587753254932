import { createContext, PropsWithChildren, useContext } from "react";
import { UserDetails } from "@/hooks/use-user-details";

export interface UserDetailsContextValue {
  userDetails: UserDetails;
}

const UserDetailsContext = createContext<UserDetailsContextValue | null>(null);

export const UserDetailsProvider = ({
  children,
  userDetails,
}: PropsWithChildren<{ userDetails: UserDetails }>) => {
  return (
    <UserDetailsContext.Provider value={{ userDetails }}>
      {children}
    </UserDetailsContext.Provider>
  );
};

export function useUserDetailsContext() {
  const context = useContext(UserDetailsContext);
  if (!context) {
    throw new Error(
      "useUserDetailsContext must be used within a UserDetailsProvider",
    );
  }
  return context;
}
