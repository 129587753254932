import { FC } from "react";
import { useNhostClient } from "@nhost/nextjs";
import { usePathname, useRouter } from "next/navigation";
import { usePostHog } from "posthog-js/react";
import { POSTHOG_EVENTS } from "@/lib/posthog";
import { Avatar, AvatarImage, AvatarFallback } from "@/components/ui/avatar";
import { KeyIcon, LogOutIcon, PlayIcon, UserIcon } from "lucide-react";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";

const iconClassName = "mr-2 h-4 w-4 text-primary";

export const MENU_ITEMS = [
  {
    label: "Dashboard",
    icon: <PlayIcon className={iconClassName} />,
    route: "/",
  },
  {
    label: "Keys",
    icon: <KeyIcon className={iconClassName} />,
    route: "/keys",
  },
];

export interface ProfileMenuProps {
  userId: string;
  avatarUrl?: string;
}

const ProfileMenu: FC<ProfileMenuProps> = ({ userId, avatarUrl }) => {
  const postHog = usePostHog();
  const nhostClient = useNhostClient();
  const router = useRouter();
  const pathname = usePathname();

  const handleLogout = async () => {
    await nhostClient.auth.signOut();

    postHog.capture(POSTHOG_EVENTS.USER_SIGNED_OUT, {
      user_id: userId,
      signout_date: new Date().toISOString(),
    });
    postHog.reset();
    router.push("/login");
  };

  const navigateTo = (route: string) => {
    router.push(route);
  };

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Avatar className="cursor-pointer hover:opacity-80 h-8 w-8">
          <AvatarImage src={avatarUrl} alt="User avatar" />
          <AvatarFallback>
            <UserIcon size={16} />
          </AvatarFallback>
        </Avatar>
      </DropdownMenuTrigger>
      <DropdownMenuContent align="end" className="w-[182px]">
        {MENU_ITEMS.map((item) => (
          <DropdownMenuItem
            key={item.label}
            onClick={() => navigateTo(item.route)}
            className={pathname === item.route ? "bg-accent" : ""}
          >
            {item.icon}
            {item.label}
          </DropdownMenuItem>
        ))}
        <DropdownMenuSeparator />
        <DropdownMenuItem onClick={handleLogout}>
          <LogOutIcon className="mr-2 h-4 w-4 text-primary" />
          sign out
        </DropdownMenuItem>
      </DropdownMenuContent>
    </DropdownMenu>
  );
};

export default ProfileMenu;
