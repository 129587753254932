import { PostHog } from "posthog-node";

// Events being track in PostHog
export const POSTHOG_EVENTS = {
  // User Events
  USER_SIGNED_UP: "user_signed_up",
  USER_SIGNED_IN: "user_signed_in",
  USER_SIGNED_OUT: "user_signed_out",
  PASSWORD_REQUEST_REQUESTED: "password_request_requested",
  // API key Events
  API_KEY_GENERATED: "api_key_generated",
  API_KEY_DELETED: "api_key_deleted",
  // API Request Events
  API_CALL_MADE: "api_call_made",
  API_ERROR: "api_error",
  ACCOUNT_DELETED: "account_deleted",
  USER_CREATED: "user_created",
} as const;

export const posthog = new PostHog(process.env.NEXT_PUBLIC_POSTHOG_KEY!, {
  host: process.env.NEXT_PUBLIC_POSTHOG_HOST!,
});
