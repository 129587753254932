"use client";

import Image from "next/image";
import { cn } from "@/lib/utils";
import { SideNavigationLink } from "./types";
import Link from "next/link";
import { Large, P } from "@/components/ui";

interface SideNavItemProps {
  link: SideNavigationLink;
  isActive: boolean;
  expanded: boolean;
}

export const SideNavItem = ({ link, isActive, expanded }: SideNavItemProps) => {
  return (
    <Link href={link.href} className="flex w-full h-full hover:text-primary">
      <div
        className={cn(
          "flex w-full justify-start gap-4 px-2 pb-2 border-b-2 border-transparent",
          { "border-primary": isActive },
        )}
      >
        <link.icon className="size-6 shrink-0" />
        {expanded && (
          <span className="whitespace-nowrap overflow-hidden font-medium">
            {link.label}
          </span>
        )}
      </div>
    </Link>
  );
};
