"use client";

import { FC, ReactNode, Suspense, useEffect } from "react";
import { useNhostClient } from "@nhost/react";

import { OrganizationProvider } from "@/providers/organization-provider";
import { PostHogIdentify } from "@/components/post-hog-identify";
import useAuthRedirect from "@/hooks/use-auth-redirect";
import useUserDetails from "@/hooks/use-user-details";
import { UserDetailsProvider } from "@/providers/user-details-provider";
import { Header } from "@/components/layout/header";
import { SideNav } from "@/components/layout/side-nav";
import { Footer } from "@/components/layout/footer";

interface ProtectedPageProps {
  children: ReactNode;
}

const ProtectedPage: FC<ProtectedPageProps> = ({ children }) => {
  const { isLoading, isAuthenticated } = useAuthRedirect();
  const { userDetails, fetchUserDetails } = useUserDetails();
  const nhost = useNhostClient();

  useEffect(() => {
    if (isAuthenticated) {
      fetchUserDetails(nhost.auth.getUser());
    }
  }, [isAuthenticated, nhost.auth, fetchUserDetails]);

  if (isLoading || !userDetails || !isAuthenticated) return null;

  return (
    <UserDetailsProvider userDetails={userDetails}>
      <Suspense>
        <PostHogIdentify />
      </Suspense>

      <OrganizationProvider userId={userDetails.id}>
        <div className="min-w-screen min-h-screen max-h-screen h-screen flex flex-col overflow-hidden">
          <Header userDetails={userDetails} />

          <div className="flex-1 overflow-hidden flex">
            <div className="h-full">
              <SideNav />
            </div>
            <div className="flex-1 bg-card">{children}</div>
          </div>

          <Footer />
        </div>
      </OrganizationProvider>
    </UserDetailsProvider>
  );
};

export default ProtectedPage;
