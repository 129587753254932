"use client";

import { usePostHog } from "posthog-js/react";
import { useEffect } from "react";
import { useSearchParams, useRouter, usePathname } from "next/navigation";
import { POSTHOG_EVENTS } from "@/lib/posthog";
import { useUserDetailsContext } from "@/providers/user-details-provider";

export function PostHogIdentify({ children }: { children?: React.ReactNode }) {
  const postHog = usePostHog();
  const { userDetails } = useUserDetailsContext();
  const searchParams = useSearchParams();
  const router = useRouter();
  const pathname = usePathname();

  const action = searchParams.get("action");
  const provider = searchParams.get("provider");

  useEffect(() => {
    postHog.identify(userDetails.id, {
      email: userDetails.email,
    });
  }, [postHog, userDetails]);

  useEffect(() => {
    if (action && userDetails) {
      postHog.capture(POSTHOG_EVENTS.USER_SIGNED_IN, {
        user_id: userDetails.id,
        signin_method: provider,
        signin_date: new Date().toISOString(),
      });

      // Remove query parameters from the URL
      const params = new URLSearchParams(searchParams);
      params.delete("action");
      params.delete("provider");
      router.replace(
        `${pathname}${params.toString() ? `?${params.toString()}` : ""}`,
      );
    }
  }, [action, userDetails, postHog, provider, router, pathname, searchParams]);

  return <>{children}</>;
}
