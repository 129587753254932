import { GraphQLResolveInfo, GraphQLScalarType, GraphQLScalarTypeConfig } from 'graphql';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
export type RequireFields<T, K extends keyof T> = Omit<T, K> & { [P in K]-?: NonNullable<T[P]> };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  bigint: { input: any; output: any; }
  bytea: { input: any; output: any; }
  citext: { input: any; output: any; }
  jsonb: { input: any; output: any; }
  timestamptz: { input: any; output: any; }
  uuid: { input: any; output: any; }
};

/** Boolean expression to compare columns of type "Boolean". All fields are combined with logical 'AND'. */
export type BooleanComparisonExp = {
  _eq?: InputMaybe<Scalars['Boolean']['input']>;
  _gt?: InputMaybe<Scalars['Boolean']['input']>;
  _gte?: InputMaybe<Scalars['Boolean']['input']>;
  _in?: InputMaybe<Array<Scalars['Boolean']['input']>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['Boolean']['input']>;
  _lte?: InputMaybe<Scalars['Boolean']['input']>;
  _neq?: InputMaybe<Scalars['Boolean']['input']>;
  _nin?: InputMaybe<Array<Scalars['Boolean']['input']>>;
};

/** Boolean expression to compare columns of type "Int". All fields are combined with logical 'AND'. */
export type IntComparisonExp = {
  _eq?: InputMaybe<Scalars['Int']['input']>;
  _gt?: InputMaybe<Scalars['Int']['input']>;
  _gte?: InputMaybe<Scalars['Int']['input']>;
  _in?: InputMaybe<Array<Scalars['Int']['input']>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['Int']['input']>;
  _lte?: InputMaybe<Scalars['Int']['input']>;
  _neq?: InputMaybe<Scalars['Int']['input']>;
  _nin?: InputMaybe<Array<Scalars['Int']['input']>>;
};

/** Boolean expression to compare columns of type "String". All fields are combined with logical 'AND'. */
export type StringComparisonExp = {
  _eq?: InputMaybe<Scalars['String']['input']>;
  _gt?: InputMaybe<Scalars['String']['input']>;
  _gte?: InputMaybe<Scalars['String']['input']>;
  /** does the column match the given case-insensitive pattern */
  _ilike?: InputMaybe<Scalars['String']['input']>;
  _in?: InputMaybe<Array<Scalars['String']['input']>>;
  /** does the column match the given POSIX regular expression, case insensitive */
  _iregex?: InputMaybe<Scalars['String']['input']>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  /** does the column match the given pattern */
  _like?: InputMaybe<Scalars['String']['input']>;
  _lt?: InputMaybe<Scalars['String']['input']>;
  _lte?: InputMaybe<Scalars['String']['input']>;
  _neq?: InputMaybe<Scalars['String']['input']>;
  /** does the column NOT match the given case-insensitive pattern */
  _nilike?: InputMaybe<Scalars['String']['input']>;
  _nin?: InputMaybe<Array<Scalars['String']['input']>>;
  /** does the column NOT match the given POSIX regular expression, case insensitive */
  _niregex?: InputMaybe<Scalars['String']['input']>;
  /** does the column NOT match the given pattern */
  _nlike?: InputMaybe<Scalars['String']['input']>;
  /** does the column NOT match the given POSIX regular expression, case sensitive */
  _nregex?: InputMaybe<Scalars['String']['input']>;
  /** does the column NOT match the given SQL regular expression */
  _nsimilar?: InputMaybe<Scalars['String']['input']>;
  /** does the column match the given POSIX regular expression, case sensitive */
  _regex?: InputMaybe<Scalars['String']['input']>;
  /** does the column match the given SQL regular expression */
  _similar?: InputMaybe<Scalars['String']['input']>;
};

/** Stores API keys associated with organizations. */
export type ApiKeys = {
  __typename?: 'api_keys';
  createdAt: Scalars['timestamptz']['output'];
  id: Scalars['uuid']['output'];
  lastAccessedAt?: Maybe<Scalars['timestamptz']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  /** An object relationship */
  organization?: Maybe<Organizations>;
  organizationId: Scalars['uuid']['output'];
  revoked?: Maybe<Scalars['Boolean']['output']>;
  revokedAt?: Maybe<Scalars['timestamptz']['output']>;
  secretHash?: Maybe<Scalars['String']['output']>;
  secretMask?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['timestamptz']['output'];
};

/** aggregated selection of "api_keys" */
export type ApiKeysAggregate = {
  __typename?: 'api_keys_aggregate';
  aggregate?: Maybe<ApiKeysAggregateFields>;
  nodes: Array<ApiKeys>;
};

export type ApiKeysAggregateBoolExp = {
  bool_and?: InputMaybe<ApiKeysAggregateBoolExpBoolAnd>;
  bool_or?: InputMaybe<ApiKeysAggregateBoolExpBoolOr>;
  count?: InputMaybe<ApiKeysAggregateBoolExpCount>;
};

export type ApiKeysAggregateBoolExpBoolAnd = {
  arguments: ApiKeysSelectColumnApiKeysAggregateBoolExpBoolAndArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<ApiKeysBoolExp>;
  predicate: BooleanComparisonExp;
};

export type ApiKeysAggregateBoolExpBoolOr = {
  arguments: ApiKeysSelectColumnApiKeysAggregateBoolExpBoolOrArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<ApiKeysBoolExp>;
  predicate: BooleanComparisonExp;
};

export type ApiKeysAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<ApiKeysSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<ApiKeysBoolExp>;
  predicate: IntComparisonExp;
};

/** aggregate fields of "api_keys" */
export type ApiKeysAggregateFields = {
  __typename?: 'api_keys_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<ApiKeysMaxFields>;
  min?: Maybe<ApiKeysMinFields>;
};


/** aggregate fields of "api_keys" */
export type ApiKeysAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<ApiKeysSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "api_keys" */
export type ApiKeysAggregateOrderBy = {
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<ApiKeysMaxOrderBy>;
  min?: InputMaybe<ApiKeysMinOrderBy>;
};

/** input type for inserting array relation for remote table "api_keys" */
export type ApiKeysArrRelInsertInput = {
  data: Array<ApiKeysInsertInput>;
  /** upsert condition */
  on_conflict?: InputMaybe<ApiKeysOnConflict>;
};

/** Boolean expression to filter rows from the table "api_keys". All fields are combined with a logical 'AND'. */
export type ApiKeysBoolExp = {
  _and?: InputMaybe<Array<ApiKeysBoolExp>>;
  _not?: InputMaybe<ApiKeysBoolExp>;
  _or?: InputMaybe<Array<ApiKeysBoolExp>>;
  createdAt?: InputMaybe<TimestamptzComparisonExp>;
  id?: InputMaybe<UuidComparisonExp>;
  lastAccessedAt?: InputMaybe<TimestamptzComparisonExp>;
  name?: InputMaybe<StringComparisonExp>;
  organization?: InputMaybe<OrganizationsBoolExp>;
  organizationId?: InputMaybe<UuidComparisonExp>;
  revoked?: InputMaybe<BooleanComparisonExp>;
  revokedAt?: InputMaybe<TimestamptzComparisonExp>;
  secretHash?: InputMaybe<StringComparisonExp>;
  secretMask?: InputMaybe<StringComparisonExp>;
  updatedAt?: InputMaybe<TimestamptzComparisonExp>;
};

/** unique or primary key constraints on table "api_keys" */
export enum ApiKeysConstraint {
  /** unique or primary key constraint on columns "id" */
  API_KEYS_PKEY = 'api_keys_pkey'
}

/** input type for inserting data into table "api_keys" */
export type ApiKeysInsertInput = {
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  lastAccessedAt?: InputMaybe<Scalars['timestamptz']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  organization?: InputMaybe<OrganizationsObjRelInsertInput>;
  organizationId?: InputMaybe<Scalars['uuid']['input']>;
  revoked?: InputMaybe<Scalars['Boolean']['input']>;
  revokedAt?: InputMaybe<Scalars['timestamptz']['input']>;
  secretHash?: InputMaybe<Scalars['String']['input']>;
  secretMask?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate max on columns */
export type ApiKeysMaxFields = {
  __typename?: 'api_keys_max_fields';
  createdAt?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  lastAccessedAt?: Maybe<Scalars['timestamptz']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  organizationId?: Maybe<Scalars['uuid']['output']>;
  revokedAt?: Maybe<Scalars['timestamptz']['output']>;
  secretHash?: Maybe<Scalars['String']['output']>;
  secretMask?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['timestamptz']['output']>;
};

/** order by max() on columns of table "api_keys" */
export type ApiKeysMaxOrderBy = {
  createdAt?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  lastAccessedAt?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  organizationId?: InputMaybe<OrderBy>;
  revokedAt?: InputMaybe<OrderBy>;
  secretHash?: InputMaybe<OrderBy>;
  secretMask?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type ApiKeysMinFields = {
  __typename?: 'api_keys_min_fields';
  createdAt?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  lastAccessedAt?: Maybe<Scalars['timestamptz']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  organizationId?: Maybe<Scalars['uuid']['output']>;
  revokedAt?: Maybe<Scalars['timestamptz']['output']>;
  secretHash?: Maybe<Scalars['String']['output']>;
  secretMask?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['timestamptz']['output']>;
};

/** order by min() on columns of table "api_keys" */
export type ApiKeysMinOrderBy = {
  createdAt?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  lastAccessedAt?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  organizationId?: InputMaybe<OrderBy>;
  revokedAt?: InputMaybe<OrderBy>;
  secretHash?: InputMaybe<OrderBy>;
  secretMask?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "api_keys" */
export type ApiKeysMutationResponse = {
  __typename?: 'api_keys_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<ApiKeys>;
};

/** on_conflict condition type for table "api_keys" */
export type ApiKeysOnConflict = {
  constraint: ApiKeysConstraint;
  update_columns?: Array<ApiKeysUpdateColumn>;
  where?: InputMaybe<ApiKeysBoolExp>;
};

/** Ordering options when selecting data from "api_keys". */
export type ApiKeysOrderBy = {
  createdAt?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  lastAccessedAt?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  organization?: InputMaybe<OrganizationsOrderBy>;
  organizationId?: InputMaybe<OrderBy>;
  revoked?: InputMaybe<OrderBy>;
  revokedAt?: InputMaybe<OrderBy>;
  secretHash?: InputMaybe<OrderBy>;
  secretMask?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: api_keys */
export type ApiKeysPkColumnsInput = {
  id: Scalars['uuid']['input'];
};

/** select columns of table "api_keys" */
export enum ApiKeysSelectColumn {
  /** column name */
  CREATEDAT = 'createdAt',
  /** column name */
  ID = 'id',
  /** column name */
  LASTACCESSEDAT = 'lastAccessedAt',
  /** column name */
  NAME = 'name',
  /** column name */
  ORGANIZATIONID = 'organizationId',
  /** column name */
  REVOKED = 'revoked',
  /** column name */
  REVOKEDAT = 'revokedAt',
  /** column name */
  SECRETHASH = 'secretHash',
  /** column name */
  SECRETMASK = 'secretMask',
  /** column name */
  UPDATEDAT = 'updatedAt'
}

/** select "api_keys_aggregate_bool_exp_bool_and_arguments_columns" columns of table "api_keys" */
export enum ApiKeysSelectColumnApiKeysAggregateBoolExpBoolAndArgumentsColumns {
  /** column name */
  REVOKED = 'revoked'
}

/** select "api_keys_aggregate_bool_exp_bool_or_arguments_columns" columns of table "api_keys" */
export enum ApiKeysSelectColumnApiKeysAggregateBoolExpBoolOrArgumentsColumns {
  /** column name */
  REVOKED = 'revoked'
}

/** input type for updating data in table "api_keys" */
export type ApiKeysSetInput = {
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  lastAccessedAt?: InputMaybe<Scalars['timestamptz']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  organizationId?: InputMaybe<Scalars['uuid']['input']>;
  revoked?: InputMaybe<Scalars['Boolean']['input']>;
  revokedAt?: InputMaybe<Scalars['timestamptz']['input']>;
  secretHash?: InputMaybe<Scalars['String']['input']>;
  secretMask?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** Streaming cursor of the table "api_keys" */
export type ApiKeysStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: ApiKeysStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type ApiKeysStreamCursorValueInput = {
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  lastAccessedAt?: InputMaybe<Scalars['timestamptz']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  organizationId?: InputMaybe<Scalars['uuid']['input']>;
  revoked?: InputMaybe<Scalars['Boolean']['input']>;
  revokedAt?: InputMaybe<Scalars['timestamptz']['input']>;
  secretHash?: InputMaybe<Scalars['String']['input']>;
  secretMask?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** update columns of table "api_keys" */
export enum ApiKeysUpdateColumn {
  /** column name */
  CREATEDAT = 'createdAt',
  /** column name */
  ID = 'id',
  /** column name */
  LASTACCESSEDAT = 'lastAccessedAt',
  /** column name */
  NAME = 'name',
  /** column name */
  ORGANIZATIONID = 'organizationId',
  /** column name */
  REVOKED = 'revoked',
  /** column name */
  REVOKEDAT = 'revokedAt',
  /** column name */
  SECRETHASH = 'secretHash',
  /** column name */
  SECRETMASK = 'secretMask',
  /** column name */
  UPDATEDAT = 'updatedAt'
}

export type ApiKeysUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<ApiKeysSetInput>;
  /** filter the rows which have to be updated */
  where: ApiKeysBoolExp;
};

/** Oauth requests, inserted before redirecting to the provider's site. Don't modify its structure as Hasura Auth relies on it to function properly. */
export type AuthProviderRequests = {
  __typename?: 'authProviderRequests';
  id: Scalars['uuid']['output'];
  options?: Maybe<Scalars['jsonb']['output']>;
};


/** Oauth requests, inserted before redirecting to the provider's site. Don't modify its structure as Hasura Auth relies on it to function properly. */
export type AuthProviderRequestsOptionsArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};

/** aggregated selection of "auth.provider_requests" */
export type AuthProviderRequestsAggregate = {
  __typename?: 'authProviderRequests_aggregate';
  aggregate?: Maybe<AuthProviderRequestsAggregateFields>;
  nodes: Array<AuthProviderRequests>;
};

/** aggregate fields of "auth.provider_requests" */
export type AuthProviderRequestsAggregateFields = {
  __typename?: 'authProviderRequests_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<AuthProviderRequestsMaxFields>;
  min?: Maybe<AuthProviderRequestsMinFields>;
};


/** aggregate fields of "auth.provider_requests" */
export type AuthProviderRequestsAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<AuthProviderRequestsSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type AuthProviderRequestsAppendInput = {
  options?: InputMaybe<Scalars['jsonb']['input']>;
};

/** Boolean expression to filter rows from the table "auth.provider_requests". All fields are combined with a logical 'AND'. */
export type AuthProviderRequestsBoolExp = {
  _and?: InputMaybe<Array<AuthProviderRequestsBoolExp>>;
  _not?: InputMaybe<AuthProviderRequestsBoolExp>;
  _or?: InputMaybe<Array<AuthProviderRequestsBoolExp>>;
  id?: InputMaybe<UuidComparisonExp>;
  options?: InputMaybe<JsonbComparisonExp>;
};

/** unique or primary key constraints on table "auth.provider_requests" */
export enum AuthProviderRequestsConstraint {
  /** unique or primary key constraint on columns "id" */
  PROVIDER_REQUESTS_PKEY = 'provider_requests_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type AuthProviderRequestsDeleteAtPathInput = {
  options?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type AuthProviderRequestsDeleteElemInput = {
  options?: InputMaybe<Scalars['Int']['input']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type AuthProviderRequestsDeleteKeyInput = {
  options?: InputMaybe<Scalars['String']['input']>;
};

/** input type for inserting data into table "auth.provider_requests" */
export type AuthProviderRequestsInsertInput = {
  id?: InputMaybe<Scalars['uuid']['input']>;
  options?: InputMaybe<Scalars['jsonb']['input']>;
};

/** aggregate max on columns */
export type AuthProviderRequestsMaxFields = {
  __typename?: 'authProviderRequests_max_fields';
  id?: Maybe<Scalars['uuid']['output']>;
};

/** aggregate min on columns */
export type AuthProviderRequestsMinFields = {
  __typename?: 'authProviderRequests_min_fields';
  id?: Maybe<Scalars['uuid']['output']>;
};

/** response of any mutation on the table "auth.provider_requests" */
export type AuthProviderRequestsMutationResponse = {
  __typename?: 'authProviderRequests_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<AuthProviderRequests>;
};

/** on_conflict condition type for table "auth.provider_requests" */
export type AuthProviderRequestsOnConflict = {
  constraint: AuthProviderRequestsConstraint;
  update_columns?: Array<AuthProviderRequestsUpdateColumn>;
  where?: InputMaybe<AuthProviderRequestsBoolExp>;
};

/** Ordering options when selecting data from "auth.provider_requests". */
export type AuthProviderRequestsOrderBy = {
  id?: InputMaybe<OrderBy>;
  options?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: auth.provider_requests */
export type AuthProviderRequestsPkColumnsInput = {
  id: Scalars['uuid']['input'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type AuthProviderRequestsPrependInput = {
  options?: InputMaybe<Scalars['jsonb']['input']>;
};

/** select columns of table "auth.provider_requests" */
export enum AuthProviderRequestsSelectColumn {
  /** column name */
  ID = 'id',
  /** column name */
  OPTIONS = 'options'
}

/** input type for updating data in table "auth.provider_requests" */
export type AuthProviderRequestsSetInput = {
  id?: InputMaybe<Scalars['uuid']['input']>;
  options?: InputMaybe<Scalars['jsonb']['input']>;
};

/** Streaming cursor of the table "authProviderRequests" */
export type AuthProviderRequestsStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: AuthProviderRequestsStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type AuthProviderRequestsStreamCursorValueInput = {
  id?: InputMaybe<Scalars['uuid']['input']>;
  options?: InputMaybe<Scalars['jsonb']['input']>;
};

/** update columns of table "auth.provider_requests" */
export enum AuthProviderRequestsUpdateColumn {
  /** column name */
  ID = 'id',
  /** column name */
  OPTIONS = 'options'
}

export type AuthProviderRequestsUpdates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<AuthProviderRequestsAppendInput>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<AuthProviderRequestsDeleteAtPathInput>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<AuthProviderRequestsDeleteElemInput>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<AuthProviderRequestsDeleteKeyInput>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<AuthProviderRequestsPrependInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<AuthProviderRequestsSetInput>;
  /** filter the rows which have to be updated */
  where: AuthProviderRequestsBoolExp;
};

/** List of available Oauth providers. Don't modify its structure as Hasura Auth relies on it to function properly. */
export type AuthProviders = {
  __typename?: 'authProviders';
  id: Scalars['String']['output'];
  /** An array relationship */
  userProviders: Array<AuthUserProviders>;
  /** An aggregate relationship */
  userProviders_aggregate: AuthUserProvidersAggregate;
};


/** List of available Oauth providers. Don't modify its structure as Hasura Auth relies on it to function properly. */
export type AuthProvidersUserProvidersArgs = {
  distinct_on?: InputMaybe<Array<AuthUserProvidersSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<AuthUserProvidersOrderBy>>;
  where?: InputMaybe<AuthUserProvidersBoolExp>;
};


/** List of available Oauth providers. Don't modify its structure as Hasura Auth relies on it to function properly. */
export type AuthProvidersUserProvidersAggregateArgs = {
  distinct_on?: InputMaybe<Array<AuthUserProvidersSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<AuthUserProvidersOrderBy>>;
  where?: InputMaybe<AuthUserProvidersBoolExp>;
};

/** aggregated selection of "auth.providers" */
export type AuthProvidersAggregate = {
  __typename?: 'authProviders_aggregate';
  aggregate?: Maybe<AuthProvidersAggregateFields>;
  nodes: Array<AuthProviders>;
};

/** aggregate fields of "auth.providers" */
export type AuthProvidersAggregateFields = {
  __typename?: 'authProviders_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<AuthProvidersMaxFields>;
  min?: Maybe<AuthProvidersMinFields>;
};


/** aggregate fields of "auth.providers" */
export type AuthProvidersAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<AuthProvidersSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "auth.providers". All fields are combined with a logical 'AND'. */
export type AuthProvidersBoolExp = {
  _and?: InputMaybe<Array<AuthProvidersBoolExp>>;
  _not?: InputMaybe<AuthProvidersBoolExp>;
  _or?: InputMaybe<Array<AuthProvidersBoolExp>>;
  id?: InputMaybe<StringComparisonExp>;
  userProviders?: InputMaybe<AuthUserProvidersBoolExp>;
  userProviders_aggregate?: InputMaybe<AuthUserProvidersAggregateBoolExp>;
};

/** unique or primary key constraints on table "auth.providers" */
export enum AuthProvidersConstraint {
  /** unique or primary key constraint on columns "id" */
  PROVIDERS_PKEY = 'providers_pkey'
}

/** input type for inserting data into table "auth.providers" */
export type AuthProvidersInsertInput = {
  id?: InputMaybe<Scalars['String']['input']>;
  userProviders?: InputMaybe<AuthUserProvidersArrRelInsertInput>;
};

/** aggregate max on columns */
export type AuthProvidersMaxFields = {
  __typename?: 'authProviders_max_fields';
  id?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type AuthProvidersMinFields = {
  __typename?: 'authProviders_min_fields';
  id?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "auth.providers" */
export type AuthProvidersMutationResponse = {
  __typename?: 'authProviders_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<AuthProviders>;
};

/** input type for inserting object relation for remote table "auth.providers" */
export type AuthProvidersObjRelInsertInput = {
  data: AuthProvidersInsertInput;
  /** upsert condition */
  on_conflict?: InputMaybe<AuthProvidersOnConflict>;
};

/** on_conflict condition type for table "auth.providers" */
export type AuthProvidersOnConflict = {
  constraint: AuthProvidersConstraint;
  update_columns?: Array<AuthProvidersUpdateColumn>;
  where?: InputMaybe<AuthProvidersBoolExp>;
};

/** Ordering options when selecting data from "auth.providers". */
export type AuthProvidersOrderBy = {
  id?: InputMaybe<OrderBy>;
  userProviders_aggregate?: InputMaybe<AuthUserProvidersAggregateOrderBy>;
};

/** primary key columns input for table: auth.providers */
export type AuthProvidersPkColumnsInput = {
  id: Scalars['String']['input'];
};

/** select columns of table "auth.providers" */
export enum AuthProvidersSelectColumn {
  /** column name */
  ID = 'id'
}

/** input type for updating data in table "auth.providers" */
export type AuthProvidersSetInput = {
  id?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "authProviders" */
export type AuthProvidersStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: AuthProvidersStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type AuthProvidersStreamCursorValueInput = {
  id?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "auth.providers" */
export enum AuthProvidersUpdateColumn {
  /** column name */
  ID = 'id'
}

export type AuthProvidersUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<AuthProvidersSetInput>;
  /** filter the rows which have to be updated */
  where: AuthProvidersBoolExp;
};

/** columns and relationships of "auth.refresh_token_types" */
export type AuthRefreshTokenTypes = {
  __typename?: 'authRefreshTokenTypes';
  comment?: Maybe<Scalars['String']['output']>;
  /** An array relationship */
  refreshTokens: Array<AuthRefreshTokens>;
  /** An aggregate relationship */
  refreshTokens_aggregate: AuthRefreshTokensAggregate;
  value: Scalars['String']['output'];
};


/** columns and relationships of "auth.refresh_token_types" */
export type AuthRefreshTokenTypesRefreshTokensArgs = {
  distinct_on?: InputMaybe<Array<AuthRefreshTokensSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<AuthRefreshTokensOrderBy>>;
  where?: InputMaybe<AuthRefreshTokensBoolExp>;
};


/** columns and relationships of "auth.refresh_token_types" */
export type AuthRefreshTokenTypesRefreshTokensAggregateArgs = {
  distinct_on?: InputMaybe<Array<AuthRefreshTokensSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<AuthRefreshTokensOrderBy>>;
  where?: InputMaybe<AuthRefreshTokensBoolExp>;
};

/** aggregated selection of "auth.refresh_token_types" */
export type AuthRefreshTokenTypesAggregate = {
  __typename?: 'authRefreshTokenTypes_aggregate';
  aggregate?: Maybe<AuthRefreshTokenTypesAggregateFields>;
  nodes: Array<AuthRefreshTokenTypes>;
};

/** aggregate fields of "auth.refresh_token_types" */
export type AuthRefreshTokenTypesAggregateFields = {
  __typename?: 'authRefreshTokenTypes_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<AuthRefreshTokenTypesMaxFields>;
  min?: Maybe<AuthRefreshTokenTypesMinFields>;
};


/** aggregate fields of "auth.refresh_token_types" */
export type AuthRefreshTokenTypesAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<AuthRefreshTokenTypesSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "auth.refresh_token_types". All fields are combined with a logical 'AND'. */
export type AuthRefreshTokenTypesBoolExp = {
  _and?: InputMaybe<Array<AuthRefreshTokenTypesBoolExp>>;
  _not?: InputMaybe<AuthRefreshTokenTypesBoolExp>;
  _or?: InputMaybe<Array<AuthRefreshTokenTypesBoolExp>>;
  comment?: InputMaybe<StringComparisonExp>;
  refreshTokens?: InputMaybe<AuthRefreshTokensBoolExp>;
  refreshTokens_aggregate?: InputMaybe<AuthRefreshTokensAggregateBoolExp>;
  value?: InputMaybe<StringComparisonExp>;
};

/** unique or primary key constraints on table "auth.refresh_token_types" */
export enum AuthRefreshTokenTypesConstraint {
  /** unique or primary key constraint on columns "value" */
  REFRESH_TOKEN_TYPES_PKEY = 'refresh_token_types_pkey'
}

export enum AuthRefreshTokenTypesEnum {
  /** Personal access token */
  PAT = 'pat',
  /** Regular refresh token */
  REGULAR = 'regular'
}

/** Boolean expression to compare columns of type "authRefreshTokenTypes_enum". All fields are combined with logical 'AND'. */
export type AuthRefreshTokenTypesEnumComparisonExp = {
  _eq?: InputMaybe<AuthRefreshTokenTypesEnum>;
  _in?: InputMaybe<Array<AuthRefreshTokenTypesEnum>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _neq?: InputMaybe<AuthRefreshTokenTypesEnum>;
  _nin?: InputMaybe<Array<AuthRefreshTokenTypesEnum>>;
};

/** input type for inserting data into table "auth.refresh_token_types" */
export type AuthRefreshTokenTypesInsertInput = {
  comment?: InputMaybe<Scalars['String']['input']>;
  refreshTokens?: InputMaybe<AuthRefreshTokensArrRelInsertInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type AuthRefreshTokenTypesMaxFields = {
  __typename?: 'authRefreshTokenTypes_max_fields';
  comment?: Maybe<Scalars['String']['output']>;
  value?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type AuthRefreshTokenTypesMinFields = {
  __typename?: 'authRefreshTokenTypes_min_fields';
  comment?: Maybe<Scalars['String']['output']>;
  value?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "auth.refresh_token_types" */
export type AuthRefreshTokenTypesMutationResponse = {
  __typename?: 'authRefreshTokenTypes_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<AuthRefreshTokenTypes>;
};

/** on_conflict condition type for table "auth.refresh_token_types" */
export type AuthRefreshTokenTypesOnConflict = {
  constraint: AuthRefreshTokenTypesConstraint;
  update_columns?: Array<AuthRefreshTokenTypesUpdateColumn>;
  where?: InputMaybe<AuthRefreshTokenTypesBoolExp>;
};

/** Ordering options when selecting data from "auth.refresh_token_types". */
export type AuthRefreshTokenTypesOrderBy = {
  comment?: InputMaybe<OrderBy>;
  refreshTokens_aggregate?: InputMaybe<AuthRefreshTokensAggregateOrderBy>;
  value?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: auth.refresh_token_types */
export type AuthRefreshTokenTypesPkColumnsInput = {
  value: Scalars['String']['input'];
};

/** select columns of table "auth.refresh_token_types" */
export enum AuthRefreshTokenTypesSelectColumn {
  /** column name */
  COMMENT = 'comment',
  /** column name */
  VALUE = 'value'
}

/** input type for updating data in table "auth.refresh_token_types" */
export type AuthRefreshTokenTypesSetInput = {
  comment?: InputMaybe<Scalars['String']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "authRefreshTokenTypes" */
export type AuthRefreshTokenTypesStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: AuthRefreshTokenTypesStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type AuthRefreshTokenTypesStreamCursorValueInput = {
  comment?: InputMaybe<Scalars['String']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "auth.refresh_token_types" */
export enum AuthRefreshTokenTypesUpdateColumn {
  /** column name */
  COMMENT = 'comment',
  /** column name */
  VALUE = 'value'
}

export type AuthRefreshTokenTypesUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<AuthRefreshTokenTypesSetInput>;
  /** filter the rows which have to be updated */
  where: AuthRefreshTokenTypesBoolExp;
};

/** User refresh tokens. Hasura auth uses them to rotate new access tokens as long as the refresh token is not expired. Don't modify its structure as Hasura Auth relies on it to function properly. */
export type AuthRefreshTokens = {
  __typename?: 'authRefreshTokens';
  createdAt: Scalars['timestamptz']['output'];
  expiresAt: Scalars['timestamptz']['output'];
  id: Scalars['uuid']['output'];
  metadata?: Maybe<Scalars['jsonb']['output']>;
  refreshTokenHash?: Maybe<Scalars['String']['output']>;
  type: AuthRefreshTokenTypesEnum;
  /** An object relationship */
  user: Users;
  userId: Scalars['uuid']['output'];
};


/** User refresh tokens. Hasura auth uses them to rotate new access tokens as long as the refresh token is not expired. Don't modify its structure as Hasura Auth relies on it to function properly. */
export type AuthRefreshTokensMetadataArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};

/** aggregated selection of "auth.refresh_tokens" */
export type AuthRefreshTokensAggregate = {
  __typename?: 'authRefreshTokens_aggregate';
  aggregate?: Maybe<AuthRefreshTokensAggregateFields>;
  nodes: Array<AuthRefreshTokens>;
};

export type AuthRefreshTokensAggregateBoolExp = {
  count?: InputMaybe<AuthRefreshTokensAggregateBoolExpCount>;
};

export type AuthRefreshTokensAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<AuthRefreshTokensSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<AuthRefreshTokensBoolExp>;
  predicate: IntComparisonExp;
};

/** aggregate fields of "auth.refresh_tokens" */
export type AuthRefreshTokensAggregateFields = {
  __typename?: 'authRefreshTokens_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<AuthRefreshTokensMaxFields>;
  min?: Maybe<AuthRefreshTokensMinFields>;
};


/** aggregate fields of "auth.refresh_tokens" */
export type AuthRefreshTokensAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<AuthRefreshTokensSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "auth.refresh_tokens" */
export type AuthRefreshTokensAggregateOrderBy = {
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<AuthRefreshTokensMaxOrderBy>;
  min?: InputMaybe<AuthRefreshTokensMinOrderBy>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type AuthRefreshTokensAppendInput = {
  metadata?: InputMaybe<Scalars['jsonb']['input']>;
};

/** input type for inserting array relation for remote table "auth.refresh_tokens" */
export type AuthRefreshTokensArrRelInsertInput = {
  data: Array<AuthRefreshTokensInsertInput>;
  /** upsert condition */
  on_conflict?: InputMaybe<AuthRefreshTokensOnConflict>;
};

/** Boolean expression to filter rows from the table "auth.refresh_tokens". All fields are combined with a logical 'AND'. */
export type AuthRefreshTokensBoolExp = {
  _and?: InputMaybe<Array<AuthRefreshTokensBoolExp>>;
  _not?: InputMaybe<AuthRefreshTokensBoolExp>;
  _or?: InputMaybe<Array<AuthRefreshTokensBoolExp>>;
  createdAt?: InputMaybe<TimestamptzComparisonExp>;
  expiresAt?: InputMaybe<TimestamptzComparisonExp>;
  id?: InputMaybe<UuidComparisonExp>;
  metadata?: InputMaybe<JsonbComparisonExp>;
  refreshTokenHash?: InputMaybe<StringComparisonExp>;
  type?: InputMaybe<AuthRefreshTokenTypesEnumComparisonExp>;
  user?: InputMaybe<UsersBoolExp>;
  userId?: InputMaybe<UuidComparisonExp>;
};

/** unique or primary key constraints on table "auth.refresh_tokens" */
export enum AuthRefreshTokensConstraint {
  /** unique or primary key constraint on columns "id" */
  REFRESH_TOKENS_PKEY = 'refresh_tokens_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type AuthRefreshTokensDeleteAtPathInput = {
  metadata?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type AuthRefreshTokensDeleteElemInput = {
  metadata?: InputMaybe<Scalars['Int']['input']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type AuthRefreshTokensDeleteKeyInput = {
  metadata?: InputMaybe<Scalars['String']['input']>;
};

/** input type for inserting data into table "auth.refresh_tokens" */
export type AuthRefreshTokensInsertInput = {
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  expiresAt?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  metadata?: InputMaybe<Scalars['jsonb']['input']>;
  refreshTokenHash?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<AuthRefreshTokenTypesEnum>;
  user?: InputMaybe<UsersObjRelInsertInput>;
  userId?: InputMaybe<Scalars['uuid']['input']>;
};

/** aggregate max on columns */
export type AuthRefreshTokensMaxFields = {
  __typename?: 'authRefreshTokens_max_fields';
  createdAt?: Maybe<Scalars['timestamptz']['output']>;
  expiresAt?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  refreshTokenHash?: Maybe<Scalars['String']['output']>;
  userId?: Maybe<Scalars['uuid']['output']>;
};

/** order by max() on columns of table "auth.refresh_tokens" */
export type AuthRefreshTokensMaxOrderBy = {
  createdAt?: InputMaybe<OrderBy>;
  expiresAt?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  refreshTokenHash?: InputMaybe<OrderBy>;
  userId?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type AuthRefreshTokensMinFields = {
  __typename?: 'authRefreshTokens_min_fields';
  createdAt?: Maybe<Scalars['timestamptz']['output']>;
  expiresAt?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  refreshTokenHash?: Maybe<Scalars['String']['output']>;
  userId?: Maybe<Scalars['uuid']['output']>;
};

/** order by min() on columns of table "auth.refresh_tokens" */
export type AuthRefreshTokensMinOrderBy = {
  createdAt?: InputMaybe<OrderBy>;
  expiresAt?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  refreshTokenHash?: InputMaybe<OrderBy>;
  userId?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "auth.refresh_tokens" */
export type AuthRefreshTokensMutationResponse = {
  __typename?: 'authRefreshTokens_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<AuthRefreshTokens>;
};

/** on_conflict condition type for table "auth.refresh_tokens" */
export type AuthRefreshTokensOnConflict = {
  constraint: AuthRefreshTokensConstraint;
  update_columns?: Array<AuthRefreshTokensUpdateColumn>;
  where?: InputMaybe<AuthRefreshTokensBoolExp>;
};

/** Ordering options when selecting data from "auth.refresh_tokens". */
export type AuthRefreshTokensOrderBy = {
  createdAt?: InputMaybe<OrderBy>;
  expiresAt?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  metadata?: InputMaybe<OrderBy>;
  refreshTokenHash?: InputMaybe<OrderBy>;
  type?: InputMaybe<OrderBy>;
  user?: InputMaybe<UsersOrderBy>;
  userId?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: auth.refresh_tokens */
export type AuthRefreshTokensPkColumnsInput = {
  id: Scalars['uuid']['input'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type AuthRefreshTokensPrependInput = {
  metadata?: InputMaybe<Scalars['jsonb']['input']>;
};

/** select columns of table "auth.refresh_tokens" */
export enum AuthRefreshTokensSelectColumn {
  /** column name */
  CREATEDAT = 'createdAt',
  /** column name */
  EXPIRESAT = 'expiresAt',
  /** column name */
  ID = 'id',
  /** column name */
  METADATA = 'metadata',
  /** column name */
  REFRESHTOKENHASH = 'refreshTokenHash',
  /** column name */
  TYPE = 'type',
  /** column name */
  USERID = 'userId'
}

/** input type for updating data in table "auth.refresh_tokens" */
export type AuthRefreshTokensSetInput = {
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  expiresAt?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  metadata?: InputMaybe<Scalars['jsonb']['input']>;
  refreshTokenHash?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<AuthRefreshTokenTypesEnum>;
  userId?: InputMaybe<Scalars['uuid']['input']>;
};

/** Streaming cursor of the table "authRefreshTokens" */
export type AuthRefreshTokensStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: AuthRefreshTokensStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type AuthRefreshTokensStreamCursorValueInput = {
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  expiresAt?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  metadata?: InputMaybe<Scalars['jsonb']['input']>;
  refreshTokenHash?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<AuthRefreshTokenTypesEnum>;
  userId?: InputMaybe<Scalars['uuid']['input']>;
};

/** update columns of table "auth.refresh_tokens" */
export enum AuthRefreshTokensUpdateColumn {
  /** column name */
  CREATEDAT = 'createdAt',
  /** column name */
  EXPIRESAT = 'expiresAt',
  /** column name */
  ID = 'id',
  /** column name */
  METADATA = 'metadata',
  /** column name */
  REFRESHTOKENHASH = 'refreshTokenHash',
  /** column name */
  TYPE = 'type',
  /** column name */
  USERID = 'userId'
}

export type AuthRefreshTokensUpdates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<AuthRefreshTokensAppendInput>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<AuthRefreshTokensDeleteAtPathInput>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<AuthRefreshTokensDeleteElemInput>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<AuthRefreshTokensDeleteKeyInput>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<AuthRefreshTokensPrependInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<AuthRefreshTokensSetInput>;
  /** filter the rows which have to be updated */
  where: AuthRefreshTokensBoolExp;
};

/** Persistent Hasura roles for users. Don't modify its structure as Hasura Auth relies on it to function properly. */
export type AuthRoles = {
  __typename?: 'authRoles';
  role: Scalars['String']['output'];
  /** An array relationship */
  userRoles: Array<AuthUserRoles>;
  /** An aggregate relationship */
  userRoles_aggregate: AuthUserRolesAggregate;
  /** An array relationship */
  usersByDefaultRole: Array<Users>;
  /** An aggregate relationship */
  usersByDefaultRole_aggregate: UsersAggregate;
};


/** Persistent Hasura roles for users. Don't modify its structure as Hasura Auth relies on it to function properly. */
export type AuthRolesUserRolesArgs = {
  distinct_on?: InputMaybe<Array<AuthUserRolesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<AuthUserRolesOrderBy>>;
  where?: InputMaybe<AuthUserRolesBoolExp>;
};


/** Persistent Hasura roles for users. Don't modify its structure as Hasura Auth relies on it to function properly. */
export type AuthRolesUserRolesAggregateArgs = {
  distinct_on?: InputMaybe<Array<AuthUserRolesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<AuthUserRolesOrderBy>>;
  where?: InputMaybe<AuthUserRolesBoolExp>;
};


/** Persistent Hasura roles for users. Don't modify its structure as Hasura Auth relies on it to function properly. */
export type AuthRolesUsersByDefaultRoleArgs = {
  distinct_on?: InputMaybe<Array<UsersSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<UsersOrderBy>>;
  where?: InputMaybe<UsersBoolExp>;
};


/** Persistent Hasura roles for users. Don't modify its structure as Hasura Auth relies on it to function properly. */
export type AuthRolesUsersByDefaultRoleAggregateArgs = {
  distinct_on?: InputMaybe<Array<UsersSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<UsersOrderBy>>;
  where?: InputMaybe<UsersBoolExp>;
};

/** aggregated selection of "auth.roles" */
export type AuthRolesAggregate = {
  __typename?: 'authRoles_aggregate';
  aggregate?: Maybe<AuthRolesAggregateFields>;
  nodes: Array<AuthRoles>;
};

/** aggregate fields of "auth.roles" */
export type AuthRolesAggregateFields = {
  __typename?: 'authRoles_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<AuthRolesMaxFields>;
  min?: Maybe<AuthRolesMinFields>;
};


/** aggregate fields of "auth.roles" */
export type AuthRolesAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<AuthRolesSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "auth.roles". All fields are combined with a logical 'AND'. */
export type AuthRolesBoolExp = {
  _and?: InputMaybe<Array<AuthRolesBoolExp>>;
  _not?: InputMaybe<AuthRolesBoolExp>;
  _or?: InputMaybe<Array<AuthRolesBoolExp>>;
  role?: InputMaybe<StringComparisonExp>;
  userRoles?: InputMaybe<AuthUserRolesBoolExp>;
  userRoles_aggregate?: InputMaybe<AuthUserRolesAggregateBoolExp>;
  usersByDefaultRole?: InputMaybe<UsersBoolExp>;
  usersByDefaultRole_aggregate?: InputMaybe<UsersAggregateBoolExp>;
};

/** unique or primary key constraints on table "auth.roles" */
export enum AuthRolesConstraint {
  /** unique or primary key constraint on columns "role" */
  ROLES_PKEY = 'roles_pkey'
}

/** input type for inserting data into table "auth.roles" */
export type AuthRolesInsertInput = {
  role?: InputMaybe<Scalars['String']['input']>;
  userRoles?: InputMaybe<AuthUserRolesArrRelInsertInput>;
  usersByDefaultRole?: InputMaybe<UsersArrRelInsertInput>;
};

/** aggregate max on columns */
export type AuthRolesMaxFields = {
  __typename?: 'authRoles_max_fields';
  role?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type AuthRolesMinFields = {
  __typename?: 'authRoles_min_fields';
  role?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "auth.roles" */
export type AuthRolesMutationResponse = {
  __typename?: 'authRoles_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<AuthRoles>;
};

/** input type for inserting object relation for remote table "auth.roles" */
export type AuthRolesObjRelInsertInput = {
  data: AuthRolesInsertInput;
  /** upsert condition */
  on_conflict?: InputMaybe<AuthRolesOnConflict>;
};

/** on_conflict condition type for table "auth.roles" */
export type AuthRolesOnConflict = {
  constraint: AuthRolesConstraint;
  update_columns?: Array<AuthRolesUpdateColumn>;
  where?: InputMaybe<AuthRolesBoolExp>;
};

/** Ordering options when selecting data from "auth.roles". */
export type AuthRolesOrderBy = {
  role?: InputMaybe<OrderBy>;
  userRoles_aggregate?: InputMaybe<AuthUserRolesAggregateOrderBy>;
  usersByDefaultRole_aggregate?: InputMaybe<UsersAggregateOrderBy>;
};

/** primary key columns input for table: auth.roles */
export type AuthRolesPkColumnsInput = {
  role: Scalars['String']['input'];
};

/** select columns of table "auth.roles" */
export enum AuthRolesSelectColumn {
  /** column name */
  ROLE = 'role'
}

/** input type for updating data in table "auth.roles" */
export type AuthRolesSetInput = {
  role?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "authRoles" */
export type AuthRolesStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: AuthRolesStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type AuthRolesStreamCursorValueInput = {
  role?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "auth.roles" */
export enum AuthRolesUpdateColumn {
  /** column name */
  ROLE = 'role'
}

export type AuthRolesUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<AuthRolesSetInput>;
  /** filter the rows which have to be updated */
  where: AuthRolesBoolExp;
};

/** Active providers for a given user. Don't modify its structure as Hasura Auth relies on it to function properly. */
export type AuthUserProviders = {
  __typename?: 'authUserProviders';
  accessToken: Scalars['String']['output'];
  createdAt: Scalars['timestamptz']['output'];
  id: Scalars['uuid']['output'];
  /** An object relationship */
  provider: AuthProviders;
  providerId: Scalars['String']['output'];
  providerUserId: Scalars['String']['output'];
  refreshToken?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['timestamptz']['output'];
  /** An object relationship */
  user: Users;
  userId: Scalars['uuid']['output'];
};

/** aggregated selection of "auth.user_providers" */
export type AuthUserProvidersAggregate = {
  __typename?: 'authUserProviders_aggregate';
  aggregate?: Maybe<AuthUserProvidersAggregateFields>;
  nodes: Array<AuthUserProviders>;
};

export type AuthUserProvidersAggregateBoolExp = {
  count?: InputMaybe<AuthUserProvidersAggregateBoolExpCount>;
};

export type AuthUserProvidersAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<AuthUserProvidersSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<AuthUserProvidersBoolExp>;
  predicate: IntComparisonExp;
};

/** aggregate fields of "auth.user_providers" */
export type AuthUserProvidersAggregateFields = {
  __typename?: 'authUserProviders_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<AuthUserProvidersMaxFields>;
  min?: Maybe<AuthUserProvidersMinFields>;
};


/** aggregate fields of "auth.user_providers" */
export type AuthUserProvidersAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<AuthUserProvidersSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "auth.user_providers" */
export type AuthUserProvidersAggregateOrderBy = {
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<AuthUserProvidersMaxOrderBy>;
  min?: InputMaybe<AuthUserProvidersMinOrderBy>;
};

/** input type for inserting array relation for remote table "auth.user_providers" */
export type AuthUserProvidersArrRelInsertInput = {
  data: Array<AuthUserProvidersInsertInput>;
  /** upsert condition */
  on_conflict?: InputMaybe<AuthUserProvidersOnConflict>;
};

/** Boolean expression to filter rows from the table "auth.user_providers". All fields are combined with a logical 'AND'. */
export type AuthUserProvidersBoolExp = {
  _and?: InputMaybe<Array<AuthUserProvidersBoolExp>>;
  _not?: InputMaybe<AuthUserProvidersBoolExp>;
  _or?: InputMaybe<Array<AuthUserProvidersBoolExp>>;
  accessToken?: InputMaybe<StringComparisonExp>;
  createdAt?: InputMaybe<TimestamptzComparisonExp>;
  id?: InputMaybe<UuidComparisonExp>;
  provider?: InputMaybe<AuthProvidersBoolExp>;
  providerId?: InputMaybe<StringComparisonExp>;
  providerUserId?: InputMaybe<StringComparisonExp>;
  refreshToken?: InputMaybe<StringComparisonExp>;
  updatedAt?: InputMaybe<TimestamptzComparisonExp>;
  user?: InputMaybe<UsersBoolExp>;
  userId?: InputMaybe<UuidComparisonExp>;
};

/** unique or primary key constraints on table "auth.user_providers" */
export enum AuthUserProvidersConstraint {
  /** unique or primary key constraint on columns "id" */
  USER_PROVIDERS_PKEY = 'user_providers_pkey',
  /** unique or primary key constraint on columns "provider_user_id", "provider_id" */
  USER_PROVIDERS_PROVIDER_ID_PROVIDER_USER_ID_KEY = 'user_providers_provider_id_provider_user_id_key',
  /** unique or primary key constraint on columns "user_id", "provider_id" */
  USER_PROVIDERS_USER_ID_PROVIDER_ID_KEY = 'user_providers_user_id_provider_id_key'
}

/** input type for inserting data into table "auth.user_providers" */
export type AuthUserProvidersInsertInput = {
  accessToken?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  provider?: InputMaybe<AuthProvidersObjRelInsertInput>;
  providerId?: InputMaybe<Scalars['String']['input']>;
  providerUserId?: InputMaybe<Scalars['String']['input']>;
  refreshToken?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']['input']>;
  user?: InputMaybe<UsersObjRelInsertInput>;
  userId?: InputMaybe<Scalars['uuid']['input']>;
};

/** aggregate max on columns */
export type AuthUserProvidersMaxFields = {
  __typename?: 'authUserProviders_max_fields';
  accessToken?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  providerId?: Maybe<Scalars['String']['output']>;
  providerUserId?: Maybe<Scalars['String']['output']>;
  refreshToken?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['timestamptz']['output']>;
  userId?: Maybe<Scalars['uuid']['output']>;
};

/** order by max() on columns of table "auth.user_providers" */
export type AuthUserProvidersMaxOrderBy = {
  accessToken?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  providerId?: InputMaybe<OrderBy>;
  providerUserId?: InputMaybe<OrderBy>;
  refreshToken?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
  userId?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type AuthUserProvidersMinFields = {
  __typename?: 'authUserProviders_min_fields';
  accessToken?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  providerId?: Maybe<Scalars['String']['output']>;
  providerUserId?: Maybe<Scalars['String']['output']>;
  refreshToken?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['timestamptz']['output']>;
  userId?: Maybe<Scalars['uuid']['output']>;
};

/** order by min() on columns of table "auth.user_providers" */
export type AuthUserProvidersMinOrderBy = {
  accessToken?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  providerId?: InputMaybe<OrderBy>;
  providerUserId?: InputMaybe<OrderBy>;
  refreshToken?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
  userId?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "auth.user_providers" */
export type AuthUserProvidersMutationResponse = {
  __typename?: 'authUserProviders_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<AuthUserProviders>;
};

/** on_conflict condition type for table "auth.user_providers" */
export type AuthUserProvidersOnConflict = {
  constraint: AuthUserProvidersConstraint;
  update_columns?: Array<AuthUserProvidersUpdateColumn>;
  where?: InputMaybe<AuthUserProvidersBoolExp>;
};

/** Ordering options when selecting data from "auth.user_providers". */
export type AuthUserProvidersOrderBy = {
  accessToken?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  provider?: InputMaybe<AuthProvidersOrderBy>;
  providerId?: InputMaybe<OrderBy>;
  providerUserId?: InputMaybe<OrderBy>;
  refreshToken?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
  user?: InputMaybe<UsersOrderBy>;
  userId?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: auth.user_providers */
export type AuthUserProvidersPkColumnsInput = {
  id: Scalars['uuid']['input'];
};

/** select columns of table "auth.user_providers" */
export enum AuthUserProvidersSelectColumn {
  /** column name */
  ACCESSTOKEN = 'accessToken',
  /** column name */
  CREATEDAT = 'createdAt',
  /** column name */
  ID = 'id',
  /** column name */
  PROVIDERID = 'providerId',
  /** column name */
  PROVIDERUSERID = 'providerUserId',
  /** column name */
  REFRESHTOKEN = 'refreshToken',
  /** column name */
  UPDATEDAT = 'updatedAt',
  /** column name */
  USERID = 'userId'
}

/** input type for updating data in table "auth.user_providers" */
export type AuthUserProvidersSetInput = {
  accessToken?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  providerId?: InputMaybe<Scalars['String']['input']>;
  providerUserId?: InputMaybe<Scalars['String']['input']>;
  refreshToken?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']['input']>;
  userId?: InputMaybe<Scalars['uuid']['input']>;
};

/** Streaming cursor of the table "authUserProviders" */
export type AuthUserProvidersStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: AuthUserProvidersStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type AuthUserProvidersStreamCursorValueInput = {
  accessToken?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  providerId?: InputMaybe<Scalars['String']['input']>;
  providerUserId?: InputMaybe<Scalars['String']['input']>;
  refreshToken?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']['input']>;
  userId?: InputMaybe<Scalars['uuid']['input']>;
};

/** update columns of table "auth.user_providers" */
export enum AuthUserProvidersUpdateColumn {
  /** column name */
  ACCESSTOKEN = 'accessToken',
  /** column name */
  CREATEDAT = 'createdAt',
  /** column name */
  ID = 'id',
  /** column name */
  PROVIDERID = 'providerId',
  /** column name */
  PROVIDERUSERID = 'providerUserId',
  /** column name */
  REFRESHTOKEN = 'refreshToken',
  /** column name */
  UPDATEDAT = 'updatedAt',
  /** column name */
  USERID = 'userId'
}

export type AuthUserProvidersUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<AuthUserProvidersSetInput>;
  /** filter the rows which have to be updated */
  where: AuthUserProvidersBoolExp;
};

/** Roles of users. Don't modify its structure as Hasura Auth relies on it to function properly. */
export type AuthUserRoles = {
  __typename?: 'authUserRoles';
  createdAt: Scalars['timestamptz']['output'];
  id: Scalars['uuid']['output'];
  role: Scalars['String']['output'];
  /** An object relationship */
  roleByRole: AuthRoles;
  /** An object relationship */
  user: Users;
  userId: Scalars['uuid']['output'];
};

/** aggregated selection of "auth.user_roles" */
export type AuthUserRolesAggregate = {
  __typename?: 'authUserRoles_aggregate';
  aggregate?: Maybe<AuthUserRolesAggregateFields>;
  nodes: Array<AuthUserRoles>;
};

export type AuthUserRolesAggregateBoolExp = {
  count?: InputMaybe<AuthUserRolesAggregateBoolExpCount>;
};

export type AuthUserRolesAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<AuthUserRolesSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<AuthUserRolesBoolExp>;
  predicate: IntComparisonExp;
};

/** aggregate fields of "auth.user_roles" */
export type AuthUserRolesAggregateFields = {
  __typename?: 'authUserRoles_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<AuthUserRolesMaxFields>;
  min?: Maybe<AuthUserRolesMinFields>;
};


/** aggregate fields of "auth.user_roles" */
export type AuthUserRolesAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<AuthUserRolesSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "auth.user_roles" */
export type AuthUserRolesAggregateOrderBy = {
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<AuthUserRolesMaxOrderBy>;
  min?: InputMaybe<AuthUserRolesMinOrderBy>;
};

/** input type for inserting array relation for remote table "auth.user_roles" */
export type AuthUserRolesArrRelInsertInput = {
  data: Array<AuthUserRolesInsertInput>;
  /** upsert condition */
  on_conflict?: InputMaybe<AuthUserRolesOnConflict>;
};

/** Boolean expression to filter rows from the table "auth.user_roles". All fields are combined with a logical 'AND'. */
export type AuthUserRolesBoolExp = {
  _and?: InputMaybe<Array<AuthUserRolesBoolExp>>;
  _not?: InputMaybe<AuthUserRolesBoolExp>;
  _or?: InputMaybe<Array<AuthUserRolesBoolExp>>;
  createdAt?: InputMaybe<TimestamptzComparisonExp>;
  id?: InputMaybe<UuidComparisonExp>;
  role?: InputMaybe<StringComparisonExp>;
  roleByRole?: InputMaybe<AuthRolesBoolExp>;
  user?: InputMaybe<UsersBoolExp>;
  userId?: InputMaybe<UuidComparisonExp>;
};

/** unique or primary key constraints on table "auth.user_roles" */
export enum AuthUserRolesConstraint {
  /** unique or primary key constraint on columns "id" */
  USER_ROLES_PKEY = 'user_roles_pkey',
  /** unique or primary key constraint on columns "user_id", "role" */
  USER_ROLES_USER_ID_ROLE_KEY = 'user_roles_user_id_role_key'
}

/** input type for inserting data into table "auth.user_roles" */
export type AuthUserRolesInsertInput = {
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  role?: InputMaybe<Scalars['String']['input']>;
  roleByRole?: InputMaybe<AuthRolesObjRelInsertInput>;
  user?: InputMaybe<UsersObjRelInsertInput>;
  userId?: InputMaybe<Scalars['uuid']['input']>;
};

/** aggregate max on columns */
export type AuthUserRolesMaxFields = {
  __typename?: 'authUserRoles_max_fields';
  createdAt?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  role?: Maybe<Scalars['String']['output']>;
  userId?: Maybe<Scalars['uuid']['output']>;
};

/** order by max() on columns of table "auth.user_roles" */
export type AuthUserRolesMaxOrderBy = {
  createdAt?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  role?: InputMaybe<OrderBy>;
  userId?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type AuthUserRolesMinFields = {
  __typename?: 'authUserRoles_min_fields';
  createdAt?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  role?: Maybe<Scalars['String']['output']>;
  userId?: Maybe<Scalars['uuid']['output']>;
};

/** order by min() on columns of table "auth.user_roles" */
export type AuthUserRolesMinOrderBy = {
  createdAt?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  role?: InputMaybe<OrderBy>;
  userId?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "auth.user_roles" */
export type AuthUserRolesMutationResponse = {
  __typename?: 'authUserRoles_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<AuthUserRoles>;
};

/** on_conflict condition type for table "auth.user_roles" */
export type AuthUserRolesOnConflict = {
  constraint: AuthUserRolesConstraint;
  update_columns?: Array<AuthUserRolesUpdateColumn>;
  where?: InputMaybe<AuthUserRolesBoolExp>;
};

/** Ordering options when selecting data from "auth.user_roles". */
export type AuthUserRolesOrderBy = {
  createdAt?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  role?: InputMaybe<OrderBy>;
  roleByRole?: InputMaybe<AuthRolesOrderBy>;
  user?: InputMaybe<UsersOrderBy>;
  userId?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: auth.user_roles */
export type AuthUserRolesPkColumnsInput = {
  id: Scalars['uuid']['input'];
};

/** select columns of table "auth.user_roles" */
export enum AuthUserRolesSelectColumn {
  /** column name */
  CREATEDAT = 'createdAt',
  /** column name */
  ID = 'id',
  /** column name */
  ROLE = 'role',
  /** column name */
  USERID = 'userId'
}

/** input type for updating data in table "auth.user_roles" */
export type AuthUserRolesSetInput = {
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  role?: InputMaybe<Scalars['String']['input']>;
  userId?: InputMaybe<Scalars['uuid']['input']>;
};

/** Streaming cursor of the table "authUserRoles" */
export type AuthUserRolesStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: AuthUserRolesStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type AuthUserRolesStreamCursorValueInput = {
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  role?: InputMaybe<Scalars['String']['input']>;
  userId?: InputMaybe<Scalars['uuid']['input']>;
};

/** update columns of table "auth.user_roles" */
export enum AuthUserRolesUpdateColumn {
  /** column name */
  CREATEDAT = 'createdAt',
  /** column name */
  ID = 'id',
  /** column name */
  ROLE = 'role',
  /** column name */
  USERID = 'userId'
}

export type AuthUserRolesUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<AuthUserRolesSetInput>;
  /** filter the rows which have to be updated */
  where: AuthUserRolesBoolExp;
};

/** User webauthn security keys. Don't modify its structure as Hasura Auth relies on it to function properly. */
export type AuthUserSecurityKeys = {
  __typename?: 'authUserSecurityKeys';
  counter: Scalars['bigint']['output'];
  credentialId: Scalars['String']['output'];
  credentialPublicKey?: Maybe<Scalars['bytea']['output']>;
  id: Scalars['uuid']['output'];
  nickname?: Maybe<Scalars['String']['output']>;
  transports: Scalars['String']['output'];
  /** An object relationship */
  user: Users;
  userId: Scalars['uuid']['output'];
};

/** aggregated selection of "auth.user_security_keys" */
export type AuthUserSecurityKeysAggregate = {
  __typename?: 'authUserSecurityKeys_aggregate';
  aggregate?: Maybe<AuthUserSecurityKeysAggregateFields>;
  nodes: Array<AuthUserSecurityKeys>;
};

export type AuthUserSecurityKeysAggregateBoolExp = {
  count?: InputMaybe<AuthUserSecurityKeysAggregateBoolExpCount>;
};

export type AuthUserSecurityKeysAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<AuthUserSecurityKeysSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<AuthUserSecurityKeysBoolExp>;
  predicate: IntComparisonExp;
};

/** aggregate fields of "auth.user_security_keys" */
export type AuthUserSecurityKeysAggregateFields = {
  __typename?: 'authUserSecurityKeys_aggregate_fields';
  avg?: Maybe<AuthUserSecurityKeysAvgFields>;
  count: Scalars['Int']['output'];
  max?: Maybe<AuthUserSecurityKeysMaxFields>;
  min?: Maybe<AuthUserSecurityKeysMinFields>;
  stddev?: Maybe<AuthUserSecurityKeysStddevFields>;
  stddev_pop?: Maybe<AuthUserSecurityKeysStddevPopFields>;
  stddev_samp?: Maybe<AuthUserSecurityKeysStddevSampFields>;
  sum?: Maybe<AuthUserSecurityKeysSumFields>;
  var_pop?: Maybe<AuthUserSecurityKeysVarPopFields>;
  var_samp?: Maybe<AuthUserSecurityKeysVarSampFields>;
  variance?: Maybe<AuthUserSecurityKeysVarianceFields>;
};


/** aggregate fields of "auth.user_security_keys" */
export type AuthUserSecurityKeysAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<AuthUserSecurityKeysSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "auth.user_security_keys" */
export type AuthUserSecurityKeysAggregateOrderBy = {
  avg?: InputMaybe<AuthUserSecurityKeysAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<AuthUserSecurityKeysMaxOrderBy>;
  min?: InputMaybe<AuthUserSecurityKeysMinOrderBy>;
  stddev?: InputMaybe<AuthUserSecurityKeysStddevOrderBy>;
  stddev_pop?: InputMaybe<AuthUserSecurityKeysStddevPopOrderBy>;
  stddev_samp?: InputMaybe<AuthUserSecurityKeysStddevSampOrderBy>;
  sum?: InputMaybe<AuthUserSecurityKeysSumOrderBy>;
  var_pop?: InputMaybe<AuthUserSecurityKeysVarPopOrderBy>;
  var_samp?: InputMaybe<AuthUserSecurityKeysVarSampOrderBy>;
  variance?: InputMaybe<AuthUserSecurityKeysVarianceOrderBy>;
};

/** input type for inserting array relation for remote table "auth.user_security_keys" */
export type AuthUserSecurityKeysArrRelInsertInput = {
  data: Array<AuthUserSecurityKeysInsertInput>;
  /** upsert condition */
  on_conflict?: InputMaybe<AuthUserSecurityKeysOnConflict>;
};

/** aggregate avg on columns */
export type AuthUserSecurityKeysAvgFields = {
  __typename?: 'authUserSecurityKeys_avg_fields';
  counter?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "auth.user_security_keys" */
export type AuthUserSecurityKeysAvgOrderBy = {
  counter?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "auth.user_security_keys". All fields are combined with a logical 'AND'. */
export type AuthUserSecurityKeysBoolExp = {
  _and?: InputMaybe<Array<AuthUserSecurityKeysBoolExp>>;
  _not?: InputMaybe<AuthUserSecurityKeysBoolExp>;
  _or?: InputMaybe<Array<AuthUserSecurityKeysBoolExp>>;
  counter?: InputMaybe<BigintComparisonExp>;
  credentialId?: InputMaybe<StringComparisonExp>;
  credentialPublicKey?: InputMaybe<ByteaComparisonExp>;
  id?: InputMaybe<UuidComparisonExp>;
  nickname?: InputMaybe<StringComparisonExp>;
  transports?: InputMaybe<StringComparisonExp>;
  user?: InputMaybe<UsersBoolExp>;
  userId?: InputMaybe<UuidComparisonExp>;
};

/** unique or primary key constraints on table "auth.user_security_keys" */
export enum AuthUserSecurityKeysConstraint {
  /** unique or primary key constraint on columns "credential_id" */
  USER_SECURITY_KEY_CREDENTIAL_ID_KEY = 'user_security_key_credential_id_key',
  /** unique or primary key constraint on columns "id" */
  USER_SECURITY_KEYS_PKEY = 'user_security_keys_pkey'
}

/** input type for incrementing numeric columns in table "auth.user_security_keys" */
export type AuthUserSecurityKeysIncInput = {
  counter?: InputMaybe<Scalars['bigint']['input']>;
};

/** input type for inserting data into table "auth.user_security_keys" */
export type AuthUserSecurityKeysInsertInput = {
  counter?: InputMaybe<Scalars['bigint']['input']>;
  credentialId?: InputMaybe<Scalars['String']['input']>;
  credentialPublicKey?: InputMaybe<Scalars['bytea']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  nickname?: InputMaybe<Scalars['String']['input']>;
  transports?: InputMaybe<Scalars['String']['input']>;
  user?: InputMaybe<UsersObjRelInsertInput>;
  userId?: InputMaybe<Scalars['uuid']['input']>;
};

/** aggregate max on columns */
export type AuthUserSecurityKeysMaxFields = {
  __typename?: 'authUserSecurityKeys_max_fields';
  counter?: Maybe<Scalars['bigint']['output']>;
  credentialId?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  nickname?: Maybe<Scalars['String']['output']>;
  transports?: Maybe<Scalars['String']['output']>;
  userId?: Maybe<Scalars['uuid']['output']>;
};

/** order by max() on columns of table "auth.user_security_keys" */
export type AuthUserSecurityKeysMaxOrderBy = {
  counter?: InputMaybe<OrderBy>;
  credentialId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  nickname?: InputMaybe<OrderBy>;
  transports?: InputMaybe<OrderBy>;
  userId?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type AuthUserSecurityKeysMinFields = {
  __typename?: 'authUserSecurityKeys_min_fields';
  counter?: Maybe<Scalars['bigint']['output']>;
  credentialId?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  nickname?: Maybe<Scalars['String']['output']>;
  transports?: Maybe<Scalars['String']['output']>;
  userId?: Maybe<Scalars['uuid']['output']>;
};

/** order by min() on columns of table "auth.user_security_keys" */
export type AuthUserSecurityKeysMinOrderBy = {
  counter?: InputMaybe<OrderBy>;
  credentialId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  nickname?: InputMaybe<OrderBy>;
  transports?: InputMaybe<OrderBy>;
  userId?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "auth.user_security_keys" */
export type AuthUserSecurityKeysMutationResponse = {
  __typename?: 'authUserSecurityKeys_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<AuthUserSecurityKeys>;
};

/** on_conflict condition type for table "auth.user_security_keys" */
export type AuthUserSecurityKeysOnConflict = {
  constraint: AuthUserSecurityKeysConstraint;
  update_columns?: Array<AuthUserSecurityKeysUpdateColumn>;
  where?: InputMaybe<AuthUserSecurityKeysBoolExp>;
};

/** Ordering options when selecting data from "auth.user_security_keys". */
export type AuthUserSecurityKeysOrderBy = {
  counter?: InputMaybe<OrderBy>;
  credentialId?: InputMaybe<OrderBy>;
  credentialPublicKey?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  nickname?: InputMaybe<OrderBy>;
  transports?: InputMaybe<OrderBy>;
  user?: InputMaybe<UsersOrderBy>;
  userId?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: auth.user_security_keys */
export type AuthUserSecurityKeysPkColumnsInput = {
  id: Scalars['uuid']['input'];
};

/** select columns of table "auth.user_security_keys" */
export enum AuthUserSecurityKeysSelectColumn {
  /** column name */
  COUNTER = 'counter',
  /** column name */
  CREDENTIALID = 'credentialId',
  /** column name */
  CREDENTIALPUBLICKEY = 'credentialPublicKey',
  /** column name */
  ID = 'id',
  /** column name */
  NICKNAME = 'nickname',
  /** column name */
  TRANSPORTS = 'transports',
  /** column name */
  USERID = 'userId'
}

/** input type for updating data in table "auth.user_security_keys" */
export type AuthUserSecurityKeysSetInput = {
  counter?: InputMaybe<Scalars['bigint']['input']>;
  credentialId?: InputMaybe<Scalars['String']['input']>;
  credentialPublicKey?: InputMaybe<Scalars['bytea']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  nickname?: InputMaybe<Scalars['String']['input']>;
  transports?: InputMaybe<Scalars['String']['input']>;
  userId?: InputMaybe<Scalars['uuid']['input']>;
};

/** aggregate stddev on columns */
export type AuthUserSecurityKeysStddevFields = {
  __typename?: 'authUserSecurityKeys_stddev_fields';
  counter?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "auth.user_security_keys" */
export type AuthUserSecurityKeysStddevOrderBy = {
  counter?: InputMaybe<OrderBy>;
};

/** aggregate stddev_pop on columns */
export type AuthUserSecurityKeysStddevPopFields = {
  __typename?: 'authUserSecurityKeys_stddev_pop_fields';
  counter?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "auth.user_security_keys" */
export type AuthUserSecurityKeysStddevPopOrderBy = {
  counter?: InputMaybe<OrderBy>;
};

/** aggregate stddev_samp on columns */
export type AuthUserSecurityKeysStddevSampFields = {
  __typename?: 'authUserSecurityKeys_stddev_samp_fields';
  counter?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "auth.user_security_keys" */
export type AuthUserSecurityKeysStddevSampOrderBy = {
  counter?: InputMaybe<OrderBy>;
};

/** Streaming cursor of the table "authUserSecurityKeys" */
export type AuthUserSecurityKeysStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: AuthUserSecurityKeysStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type AuthUserSecurityKeysStreamCursorValueInput = {
  counter?: InputMaybe<Scalars['bigint']['input']>;
  credentialId?: InputMaybe<Scalars['String']['input']>;
  credentialPublicKey?: InputMaybe<Scalars['bytea']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  nickname?: InputMaybe<Scalars['String']['input']>;
  transports?: InputMaybe<Scalars['String']['input']>;
  userId?: InputMaybe<Scalars['uuid']['input']>;
};

/** aggregate sum on columns */
export type AuthUserSecurityKeysSumFields = {
  __typename?: 'authUserSecurityKeys_sum_fields';
  counter?: Maybe<Scalars['bigint']['output']>;
};

/** order by sum() on columns of table "auth.user_security_keys" */
export type AuthUserSecurityKeysSumOrderBy = {
  counter?: InputMaybe<OrderBy>;
};

/** update columns of table "auth.user_security_keys" */
export enum AuthUserSecurityKeysUpdateColumn {
  /** column name */
  COUNTER = 'counter',
  /** column name */
  CREDENTIALID = 'credentialId',
  /** column name */
  CREDENTIALPUBLICKEY = 'credentialPublicKey',
  /** column name */
  ID = 'id',
  /** column name */
  NICKNAME = 'nickname',
  /** column name */
  TRANSPORTS = 'transports',
  /** column name */
  USERID = 'userId'
}

export type AuthUserSecurityKeysUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<AuthUserSecurityKeysIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<AuthUserSecurityKeysSetInput>;
  /** filter the rows which have to be updated */
  where: AuthUserSecurityKeysBoolExp;
};

/** aggregate var_pop on columns */
export type AuthUserSecurityKeysVarPopFields = {
  __typename?: 'authUserSecurityKeys_var_pop_fields';
  counter?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "auth.user_security_keys" */
export type AuthUserSecurityKeysVarPopOrderBy = {
  counter?: InputMaybe<OrderBy>;
};

/** aggregate var_samp on columns */
export type AuthUserSecurityKeysVarSampFields = {
  __typename?: 'authUserSecurityKeys_var_samp_fields';
  counter?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "auth.user_security_keys" */
export type AuthUserSecurityKeysVarSampOrderBy = {
  counter?: InputMaybe<OrderBy>;
};

/** aggregate variance on columns */
export type AuthUserSecurityKeysVarianceFields = {
  __typename?: 'authUserSecurityKeys_variance_fields';
  counter?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "auth.user_security_keys" */
export type AuthUserSecurityKeysVarianceOrderBy = {
  counter?: InputMaybe<OrderBy>;
};

/** Boolean expression to compare columns of type "bigint". All fields are combined with logical 'AND'. */
export type BigintComparisonExp = {
  _eq?: InputMaybe<Scalars['bigint']['input']>;
  _gt?: InputMaybe<Scalars['bigint']['input']>;
  _gte?: InputMaybe<Scalars['bigint']['input']>;
  _in?: InputMaybe<Array<Scalars['bigint']['input']>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['bigint']['input']>;
  _lte?: InputMaybe<Scalars['bigint']['input']>;
  _neq?: InputMaybe<Scalars['bigint']['input']>;
  _nin?: InputMaybe<Array<Scalars['bigint']['input']>>;
};

/** columns and relationships of "storage.buckets" */
export type Buckets = {
  __typename?: 'buckets';
  cacheControl?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['timestamptz']['output'];
  downloadExpiration: Scalars['Int']['output'];
  /** An array relationship */
  files: Array<Files>;
  /** An aggregate relationship */
  files_aggregate: FilesAggregate;
  id: Scalars['String']['output'];
  maxUploadFileSize: Scalars['Int']['output'];
  minUploadFileSize: Scalars['Int']['output'];
  presignedUrlsEnabled: Scalars['Boolean']['output'];
  updatedAt: Scalars['timestamptz']['output'];
};


/** columns and relationships of "storage.buckets" */
export type BucketsFilesArgs = {
  distinct_on?: InputMaybe<Array<FilesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<FilesOrderBy>>;
  where?: InputMaybe<FilesBoolExp>;
};


/** columns and relationships of "storage.buckets" */
export type BucketsFilesAggregateArgs = {
  distinct_on?: InputMaybe<Array<FilesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<FilesOrderBy>>;
  where?: InputMaybe<FilesBoolExp>;
};

/** aggregated selection of "storage.buckets" */
export type BucketsAggregate = {
  __typename?: 'buckets_aggregate';
  aggregate?: Maybe<BucketsAggregateFields>;
  nodes: Array<Buckets>;
};

/** aggregate fields of "storage.buckets" */
export type BucketsAggregateFields = {
  __typename?: 'buckets_aggregate_fields';
  avg?: Maybe<BucketsAvgFields>;
  count: Scalars['Int']['output'];
  max?: Maybe<BucketsMaxFields>;
  min?: Maybe<BucketsMinFields>;
  stddev?: Maybe<BucketsStddevFields>;
  stddev_pop?: Maybe<BucketsStddevPopFields>;
  stddev_samp?: Maybe<BucketsStddevSampFields>;
  sum?: Maybe<BucketsSumFields>;
  var_pop?: Maybe<BucketsVarPopFields>;
  var_samp?: Maybe<BucketsVarSampFields>;
  variance?: Maybe<BucketsVarianceFields>;
};


/** aggregate fields of "storage.buckets" */
export type BucketsAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<BucketsSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type BucketsAvgFields = {
  __typename?: 'buckets_avg_fields';
  downloadExpiration?: Maybe<Scalars['Float']['output']>;
  maxUploadFileSize?: Maybe<Scalars['Float']['output']>;
  minUploadFileSize?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "storage.buckets". All fields are combined with a logical 'AND'. */
export type BucketsBoolExp = {
  _and?: InputMaybe<Array<BucketsBoolExp>>;
  _not?: InputMaybe<BucketsBoolExp>;
  _or?: InputMaybe<Array<BucketsBoolExp>>;
  cacheControl?: InputMaybe<StringComparisonExp>;
  createdAt?: InputMaybe<TimestamptzComparisonExp>;
  downloadExpiration?: InputMaybe<IntComparisonExp>;
  files?: InputMaybe<FilesBoolExp>;
  files_aggregate?: InputMaybe<FilesAggregateBoolExp>;
  id?: InputMaybe<StringComparisonExp>;
  maxUploadFileSize?: InputMaybe<IntComparisonExp>;
  minUploadFileSize?: InputMaybe<IntComparisonExp>;
  presignedUrlsEnabled?: InputMaybe<BooleanComparisonExp>;
  updatedAt?: InputMaybe<TimestamptzComparisonExp>;
};

/** unique or primary key constraints on table "storage.buckets" */
export enum BucketsConstraint {
  /** unique or primary key constraint on columns "id" */
  BUCKETS_PKEY = 'buckets_pkey'
}

/** input type for incrementing numeric columns in table "storage.buckets" */
export type BucketsIncInput = {
  downloadExpiration?: InputMaybe<Scalars['Int']['input']>;
  maxUploadFileSize?: InputMaybe<Scalars['Int']['input']>;
  minUploadFileSize?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "storage.buckets" */
export type BucketsInsertInput = {
  cacheControl?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  downloadExpiration?: InputMaybe<Scalars['Int']['input']>;
  files?: InputMaybe<FilesArrRelInsertInput>;
  id?: InputMaybe<Scalars['String']['input']>;
  maxUploadFileSize?: InputMaybe<Scalars['Int']['input']>;
  minUploadFileSize?: InputMaybe<Scalars['Int']['input']>;
  presignedUrlsEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate max on columns */
export type BucketsMaxFields = {
  __typename?: 'buckets_max_fields';
  cacheControl?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['timestamptz']['output']>;
  downloadExpiration?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  maxUploadFileSize?: Maybe<Scalars['Int']['output']>;
  minUploadFileSize?: Maybe<Scalars['Int']['output']>;
  updatedAt?: Maybe<Scalars['timestamptz']['output']>;
};

/** aggregate min on columns */
export type BucketsMinFields = {
  __typename?: 'buckets_min_fields';
  cacheControl?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['timestamptz']['output']>;
  downloadExpiration?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  maxUploadFileSize?: Maybe<Scalars['Int']['output']>;
  minUploadFileSize?: Maybe<Scalars['Int']['output']>;
  updatedAt?: Maybe<Scalars['timestamptz']['output']>;
};

/** response of any mutation on the table "storage.buckets" */
export type BucketsMutationResponse = {
  __typename?: 'buckets_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Buckets>;
};

/** input type for inserting object relation for remote table "storage.buckets" */
export type BucketsObjRelInsertInput = {
  data: BucketsInsertInput;
  /** upsert condition */
  on_conflict?: InputMaybe<BucketsOnConflict>;
};

/** on_conflict condition type for table "storage.buckets" */
export type BucketsOnConflict = {
  constraint: BucketsConstraint;
  update_columns?: Array<BucketsUpdateColumn>;
  where?: InputMaybe<BucketsBoolExp>;
};

/** Ordering options when selecting data from "storage.buckets". */
export type BucketsOrderBy = {
  cacheControl?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  downloadExpiration?: InputMaybe<OrderBy>;
  files_aggregate?: InputMaybe<FilesAggregateOrderBy>;
  id?: InputMaybe<OrderBy>;
  maxUploadFileSize?: InputMaybe<OrderBy>;
  minUploadFileSize?: InputMaybe<OrderBy>;
  presignedUrlsEnabled?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: storage.buckets */
export type BucketsPkColumnsInput = {
  id: Scalars['String']['input'];
};

/** select columns of table "storage.buckets" */
export enum BucketsSelectColumn {
  /** column name */
  CACHECONTROL = 'cacheControl',
  /** column name */
  CREATEDAT = 'createdAt',
  /** column name */
  DOWNLOADEXPIRATION = 'downloadExpiration',
  /** column name */
  ID = 'id',
  /** column name */
  MAXUPLOADFILESIZE = 'maxUploadFileSize',
  /** column name */
  MINUPLOADFILESIZE = 'minUploadFileSize',
  /** column name */
  PRESIGNEDURLSENABLED = 'presignedUrlsEnabled',
  /** column name */
  UPDATEDAT = 'updatedAt'
}

/** input type for updating data in table "storage.buckets" */
export type BucketsSetInput = {
  cacheControl?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  downloadExpiration?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  maxUploadFileSize?: InputMaybe<Scalars['Int']['input']>;
  minUploadFileSize?: InputMaybe<Scalars['Int']['input']>;
  presignedUrlsEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate stddev on columns */
export type BucketsStddevFields = {
  __typename?: 'buckets_stddev_fields';
  downloadExpiration?: Maybe<Scalars['Float']['output']>;
  maxUploadFileSize?: Maybe<Scalars['Float']['output']>;
  minUploadFileSize?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type BucketsStddevPopFields = {
  __typename?: 'buckets_stddev_pop_fields';
  downloadExpiration?: Maybe<Scalars['Float']['output']>;
  maxUploadFileSize?: Maybe<Scalars['Float']['output']>;
  minUploadFileSize?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type BucketsStddevSampFields = {
  __typename?: 'buckets_stddev_samp_fields';
  downloadExpiration?: Maybe<Scalars['Float']['output']>;
  maxUploadFileSize?: Maybe<Scalars['Float']['output']>;
  minUploadFileSize?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "buckets" */
export type BucketsStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: BucketsStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type BucketsStreamCursorValueInput = {
  cacheControl?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  downloadExpiration?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  maxUploadFileSize?: InputMaybe<Scalars['Int']['input']>;
  minUploadFileSize?: InputMaybe<Scalars['Int']['input']>;
  presignedUrlsEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate sum on columns */
export type BucketsSumFields = {
  __typename?: 'buckets_sum_fields';
  downloadExpiration?: Maybe<Scalars['Int']['output']>;
  maxUploadFileSize?: Maybe<Scalars['Int']['output']>;
  minUploadFileSize?: Maybe<Scalars['Int']['output']>;
};

/** update columns of table "storage.buckets" */
export enum BucketsUpdateColumn {
  /** column name */
  CACHECONTROL = 'cacheControl',
  /** column name */
  CREATEDAT = 'createdAt',
  /** column name */
  DOWNLOADEXPIRATION = 'downloadExpiration',
  /** column name */
  ID = 'id',
  /** column name */
  MAXUPLOADFILESIZE = 'maxUploadFileSize',
  /** column name */
  MINUPLOADFILESIZE = 'minUploadFileSize',
  /** column name */
  PRESIGNEDURLSENABLED = 'presignedUrlsEnabled',
  /** column name */
  UPDATEDAT = 'updatedAt'
}

export type BucketsUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<BucketsIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<BucketsSetInput>;
  /** filter the rows which have to be updated */
  where: BucketsBoolExp;
};

/** aggregate var_pop on columns */
export type BucketsVarPopFields = {
  __typename?: 'buckets_var_pop_fields';
  downloadExpiration?: Maybe<Scalars['Float']['output']>;
  maxUploadFileSize?: Maybe<Scalars['Float']['output']>;
  minUploadFileSize?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type BucketsVarSampFields = {
  __typename?: 'buckets_var_samp_fields';
  downloadExpiration?: Maybe<Scalars['Float']['output']>;
  maxUploadFileSize?: Maybe<Scalars['Float']['output']>;
  minUploadFileSize?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type BucketsVarianceFields = {
  __typename?: 'buckets_variance_fields';
  downloadExpiration?: Maybe<Scalars['Float']['output']>;
  maxUploadFileSize?: Maybe<Scalars['Float']['output']>;
  minUploadFileSize?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to compare columns of type "bytea". All fields are combined with logical 'AND'. */
export type ByteaComparisonExp = {
  _eq?: InputMaybe<Scalars['bytea']['input']>;
  _gt?: InputMaybe<Scalars['bytea']['input']>;
  _gte?: InputMaybe<Scalars['bytea']['input']>;
  _in?: InputMaybe<Array<Scalars['bytea']['input']>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['bytea']['input']>;
  _lte?: InputMaybe<Scalars['bytea']['input']>;
  _neq?: InputMaybe<Scalars['bytea']['input']>;
  _nin?: InputMaybe<Array<Scalars['bytea']['input']>>;
};

/** Boolean expression to compare columns of type "citext". All fields are combined with logical 'AND'. */
export type CitextComparisonExp = {
  _eq?: InputMaybe<Scalars['citext']['input']>;
  _gt?: InputMaybe<Scalars['citext']['input']>;
  _gte?: InputMaybe<Scalars['citext']['input']>;
  /** does the column match the given case-insensitive pattern */
  _ilike?: InputMaybe<Scalars['citext']['input']>;
  _in?: InputMaybe<Array<Scalars['citext']['input']>>;
  /** does the column match the given POSIX regular expression, case insensitive */
  _iregex?: InputMaybe<Scalars['citext']['input']>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  /** does the column match the given pattern */
  _like?: InputMaybe<Scalars['citext']['input']>;
  _lt?: InputMaybe<Scalars['citext']['input']>;
  _lte?: InputMaybe<Scalars['citext']['input']>;
  _neq?: InputMaybe<Scalars['citext']['input']>;
  /** does the column NOT match the given case-insensitive pattern */
  _nilike?: InputMaybe<Scalars['citext']['input']>;
  _nin?: InputMaybe<Array<Scalars['citext']['input']>>;
  /** does the column NOT match the given POSIX regular expression, case insensitive */
  _niregex?: InputMaybe<Scalars['citext']['input']>;
  /** does the column NOT match the given pattern */
  _nlike?: InputMaybe<Scalars['citext']['input']>;
  /** does the column NOT match the given POSIX regular expression, case sensitive */
  _nregex?: InputMaybe<Scalars['citext']['input']>;
  /** does the column NOT match the given SQL regular expression */
  _nsimilar?: InputMaybe<Scalars['citext']['input']>;
  /** does the column match the given POSIX regular expression, case sensitive */
  _regex?: InputMaybe<Scalars['citext']['input']>;
  /** does the column match the given SQL regular expression */
  _similar?: InputMaybe<Scalars['citext']['input']>;
};

/** ordering argument of a cursor */
export enum CursorOrdering {
  /** ascending ordering of the cursor */
  ASC = 'ASC',
  /** descending ordering of the cursor */
  DESC = 'DESC'
}

/** columns and relationships of "storage.files" */
export type Files = {
  __typename?: 'files';
  /** An object relationship */
  bucket: Buckets;
  bucketId: Scalars['String']['output'];
  createdAt: Scalars['timestamptz']['output'];
  etag?: Maybe<Scalars['String']['output']>;
  id: Scalars['uuid']['output'];
  isUploaded?: Maybe<Scalars['Boolean']['output']>;
  metadata?: Maybe<Scalars['jsonb']['output']>;
  mimeType?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  size?: Maybe<Scalars['Int']['output']>;
  updatedAt: Scalars['timestamptz']['output'];
  uploadedByUserId?: Maybe<Scalars['uuid']['output']>;
};


/** columns and relationships of "storage.files" */
export type FilesMetadataArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};

/** aggregated selection of "storage.files" */
export type FilesAggregate = {
  __typename?: 'files_aggregate';
  aggregate?: Maybe<FilesAggregateFields>;
  nodes: Array<Files>;
};

export type FilesAggregateBoolExp = {
  bool_and?: InputMaybe<FilesAggregateBoolExpBoolAnd>;
  bool_or?: InputMaybe<FilesAggregateBoolExpBoolOr>;
  count?: InputMaybe<FilesAggregateBoolExpCount>;
};

export type FilesAggregateBoolExpBoolAnd = {
  arguments: FilesSelectColumnFilesAggregateBoolExpBoolAndArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<FilesBoolExp>;
  predicate: BooleanComparisonExp;
};

export type FilesAggregateBoolExpBoolOr = {
  arguments: FilesSelectColumnFilesAggregateBoolExpBoolOrArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<FilesBoolExp>;
  predicate: BooleanComparisonExp;
};

export type FilesAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<FilesSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<FilesBoolExp>;
  predicate: IntComparisonExp;
};

/** aggregate fields of "storage.files" */
export type FilesAggregateFields = {
  __typename?: 'files_aggregate_fields';
  avg?: Maybe<FilesAvgFields>;
  count: Scalars['Int']['output'];
  max?: Maybe<FilesMaxFields>;
  min?: Maybe<FilesMinFields>;
  stddev?: Maybe<FilesStddevFields>;
  stddev_pop?: Maybe<FilesStddevPopFields>;
  stddev_samp?: Maybe<FilesStddevSampFields>;
  sum?: Maybe<FilesSumFields>;
  var_pop?: Maybe<FilesVarPopFields>;
  var_samp?: Maybe<FilesVarSampFields>;
  variance?: Maybe<FilesVarianceFields>;
};


/** aggregate fields of "storage.files" */
export type FilesAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<FilesSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "storage.files" */
export type FilesAggregateOrderBy = {
  avg?: InputMaybe<FilesAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<FilesMaxOrderBy>;
  min?: InputMaybe<FilesMinOrderBy>;
  stddev?: InputMaybe<FilesStddevOrderBy>;
  stddev_pop?: InputMaybe<FilesStddevPopOrderBy>;
  stddev_samp?: InputMaybe<FilesStddevSampOrderBy>;
  sum?: InputMaybe<FilesSumOrderBy>;
  var_pop?: InputMaybe<FilesVarPopOrderBy>;
  var_samp?: InputMaybe<FilesVarSampOrderBy>;
  variance?: InputMaybe<FilesVarianceOrderBy>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type FilesAppendInput = {
  metadata?: InputMaybe<Scalars['jsonb']['input']>;
};

/** input type for inserting array relation for remote table "storage.files" */
export type FilesArrRelInsertInput = {
  data: Array<FilesInsertInput>;
  /** upsert condition */
  on_conflict?: InputMaybe<FilesOnConflict>;
};

/** aggregate avg on columns */
export type FilesAvgFields = {
  __typename?: 'files_avg_fields';
  size?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "storage.files" */
export type FilesAvgOrderBy = {
  size?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "storage.files". All fields are combined with a logical 'AND'. */
export type FilesBoolExp = {
  _and?: InputMaybe<Array<FilesBoolExp>>;
  _not?: InputMaybe<FilesBoolExp>;
  _or?: InputMaybe<Array<FilesBoolExp>>;
  bucket?: InputMaybe<BucketsBoolExp>;
  bucketId?: InputMaybe<StringComparisonExp>;
  createdAt?: InputMaybe<TimestamptzComparisonExp>;
  etag?: InputMaybe<StringComparisonExp>;
  id?: InputMaybe<UuidComparisonExp>;
  isUploaded?: InputMaybe<BooleanComparisonExp>;
  metadata?: InputMaybe<JsonbComparisonExp>;
  mimeType?: InputMaybe<StringComparisonExp>;
  name?: InputMaybe<StringComparisonExp>;
  size?: InputMaybe<IntComparisonExp>;
  updatedAt?: InputMaybe<TimestamptzComparisonExp>;
  uploadedByUserId?: InputMaybe<UuidComparisonExp>;
};

/** unique or primary key constraints on table "storage.files" */
export enum FilesConstraint {
  /** unique or primary key constraint on columns "id" */
  FILES_PKEY = 'files_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type FilesDeleteAtPathInput = {
  metadata?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type FilesDeleteElemInput = {
  metadata?: InputMaybe<Scalars['Int']['input']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type FilesDeleteKeyInput = {
  metadata?: InputMaybe<Scalars['String']['input']>;
};

/** input type for incrementing numeric columns in table "storage.files" */
export type FilesIncInput = {
  size?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "storage.files" */
export type FilesInsertInput = {
  bucket?: InputMaybe<BucketsObjRelInsertInput>;
  bucketId?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  etag?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  isUploaded?: InputMaybe<Scalars['Boolean']['input']>;
  metadata?: InputMaybe<Scalars['jsonb']['input']>;
  mimeType?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  size?: InputMaybe<Scalars['Int']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']['input']>;
  uploadedByUserId?: InputMaybe<Scalars['uuid']['input']>;
};

/** aggregate max on columns */
export type FilesMaxFields = {
  __typename?: 'files_max_fields';
  bucketId?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['timestamptz']['output']>;
  etag?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  mimeType?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  size?: Maybe<Scalars['Int']['output']>;
  updatedAt?: Maybe<Scalars['timestamptz']['output']>;
  uploadedByUserId?: Maybe<Scalars['uuid']['output']>;
};

/** order by max() on columns of table "storage.files" */
export type FilesMaxOrderBy = {
  bucketId?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  etag?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  mimeType?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  size?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
  uploadedByUserId?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type FilesMinFields = {
  __typename?: 'files_min_fields';
  bucketId?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['timestamptz']['output']>;
  etag?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  mimeType?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  size?: Maybe<Scalars['Int']['output']>;
  updatedAt?: Maybe<Scalars['timestamptz']['output']>;
  uploadedByUserId?: Maybe<Scalars['uuid']['output']>;
};

/** order by min() on columns of table "storage.files" */
export type FilesMinOrderBy = {
  bucketId?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  etag?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  mimeType?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  size?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
  uploadedByUserId?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "storage.files" */
export type FilesMutationResponse = {
  __typename?: 'files_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Files>;
};

/** input type for inserting object relation for remote table "storage.files" */
export type FilesObjRelInsertInput = {
  data: FilesInsertInput;
  /** upsert condition */
  on_conflict?: InputMaybe<FilesOnConflict>;
};

/** on_conflict condition type for table "storage.files" */
export type FilesOnConflict = {
  constraint: FilesConstraint;
  update_columns?: Array<FilesUpdateColumn>;
  where?: InputMaybe<FilesBoolExp>;
};

/** Ordering options when selecting data from "storage.files". */
export type FilesOrderBy = {
  bucket?: InputMaybe<BucketsOrderBy>;
  bucketId?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  etag?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  isUploaded?: InputMaybe<OrderBy>;
  metadata?: InputMaybe<OrderBy>;
  mimeType?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  size?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
  uploadedByUserId?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: storage.files */
export type FilesPkColumnsInput = {
  id: Scalars['uuid']['input'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type FilesPrependInput = {
  metadata?: InputMaybe<Scalars['jsonb']['input']>;
};

/** select columns of table "storage.files" */
export enum FilesSelectColumn {
  /** column name */
  BUCKETID = 'bucketId',
  /** column name */
  CREATEDAT = 'createdAt',
  /** column name */
  ETAG = 'etag',
  /** column name */
  ID = 'id',
  /** column name */
  ISUPLOADED = 'isUploaded',
  /** column name */
  METADATA = 'metadata',
  /** column name */
  MIMETYPE = 'mimeType',
  /** column name */
  NAME = 'name',
  /** column name */
  SIZE = 'size',
  /** column name */
  UPDATEDAT = 'updatedAt',
  /** column name */
  UPLOADEDBYUSERID = 'uploadedByUserId'
}

/** select "files_aggregate_bool_exp_bool_and_arguments_columns" columns of table "storage.files" */
export enum FilesSelectColumnFilesAggregateBoolExpBoolAndArgumentsColumns {
  /** column name */
  ISUPLOADED = 'isUploaded'
}

/** select "files_aggregate_bool_exp_bool_or_arguments_columns" columns of table "storage.files" */
export enum FilesSelectColumnFilesAggregateBoolExpBoolOrArgumentsColumns {
  /** column name */
  ISUPLOADED = 'isUploaded'
}

/** input type for updating data in table "storage.files" */
export type FilesSetInput = {
  bucketId?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  etag?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  isUploaded?: InputMaybe<Scalars['Boolean']['input']>;
  metadata?: InputMaybe<Scalars['jsonb']['input']>;
  mimeType?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  size?: InputMaybe<Scalars['Int']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']['input']>;
  uploadedByUserId?: InputMaybe<Scalars['uuid']['input']>;
};

/** aggregate stddev on columns */
export type FilesStddevFields = {
  __typename?: 'files_stddev_fields';
  size?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "storage.files" */
export type FilesStddevOrderBy = {
  size?: InputMaybe<OrderBy>;
};

/** aggregate stddev_pop on columns */
export type FilesStddevPopFields = {
  __typename?: 'files_stddev_pop_fields';
  size?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "storage.files" */
export type FilesStddevPopOrderBy = {
  size?: InputMaybe<OrderBy>;
};

/** aggregate stddev_samp on columns */
export type FilesStddevSampFields = {
  __typename?: 'files_stddev_samp_fields';
  size?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "storage.files" */
export type FilesStddevSampOrderBy = {
  size?: InputMaybe<OrderBy>;
};

/** Streaming cursor of the table "files" */
export type FilesStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: FilesStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type FilesStreamCursorValueInput = {
  bucketId?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  etag?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  isUploaded?: InputMaybe<Scalars['Boolean']['input']>;
  metadata?: InputMaybe<Scalars['jsonb']['input']>;
  mimeType?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  size?: InputMaybe<Scalars['Int']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']['input']>;
  uploadedByUserId?: InputMaybe<Scalars['uuid']['input']>;
};

/** aggregate sum on columns */
export type FilesSumFields = {
  __typename?: 'files_sum_fields';
  size?: Maybe<Scalars['Int']['output']>;
};

/** order by sum() on columns of table "storage.files" */
export type FilesSumOrderBy = {
  size?: InputMaybe<OrderBy>;
};

/** update columns of table "storage.files" */
export enum FilesUpdateColumn {
  /** column name */
  BUCKETID = 'bucketId',
  /** column name */
  CREATEDAT = 'createdAt',
  /** column name */
  ETAG = 'etag',
  /** column name */
  ID = 'id',
  /** column name */
  ISUPLOADED = 'isUploaded',
  /** column name */
  METADATA = 'metadata',
  /** column name */
  MIMETYPE = 'mimeType',
  /** column name */
  NAME = 'name',
  /** column name */
  SIZE = 'size',
  /** column name */
  UPDATEDAT = 'updatedAt',
  /** column name */
  UPLOADEDBYUSERID = 'uploadedByUserId'
}

export type FilesUpdates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<FilesAppendInput>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<FilesDeleteAtPathInput>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<FilesDeleteElemInput>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<FilesDeleteKeyInput>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<FilesIncInput>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<FilesPrependInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<FilesSetInput>;
  /** filter the rows which have to be updated */
  where: FilesBoolExp;
};

/** aggregate var_pop on columns */
export type FilesVarPopFields = {
  __typename?: 'files_var_pop_fields';
  size?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "storage.files" */
export type FilesVarPopOrderBy = {
  size?: InputMaybe<OrderBy>;
};

/** aggregate var_samp on columns */
export type FilesVarSampFields = {
  __typename?: 'files_var_samp_fields';
  size?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "storage.files" */
export type FilesVarSampOrderBy = {
  size?: InputMaybe<OrderBy>;
};

/** aggregate variance on columns */
export type FilesVarianceFields = {
  __typename?: 'files_variance_fields';
  size?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "storage.files" */
export type FilesVarianceOrderBy = {
  size?: InputMaybe<OrderBy>;
};

/** Presets saved by an organization or user for use in thedeveloper playground */
export type GenerationPresets = {
  __typename?: 'generation_presets';
  createdAt: Scalars['timestamptz']['output'];
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['uuid']['output'];
  isShared?: Maybe<Scalars['Boolean']['output']>;
  /** An object relationship */
  model: Models;
  modelName: Scalars['String']['output'];
  name: Scalars['String']['output'];
  options: Scalars['jsonb']['output'];
  /** An object relationship */
  organization: Organizations;
  organizationId: Scalars['uuid']['output'];
  updatedAt: Scalars['timestamptz']['output'];
  /** An object relationship */
  user: Users;
  userId: Scalars['uuid']['output'];
};


/** Presets saved by an organization or user for use in thedeveloper playground */
export type GenerationPresetsOptionsArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};

/** aggregated selection of "generation_presets" */
export type GenerationPresetsAggregate = {
  __typename?: 'generation_presets_aggregate';
  aggregate?: Maybe<GenerationPresetsAggregateFields>;
  nodes: Array<GenerationPresets>;
};

/** aggregate fields of "generation_presets" */
export type GenerationPresetsAggregateFields = {
  __typename?: 'generation_presets_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<GenerationPresetsMaxFields>;
  min?: Maybe<GenerationPresetsMinFields>;
};


/** aggregate fields of "generation_presets" */
export type GenerationPresetsAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<GenerationPresetsSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type GenerationPresetsAppendInput = {
  options?: InputMaybe<Scalars['jsonb']['input']>;
};

/** Boolean expression to filter rows from the table "generation_presets". All fields are combined with a logical 'AND'. */
export type GenerationPresetsBoolExp = {
  _and?: InputMaybe<Array<GenerationPresetsBoolExp>>;
  _not?: InputMaybe<GenerationPresetsBoolExp>;
  _or?: InputMaybe<Array<GenerationPresetsBoolExp>>;
  createdAt?: InputMaybe<TimestamptzComparisonExp>;
  description?: InputMaybe<StringComparisonExp>;
  id?: InputMaybe<UuidComparisonExp>;
  isShared?: InputMaybe<BooleanComparisonExp>;
  model?: InputMaybe<ModelsBoolExp>;
  modelName?: InputMaybe<StringComparisonExp>;
  name?: InputMaybe<StringComparisonExp>;
  options?: InputMaybe<JsonbComparisonExp>;
  organization?: InputMaybe<OrganizationsBoolExp>;
  organizationId?: InputMaybe<UuidComparisonExp>;
  updatedAt?: InputMaybe<TimestamptzComparisonExp>;
  user?: InputMaybe<UsersBoolExp>;
  userId?: InputMaybe<UuidComparisonExp>;
};

/** unique or primary key constraints on table "generation_presets" */
export enum GenerationPresetsConstraint {
  /** unique or primary key constraint on columns "id" */
  GENERATION_PRESETS_PKEY = 'generation_presets_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type GenerationPresetsDeleteAtPathInput = {
  options?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type GenerationPresetsDeleteElemInput = {
  options?: InputMaybe<Scalars['Int']['input']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type GenerationPresetsDeleteKeyInput = {
  options?: InputMaybe<Scalars['String']['input']>;
};

/** input type for inserting data into table "generation_presets" */
export type GenerationPresetsInsertInput = {
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  isShared?: InputMaybe<Scalars['Boolean']['input']>;
  model?: InputMaybe<ModelsObjRelInsertInput>;
  modelName?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  options?: InputMaybe<Scalars['jsonb']['input']>;
  organization?: InputMaybe<OrganizationsObjRelInsertInput>;
  organizationId?: InputMaybe<Scalars['uuid']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']['input']>;
  user?: InputMaybe<UsersObjRelInsertInput>;
  userId?: InputMaybe<Scalars['uuid']['input']>;
};

/** aggregate max on columns */
export type GenerationPresetsMaxFields = {
  __typename?: 'generation_presets_max_fields';
  createdAt?: Maybe<Scalars['timestamptz']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  modelName?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  organizationId?: Maybe<Scalars['uuid']['output']>;
  updatedAt?: Maybe<Scalars['timestamptz']['output']>;
  userId?: Maybe<Scalars['uuid']['output']>;
};

/** aggregate min on columns */
export type GenerationPresetsMinFields = {
  __typename?: 'generation_presets_min_fields';
  createdAt?: Maybe<Scalars['timestamptz']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  modelName?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  organizationId?: Maybe<Scalars['uuid']['output']>;
  updatedAt?: Maybe<Scalars['timestamptz']['output']>;
  userId?: Maybe<Scalars['uuid']['output']>;
};

/** response of any mutation on the table "generation_presets" */
export type GenerationPresetsMutationResponse = {
  __typename?: 'generation_presets_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<GenerationPresets>;
};

/** on_conflict condition type for table "generation_presets" */
export type GenerationPresetsOnConflict = {
  constraint: GenerationPresetsConstraint;
  update_columns?: Array<GenerationPresetsUpdateColumn>;
  where?: InputMaybe<GenerationPresetsBoolExp>;
};

/** Ordering options when selecting data from "generation_presets". */
export type GenerationPresetsOrderBy = {
  createdAt?: InputMaybe<OrderBy>;
  description?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  isShared?: InputMaybe<OrderBy>;
  model?: InputMaybe<ModelsOrderBy>;
  modelName?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  options?: InputMaybe<OrderBy>;
  organization?: InputMaybe<OrganizationsOrderBy>;
  organizationId?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
  user?: InputMaybe<UsersOrderBy>;
  userId?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: generation_presets */
export type GenerationPresetsPkColumnsInput = {
  id: Scalars['uuid']['input'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type GenerationPresetsPrependInput = {
  options?: InputMaybe<Scalars['jsonb']['input']>;
};

/** select columns of table "generation_presets" */
export enum GenerationPresetsSelectColumn {
  /** column name */
  CREATEDAT = 'createdAt',
  /** column name */
  DESCRIPTION = 'description',
  /** column name */
  ID = 'id',
  /** column name */
  ISSHARED = 'isShared',
  /** column name */
  MODELNAME = 'modelName',
  /** column name */
  NAME = 'name',
  /** column name */
  OPTIONS = 'options',
  /** column name */
  ORGANIZATIONID = 'organizationId',
  /** column name */
  UPDATEDAT = 'updatedAt',
  /** column name */
  USERID = 'userId'
}

/** input type for updating data in table "generation_presets" */
export type GenerationPresetsSetInput = {
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  isShared?: InputMaybe<Scalars['Boolean']['input']>;
  modelName?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  options?: InputMaybe<Scalars['jsonb']['input']>;
  organizationId?: InputMaybe<Scalars['uuid']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']['input']>;
  userId?: InputMaybe<Scalars['uuid']['input']>;
};

/** Streaming cursor of the table "generation_presets" */
export type GenerationPresetsStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: GenerationPresetsStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type GenerationPresetsStreamCursorValueInput = {
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  isShared?: InputMaybe<Scalars['Boolean']['input']>;
  modelName?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  options?: InputMaybe<Scalars['jsonb']['input']>;
  organizationId?: InputMaybe<Scalars['uuid']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']['input']>;
  userId?: InputMaybe<Scalars['uuid']['input']>;
};

/** update columns of table "generation_presets" */
export enum GenerationPresetsUpdateColumn {
  /** column name */
  CREATEDAT = 'createdAt',
  /** column name */
  DESCRIPTION = 'description',
  /** column name */
  ID = 'id',
  /** column name */
  ISSHARED = 'isShared',
  /** column name */
  MODELNAME = 'modelName',
  /** column name */
  NAME = 'name',
  /** column name */
  OPTIONS = 'options',
  /** column name */
  ORGANIZATIONID = 'organizationId',
  /** column name */
  UPDATEDAT = 'updatedAt',
  /** column name */
  USERID = 'userId'
}

export type GenerationPresetsUpdates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<GenerationPresetsAppendInput>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<GenerationPresetsDeleteAtPathInput>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<GenerationPresetsDeleteElemInput>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<GenerationPresetsDeleteKeyInput>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<GenerationPresetsPrependInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<GenerationPresetsSetInput>;
  /** filter the rows which have to be updated */
  where: GenerationPresetsBoolExp;
};

/** Stores information about generations */
export type Generations = {
  __typename?: 'generations';
  createdAt: Scalars['timestamptz']['output'];
  finishedAt?: Maybe<Scalars['timestamptz']['output']>;
  id: Scalars['uuid']['output'];
  inputs?: Maybe<Scalars['jsonb']['output']>;
  /** An object relationship */
  model: Models;
  modelName: Scalars['String']['output'];
  options?: Maybe<Scalars['jsonb']['output']>;
  /** An object relationship */
  organization: Organizations;
  organizationId: Scalars['uuid']['output'];
  outputMediaLength?: Maybe<Scalars['Int']['output']>;
  outputMediaUrl?: Maybe<Scalars['String']['output']>;
  potentialError?: Maybe<Scalars['jsonb']['output']>;
  startedAt?: Maybe<Scalars['timestamptz']['output']>;
  status: StatusEnum;
  stream?: Maybe<Scalars['Boolean']['output']>;
  /** An object relationship */
  transaction: Transactions;
  transactionId: Scalars['uuid']['output'];
  updatedAt: Scalars['timestamptz']['output'];
  webhookUrl?: Maybe<Scalars['String']['output']>;
};


/** Stores information about generations */
export type GenerationsInputsArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};


/** Stores information about generations */
export type GenerationsOptionsArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};


/** Stores information about generations */
export type GenerationsPotentialErrorArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};

/** aggregated selection of "generations" */
export type GenerationsAggregate = {
  __typename?: 'generations_aggregate';
  aggregate?: Maybe<GenerationsAggregateFields>;
  nodes: Array<Generations>;
};

/** aggregate fields of "generations" */
export type GenerationsAggregateFields = {
  __typename?: 'generations_aggregate_fields';
  avg?: Maybe<GenerationsAvgFields>;
  count: Scalars['Int']['output'];
  max?: Maybe<GenerationsMaxFields>;
  min?: Maybe<GenerationsMinFields>;
  stddev?: Maybe<GenerationsStddevFields>;
  stddev_pop?: Maybe<GenerationsStddevPopFields>;
  stddev_samp?: Maybe<GenerationsStddevSampFields>;
  sum?: Maybe<GenerationsSumFields>;
  var_pop?: Maybe<GenerationsVarPopFields>;
  var_samp?: Maybe<GenerationsVarSampFields>;
  variance?: Maybe<GenerationsVarianceFields>;
};


/** aggregate fields of "generations" */
export type GenerationsAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<GenerationsSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type GenerationsAppendInput = {
  inputs?: InputMaybe<Scalars['jsonb']['input']>;
  options?: InputMaybe<Scalars['jsonb']['input']>;
  potentialError?: InputMaybe<Scalars['jsonb']['input']>;
};

/** aggregate avg on columns */
export type GenerationsAvgFields = {
  __typename?: 'generations_avg_fields';
  outputMediaLength?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "generations". All fields are combined with a logical 'AND'. */
export type GenerationsBoolExp = {
  _and?: InputMaybe<Array<GenerationsBoolExp>>;
  _not?: InputMaybe<GenerationsBoolExp>;
  _or?: InputMaybe<Array<GenerationsBoolExp>>;
  createdAt?: InputMaybe<TimestamptzComparisonExp>;
  finishedAt?: InputMaybe<TimestamptzComparisonExp>;
  id?: InputMaybe<UuidComparisonExp>;
  inputs?: InputMaybe<JsonbComparisonExp>;
  model?: InputMaybe<ModelsBoolExp>;
  modelName?: InputMaybe<StringComparisonExp>;
  options?: InputMaybe<JsonbComparisonExp>;
  organization?: InputMaybe<OrganizationsBoolExp>;
  organizationId?: InputMaybe<UuidComparisonExp>;
  outputMediaLength?: InputMaybe<IntComparisonExp>;
  outputMediaUrl?: InputMaybe<StringComparisonExp>;
  potentialError?: InputMaybe<JsonbComparisonExp>;
  startedAt?: InputMaybe<TimestamptzComparisonExp>;
  status?: InputMaybe<StatusEnumComparisonExp>;
  stream?: InputMaybe<BooleanComparisonExp>;
  transaction?: InputMaybe<TransactionsBoolExp>;
  transactionId?: InputMaybe<UuidComparisonExp>;
  updatedAt?: InputMaybe<TimestamptzComparisonExp>;
  webhookUrl?: InputMaybe<StringComparisonExp>;
};

/** unique or primary key constraints on table "generations" */
export enum GenerationsConstraint {
  /** unique or primary key constraint on columns "id" */
  GENERATIONS_PKEY = 'generations_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type GenerationsDeleteAtPathInput = {
  inputs?: InputMaybe<Array<Scalars['String']['input']>>;
  options?: InputMaybe<Array<Scalars['String']['input']>>;
  potentialError?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type GenerationsDeleteElemInput = {
  inputs?: InputMaybe<Scalars['Int']['input']>;
  options?: InputMaybe<Scalars['Int']['input']>;
  potentialError?: InputMaybe<Scalars['Int']['input']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type GenerationsDeleteKeyInput = {
  inputs?: InputMaybe<Scalars['String']['input']>;
  options?: InputMaybe<Scalars['String']['input']>;
  potentialError?: InputMaybe<Scalars['String']['input']>;
};

/** input type for incrementing numeric columns in table "generations" */
export type GenerationsIncInput = {
  outputMediaLength?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "generations" */
export type GenerationsInsertInput = {
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  finishedAt?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  inputs?: InputMaybe<Scalars['jsonb']['input']>;
  model?: InputMaybe<ModelsObjRelInsertInput>;
  modelName?: InputMaybe<Scalars['String']['input']>;
  options?: InputMaybe<Scalars['jsonb']['input']>;
  organization?: InputMaybe<OrganizationsObjRelInsertInput>;
  organizationId?: InputMaybe<Scalars['uuid']['input']>;
  outputMediaLength?: InputMaybe<Scalars['Int']['input']>;
  outputMediaUrl?: InputMaybe<Scalars['String']['input']>;
  potentialError?: InputMaybe<Scalars['jsonb']['input']>;
  startedAt?: InputMaybe<Scalars['timestamptz']['input']>;
  status?: InputMaybe<StatusEnum>;
  stream?: InputMaybe<Scalars['Boolean']['input']>;
  transaction?: InputMaybe<TransactionsObjRelInsertInput>;
  transactionId?: InputMaybe<Scalars['uuid']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']['input']>;
  webhookUrl?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type GenerationsMaxFields = {
  __typename?: 'generations_max_fields';
  createdAt?: Maybe<Scalars['timestamptz']['output']>;
  finishedAt?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  modelName?: Maybe<Scalars['String']['output']>;
  organizationId?: Maybe<Scalars['uuid']['output']>;
  outputMediaLength?: Maybe<Scalars['Int']['output']>;
  outputMediaUrl?: Maybe<Scalars['String']['output']>;
  startedAt?: Maybe<Scalars['timestamptz']['output']>;
  transactionId?: Maybe<Scalars['uuid']['output']>;
  updatedAt?: Maybe<Scalars['timestamptz']['output']>;
  webhookUrl?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type GenerationsMinFields = {
  __typename?: 'generations_min_fields';
  createdAt?: Maybe<Scalars['timestamptz']['output']>;
  finishedAt?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  modelName?: Maybe<Scalars['String']['output']>;
  organizationId?: Maybe<Scalars['uuid']['output']>;
  outputMediaLength?: Maybe<Scalars['Int']['output']>;
  outputMediaUrl?: Maybe<Scalars['String']['output']>;
  startedAt?: Maybe<Scalars['timestamptz']['output']>;
  transactionId?: Maybe<Scalars['uuid']['output']>;
  updatedAt?: Maybe<Scalars['timestamptz']['output']>;
  webhookUrl?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "generations" */
export type GenerationsMutationResponse = {
  __typename?: 'generations_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Generations>;
};

/** input type for inserting object relation for remote table "generations" */
export type GenerationsObjRelInsertInput = {
  data: GenerationsInsertInput;
  /** upsert condition */
  on_conflict?: InputMaybe<GenerationsOnConflict>;
};

/** on_conflict condition type for table "generations" */
export type GenerationsOnConflict = {
  constraint: GenerationsConstraint;
  update_columns?: Array<GenerationsUpdateColumn>;
  where?: InputMaybe<GenerationsBoolExp>;
};

/** Ordering options when selecting data from "generations". */
export type GenerationsOrderBy = {
  createdAt?: InputMaybe<OrderBy>;
  finishedAt?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  inputs?: InputMaybe<OrderBy>;
  model?: InputMaybe<ModelsOrderBy>;
  modelName?: InputMaybe<OrderBy>;
  options?: InputMaybe<OrderBy>;
  organization?: InputMaybe<OrganizationsOrderBy>;
  organizationId?: InputMaybe<OrderBy>;
  outputMediaLength?: InputMaybe<OrderBy>;
  outputMediaUrl?: InputMaybe<OrderBy>;
  potentialError?: InputMaybe<OrderBy>;
  startedAt?: InputMaybe<OrderBy>;
  status?: InputMaybe<OrderBy>;
  stream?: InputMaybe<OrderBy>;
  transaction?: InputMaybe<TransactionsOrderBy>;
  transactionId?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
  webhookUrl?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: generations */
export type GenerationsPkColumnsInput = {
  id: Scalars['uuid']['input'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type GenerationsPrependInput = {
  inputs?: InputMaybe<Scalars['jsonb']['input']>;
  options?: InputMaybe<Scalars['jsonb']['input']>;
  potentialError?: InputMaybe<Scalars['jsonb']['input']>;
};

/** select columns of table "generations" */
export enum GenerationsSelectColumn {
  /** column name */
  CREATEDAT = 'createdAt',
  /** column name */
  FINISHEDAT = 'finishedAt',
  /** column name */
  ID = 'id',
  /** column name */
  INPUTS = 'inputs',
  /** column name */
  MODELNAME = 'modelName',
  /** column name */
  OPTIONS = 'options',
  /** column name */
  ORGANIZATIONID = 'organizationId',
  /** column name */
  OUTPUTMEDIALENGTH = 'outputMediaLength',
  /** column name */
  OUTPUTMEDIAURL = 'outputMediaUrl',
  /** column name */
  POTENTIALERROR = 'potentialError',
  /** column name */
  STARTEDAT = 'startedAt',
  /** column name */
  STATUS = 'status',
  /** column name */
  STREAM = 'stream',
  /** column name */
  TRANSACTIONID = 'transactionId',
  /** column name */
  UPDATEDAT = 'updatedAt',
  /** column name */
  WEBHOOKURL = 'webhookUrl'
}

/** input type for updating data in table "generations" */
export type GenerationsSetInput = {
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  finishedAt?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  inputs?: InputMaybe<Scalars['jsonb']['input']>;
  modelName?: InputMaybe<Scalars['String']['input']>;
  options?: InputMaybe<Scalars['jsonb']['input']>;
  organizationId?: InputMaybe<Scalars['uuid']['input']>;
  outputMediaLength?: InputMaybe<Scalars['Int']['input']>;
  outputMediaUrl?: InputMaybe<Scalars['String']['input']>;
  potentialError?: InputMaybe<Scalars['jsonb']['input']>;
  startedAt?: InputMaybe<Scalars['timestamptz']['input']>;
  status?: InputMaybe<StatusEnum>;
  stream?: InputMaybe<Scalars['Boolean']['input']>;
  transactionId?: InputMaybe<Scalars['uuid']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']['input']>;
  webhookUrl?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate stddev on columns */
export type GenerationsStddevFields = {
  __typename?: 'generations_stddev_fields';
  outputMediaLength?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type GenerationsStddevPopFields = {
  __typename?: 'generations_stddev_pop_fields';
  outputMediaLength?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type GenerationsStddevSampFields = {
  __typename?: 'generations_stddev_samp_fields';
  outputMediaLength?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "generations" */
export type GenerationsStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: GenerationsStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type GenerationsStreamCursorValueInput = {
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  finishedAt?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  inputs?: InputMaybe<Scalars['jsonb']['input']>;
  modelName?: InputMaybe<Scalars['String']['input']>;
  options?: InputMaybe<Scalars['jsonb']['input']>;
  organizationId?: InputMaybe<Scalars['uuid']['input']>;
  outputMediaLength?: InputMaybe<Scalars['Int']['input']>;
  outputMediaUrl?: InputMaybe<Scalars['String']['input']>;
  potentialError?: InputMaybe<Scalars['jsonb']['input']>;
  startedAt?: InputMaybe<Scalars['timestamptz']['input']>;
  status?: InputMaybe<StatusEnum>;
  stream?: InputMaybe<Scalars['Boolean']['input']>;
  transactionId?: InputMaybe<Scalars['uuid']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']['input']>;
  webhookUrl?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate sum on columns */
export type GenerationsSumFields = {
  __typename?: 'generations_sum_fields';
  outputMediaLength?: Maybe<Scalars['Int']['output']>;
};

/** update columns of table "generations" */
export enum GenerationsUpdateColumn {
  /** column name */
  CREATEDAT = 'createdAt',
  /** column name */
  FINISHEDAT = 'finishedAt',
  /** column name */
  ID = 'id',
  /** column name */
  INPUTS = 'inputs',
  /** column name */
  MODELNAME = 'modelName',
  /** column name */
  OPTIONS = 'options',
  /** column name */
  ORGANIZATIONID = 'organizationId',
  /** column name */
  OUTPUTMEDIALENGTH = 'outputMediaLength',
  /** column name */
  OUTPUTMEDIAURL = 'outputMediaUrl',
  /** column name */
  POTENTIALERROR = 'potentialError',
  /** column name */
  STARTEDAT = 'startedAt',
  /** column name */
  STATUS = 'status',
  /** column name */
  STREAM = 'stream',
  /** column name */
  TRANSACTIONID = 'transactionId',
  /** column name */
  UPDATEDAT = 'updatedAt',
  /** column name */
  WEBHOOKURL = 'webhookUrl'
}

export type GenerationsUpdates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<GenerationsAppendInput>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<GenerationsDeleteAtPathInput>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<GenerationsDeleteElemInput>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<GenerationsDeleteKeyInput>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<GenerationsIncInput>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<GenerationsPrependInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<GenerationsSetInput>;
  /** filter the rows which have to be updated */
  where: GenerationsBoolExp;
};

/** aggregate var_pop on columns */
export type GenerationsVarPopFields = {
  __typename?: 'generations_var_pop_fields';
  outputMediaLength?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type GenerationsVarSampFields = {
  __typename?: 'generations_var_samp_fields';
  outputMediaLength?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type GenerationsVarianceFields = {
  __typename?: 'generations_variance_fields';
  outputMediaLength?: Maybe<Scalars['Float']['output']>;
};

export type JsonbCastExp = {
  String?: InputMaybe<StringComparisonExp>;
};

/** Boolean expression to compare columns of type "jsonb". All fields are combined with logical 'AND'. */
export type JsonbComparisonExp = {
  _cast?: InputMaybe<JsonbCastExp>;
  /** is the column contained in the given json value */
  _contained_in?: InputMaybe<Scalars['jsonb']['input']>;
  /** does the column contain the given json value at the top level */
  _contains?: InputMaybe<Scalars['jsonb']['input']>;
  _eq?: InputMaybe<Scalars['jsonb']['input']>;
  _gt?: InputMaybe<Scalars['jsonb']['input']>;
  _gte?: InputMaybe<Scalars['jsonb']['input']>;
  /** does the string exist as a top-level key in the column */
  _has_key?: InputMaybe<Scalars['String']['input']>;
  /** do all of these strings exist as top-level keys in the column */
  _has_keys_all?: InputMaybe<Array<Scalars['String']['input']>>;
  /** do any of these strings exist as top-level keys in the column */
  _has_keys_any?: InputMaybe<Array<Scalars['String']['input']>>;
  _in?: InputMaybe<Array<Scalars['jsonb']['input']>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['jsonb']['input']>;
  _lte?: InputMaybe<Scalars['jsonb']['input']>;
  _neq?: InputMaybe<Scalars['jsonb']['input']>;
  _nin?: InputMaybe<Array<Scalars['jsonb']['input']>>;
};

/** Enum table for holding model input types */
export type ModelInputType = {
  __typename?: 'model_input_type';
  type: Scalars['String']['output'];
};

/** aggregated selection of "model_input_type" */
export type ModelInputTypeAggregate = {
  __typename?: 'model_input_type_aggregate';
  aggregate?: Maybe<ModelInputTypeAggregateFields>;
  nodes: Array<ModelInputType>;
};

/** aggregate fields of "model_input_type" */
export type ModelInputTypeAggregateFields = {
  __typename?: 'model_input_type_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<ModelInputTypeMaxFields>;
  min?: Maybe<ModelInputTypeMinFields>;
};


/** aggregate fields of "model_input_type" */
export type ModelInputTypeAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<ModelInputTypeSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "model_input_type". All fields are combined with a logical 'AND'. */
export type ModelInputTypeBoolExp = {
  _and?: InputMaybe<Array<ModelInputTypeBoolExp>>;
  _not?: InputMaybe<ModelInputTypeBoolExp>;
  _or?: InputMaybe<Array<ModelInputTypeBoolExp>>;
  type?: InputMaybe<StringComparisonExp>;
};

/** unique or primary key constraints on table "model_input_type" */
export enum ModelInputTypeConstraint {
  /** unique or primary key constraint on columns "type" */
  MODEL_INPUT_TYPE_PKEY = 'model_input_type_pkey'
}

/** input type for inserting data into table "model_input_type" */
export type ModelInputTypeInsertInput = {
  type?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type ModelInputTypeMaxFields = {
  __typename?: 'model_input_type_max_fields';
  type?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type ModelInputTypeMinFields = {
  __typename?: 'model_input_type_min_fields';
  type?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "model_input_type" */
export type ModelInputTypeMutationResponse = {
  __typename?: 'model_input_type_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<ModelInputType>;
};

/** on_conflict condition type for table "model_input_type" */
export type ModelInputTypeOnConflict = {
  constraint: ModelInputTypeConstraint;
  update_columns?: Array<ModelInputTypeUpdateColumn>;
  where?: InputMaybe<ModelInputTypeBoolExp>;
};

/** Ordering options when selecting data from "model_input_type". */
export type ModelInputTypeOrderBy = {
  type?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: model_input_type */
export type ModelInputTypePkColumnsInput = {
  type: Scalars['String']['input'];
};

/** select columns of table "model_input_type" */
export enum ModelInputTypeSelectColumn {
  /** column name */
  TYPE = 'type'
}

/** input type for updating data in table "model_input_type" */
export type ModelInputTypeSetInput = {
  type?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "model_input_type" */
export type ModelInputTypeStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: ModelInputTypeStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type ModelInputTypeStreamCursorValueInput = {
  type?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "model_input_type" */
export enum ModelInputTypeUpdateColumn {
  /** column name */
  TYPE = 'type'
}

export type ModelInputTypeUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<ModelInputTypeSetInput>;
  /** filter the rows which have to be updated */
  where: ModelInputTypeBoolExp;
};

/** Enum table for holding model types */
export type ModelType = {
  __typename?: 'model_type';
  type: Scalars['String']['output'];
};

/** aggregated selection of "model_type" */
export type ModelTypeAggregate = {
  __typename?: 'model_type_aggregate';
  aggregate?: Maybe<ModelTypeAggregateFields>;
  nodes: Array<ModelType>;
};

/** aggregate fields of "model_type" */
export type ModelTypeAggregateFields = {
  __typename?: 'model_type_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<ModelTypeMaxFields>;
  min?: Maybe<ModelTypeMinFields>;
};


/** aggregate fields of "model_type" */
export type ModelTypeAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<ModelTypeSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "model_type". All fields are combined with a logical 'AND'. */
export type ModelTypeBoolExp = {
  _and?: InputMaybe<Array<ModelTypeBoolExp>>;
  _not?: InputMaybe<ModelTypeBoolExp>;
  _or?: InputMaybe<Array<ModelTypeBoolExp>>;
  type?: InputMaybe<StringComparisonExp>;
};

/** unique or primary key constraints on table "model_type" */
export enum ModelTypeConstraint {
  /** unique or primary key constraint on columns "type" */
  MODEL_TYPE_PKEY = 'model_type_pkey'
}

export enum ModelTypeEnum {
  ANIMATE = 'Animate',
  LIPSYNC = 'LipSync'
}

/** Boolean expression to compare columns of type "model_type_enum". All fields are combined with logical 'AND'. */
export type ModelTypeEnumComparisonExp = {
  _eq?: InputMaybe<ModelTypeEnum>;
  _in?: InputMaybe<Array<ModelTypeEnum>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _neq?: InputMaybe<ModelTypeEnum>;
  _nin?: InputMaybe<Array<ModelTypeEnum>>;
};

/** input type for inserting data into table "model_type" */
export type ModelTypeInsertInput = {
  type?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type ModelTypeMaxFields = {
  __typename?: 'model_type_max_fields';
  type?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type ModelTypeMinFields = {
  __typename?: 'model_type_min_fields';
  type?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "model_type" */
export type ModelTypeMutationResponse = {
  __typename?: 'model_type_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<ModelType>;
};

/** on_conflict condition type for table "model_type" */
export type ModelTypeOnConflict = {
  constraint: ModelTypeConstraint;
  update_columns?: Array<ModelTypeUpdateColumn>;
  where?: InputMaybe<ModelTypeBoolExp>;
};

/** Ordering options when selecting data from "model_type". */
export type ModelTypeOrderBy = {
  type?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: model_type */
export type ModelTypePkColumnsInput = {
  type: Scalars['String']['input'];
};

/** select columns of table "model_type" */
export enum ModelTypeSelectColumn {
  /** column name */
  TYPE = 'type'
}

/** input type for updating data in table "model_type" */
export type ModelTypeSetInput = {
  type?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "model_type" */
export type ModelTypeStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: ModelTypeStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type ModelTypeStreamCursorValueInput = {
  type?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "model_type" */
export enum ModelTypeUpdateColumn {
  /** column name */
  TYPE = 'type'
}

export type ModelTypeUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<ModelTypeSetInput>;
  /** filter the rows which have to be updated */
  where: ModelTypeBoolExp;
};

/** Stores information about models */
export type Models = {
  __typename?: 'models';
  createdAt: Scalars['timestamptz']['output'];
  description?: Maybe<Scalars['String']['output']>;
  endpoint: Scalars['String']['output'];
  id: Scalars['uuid']['output'];
  name: Scalars['String']['output'];
  requiredInputCombinations: Scalars['jsonb']['output'];
  type: ModelTypeEnum;
  updatedAt: Scalars['timestamptz']['output'];
  version: Scalars['String']['output'];
};


/** Stores information about models */
export type ModelsRequiredInputCombinationsArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};

/** aggregated selection of "models" */
export type ModelsAggregate = {
  __typename?: 'models_aggregate';
  aggregate?: Maybe<ModelsAggregateFields>;
  nodes: Array<Models>;
};

/** aggregate fields of "models" */
export type ModelsAggregateFields = {
  __typename?: 'models_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<ModelsMaxFields>;
  min?: Maybe<ModelsMinFields>;
};


/** aggregate fields of "models" */
export type ModelsAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<ModelsSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type ModelsAppendInput = {
  requiredInputCombinations?: InputMaybe<Scalars['jsonb']['input']>;
};

/** Boolean expression to filter rows from the table "models". All fields are combined with a logical 'AND'. */
export type ModelsBoolExp = {
  _and?: InputMaybe<Array<ModelsBoolExp>>;
  _not?: InputMaybe<ModelsBoolExp>;
  _or?: InputMaybe<Array<ModelsBoolExp>>;
  createdAt?: InputMaybe<TimestamptzComparisonExp>;
  description?: InputMaybe<StringComparisonExp>;
  endpoint?: InputMaybe<StringComparisonExp>;
  id?: InputMaybe<UuidComparisonExp>;
  name?: InputMaybe<StringComparisonExp>;
  requiredInputCombinations?: InputMaybe<JsonbComparisonExp>;
  type?: InputMaybe<ModelTypeEnumComparisonExp>;
  updatedAt?: InputMaybe<TimestamptzComparisonExp>;
  version?: InputMaybe<StringComparisonExp>;
};

/** unique or primary key constraints on table "models" */
export enum ModelsConstraint {
  /** unique or primary key constraint on columns "name" */
  MODELS_NAME_KEY = 'models_name_key',
  /** unique or primary key constraint on columns "id" */
  MODELS_PKEY = 'models_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type ModelsDeleteAtPathInput = {
  requiredInputCombinations?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type ModelsDeleteElemInput = {
  requiredInputCombinations?: InputMaybe<Scalars['Int']['input']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type ModelsDeleteKeyInput = {
  requiredInputCombinations?: InputMaybe<Scalars['String']['input']>;
};

/** input type for inserting data into table "models" */
export type ModelsInsertInput = {
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  endpoint?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  requiredInputCombinations?: InputMaybe<Scalars['jsonb']['input']>;
  type?: InputMaybe<ModelTypeEnum>;
  updatedAt?: InputMaybe<Scalars['timestamptz']['input']>;
  version?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type ModelsMaxFields = {
  __typename?: 'models_max_fields';
  createdAt?: Maybe<Scalars['timestamptz']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  endpoint?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['timestamptz']['output']>;
  version?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type ModelsMinFields = {
  __typename?: 'models_min_fields';
  createdAt?: Maybe<Scalars['timestamptz']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  endpoint?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['timestamptz']['output']>;
  version?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "models" */
export type ModelsMutationResponse = {
  __typename?: 'models_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Models>;
};

/** input type for inserting object relation for remote table "models" */
export type ModelsObjRelInsertInput = {
  data: ModelsInsertInput;
  /** upsert condition */
  on_conflict?: InputMaybe<ModelsOnConflict>;
};

/** on_conflict condition type for table "models" */
export type ModelsOnConflict = {
  constraint: ModelsConstraint;
  update_columns?: Array<ModelsUpdateColumn>;
  where?: InputMaybe<ModelsBoolExp>;
};

/** Ordering options when selecting data from "models". */
export type ModelsOrderBy = {
  createdAt?: InputMaybe<OrderBy>;
  description?: InputMaybe<OrderBy>;
  endpoint?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  requiredInputCombinations?: InputMaybe<OrderBy>;
  type?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
  version?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: models */
export type ModelsPkColumnsInput = {
  id: Scalars['uuid']['input'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type ModelsPrependInput = {
  requiredInputCombinations?: InputMaybe<Scalars['jsonb']['input']>;
};

/** select columns of table "models" */
export enum ModelsSelectColumn {
  /** column name */
  CREATEDAT = 'createdAt',
  /** column name */
  DESCRIPTION = 'description',
  /** column name */
  ENDPOINT = 'endpoint',
  /** column name */
  ID = 'id',
  /** column name */
  NAME = 'name',
  /** column name */
  REQUIREDINPUTCOMBINATIONS = 'requiredInputCombinations',
  /** column name */
  TYPE = 'type',
  /** column name */
  UPDATEDAT = 'updatedAt',
  /** column name */
  VERSION = 'version'
}

/** input type for updating data in table "models" */
export type ModelsSetInput = {
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  endpoint?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  requiredInputCombinations?: InputMaybe<Scalars['jsonb']['input']>;
  type?: InputMaybe<ModelTypeEnum>;
  updatedAt?: InputMaybe<Scalars['timestamptz']['input']>;
  version?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "models" */
export type ModelsStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: ModelsStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type ModelsStreamCursorValueInput = {
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  endpoint?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  requiredInputCombinations?: InputMaybe<Scalars['jsonb']['input']>;
  type?: InputMaybe<ModelTypeEnum>;
  updatedAt?: InputMaybe<Scalars['timestamptz']['input']>;
  version?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "models" */
export enum ModelsUpdateColumn {
  /** column name */
  CREATEDAT = 'createdAt',
  /** column name */
  DESCRIPTION = 'description',
  /** column name */
  ENDPOINT = 'endpoint',
  /** column name */
  ID = 'id',
  /** column name */
  NAME = 'name',
  /** column name */
  REQUIREDINPUTCOMBINATIONS = 'requiredInputCombinations',
  /** column name */
  TYPE = 'type',
  /** column name */
  UPDATEDAT = 'updatedAt',
  /** column name */
  VERSION = 'version'
}

export type ModelsUpdates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<ModelsAppendInput>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<ModelsDeleteAtPathInput>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<ModelsDeleteElemInput>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<ModelsDeleteKeyInput>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<ModelsPrependInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<ModelsSetInput>;
  /** filter the rows which have to be updated */
  where: ModelsBoolExp;
};

/** mutation root */
export type MutationRoot = {
  __typename?: 'mutation_root';
  /** delete single row from the table: "auth.providers" */
  deleteAuthProvider?: Maybe<AuthProviders>;
  /** delete single row from the table: "auth.provider_requests" */
  deleteAuthProviderRequest?: Maybe<AuthProviderRequests>;
  /** delete data from the table: "auth.provider_requests" */
  deleteAuthProviderRequests?: Maybe<AuthProviderRequestsMutationResponse>;
  /** delete data from the table: "auth.providers" */
  deleteAuthProviders?: Maybe<AuthProvidersMutationResponse>;
  /** delete single row from the table: "auth.refresh_tokens" */
  deleteAuthRefreshToken?: Maybe<AuthRefreshTokens>;
  /** delete single row from the table: "auth.refresh_token_types" */
  deleteAuthRefreshTokenType?: Maybe<AuthRefreshTokenTypes>;
  /** delete data from the table: "auth.refresh_token_types" */
  deleteAuthRefreshTokenTypes?: Maybe<AuthRefreshTokenTypesMutationResponse>;
  /** delete data from the table: "auth.refresh_tokens" */
  deleteAuthRefreshTokens?: Maybe<AuthRefreshTokensMutationResponse>;
  /** delete single row from the table: "auth.roles" */
  deleteAuthRole?: Maybe<AuthRoles>;
  /** delete data from the table: "auth.roles" */
  deleteAuthRoles?: Maybe<AuthRolesMutationResponse>;
  /** delete single row from the table: "auth.user_providers" */
  deleteAuthUserProvider?: Maybe<AuthUserProviders>;
  /** delete data from the table: "auth.user_providers" */
  deleteAuthUserProviders?: Maybe<AuthUserProvidersMutationResponse>;
  /** delete single row from the table: "auth.user_roles" */
  deleteAuthUserRole?: Maybe<AuthUserRoles>;
  /** delete data from the table: "auth.user_roles" */
  deleteAuthUserRoles?: Maybe<AuthUserRolesMutationResponse>;
  /** delete single row from the table: "auth.user_security_keys" */
  deleteAuthUserSecurityKey?: Maybe<AuthUserSecurityKeys>;
  /** delete data from the table: "auth.user_security_keys" */
  deleteAuthUserSecurityKeys?: Maybe<AuthUserSecurityKeysMutationResponse>;
  /** delete single row from the table: "storage.buckets" */
  deleteBucket?: Maybe<Buckets>;
  /** delete data from the table: "storage.buckets" */
  deleteBuckets?: Maybe<BucketsMutationResponse>;
  /** delete single row from the table: "storage.files" */
  deleteFile?: Maybe<Files>;
  /** delete data from the table: "storage.files" */
  deleteFiles?: Maybe<FilesMutationResponse>;
  /** delete single row from the table: "auth.users" */
  deleteUser?: Maybe<Users>;
  /** delete data from the table: "auth.users" */
  deleteUsers?: Maybe<UsersMutationResponse>;
  /** delete single row from the table: "storage.virus" */
  deleteVirus?: Maybe<Virus>;
  /** delete data from the table: "storage.virus" */
  deleteViruses?: Maybe<VirusMutationResponse>;
  /** delete data from the table: "api_keys" */
  delete_api_keys?: Maybe<ApiKeysMutationResponse>;
  /** delete single row from the table: "api_keys" */
  delete_api_keys_by_pk?: Maybe<ApiKeys>;
  /** delete data from the table: "generation_presets" */
  delete_generation_presets?: Maybe<GenerationPresetsMutationResponse>;
  /** delete single row from the table: "generation_presets" */
  delete_generation_presets_by_pk?: Maybe<GenerationPresets>;
  /** delete data from the table: "generations" */
  delete_generations?: Maybe<GenerationsMutationResponse>;
  /** delete single row from the table: "generations" */
  delete_generations_by_pk?: Maybe<Generations>;
  /** delete data from the table: "model_input_type" */
  delete_model_input_type?: Maybe<ModelInputTypeMutationResponse>;
  /** delete single row from the table: "model_input_type" */
  delete_model_input_type_by_pk?: Maybe<ModelInputType>;
  /** delete data from the table: "model_type" */
  delete_model_type?: Maybe<ModelTypeMutationResponse>;
  /** delete single row from the table: "model_type" */
  delete_model_type_by_pk?: Maybe<ModelType>;
  /** delete data from the table: "models" */
  delete_models?: Maybe<ModelsMutationResponse>;
  /** delete single row from the table: "models" */
  delete_models_by_pk?: Maybe<Models>;
  /** delete data from the table: "organization_features" */
  delete_organization_features?: Maybe<OrganizationFeaturesMutationResponse>;
  /** delete single row from the table: "organization_features" */
  delete_organization_features_by_pk?: Maybe<OrganizationFeatures>;
  /** delete data from the table: "organizations" */
  delete_organizations?: Maybe<OrganizationsMutationResponse>;
  /** delete single row from the table: "organizations" */
  delete_organizations_by_pk?: Maybe<Organizations>;
  /** delete data from the table: "status" */
  delete_status?: Maybe<StatusMutationResponse>;
  /** delete single row from the table: "status" */
  delete_status_by_pk?: Maybe<Status>;
  /** delete data from the table: "transaction_type" */
  delete_transaction_type?: Maybe<TransactionTypeMutationResponse>;
  /** delete single row from the table: "transaction_type" */
  delete_transaction_type_by_pk?: Maybe<TransactionType>;
  /** delete data from the table: "transactions" */
  delete_transactions?: Maybe<TransactionsMutationResponse>;
  /** delete single row from the table: "transactions" */
  delete_transactions_by_pk?: Maybe<Transactions>;
  /** delete data from the table: "user_organizations" */
  delete_user_organizations?: Maybe<UserOrganizationsMutationResponse>;
  /** delete single row from the table: "user_organizations" */
  delete_user_organizations_by_pk?: Maybe<UserOrganizations>;
  /** insert a single row into the table: "auth.providers" */
  insertAuthProvider?: Maybe<AuthProviders>;
  /** insert a single row into the table: "auth.provider_requests" */
  insertAuthProviderRequest?: Maybe<AuthProviderRequests>;
  /** insert data into the table: "auth.provider_requests" */
  insertAuthProviderRequests?: Maybe<AuthProviderRequestsMutationResponse>;
  /** insert data into the table: "auth.providers" */
  insertAuthProviders?: Maybe<AuthProvidersMutationResponse>;
  /** insert a single row into the table: "auth.refresh_tokens" */
  insertAuthRefreshToken?: Maybe<AuthRefreshTokens>;
  /** insert a single row into the table: "auth.refresh_token_types" */
  insertAuthRefreshTokenType?: Maybe<AuthRefreshTokenTypes>;
  /** insert data into the table: "auth.refresh_token_types" */
  insertAuthRefreshTokenTypes?: Maybe<AuthRefreshTokenTypesMutationResponse>;
  /** insert data into the table: "auth.refresh_tokens" */
  insertAuthRefreshTokens?: Maybe<AuthRefreshTokensMutationResponse>;
  /** insert a single row into the table: "auth.roles" */
  insertAuthRole?: Maybe<AuthRoles>;
  /** insert data into the table: "auth.roles" */
  insertAuthRoles?: Maybe<AuthRolesMutationResponse>;
  /** insert a single row into the table: "auth.user_providers" */
  insertAuthUserProvider?: Maybe<AuthUserProviders>;
  /** insert data into the table: "auth.user_providers" */
  insertAuthUserProviders?: Maybe<AuthUserProvidersMutationResponse>;
  /** insert a single row into the table: "auth.user_roles" */
  insertAuthUserRole?: Maybe<AuthUserRoles>;
  /** insert data into the table: "auth.user_roles" */
  insertAuthUserRoles?: Maybe<AuthUserRolesMutationResponse>;
  /** insert a single row into the table: "auth.user_security_keys" */
  insertAuthUserSecurityKey?: Maybe<AuthUserSecurityKeys>;
  /** insert data into the table: "auth.user_security_keys" */
  insertAuthUserSecurityKeys?: Maybe<AuthUserSecurityKeysMutationResponse>;
  /** insert a single row into the table: "storage.buckets" */
  insertBucket?: Maybe<Buckets>;
  /** insert data into the table: "storage.buckets" */
  insertBuckets?: Maybe<BucketsMutationResponse>;
  /** insert a single row into the table: "storage.files" */
  insertFile?: Maybe<Files>;
  /** insert data into the table: "storage.files" */
  insertFiles?: Maybe<FilesMutationResponse>;
  /** insert a single row into the table: "auth.users" */
  insertUser?: Maybe<Users>;
  /** insert data into the table: "auth.users" */
  insertUsers?: Maybe<UsersMutationResponse>;
  /** insert a single row into the table: "storage.virus" */
  insertVirus?: Maybe<Virus>;
  /** insert data into the table: "storage.virus" */
  insertViruses?: Maybe<VirusMutationResponse>;
  /** insert data into the table: "api_keys" */
  insert_api_keys?: Maybe<ApiKeysMutationResponse>;
  /** insert a single row into the table: "api_keys" */
  insert_api_keys_one?: Maybe<ApiKeys>;
  /** insert data into the table: "generation_presets" */
  insert_generation_presets?: Maybe<GenerationPresetsMutationResponse>;
  /** insert a single row into the table: "generation_presets" */
  insert_generation_presets_one?: Maybe<GenerationPresets>;
  /** insert data into the table: "generations" */
  insert_generations?: Maybe<GenerationsMutationResponse>;
  /** insert a single row into the table: "generations" */
  insert_generations_one?: Maybe<Generations>;
  /** insert data into the table: "model_input_type" */
  insert_model_input_type?: Maybe<ModelInputTypeMutationResponse>;
  /** insert a single row into the table: "model_input_type" */
  insert_model_input_type_one?: Maybe<ModelInputType>;
  /** insert data into the table: "model_type" */
  insert_model_type?: Maybe<ModelTypeMutationResponse>;
  /** insert a single row into the table: "model_type" */
  insert_model_type_one?: Maybe<ModelType>;
  /** insert data into the table: "models" */
  insert_models?: Maybe<ModelsMutationResponse>;
  /** insert a single row into the table: "models" */
  insert_models_one?: Maybe<Models>;
  /** insert data into the table: "organization_features" */
  insert_organization_features?: Maybe<OrganizationFeaturesMutationResponse>;
  /** insert a single row into the table: "organization_features" */
  insert_organization_features_one?: Maybe<OrganizationFeatures>;
  /** insert data into the table: "organizations" */
  insert_organizations?: Maybe<OrganizationsMutationResponse>;
  /** insert a single row into the table: "organizations" */
  insert_organizations_one?: Maybe<Organizations>;
  /** insert data into the table: "status" */
  insert_status?: Maybe<StatusMutationResponse>;
  /** insert a single row into the table: "status" */
  insert_status_one?: Maybe<Status>;
  /** insert data into the table: "transaction_type" */
  insert_transaction_type?: Maybe<TransactionTypeMutationResponse>;
  /** insert a single row into the table: "transaction_type" */
  insert_transaction_type_one?: Maybe<TransactionType>;
  /** insert data into the table: "transactions" */
  insert_transactions?: Maybe<TransactionsMutationResponse>;
  /** insert a single row into the table: "transactions" */
  insert_transactions_one?: Maybe<Transactions>;
  /** insert data into the table: "user_organizations" */
  insert_user_organizations?: Maybe<UserOrganizationsMutationResponse>;
  /** insert a single row into the table: "user_organizations" */
  insert_user_organizations_one?: Maybe<UserOrganizations>;
  /** update single row of the table: "auth.providers" */
  updateAuthProvider?: Maybe<AuthProviders>;
  /** update single row of the table: "auth.provider_requests" */
  updateAuthProviderRequest?: Maybe<AuthProviderRequests>;
  /** update data of the table: "auth.provider_requests" */
  updateAuthProviderRequests?: Maybe<AuthProviderRequestsMutationResponse>;
  /** update data of the table: "auth.providers" */
  updateAuthProviders?: Maybe<AuthProvidersMutationResponse>;
  /** update single row of the table: "auth.refresh_tokens" */
  updateAuthRefreshToken?: Maybe<AuthRefreshTokens>;
  /** update single row of the table: "auth.refresh_token_types" */
  updateAuthRefreshTokenType?: Maybe<AuthRefreshTokenTypes>;
  /** update data of the table: "auth.refresh_token_types" */
  updateAuthRefreshTokenTypes?: Maybe<AuthRefreshTokenTypesMutationResponse>;
  /** update data of the table: "auth.refresh_tokens" */
  updateAuthRefreshTokens?: Maybe<AuthRefreshTokensMutationResponse>;
  /** update single row of the table: "auth.roles" */
  updateAuthRole?: Maybe<AuthRoles>;
  /** update data of the table: "auth.roles" */
  updateAuthRoles?: Maybe<AuthRolesMutationResponse>;
  /** update single row of the table: "auth.user_providers" */
  updateAuthUserProvider?: Maybe<AuthUserProviders>;
  /** update data of the table: "auth.user_providers" */
  updateAuthUserProviders?: Maybe<AuthUserProvidersMutationResponse>;
  /** update single row of the table: "auth.user_roles" */
  updateAuthUserRole?: Maybe<AuthUserRoles>;
  /** update data of the table: "auth.user_roles" */
  updateAuthUserRoles?: Maybe<AuthUserRolesMutationResponse>;
  /** update single row of the table: "auth.user_security_keys" */
  updateAuthUserSecurityKey?: Maybe<AuthUserSecurityKeys>;
  /** update data of the table: "auth.user_security_keys" */
  updateAuthUserSecurityKeys?: Maybe<AuthUserSecurityKeysMutationResponse>;
  /** update single row of the table: "storage.buckets" */
  updateBucket?: Maybe<Buckets>;
  /** update data of the table: "storage.buckets" */
  updateBuckets?: Maybe<BucketsMutationResponse>;
  /** update single row of the table: "storage.files" */
  updateFile?: Maybe<Files>;
  /** update data of the table: "storage.files" */
  updateFiles?: Maybe<FilesMutationResponse>;
  /** update single row of the table: "auth.users" */
  updateUser?: Maybe<Users>;
  /** update data of the table: "auth.users" */
  updateUsers?: Maybe<UsersMutationResponse>;
  /** update single row of the table: "storage.virus" */
  updateVirus?: Maybe<Virus>;
  /** update data of the table: "storage.virus" */
  updateViruses?: Maybe<VirusMutationResponse>;
  /** update data of the table: "api_keys" */
  update_api_keys?: Maybe<ApiKeysMutationResponse>;
  /** update single row of the table: "api_keys" */
  update_api_keys_by_pk?: Maybe<ApiKeys>;
  /** update multiples rows of table: "api_keys" */
  update_api_keys_many?: Maybe<Array<Maybe<ApiKeysMutationResponse>>>;
  /** update multiples rows of table: "auth.provider_requests" */
  update_authProviderRequests_many?: Maybe<Array<Maybe<AuthProviderRequestsMutationResponse>>>;
  /** update multiples rows of table: "auth.providers" */
  update_authProviders_many?: Maybe<Array<Maybe<AuthProvidersMutationResponse>>>;
  /** update multiples rows of table: "auth.refresh_token_types" */
  update_authRefreshTokenTypes_many?: Maybe<Array<Maybe<AuthRefreshTokenTypesMutationResponse>>>;
  /** update multiples rows of table: "auth.refresh_tokens" */
  update_authRefreshTokens_many?: Maybe<Array<Maybe<AuthRefreshTokensMutationResponse>>>;
  /** update multiples rows of table: "auth.roles" */
  update_authRoles_many?: Maybe<Array<Maybe<AuthRolesMutationResponse>>>;
  /** update multiples rows of table: "auth.user_providers" */
  update_authUserProviders_many?: Maybe<Array<Maybe<AuthUserProvidersMutationResponse>>>;
  /** update multiples rows of table: "auth.user_roles" */
  update_authUserRoles_many?: Maybe<Array<Maybe<AuthUserRolesMutationResponse>>>;
  /** update multiples rows of table: "auth.user_security_keys" */
  update_authUserSecurityKeys_many?: Maybe<Array<Maybe<AuthUserSecurityKeysMutationResponse>>>;
  /** update multiples rows of table: "storage.buckets" */
  update_buckets_many?: Maybe<Array<Maybe<BucketsMutationResponse>>>;
  /** update multiples rows of table: "storage.files" */
  update_files_many?: Maybe<Array<Maybe<FilesMutationResponse>>>;
  /** update data of the table: "generation_presets" */
  update_generation_presets?: Maybe<GenerationPresetsMutationResponse>;
  /** update single row of the table: "generation_presets" */
  update_generation_presets_by_pk?: Maybe<GenerationPresets>;
  /** update multiples rows of table: "generation_presets" */
  update_generation_presets_many?: Maybe<Array<Maybe<GenerationPresetsMutationResponse>>>;
  /** update data of the table: "generations" */
  update_generations?: Maybe<GenerationsMutationResponse>;
  /** update single row of the table: "generations" */
  update_generations_by_pk?: Maybe<Generations>;
  /** update multiples rows of table: "generations" */
  update_generations_many?: Maybe<Array<Maybe<GenerationsMutationResponse>>>;
  /** update data of the table: "model_input_type" */
  update_model_input_type?: Maybe<ModelInputTypeMutationResponse>;
  /** update single row of the table: "model_input_type" */
  update_model_input_type_by_pk?: Maybe<ModelInputType>;
  /** update multiples rows of table: "model_input_type" */
  update_model_input_type_many?: Maybe<Array<Maybe<ModelInputTypeMutationResponse>>>;
  /** update data of the table: "model_type" */
  update_model_type?: Maybe<ModelTypeMutationResponse>;
  /** update single row of the table: "model_type" */
  update_model_type_by_pk?: Maybe<ModelType>;
  /** update multiples rows of table: "model_type" */
  update_model_type_many?: Maybe<Array<Maybe<ModelTypeMutationResponse>>>;
  /** update data of the table: "models" */
  update_models?: Maybe<ModelsMutationResponse>;
  /** update single row of the table: "models" */
  update_models_by_pk?: Maybe<Models>;
  /** update multiples rows of table: "models" */
  update_models_many?: Maybe<Array<Maybe<ModelsMutationResponse>>>;
  /** update data of the table: "organization_features" */
  update_organization_features?: Maybe<OrganizationFeaturesMutationResponse>;
  /** update single row of the table: "organization_features" */
  update_organization_features_by_pk?: Maybe<OrganizationFeatures>;
  /** update multiples rows of table: "organization_features" */
  update_organization_features_many?: Maybe<Array<Maybe<OrganizationFeaturesMutationResponse>>>;
  /** update data of the table: "organizations" */
  update_organizations?: Maybe<OrganizationsMutationResponse>;
  /** update single row of the table: "organizations" */
  update_organizations_by_pk?: Maybe<Organizations>;
  /** update multiples rows of table: "organizations" */
  update_organizations_many?: Maybe<Array<Maybe<OrganizationsMutationResponse>>>;
  /** update data of the table: "status" */
  update_status?: Maybe<StatusMutationResponse>;
  /** update single row of the table: "status" */
  update_status_by_pk?: Maybe<Status>;
  /** update multiples rows of table: "status" */
  update_status_many?: Maybe<Array<Maybe<StatusMutationResponse>>>;
  /** update data of the table: "transaction_type" */
  update_transaction_type?: Maybe<TransactionTypeMutationResponse>;
  /** update single row of the table: "transaction_type" */
  update_transaction_type_by_pk?: Maybe<TransactionType>;
  /** update multiples rows of table: "transaction_type" */
  update_transaction_type_many?: Maybe<Array<Maybe<TransactionTypeMutationResponse>>>;
  /** update data of the table: "transactions" */
  update_transactions?: Maybe<TransactionsMutationResponse>;
  /** update single row of the table: "transactions" */
  update_transactions_by_pk?: Maybe<Transactions>;
  /** update multiples rows of table: "transactions" */
  update_transactions_many?: Maybe<Array<Maybe<TransactionsMutationResponse>>>;
  /** update data of the table: "user_organizations" */
  update_user_organizations?: Maybe<UserOrganizationsMutationResponse>;
  /** update single row of the table: "user_organizations" */
  update_user_organizations_by_pk?: Maybe<UserOrganizations>;
  /** update multiples rows of table: "user_organizations" */
  update_user_organizations_many?: Maybe<Array<Maybe<UserOrganizationsMutationResponse>>>;
  /** update multiples rows of table: "auth.users" */
  update_users_many?: Maybe<Array<Maybe<UsersMutationResponse>>>;
  /** update multiples rows of table: "storage.virus" */
  update_virus_many?: Maybe<Array<Maybe<VirusMutationResponse>>>;
};


/** mutation root */
export type MutationRootDeleteAuthProviderArgs = {
  id: Scalars['String']['input'];
};


/** mutation root */
export type MutationRootDeleteAuthProviderRequestArgs = {
  id: Scalars['uuid']['input'];
};


/** mutation root */
export type MutationRootDeleteAuthProviderRequestsArgs = {
  where: AuthProviderRequestsBoolExp;
};


/** mutation root */
export type MutationRootDeleteAuthProvidersArgs = {
  where: AuthProvidersBoolExp;
};


/** mutation root */
export type MutationRootDeleteAuthRefreshTokenArgs = {
  id: Scalars['uuid']['input'];
};


/** mutation root */
export type MutationRootDeleteAuthRefreshTokenTypeArgs = {
  value: Scalars['String']['input'];
};


/** mutation root */
export type MutationRootDeleteAuthRefreshTokenTypesArgs = {
  where: AuthRefreshTokenTypesBoolExp;
};


/** mutation root */
export type MutationRootDeleteAuthRefreshTokensArgs = {
  where: AuthRefreshTokensBoolExp;
};


/** mutation root */
export type MutationRootDeleteAuthRoleArgs = {
  role: Scalars['String']['input'];
};


/** mutation root */
export type MutationRootDeleteAuthRolesArgs = {
  where: AuthRolesBoolExp;
};


/** mutation root */
export type MutationRootDeleteAuthUserProviderArgs = {
  id: Scalars['uuid']['input'];
};


/** mutation root */
export type MutationRootDeleteAuthUserProvidersArgs = {
  where: AuthUserProvidersBoolExp;
};


/** mutation root */
export type MutationRootDeleteAuthUserRoleArgs = {
  id: Scalars['uuid']['input'];
};


/** mutation root */
export type MutationRootDeleteAuthUserRolesArgs = {
  where: AuthUserRolesBoolExp;
};


/** mutation root */
export type MutationRootDeleteAuthUserSecurityKeyArgs = {
  id: Scalars['uuid']['input'];
};


/** mutation root */
export type MutationRootDeleteAuthUserSecurityKeysArgs = {
  where: AuthUserSecurityKeysBoolExp;
};


/** mutation root */
export type MutationRootDeleteBucketArgs = {
  id: Scalars['String']['input'];
};


/** mutation root */
export type MutationRootDeleteBucketsArgs = {
  where: BucketsBoolExp;
};


/** mutation root */
export type MutationRootDeleteFileArgs = {
  id: Scalars['uuid']['input'];
};


/** mutation root */
export type MutationRootDeleteFilesArgs = {
  where: FilesBoolExp;
};


/** mutation root */
export type MutationRootDeleteUserArgs = {
  id: Scalars['uuid']['input'];
};


/** mutation root */
export type MutationRootDeleteUsersArgs = {
  where: UsersBoolExp;
};


/** mutation root */
export type MutationRootDeleteVirusArgs = {
  id: Scalars['uuid']['input'];
};


/** mutation root */
export type MutationRootDeleteVirusesArgs = {
  where: VirusBoolExp;
};


/** mutation root */
export type MutationRootDeleteApiKeysArgs = {
  where: ApiKeysBoolExp;
};


/** mutation root */
export type MutationRootDeleteApiKeysByPkArgs = {
  id: Scalars['uuid']['input'];
};


/** mutation root */
export type MutationRootDeleteGenerationPresetsArgs = {
  where: GenerationPresetsBoolExp;
};


/** mutation root */
export type MutationRootDeleteGenerationPresetsByPkArgs = {
  id: Scalars['uuid']['input'];
};


/** mutation root */
export type MutationRootDeleteGenerationsArgs = {
  where: GenerationsBoolExp;
};


/** mutation root */
export type MutationRootDeleteGenerationsByPkArgs = {
  id: Scalars['uuid']['input'];
};


/** mutation root */
export type MutationRootDeleteModelInputTypeArgs = {
  where: ModelInputTypeBoolExp;
};


/** mutation root */
export type MutationRootDeleteModelInputTypeByPkArgs = {
  type: Scalars['String']['input'];
};


/** mutation root */
export type MutationRootDeleteModelTypeArgs = {
  where: ModelTypeBoolExp;
};


/** mutation root */
export type MutationRootDeleteModelTypeByPkArgs = {
  type: Scalars['String']['input'];
};


/** mutation root */
export type MutationRootDeleteModelsArgs = {
  where: ModelsBoolExp;
};


/** mutation root */
export type MutationRootDeleteModelsByPkArgs = {
  id: Scalars['uuid']['input'];
};


/** mutation root */
export type MutationRootDeleteOrganizationFeaturesArgs = {
  where: OrganizationFeaturesBoolExp;
};


/** mutation root */
export type MutationRootDeleteOrganizationFeaturesByPkArgs = {
  organizationId: Scalars['uuid']['input'];
};


/** mutation root */
export type MutationRootDeleteOrganizationsArgs = {
  where: OrganizationsBoolExp;
};


/** mutation root */
export type MutationRootDeleteOrganizationsByPkArgs = {
  id: Scalars['uuid']['input'];
};


/** mutation root */
export type MutationRootDeleteStatusArgs = {
  where: StatusBoolExp;
};


/** mutation root */
export type MutationRootDeleteStatusByPkArgs = {
  name: Scalars['String']['input'];
};


/** mutation root */
export type MutationRootDeleteTransactionTypeArgs = {
  where: TransactionTypeBoolExp;
};


/** mutation root */
export type MutationRootDeleteTransactionTypeByPkArgs = {
  type: Scalars['String']['input'];
};


/** mutation root */
export type MutationRootDeleteTransactionsArgs = {
  where: TransactionsBoolExp;
};


/** mutation root */
export type MutationRootDeleteTransactionsByPkArgs = {
  id: Scalars['uuid']['input'];
};


/** mutation root */
export type MutationRootDeleteUserOrganizationsArgs = {
  where: UserOrganizationsBoolExp;
};


/** mutation root */
export type MutationRootDeleteUserOrganizationsByPkArgs = {
  id: Scalars['uuid']['input'];
};


/** mutation root */
export type MutationRootInsertAuthProviderArgs = {
  object: AuthProvidersInsertInput;
  on_conflict?: InputMaybe<AuthProvidersOnConflict>;
};


/** mutation root */
export type MutationRootInsertAuthProviderRequestArgs = {
  object: AuthProviderRequestsInsertInput;
  on_conflict?: InputMaybe<AuthProviderRequestsOnConflict>;
};


/** mutation root */
export type MutationRootInsertAuthProviderRequestsArgs = {
  objects: Array<AuthProviderRequestsInsertInput>;
  on_conflict?: InputMaybe<AuthProviderRequestsOnConflict>;
};


/** mutation root */
export type MutationRootInsertAuthProvidersArgs = {
  objects: Array<AuthProvidersInsertInput>;
  on_conflict?: InputMaybe<AuthProvidersOnConflict>;
};


/** mutation root */
export type MutationRootInsertAuthRefreshTokenArgs = {
  object: AuthRefreshTokensInsertInput;
  on_conflict?: InputMaybe<AuthRefreshTokensOnConflict>;
};


/** mutation root */
export type MutationRootInsertAuthRefreshTokenTypeArgs = {
  object: AuthRefreshTokenTypesInsertInput;
  on_conflict?: InputMaybe<AuthRefreshTokenTypesOnConflict>;
};


/** mutation root */
export type MutationRootInsertAuthRefreshTokenTypesArgs = {
  objects: Array<AuthRefreshTokenTypesInsertInput>;
  on_conflict?: InputMaybe<AuthRefreshTokenTypesOnConflict>;
};


/** mutation root */
export type MutationRootInsertAuthRefreshTokensArgs = {
  objects: Array<AuthRefreshTokensInsertInput>;
  on_conflict?: InputMaybe<AuthRefreshTokensOnConflict>;
};


/** mutation root */
export type MutationRootInsertAuthRoleArgs = {
  object: AuthRolesInsertInput;
  on_conflict?: InputMaybe<AuthRolesOnConflict>;
};


/** mutation root */
export type MutationRootInsertAuthRolesArgs = {
  objects: Array<AuthRolesInsertInput>;
  on_conflict?: InputMaybe<AuthRolesOnConflict>;
};


/** mutation root */
export type MutationRootInsertAuthUserProviderArgs = {
  object: AuthUserProvidersInsertInput;
  on_conflict?: InputMaybe<AuthUserProvidersOnConflict>;
};


/** mutation root */
export type MutationRootInsertAuthUserProvidersArgs = {
  objects: Array<AuthUserProvidersInsertInput>;
  on_conflict?: InputMaybe<AuthUserProvidersOnConflict>;
};


/** mutation root */
export type MutationRootInsertAuthUserRoleArgs = {
  object: AuthUserRolesInsertInput;
  on_conflict?: InputMaybe<AuthUserRolesOnConflict>;
};


/** mutation root */
export type MutationRootInsertAuthUserRolesArgs = {
  objects: Array<AuthUserRolesInsertInput>;
  on_conflict?: InputMaybe<AuthUserRolesOnConflict>;
};


/** mutation root */
export type MutationRootInsertAuthUserSecurityKeyArgs = {
  object: AuthUserSecurityKeysInsertInput;
  on_conflict?: InputMaybe<AuthUserSecurityKeysOnConflict>;
};


/** mutation root */
export type MutationRootInsertAuthUserSecurityKeysArgs = {
  objects: Array<AuthUserSecurityKeysInsertInput>;
  on_conflict?: InputMaybe<AuthUserSecurityKeysOnConflict>;
};


/** mutation root */
export type MutationRootInsertBucketArgs = {
  object: BucketsInsertInput;
  on_conflict?: InputMaybe<BucketsOnConflict>;
};


/** mutation root */
export type MutationRootInsertBucketsArgs = {
  objects: Array<BucketsInsertInput>;
  on_conflict?: InputMaybe<BucketsOnConflict>;
};


/** mutation root */
export type MutationRootInsertFileArgs = {
  object: FilesInsertInput;
  on_conflict?: InputMaybe<FilesOnConflict>;
};


/** mutation root */
export type MutationRootInsertFilesArgs = {
  objects: Array<FilesInsertInput>;
  on_conflict?: InputMaybe<FilesOnConflict>;
};


/** mutation root */
export type MutationRootInsertUserArgs = {
  object: UsersInsertInput;
  on_conflict?: InputMaybe<UsersOnConflict>;
};


/** mutation root */
export type MutationRootInsertUsersArgs = {
  objects: Array<UsersInsertInput>;
  on_conflict?: InputMaybe<UsersOnConflict>;
};


/** mutation root */
export type MutationRootInsertVirusArgs = {
  object: VirusInsertInput;
  on_conflict?: InputMaybe<VirusOnConflict>;
};


/** mutation root */
export type MutationRootInsertVirusesArgs = {
  objects: Array<VirusInsertInput>;
  on_conflict?: InputMaybe<VirusOnConflict>;
};


/** mutation root */
export type MutationRootInsertApiKeysArgs = {
  objects: Array<ApiKeysInsertInput>;
  on_conflict?: InputMaybe<ApiKeysOnConflict>;
};


/** mutation root */
export type MutationRootInsertApiKeysOneArgs = {
  object: ApiKeysInsertInput;
  on_conflict?: InputMaybe<ApiKeysOnConflict>;
};


/** mutation root */
export type MutationRootInsertGenerationPresetsArgs = {
  objects: Array<GenerationPresetsInsertInput>;
  on_conflict?: InputMaybe<GenerationPresetsOnConflict>;
};


/** mutation root */
export type MutationRootInsertGenerationPresetsOneArgs = {
  object: GenerationPresetsInsertInput;
  on_conflict?: InputMaybe<GenerationPresetsOnConflict>;
};


/** mutation root */
export type MutationRootInsertGenerationsArgs = {
  objects: Array<GenerationsInsertInput>;
  on_conflict?: InputMaybe<GenerationsOnConflict>;
};


/** mutation root */
export type MutationRootInsertGenerationsOneArgs = {
  object: GenerationsInsertInput;
  on_conflict?: InputMaybe<GenerationsOnConflict>;
};


/** mutation root */
export type MutationRootInsertModelInputTypeArgs = {
  objects: Array<ModelInputTypeInsertInput>;
  on_conflict?: InputMaybe<ModelInputTypeOnConflict>;
};


/** mutation root */
export type MutationRootInsertModelInputTypeOneArgs = {
  object: ModelInputTypeInsertInput;
  on_conflict?: InputMaybe<ModelInputTypeOnConflict>;
};


/** mutation root */
export type MutationRootInsertModelTypeArgs = {
  objects: Array<ModelTypeInsertInput>;
  on_conflict?: InputMaybe<ModelTypeOnConflict>;
};


/** mutation root */
export type MutationRootInsertModelTypeOneArgs = {
  object: ModelTypeInsertInput;
  on_conflict?: InputMaybe<ModelTypeOnConflict>;
};


/** mutation root */
export type MutationRootInsertModelsArgs = {
  objects: Array<ModelsInsertInput>;
  on_conflict?: InputMaybe<ModelsOnConflict>;
};


/** mutation root */
export type MutationRootInsertModelsOneArgs = {
  object: ModelsInsertInput;
  on_conflict?: InputMaybe<ModelsOnConflict>;
};


/** mutation root */
export type MutationRootInsertOrganizationFeaturesArgs = {
  objects: Array<OrganizationFeaturesInsertInput>;
  on_conflict?: InputMaybe<OrganizationFeaturesOnConflict>;
};


/** mutation root */
export type MutationRootInsertOrganizationFeaturesOneArgs = {
  object: OrganizationFeaturesInsertInput;
  on_conflict?: InputMaybe<OrganizationFeaturesOnConflict>;
};


/** mutation root */
export type MutationRootInsertOrganizationsArgs = {
  objects: Array<OrganizationsInsertInput>;
  on_conflict?: InputMaybe<OrganizationsOnConflict>;
};


/** mutation root */
export type MutationRootInsertOrganizationsOneArgs = {
  object: OrganizationsInsertInput;
  on_conflict?: InputMaybe<OrganizationsOnConflict>;
};


/** mutation root */
export type MutationRootInsertStatusArgs = {
  objects: Array<StatusInsertInput>;
  on_conflict?: InputMaybe<StatusOnConflict>;
};


/** mutation root */
export type MutationRootInsertStatusOneArgs = {
  object: StatusInsertInput;
  on_conflict?: InputMaybe<StatusOnConflict>;
};


/** mutation root */
export type MutationRootInsertTransactionTypeArgs = {
  objects: Array<TransactionTypeInsertInput>;
  on_conflict?: InputMaybe<TransactionTypeOnConflict>;
};


/** mutation root */
export type MutationRootInsertTransactionTypeOneArgs = {
  object: TransactionTypeInsertInput;
  on_conflict?: InputMaybe<TransactionTypeOnConflict>;
};


/** mutation root */
export type MutationRootInsertTransactionsArgs = {
  objects: Array<TransactionsInsertInput>;
  on_conflict?: InputMaybe<TransactionsOnConflict>;
};


/** mutation root */
export type MutationRootInsertTransactionsOneArgs = {
  object: TransactionsInsertInput;
  on_conflict?: InputMaybe<TransactionsOnConflict>;
};


/** mutation root */
export type MutationRootInsertUserOrganizationsArgs = {
  objects: Array<UserOrganizationsInsertInput>;
  on_conflict?: InputMaybe<UserOrganizationsOnConflict>;
};


/** mutation root */
export type MutationRootInsertUserOrganizationsOneArgs = {
  object: UserOrganizationsInsertInput;
  on_conflict?: InputMaybe<UserOrganizationsOnConflict>;
};


/** mutation root */
export type MutationRootUpdateAuthProviderArgs = {
  _set?: InputMaybe<AuthProvidersSetInput>;
  pk_columns: AuthProvidersPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateAuthProviderRequestArgs = {
  _append?: InputMaybe<AuthProviderRequestsAppendInput>;
  _delete_at_path?: InputMaybe<AuthProviderRequestsDeleteAtPathInput>;
  _delete_elem?: InputMaybe<AuthProviderRequestsDeleteElemInput>;
  _delete_key?: InputMaybe<AuthProviderRequestsDeleteKeyInput>;
  _prepend?: InputMaybe<AuthProviderRequestsPrependInput>;
  _set?: InputMaybe<AuthProviderRequestsSetInput>;
  pk_columns: AuthProviderRequestsPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateAuthProviderRequestsArgs = {
  _append?: InputMaybe<AuthProviderRequestsAppendInput>;
  _delete_at_path?: InputMaybe<AuthProviderRequestsDeleteAtPathInput>;
  _delete_elem?: InputMaybe<AuthProviderRequestsDeleteElemInput>;
  _delete_key?: InputMaybe<AuthProviderRequestsDeleteKeyInput>;
  _prepend?: InputMaybe<AuthProviderRequestsPrependInput>;
  _set?: InputMaybe<AuthProviderRequestsSetInput>;
  where: AuthProviderRequestsBoolExp;
};


/** mutation root */
export type MutationRootUpdateAuthProvidersArgs = {
  _set?: InputMaybe<AuthProvidersSetInput>;
  where: AuthProvidersBoolExp;
};


/** mutation root */
export type MutationRootUpdateAuthRefreshTokenArgs = {
  _append?: InputMaybe<AuthRefreshTokensAppendInput>;
  _delete_at_path?: InputMaybe<AuthRefreshTokensDeleteAtPathInput>;
  _delete_elem?: InputMaybe<AuthRefreshTokensDeleteElemInput>;
  _delete_key?: InputMaybe<AuthRefreshTokensDeleteKeyInput>;
  _prepend?: InputMaybe<AuthRefreshTokensPrependInput>;
  _set?: InputMaybe<AuthRefreshTokensSetInput>;
  pk_columns: AuthRefreshTokensPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateAuthRefreshTokenTypeArgs = {
  _set?: InputMaybe<AuthRefreshTokenTypesSetInput>;
  pk_columns: AuthRefreshTokenTypesPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateAuthRefreshTokenTypesArgs = {
  _set?: InputMaybe<AuthRefreshTokenTypesSetInput>;
  where: AuthRefreshTokenTypesBoolExp;
};


/** mutation root */
export type MutationRootUpdateAuthRefreshTokensArgs = {
  _append?: InputMaybe<AuthRefreshTokensAppendInput>;
  _delete_at_path?: InputMaybe<AuthRefreshTokensDeleteAtPathInput>;
  _delete_elem?: InputMaybe<AuthRefreshTokensDeleteElemInput>;
  _delete_key?: InputMaybe<AuthRefreshTokensDeleteKeyInput>;
  _prepend?: InputMaybe<AuthRefreshTokensPrependInput>;
  _set?: InputMaybe<AuthRefreshTokensSetInput>;
  where: AuthRefreshTokensBoolExp;
};


/** mutation root */
export type MutationRootUpdateAuthRoleArgs = {
  _set?: InputMaybe<AuthRolesSetInput>;
  pk_columns: AuthRolesPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateAuthRolesArgs = {
  _set?: InputMaybe<AuthRolesSetInput>;
  where: AuthRolesBoolExp;
};


/** mutation root */
export type MutationRootUpdateAuthUserProviderArgs = {
  _set?: InputMaybe<AuthUserProvidersSetInput>;
  pk_columns: AuthUserProvidersPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateAuthUserProvidersArgs = {
  _set?: InputMaybe<AuthUserProvidersSetInput>;
  where: AuthUserProvidersBoolExp;
};


/** mutation root */
export type MutationRootUpdateAuthUserRoleArgs = {
  _set?: InputMaybe<AuthUserRolesSetInput>;
  pk_columns: AuthUserRolesPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateAuthUserRolesArgs = {
  _set?: InputMaybe<AuthUserRolesSetInput>;
  where: AuthUserRolesBoolExp;
};


/** mutation root */
export type MutationRootUpdateAuthUserSecurityKeyArgs = {
  _inc?: InputMaybe<AuthUserSecurityKeysIncInput>;
  _set?: InputMaybe<AuthUserSecurityKeysSetInput>;
  pk_columns: AuthUserSecurityKeysPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateAuthUserSecurityKeysArgs = {
  _inc?: InputMaybe<AuthUserSecurityKeysIncInput>;
  _set?: InputMaybe<AuthUserSecurityKeysSetInput>;
  where: AuthUserSecurityKeysBoolExp;
};


/** mutation root */
export type MutationRootUpdateBucketArgs = {
  _inc?: InputMaybe<BucketsIncInput>;
  _set?: InputMaybe<BucketsSetInput>;
  pk_columns: BucketsPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateBucketsArgs = {
  _inc?: InputMaybe<BucketsIncInput>;
  _set?: InputMaybe<BucketsSetInput>;
  where: BucketsBoolExp;
};


/** mutation root */
export type MutationRootUpdateFileArgs = {
  _append?: InputMaybe<FilesAppendInput>;
  _delete_at_path?: InputMaybe<FilesDeleteAtPathInput>;
  _delete_elem?: InputMaybe<FilesDeleteElemInput>;
  _delete_key?: InputMaybe<FilesDeleteKeyInput>;
  _inc?: InputMaybe<FilesIncInput>;
  _prepend?: InputMaybe<FilesPrependInput>;
  _set?: InputMaybe<FilesSetInput>;
  pk_columns: FilesPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateFilesArgs = {
  _append?: InputMaybe<FilesAppendInput>;
  _delete_at_path?: InputMaybe<FilesDeleteAtPathInput>;
  _delete_elem?: InputMaybe<FilesDeleteElemInput>;
  _delete_key?: InputMaybe<FilesDeleteKeyInput>;
  _inc?: InputMaybe<FilesIncInput>;
  _prepend?: InputMaybe<FilesPrependInput>;
  _set?: InputMaybe<FilesSetInput>;
  where: FilesBoolExp;
};


/** mutation root */
export type MutationRootUpdateUserArgs = {
  _append?: InputMaybe<UsersAppendInput>;
  _delete_at_path?: InputMaybe<UsersDeleteAtPathInput>;
  _delete_elem?: InputMaybe<UsersDeleteElemInput>;
  _delete_key?: InputMaybe<UsersDeleteKeyInput>;
  _prepend?: InputMaybe<UsersPrependInput>;
  _set?: InputMaybe<UsersSetInput>;
  pk_columns: UsersPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateUsersArgs = {
  _append?: InputMaybe<UsersAppendInput>;
  _delete_at_path?: InputMaybe<UsersDeleteAtPathInput>;
  _delete_elem?: InputMaybe<UsersDeleteElemInput>;
  _delete_key?: InputMaybe<UsersDeleteKeyInput>;
  _prepend?: InputMaybe<UsersPrependInput>;
  _set?: InputMaybe<UsersSetInput>;
  where: UsersBoolExp;
};


/** mutation root */
export type MutationRootUpdateVirusArgs = {
  _append?: InputMaybe<VirusAppendInput>;
  _delete_at_path?: InputMaybe<VirusDeleteAtPathInput>;
  _delete_elem?: InputMaybe<VirusDeleteElemInput>;
  _delete_key?: InputMaybe<VirusDeleteKeyInput>;
  _prepend?: InputMaybe<VirusPrependInput>;
  _set?: InputMaybe<VirusSetInput>;
  pk_columns: VirusPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateVirusesArgs = {
  _append?: InputMaybe<VirusAppendInput>;
  _delete_at_path?: InputMaybe<VirusDeleteAtPathInput>;
  _delete_elem?: InputMaybe<VirusDeleteElemInput>;
  _delete_key?: InputMaybe<VirusDeleteKeyInput>;
  _prepend?: InputMaybe<VirusPrependInput>;
  _set?: InputMaybe<VirusSetInput>;
  where: VirusBoolExp;
};


/** mutation root */
export type MutationRootUpdateApiKeysArgs = {
  _set?: InputMaybe<ApiKeysSetInput>;
  where: ApiKeysBoolExp;
};


/** mutation root */
export type MutationRootUpdateApiKeysByPkArgs = {
  _set?: InputMaybe<ApiKeysSetInput>;
  pk_columns: ApiKeysPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateApiKeysManyArgs = {
  updates: Array<ApiKeysUpdates>;
};


/** mutation root */
export type MutationRootUpdateAuthProviderRequestsManyArgs = {
  updates: Array<AuthProviderRequestsUpdates>;
};


/** mutation root */
export type MutationRootUpdateAuthProvidersManyArgs = {
  updates: Array<AuthProvidersUpdates>;
};


/** mutation root */
export type MutationRootUpdateAuthRefreshTokenTypesManyArgs = {
  updates: Array<AuthRefreshTokenTypesUpdates>;
};


/** mutation root */
export type MutationRootUpdateAuthRefreshTokensManyArgs = {
  updates: Array<AuthRefreshTokensUpdates>;
};


/** mutation root */
export type MutationRootUpdateAuthRolesManyArgs = {
  updates: Array<AuthRolesUpdates>;
};


/** mutation root */
export type MutationRootUpdateAuthUserProvidersManyArgs = {
  updates: Array<AuthUserProvidersUpdates>;
};


/** mutation root */
export type MutationRootUpdateAuthUserRolesManyArgs = {
  updates: Array<AuthUserRolesUpdates>;
};


/** mutation root */
export type MutationRootUpdateAuthUserSecurityKeysManyArgs = {
  updates: Array<AuthUserSecurityKeysUpdates>;
};


/** mutation root */
export type MutationRootUpdateBucketsManyArgs = {
  updates: Array<BucketsUpdates>;
};


/** mutation root */
export type MutationRootUpdateFilesManyArgs = {
  updates: Array<FilesUpdates>;
};


/** mutation root */
export type MutationRootUpdateGenerationPresetsArgs = {
  _append?: InputMaybe<GenerationPresetsAppendInput>;
  _delete_at_path?: InputMaybe<GenerationPresetsDeleteAtPathInput>;
  _delete_elem?: InputMaybe<GenerationPresetsDeleteElemInput>;
  _delete_key?: InputMaybe<GenerationPresetsDeleteKeyInput>;
  _prepend?: InputMaybe<GenerationPresetsPrependInput>;
  _set?: InputMaybe<GenerationPresetsSetInput>;
  where: GenerationPresetsBoolExp;
};


/** mutation root */
export type MutationRootUpdateGenerationPresetsByPkArgs = {
  _append?: InputMaybe<GenerationPresetsAppendInput>;
  _delete_at_path?: InputMaybe<GenerationPresetsDeleteAtPathInput>;
  _delete_elem?: InputMaybe<GenerationPresetsDeleteElemInput>;
  _delete_key?: InputMaybe<GenerationPresetsDeleteKeyInput>;
  _prepend?: InputMaybe<GenerationPresetsPrependInput>;
  _set?: InputMaybe<GenerationPresetsSetInput>;
  pk_columns: GenerationPresetsPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateGenerationPresetsManyArgs = {
  updates: Array<GenerationPresetsUpdates>;
};


/** mutation root */
export type MutationRootUpdateGenerationsArgs = {
  _append?: InputMaybe<GenerationsAppendInput>;
  _delete_at_path?: InputMaybe<GenerationsDeleteAtPathInput>;
  _delete_elem?: InputMaybe<GenerationsDeleteElemInput>;
  _delete_key?: InputMaybe<GenerationsDeleteKeyInput>;
  _inc?: InputMaybe<GenerationsIncInput>;
  _prepend?: InputMaybe<GenerationsPrependInput>;
  _set?: InputMaybe<GenerationsSetInput>;
  where: GenerationsBoolExp;
};


/** mutation root */
export type MutationRootUpdateGenerationsByPkArgs = {
  _append?: InputMaybe<GenerationsAppendInput>;
  _delete_at_path?: InputMaybe<GenerationsDeleteAtPathInput>;
  _delete_elem?: InputMaybe<GenerationsDeleteElemInput>;
  _delete_key?: InputMaybe<GenerationsDeleteKeyInput>;
  _inc?: InputMaybe<GenerationsIncInput>;
  _prepend?: InputMaybe<GenerationsPrependInput>;
  _set?: InputMaybe<GenerationsSetInput>;
  pk_columns: GenerationsPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateGenerationsManyArgs = {
  updates: Array<GenerationsUpdates>;
};


/** mutation root */
export type MutationRootUpdateModelInputTypeArgs = {
  _set?: InputMaybe<ModelInputTypeSetInput>;
  where: ModelInputTypeBoolExp;
};


/** mutation root */
export type MutationRootUpdateModelInputTypeByPkArgs = {
  _set?: InputMaybe<ModelInputTypeSetInput>;
  pk_columns: ModelInputTypePkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateModelInputTypeManyArgs = {
  updates: Array<ModelInputTypeUpdates>;
};


/** mutation root */
export type MutationRootUpdateModelTypeArgs = {
  _set?: InputMaybe<ModelTypeSetInput>;
  where: ModelTypeBoolExp;
};


/** mutation root */
export type MutationRootUpdateModelTypeByPkArgs = {
  _set?: InputMaybe<ModelTypeSetInput>;
  pk_columns: ModelTypePkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateModelTypeManyArgs = {
  updates: Array<ModelTypeUpdates>;
};


/** mutation root */
export type MutationRootUpdateModelsArgs = {
  _append?: InputMaybe<ModelsAppendInput>;
  _delete_at_path?: InputMaybe<ModelsDeleteAtPathInput>;
  _delete_elem?: InputMaybe<ModelsDeleteElemInput>;
  _delete_key?: InputMaybe<ModelsDeleteKeyInput>;
  _prepend?: InputMaybe<ModelsPrependInput>;
  _set?: InputMaybe<ModelsSetInput>;
  where: ModelsBoolExp;
};


/** mutation root */
export type MutationRootUpdateModelsByPkArgs = {
  _append?: InputMaybe<ModelsAppendInput>;
  _delete_at_path?: InputMaybe<ModelsDeleteAtPathInput>;
  _delete_elem?: InputMaybe<ModelsDeleteElemInput>;
  _delete_key?: InputMaybe<ModelsDeleteKeyInput>;
  _prepend?: InputMaybe<ModelsPrependInput>;
  _set?: InputMaybe<ModelsSetInput>;
  pk_columns: ModelsPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateModelsManyArgs = {
  updates: Array<ModelsUpdates>;
};


/** mutation root */
export type MutationRootUpdateOrganizationFeaturesArgs = {
  _inc?: InputMaybe<OrganizationFeaturesIncInput>;
  _set?: InputMaybe<OrganizationFeaturesSetInput>;
  where: OrganizationFeaturesBoolExp;
};


/** mutation root */
export type MutationRootUpdateOrganizationFeaturesByPkArgs = {
  _inc?: InputMaybe<OrganizationFeaturesIncInput>;
  _set?: InputMaybe<OrganizationFeaturesSetInput>;
  pk_columns: OrganizationFeaturesPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateOrganizationFeaturesManyArgs = {
  updates: Array<OrganizationFeaturesUpdates>;
};


/** mutation root */
export type MutationRootUpdateOrganizationsArgs = {
  _set?: InputMaybe<OrganizationsSetInput>;
  where: OrganizationsBoolExp;
};


/** mutation root */
export type MutationRootUpdateOrganizationsByPkArgs = {
  _set?: InputMaybe<OrganizationsSetInput>;
  pk_columns: OrganizationsPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateOrganizationsManyArgs = {
  updates: Array<OrganizationsUpdates>;
};


/** mutation root */
export type MutationRootUpdateStatusArgs = {
  _set?: InputMaybe<StatusSetInput>;
  where: StatusBoolExp;
};


/** mutation root */
export type MutationRootUpdateStatusByPkArgs = {
  _set?: InputMaybe<StatusSetInput>;
  pk_columns: StatusPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateStatusManyArgs = {
  updates: Array<StatusUpdates>;
};


/** mutation root */
export type MutationRootUpdateTransactionTypeArgs = {
  _set?: InputMaybe<TransactionTypeSetInput>;
  where: TransactionTypeBoolExp;
};


/** mutation root */
export type MutationRootUpdateTransactionTypeByPkArgs = {
  _set?: InputMaybe<TransactionTypeSetInput>;
  pk_columns: TransactionTypePkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateTransactionTypeManyArgs = {
  updates: Array<TransactionTypeUpdates>;
};


/** mutation root */
export type MutationRootUpdateTransactionsArgs = {
  _inc?: InputMaybe<TransactionsIncInput>;
  _set?: InputMaybe<TransactionsSetInput>;
  where: TransactionsBoolExp;
};


/** mutation root */
export type MutationRootUpdateTransactionsByPkArgs = {
  _inc?: InputMaybe<TransactionsIncInput>;
  _set?: InputMaybe<TransactionsSetInput>;
  pk_columns: TransactionsPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateTransactionsManyArgs = {
  updates: Array<TransactionsUpdates>;
};


/** mutation root */
export type MutationRootUpdateUserOrganizationsArgs = {
  _set?: InputMaybe<UserOrganizationsSetInput>;
  where: UserOrganizationsBoolExp;
};


/** mutation root */
export type MutationRootUpdateUserOrganizationsByPkArgs = {
  _set?: InputMaybe<UserOrganizationsSetInput>;
  pk_columns: UserOrganizationsPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateUserOrganizationsManyArgs = {
  updates: Array<UserOrganizationsUpdates>;
};


/** mutation root */
export type MutationRootUpdateUsersManyArgs = {
  updates: Array<UsersUpdates>;
};


/** mutation root */
export type MutationRootUpdateVirusManyArgs = {
  updates: Array<VirusUpdates>;
};

/** column ordering options */
export enum OrderBy {
  /** in ascending order, nulls last */
  ASC = 'asc',
  /** in ascending order, nulls first */
  ASC_NULLS_FIRST = 'asc_nulls_first',
  /** in ascending order, nulls last */
  ASC_NULLS_LAST = 'asc_nulls_last',
  /** in descending order, nulls first */
  DESC = 'desc',
  /** in descending order, nulls first */
  DESC_NULLS_FIRST = 'desc_nulls_first',
  /** in descending order, nulls last */
  DESC_NULLS_LAST = 'desc_nulls_last'
}

/** Features associated with organizations */
export type OrganizationFeatures = {
  __typename?: 'organization_features';
  concurrencyOverride?: Maybe<Scalars['Int']['output']>;
  createdAt: Scalars['timestamptz']['output'];
  organizationId: Scalars['uuid']['output'];
  updatedAt: Scalars['timestamptz']['output'];
};

/** aggregated selection of "organization_features" */
export type OrganizationFeaturesAggregate = {
  __typename?: 'organization_features_aggregate';
  aggregate?: Maybe<OrganizationFeaturesAggregateFields>;
  nodes: Array<OrganizationFeatures>;
};

/** aggregate fields of "organization_features" */
export type OrganizationFeaturesAggregateFields = {
  __typename?: 'organization_features_aggregate_fields';
  avg?: Maybe<OrganizationFeaturesAvgFields>;
  count: Scalars['Int']['output'];
  max?: Maybe<OrganizationFeaturesMaxFields>;
  min?: Maybe<OrganizationFeaturesMinFields>;
  stddev?: Maybe<OrganizationFeaturesStddevFields>;
  stddev_pop?: Maybe<OrganizationFeaturesStddevPopFields>;
  stddev_samp?: Maybe<OrganizationFeaturesStddevSampFields>;
  sum?: Maybe<OrganizationFeaturesSumFields>;
  var_pop?: Maybe<OrganizationFeaturesVarPopFields>;
  var_samp?: Maybe<OrganizationFeaturesVarSampFields>;
  variance?: Maybe<OrganizationFeaturesVarianceFields>;
};


/** aggregate fields of "organization_features" */
export type OrganizationFeaturesAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<OrganizationFeaturesSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type OrganizationFeaturesAvgFields = {
  __typename?: 'organization_features_avg_fields';
  concurrencyOverride?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "organization_features". All fields are combined with a logical 'AND'. */
export type OrganizationFeaturesBoolExp = {
  _and?: InputMaybe<Array<OrganizationFeaturesBoolExp>>;
  _not?: InputMaybe<OrganizationFeaturesBoolExp>;
  _or?: InputMaybe<Array<OrganizationFeaturesBoolExp>>;
  concurrencyOverride?: InputMaybe<IntComparisonExp>;
  createdAt?: InputMaybe<TimestamptzComparisonExp>;
  organizationId?: InputMaybe<UuidComparisonExp>;
  updatedAt?: InputMaybe<TimestamptzComparisonExp>;
};

/** unique or primary key constraints on table "organization_features" */
export enum OrganizationFeaturesConstraint {
  /** unique or primary key constraint on columns "organization_id" */
  ORGANIZATION_FEATURES_PKEY = 'organization_features_pkey'
}

/** input type for incrementing numeric columns in table "organization_features" */
export type OrganizationFeaturesIncInput = {
  concurrencyOverride?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "organization_features" */
export type OrganizationFeaturesInsertInput = {
  concurrencyOverride?: InputMaybe<Scalars['Int']['input']>;
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  organizationId?: InputMaybe<Scalars['uuid']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate max on columns */
export type OrganizationFeaturesMaxFields = {
  __typename?: 'organization_features_max_fields';
  concurrencyOverride?: Maybe<Scalars['Int']['output']>;
  createdAt?: Maybe<Scalars['timestamptz']['output']>;
  organizationId?: Maybe<Scalars['uuid']['output']>;
  updatedAt?: Maybe<Scalars['timestamptz']['output']>;
};

/** aggregate min on columns */
export type OrganizationFeaturesMinFields = {
  __typename?: 'organization_features_min_fields';
  concurrencyOverride?: Maybe<Scalars['Int']['output']>;
  createdAt?: Maybe<Scalars['timestamptz']['output']>;
  organizationId?: Maybe<Scalars['uuid']['output']>;
  updatedAt?: Maybe<Scalars['timestamptz']['output']>;
};

/** response of any mutation on the table "organization_features" */
export type OrganizationFeaturesMutationResponse = {
  __typename?: 'organization_features_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<OrganizationFeatures>;
};

/** input type for inserting object relation for remote table "organization_features" */
export type OrganizationFeaturesObjRelInsertInput = {
  data: OrganizationFeaturesInsertInput;
  /** upsert condition */
  on_conflict?: InputMaybe<OrganizationFeaturesOnConflict>;
};

/** on_conflict condition type for table "organization_features" */
export type OrganizationFeaturesOnConflict = {
  constraint: OrganizationFeaturesConstraint;
  update_columns?: Array<OrganizationFeaturesUpdateColumn>;
  where?: InputMaybe<OrganizationFeaturesBoolExp>;
};

/** Ordering options when selecting data from "organization_features". */
export type OrganizationFeaturesOrderBy = {
  concurrencyOverride?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  organizationId?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: organization_features */
export type OrganizationFeaturesPkColumnsInput = {
  organizationId: Scalars['uuid']['input'];
};

/** select columns of table "organization_features" */
export enum OrganizationFeaturesSelectColumn {
  /** column name */
  CONCURRENCYOVERRIDE = 'concurrencyOverride',
  /** column name */
  CREATEDAT = 'createdAt',
  /** column name */
  ORGANIZATIONID = 'organizationId',
  /** column name */
  UPDATEDAT = 'updatedAt'
}

/** input type for updating data in table "organization_features" */
export type OrganizationFeaturesSetInput = {
  concurrencyOverride?: InputMaybe<Scalars['Int']['input']>;
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  organizationId?: InputMaybe<Scalars['uuid']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate stddev on columns */
export type OrganizationFeaturesStddevFields = {
  __typename?: 'organization_features_stddev_fields';
  concurrencyOverride?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type OrganizationFeaturesStddevPopFields = {
  __typename?: 'organization_features_stddev_pop_fields';
  concurrencyOverride?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type OrganizationFeaturesStddevSampFields = {
  __typename?: 'organization_features_stddev_samp_fields';
  concurrencyOverride?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "organization_features" */
export type OrganizationFeaturesStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: OrganizationFeaturesStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type OrganizationFeaturesStreamCursorValueInput = {
  concurrencyOverride?: InputMaybe<Scalars['Int']['input']>;
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  organizationId?: InputMaybe<Scalars['uuid']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate sum on columns */
export type OrganizationFeaturesSumFields = {
  __typename?: 'organization_features_sum_fields';
  concurrencyOverride?: Maybe<Scalars['Int']['output']>;
};

/** update columns of table "organization_features" */
export enum OrganizationFeaturesUpdateColumn {
  /** column name */
  CONCURRENCYOVERRIDE = 'concurrencyOverride',
  /** column name */
  CREATEDAT = 'createdAt',
  /** column name */
  ORGANIZATIONID = 'organizationId',
  /** column name */
  UPDATEDAT = 'updatedAt'
}

export type OrganizationFeaturesUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<OrganizationFeaturesIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<OrganizationFeaturesSetInput>;
  /** filter the rows which have to be updated */
  where: OrganizationFeaturesBoolExp;
};

/** aggregate var_pop on columns */
export type OrganizationFeaturesVarPopFields = {
  __typename?: 'organization_features_var_pop_fields';
  concurrencyOverride?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type OrganizationFeaturesVarSampFields = {
  __typename?: 'organization_features_var_samp_fields';
  concurrencyOverride?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type OrganizationFeaturesVarianceFields = {
  __typename?: 'organization_features_variance_fields';
  concurrencyOverride?: Maybe<Scalars['Float']['output']>;
};

/** Stores information about organizations */
export type Organizations = {
  __typename?: 'organizations';
  createdAt: Scalars['timestamptz']['output'];
  id: Scalars['uuid']['output'];
  /** An array relationship */
  keys: Array<ApiKeys>;
  /** An aggregate relationship */
  keys_aggregate: ApiKeysAggregate;
  name?: Maybe<Scalars['String']['output']>;
  /** An object relationship */
  organization_features?: Maybe<OrganizationFeatures>;
  updatedAt: Scalars['timestamptz']['output'];
  /** An object relationship */
  users?: Maybe<UserOrganizations>;
};


/** Stores information about organizations */
export type OrganizationsKeysArgs = {
  distinct_on?: InputMaybe<Array<ApiKeysSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ApiKeysOrderBy>>;
  where?: InputMaybe<ApiKeysBoolExp>;
};


/** Stores information about organizations */
export type OrganizationsKeysAggregateArgs = {
  distinct_on?: InputMaybe<Array<ApiKeysSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ApiKeysOrderBy>>;
  where?: InputMaybe<ApiKeysBoolExp>;
};

/** aggregated selection of "organizations" */
export type OrganizationsAggregate = {
  __typename?: 'organizations_aggregate';
  aggregate?: Maybe<OrganizationsAggregateFields>;
  nodes: Array<Organizations>;
};

/** aggregate fields of "organizations" */
export type OrganizationsAggregateFields = {
  __typename?: 'organizations_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<OrganizationsMaxFields>;
  min?: Maybe<OrganizationsMinFields>;
};


/** aggregate fields of "organizations" */
export type OrganizationsAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<OrganizationsSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "organizations". All fields are combined with a logical 'AND'. */
export type OrganizationsBoolExp = {
  _and?: InputMaybe<Array<OrganizationsBoolExp>>;
  _not?: InputMaybe<OrganizationsBoolExp>;
  _or?: InputMaybe<Array<OrganizationsBoolExp>>;
  createdAt?: InputMaybe<TimestamptzComparisonExp>;
  id?: InputMaybe<UuidComparisonExp>;
  keys?: InputMaybe<ApiKeysBoolExp>;
  keys_aggregate?: InputMaybe<ApiKeysAggregateBoolExp>;
  name?: InputMaybe<StringComparisonExp>;
  organization_features?: InputMaybe<OrganizationFeaturesBoolExp>;
  updatedAt?: InputMaybe<TimestamptzComparisonExp>;
  users?: InputMaybe<UserOrganizationsBoolExp>;
};

/** unique or primary key constraints on table "organizations" */
export enum OrganizationsConstraint {
  /** unique or primary key constraint on columns "id" */
  ORGANIZATIONS_PKEY = 'organizations_pkey'
}

/** input type for inserting data into table "organizations" */
export type OrganizationsInsertInput = {
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  keys?: InputMaybe<ApiKeysArrRelInsertInput>;
  name?: InputMaybe<Scalars['String']['input']>;
  organization_features?: InputMaybe<OrganizationFeaturesObjRelInsertInput>;
  updatedAt?: InputMaybe<Scalars['timestamptz']['input']>;
  users?: InputMaybe<UserOrganizationsObjRelInsertInput>;
};

/** aggregate max on columns */
export type OrganizationsMaxFields = {
  __typename?: 'organizations_max_fields';
  createdAt?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['timestamptz']['output']>;
};

/** aggregate min on columns */
export type OrganizationsMinFields = {
  __typename?: 'organizations_min_fields';
  createdAt?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['timestamptz']['output']>;
};

/** response of any mutation on the table "organizations" */
export type OrganizationsMutationResponse = {
  __typename?: 'organizations_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Organizations>;
};

/** input type for inserting object relation for remote table "organizations" */
export type OrganizationsObjRelInsertInput = {
  data: OrganizationsInsertInput;
  /** upsert condition */
  on_conflict?: InputMaybe<OrganizationsOnConflict>;
};

/** on_conflict condition type for table "organizations" */
export type OrganizationsOnConflict = {
  constraint: OrganizationsConstraint;
  update_columns?: Array<OrganizationsUpdateColumn>;
  where?: InputMaybe<OrganizationsBoolExp>;
};

/** Ordering options when selecting data from "organizations". */
export type OrganizationsOrderBy = {
  createdAt?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  keys_aggregate?: InputMaybe<ApiKeysAggregateOrderBy>;
  name?: InputMaybe<OrderBy>;
  organization_features?: InputMaybe<OrganizationFeaturesOrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
  users?: InputMaybe<UserOrganizationsOrderBy>;
};

/** primary key columns input for table: organizations */
export type OrganizationsPkColumnsInput = {
  id: Scalars['uuid']['input'];
};

/** select columns of table "organizations" */
export enum OrganizationsSelectColumn {
  /** column name */
  CREATEDAT = 'createdAt',
  /** column name */
  ID = 'id',
  /** column name */
  NAME = 'name',
  /** column name */
  UPDATEDAT = 'updatedAt'
}

/** input type for updating data in table "organizations" */
export type OrganizationsSetInput = {
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** Streaming cursor of the table "organizations" */
export type OrganizationsStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: OrganizationsStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type OrganizationsStreamCursorValueInput = {
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** update columns of table "organizations" */
export enum OrganizationsUpdateColumn {
  /** column name */
  CREATEDAT = 'createdAt',
  /** column name */
  ID = 'id',
  /** column name */
  NAME = 'name',
  /** column name */
  UPDATEDAT = 'updatedAt'
}

export type OrganizationsUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<OrganizationsSetInput>;
  /** filter the rows which have to be updated */
  where: OrganizationsBoolExp;
};

export type QueryRoot = {
  __typename?: 'query_root';
  /** fetch data from the table: "api_keys" */
  api_keys: Array<ApiKeys>;
  /** fetch aggregated fields from the table: "api_keys" */
  api_keys_aggregate: ApiKeysAggregate;
  /** fetch data from the table: "api_keys" using primary key columns */
  api_keys_by_pk?: Maybe<ApiKeys>;
  /** fetch data from the table: "auth.providers" using primary key columns */
  authProvider?: Maybe<AuthProviders>;
  /** fetch data from the table: "auth.provider_requests" using primary key columns */
  authProviderRequest?: Maybe<AuthProviderRequests>;
  /** fetch data from the table: "auth.provider_requests" */
  authProviderRequests: Array<AuthProviderRequests>;
  /** fetch aggregated fields from the table: "auth.provider_requests" */
  authProviderRequestsAggregate: AuthProviderRequestsAggregate;
  /** fetch data from the table: "auth.providers" */
  authProviders: Array<AuthProviders>;
  /** fetch aggregated fields from the table: "auth.providers" */
  authProvidersAggregate: AuthProvidersAggregate;
  /** fetch data from the table: "auth.refresh_tokens" using primary key columns */
  authRefreshToken?: Maybe<AuthRefreshTokens>;
  /** fetch data from the table: "auth.refresh_token_types" using primary key columns */
  authRefreshTokenType?: Maybe<AuthRefreshTokenTypes>;
  /** fetch data from the table: "auth.refresh_token_types" */
  authRefreshTokenTypes: Array<AuthRefreshTokenTypes>;
  /** fetch aggregated fields from the table: "auth.refresh_token_types" */
  authRefreshTokenTypesAggregate: AuthRefreshTokenTypesAggregate;
  /** fetch data from the table: "auth.refresh_tokens" */
  authRefreshTokens: Array<AuthRefreshTokens>;
  /** fetch aggregated fields from the table: "auth.refresh_tokens" */
  authRefreshTokensAggregate: AuthRefreshTokensAggregate;
  /** fetch data from the table: "auth.roles" using primary key columns */
  authRole?: Maybe<AuthRoles>;
  /** fetch data from the table: "auth.roles" */
  authRoles: Array<AuthRoles>;
  /** fetch aggregated fields from the table: "auth.roles" */
  authRolesAggregate: AuthRolesAggregate;
  /** fetch data from the table: "auth.user_providers" using primary key columns */
  authUserProvider?: Maybe<AuthUserProviders>;
  /** fetch data from the table: "auth.user_providers" */
  authUserProviders: Array<AuthUserProviders>;
  /** fetch aggregated fields from the table: "auth.user_providers" */
  authUserProvidersAggregate: AuthUserProvidersAggregate;
  /** fetch data from the table: "auth.user_roles" using primary key columns */
  authUserRole?: Maybe<AuthUserRoles>;
  /** fetch data from the table: "auth.user_roles" */
  authUserRoles: Array<AuthUserRoles>;
  /** fetch aggregated fields from the table: "auth.user_roles" */
  authUserRolesAggregate: AuthUserRolesAggregate;
  /** fetch data from the table: "auth.user_security_keys" using primary key columns */
  authUserSecurityKey?: Maybe<AuthUserSecurityKeys>;
  /** fetch data from the table: "auth.user_security_keys" */
  authUserSecurityKeys: Array<AuthUserSecurityKeys>;
  /** fetch aggregated fields from the table: "auth.user_security_keys" */
  authUserSecurityKeysAggregate: AuthUserSecurityKeysAggregate;
  /** fetch data from the table: "storage.buckets" using primary key columns */
  bucket?: Maybe<Buckets>;
  /** fetch data from the table: "storage.buckets" */
  buckets: Array<Buckets>;
  /** fetch aggregated fields from the table: "storage.buckets" */
  bucketsAggregate: BucketsAggregate;
  /** fetch data from the table: "storage.files" using primary key columns */
  file?: Maybe<Files>;
  /** An array relationship */
  files: Array<Files>;
  /** fetch aggregated fields from the table: "storage.files" */
  filesAggregate: FilesAggregate;
  /** fetch data from the table: "generation_presets" */
  generation_presets: Array<GenerationPresets>;
  /** fetch aggregated fields from the table: "generation_presets" */
  generation_presets_aggregate: GenerationPresetsAggregate;
  /** fetch data from the table: "generation_presets" using primary key columns */
  generation_presets_by_pk?: Maybe<GenerationPresets>;
  /** fetch data from the table: "generations" */
  generations: Array<Generations>;
  /** fetch aggregated fields from the table: "generations" */
  generations_aggregate: GenerationsAggregate;
  /** fetch data from the table: "generations" using primary key columns */
  generations_by_pk?: Maybe<Generations>;
  /** fetch data from the table: "model_input_type" */
  model_input_type: Array<ModelInputType>;
  /** fetch aggregated fields from the table: "model_input_type" */
  model_input_type_aggregate: ModelInputTypeAggregate;
  /** fetch data from the table: "model_input_type" using primary key columns */
  model_input_type_by_pk?: Maybe<ModelInputType>;
  /** fetch data from the table: "model_type" */
  model_type: Array<ModelType>;
  /** fetch aggregated fields from the table: "model_type" */
  model_type_aggregate: ModelTypeAggregate;
  /** fetch data from the table: "model_type" using primary key columns */
  model_type_by_pk?: Maybe<ModelType>;
  /** fetch data from the table: "models" */
  models: Array<Models>;
  /** fetch aggregated fields from the table: "models" */
  models_aggregate: ModelsAggregate;
  /** fetch data from the table: "models" using primary key columns */
  models_by_pk?: Maybe<Models>;
  /** fetch data from the table: "organization_features" */
  organization_features: Array<OrganizationFeatures>;
  /** fetch aggregated fields from the table: "organization_features" */
  organization_features_aggregate: OrganizationFeaturesAggregate;
  /** fetch data from the table: "organization_features" using primary key columns */
  organization_features_by_pk?: Maybe<OrganizationFeatures>;
  /** fetch data from the table: "organizations" */
  organizations: Array<Organizations>;
  /** fetch aggregated fields from the table: "organizations" */
  organizations_aggregate: OrganizationsAggregate;
  /** fetch data from the table: "organizations" using primary key columns */
  organizations_by_pk?: Maybe<Organizations>;
  /** fetch data from the table: "status" */
  status: Array<Status>;
  /** fetch aggregated fields from the table: "status" */
  status_aggregate: StatusAggregate;
  /** fetch data from the table: "status" using primary key columns */
  status_by_pk?: Maybe<Status>;
  /** fetch data from the table: "transaction_type" */
  transaction_type: Array<TransactionType>;
  /** fetch aggregated fields from the table: "transaction_type" */
  transaction_type_aggregate: TransactionTypeAggregate;
  /** fetch data from the table: "transaction_type" using primary key columns */
  transaction_type_by_pk?: Maybe<TransactionType>;
  /** fetch data from the table: "transactions" */
  transactions: Array<Transactions>;
  /** fetch aggregated fields from the table: "transactions" */
  transactions_aggregate: TransactionsAggregate;
  /** fetch data from the table: "transactions" using primary key columns */
  transactions_by_pk?: Maybe<Transactions>;
  /** fetch data from the table: "auth.users" using primary key columns */
  user?: Maybe<Users>;
  /** fetch data from the table: "user_organizations" */
  user_organizations: Array<UserOrganizations>;
  /** fetch aggregated fields from the table: "user_organizations" */
  user_organizations_aggregate: UserOrganizationsAggregate;
  /** fetch data from the table: "user_organizations" using primary key columns */
  user_organizations_by_pk?: Maybe<UserOrganizations>;
  /** fetch data from the table: "auth.users" */
  users: Array<Users>;
  /** fetch aggregated fields from the table: "auth.users" */
  usersAggregate: UsersAggregate;
  /** fetch data from the table: "storage.virus" using primary key columns */
  virus?: Maybe<Virus>;
  /** fetch data from the table: "storage.virus" */
  viruses: Array<Virus>;
  /** fetch aggregated fields from the table: "storage.virus" */
  virusesAggregate: VirusAggregate;
};


export type QueryRootApiKeysArgs = {
  distinct_on?: InputMaybe<Array<ApiKeysSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ApiKeysOrderBy>>;
  where?: InputMaybe<ApiKeysBoolExp>;
};


export type QueryRootApiKeysAggregateArgs = {
  distinct_on?: InputMaybe<Array<ApiKeysSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ApiKeysOrderBy>>;
  where?: InputMaybe<ApiKeysBoolExp>;
};


export type QueryRootApiKeysByPkArgs = {
  id: Scalars['uuid']['input'];
};


export type QueryRootAuthProviderArgs = {
  id: Scalars['String']['input'];
};


export type QueryRootAuthProviderRequestArgs = {
  id: Scalars['uuid']['input'];
};


export type QueryRootAuthProviderRequestsArgs = {
  distinct_on?: InputMaybe<Array<AuthProviderRequestsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<AuthProviderRequestsOrderBy>>;
  where?: InputMaybe<AuthProviderRequestsBoolExp>;
};


export type QueryRootAuthProviderRequestsAggregateArgs = {
  distinct_on?: InputMaybe<Array<AuthProviderRequestsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<AuthProviderRequestsOrderBy>>;
  where?: InputMaybe<AuthProviderRequestsBoolExp>;
};


export type QueryRootAuthProvidersArgs = {
  distinct_on?: InputMaybe<Array<AuthProvidersSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<AuthProvidersOrderBy>>;
  where?: InputMaybe<AuthProvidersBoolExp>;
};


export type QueryRootAuthProvidersAggregateArgs = {
  distinct_on?: InputMaybe<Array<AuthProvidersSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<AuthProvidersOrderBy>>;
  where?: InputMaybe<AuthProvidersBoolExp>;
};


export type QueryRootAuthRefreshTokenArgs = {
  id: Scalars['uuid']['input'];
};


export type QueryRootAuthRefreshTokenTypeArgs = {
  value: Scalars['String']['input'];
};


export type QueryRootAuthRefreshTokenTypesArgs = {
  distinct_on?: InputMaybe<Array<AuthRefreshTokenTypesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<AuthRefreshTokenTypesOrderBy>>;
  where?: InputMaybe<AuthRefreshTokenTypesBoolExp>;
};


export type QueryRootAuthRefreshTokenTypesAggregateArgs = {
  distinct_on?: InputMaybe<Array<AuthRefreshTokenTypesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<AuthRefreshTokenTypesOrderBy>>;
  where?: InputMaybe<AuthRefreshTokenTypesBoolExp>;
};


export type QueryRootAuthRefreshTokensArgs = {
  distinct_on?: InputMaybe<Array<AuthRefreshTokensSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<AuthRefreshTokensOrderBy>>;
  where?: InputMaybe<AuthRefreshTokensBoolExp>;
};


export type QueryRootAuthRefreshTokensAggregateArgs = {
  distinct_on?: InputMaybe<Array<AuthRefreshTokensSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<AuthRefreshTokensOrderBy>>;
  where?: InputMaybe<AuthRefreshTokensBoolExp>;
};


export type QueryRootAuthRoleArgs = {
  role: Scalars['String']['input'];
};


export type QueryRootAuthRolesArgs = {
  distinct_on?: InputMaybe<Array<AuthRolesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<AuthRolesOrderBy>>;
  where?: InputMaybe<AuthRolesBoolExp>;
};


export type QueryRootAuthRolesAggregateArgs = {
  distinct_on?: InputMaybe<Array<AuthRolesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<AuthRolesOrderBy>>;
  where?: InputMaybe<AuthRolesBoolExp>;
};


export type QueryRootAuthUserProviderArgs = {
  id: Scalars['uuid']['input'];
};


export type QueryRootAuthUserProvidersArgs = {
  distinct_on?: InputMaybe<Array<AuthUserProvidersSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<AuthUserProvidersOrderBy>>;
  where?: InputMaybe<AuthUserProvidersBoolExp>;
};


export type QueryRootAuthUserProvidersAggregateArgs = {
  distinct_on?: InputMaybe<Array<AuthUserProvidersSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<AuthUserProvidersOrderBy>>;
  where?: InputMaybe<AuthUserProvidersBoolExp>;
};


export type QueryRootAuthUserRoleArgs = {
  id: Scalars['uuid']['input'];
};


export type QueryRootAuthUserRolesArgs = {
  distinct_on?: InputMaybe<Array<AuthUserRolesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<AuthUserRolesOrderBy>>;
  where?: InputMaybe<AuthUserRolesBoolExp>;
};


export type QueryRootAuthUserRolesAggregateArgs = {
  distinct_on?: InputMaybe<Array<AuthUserRolesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<AuthUserRolesOrderBy>>;
  where?: InputMaybe<AuthUserRolesBoolExp>;
};


export type QueryRootAuthUserSecurityKeyArgs = {
  id: Scalars['uuid']['input'];
};


export type QueryRootAuthUserSecurityKeysArgs = {
  distinct_on?: InputMaybe<Array<AuthUserSecurityKeysSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<AuthUserSecurityKeysOrderBy>>;
  where?: InputMaybe<AuthUserSecurityKeysBoolExp>;
};


export type QueryRootAuthUserSecurityKeysAggregateArgs = {
  distinct_on?: InputMaybe<Array<AuthUserSecurityKeysSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<AuthUserSecurityKeysOrderBy>>;
  where?: InputMaybe<AuthUserSecurityKeysBoolExp>;
};


export type QueryRootBucketArgs = {
  id: Scalars['String']['input'];
};


export type QueryRootBucketsArgs = {
  distinct_on?: InputMaybe<Array<BucketsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<BucketsOrderBy>>;
  where?: InputMaybe<BucketsBoolExp>;
};


export type QueryRootBucketsAggregateArgs = {
  distinct_on?: InputMaybe<Array<BucketsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<BucketsOrderBy>>;
  where?: InputMaybe<BucketsBoolExp>;
};


export type QueryRootFileArgs = {
  id: Scalars['uuid']['input'];
};


export type QueryRootFilesArgs = {
  distinct_on?: InputMaybe<Array<FilesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<FilesOrderBy>>;
  where?: InputMaybe<FilesBoolExp>;
};


export type QueryRootFilesAggregateArgs = {
  distinct_on?: InputMaybe<Array<FilesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<FilesOrderBy>>;
  where?: InputMaybe<FilesBoolExp>;
};


export type QueryRootGenerationPresetsArgs = {
  distinct_on?: InputMaybe<Array<GenerationPresetsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<GenerationPresetsOrderBy>>;
  where?: InputMaybe<GenerationPresetsBoolExp>;
};


export type QueryRootGenerationPresetsAggregateArgs = {
  distinct_on?: InputMaybe<Array<GenerationPresetsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<GenerationPresetsOrderBy>>;
  where?: InputMaybe<GenerationPresetsBoolExp>;
};


export type QueryRootGenerationPresetsByPkArgs = {
  id: Scalars['uuid']['input'];
};


export type QueryRootGenerationsArgs = {
  distinct_on?: InputMaybe<Array<GenerationsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<GenerationsOrderBy>>;
  where?: InputMaybe<GenerationsBoolExp>;
};


export type QueryRootGenerationsAggregateArgs = {
  distinct_on?: InputMaybe<Array<GenerationsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<GenerationsOrderBy>>;
  where?: InputMaybe<GenerationsBoolExp>;
};


export type QueryRootGenerationsByPkArgs = {
  id: Scalars['uuid']['input'];
};


export type QueryRootModelInputTypeArgs = {
  distinct_on?: InputMaybe<Array<ModelInputTypeSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ModelInputTypeOrderBy>>;
  where?: InputMaybe<ModelInputTypeBoolExp>;
};


export type QueryRootModelInputTypeAggregateArgs = {
  distinct_on?: InputMaybe<Array<ModelInputTypeSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ModelInputTypeOrderBy>>;
  where?: InputMaybe<ModelInputTypeBoolExp>;
};


export type QueryRootModelInputTypeByPkArgs = {
  type: Scalars['String']['input'];
};


export type QueryRootModelTypeArgs = {
  distinct_on?: InputMaybe<Array<ModelTypeSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ModelTypeOrderBy>>;
  where?: InputMaybe<ModelTypeBoolExp>;
};


export type QueryRootModelTypeAggregateArgs = {
  distinct_on?: InputMaybe<Array<ModelTypeSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ModelTypeOrderBy>>;
  where?: InputMaybe<ModelTypeBoolExp>;
};


export type QueryRootModelTypeByPkArgs = {
  type: Scalars['String']['input'];
};


export type QueryRootModelsArgs = {
  distinct_on?: InputMaybe<Array<ModelsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ModelsOrderBy>>;
  where?: InputMaybe<ModelsBoolExp>;
};


export type QueryRootModelsAggregateArgs = {
  distinct_on?: InputMaybe<Array<ModelsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ModelsOrderBy>>;
  where?: InputMaybe<ModelsBoolExp>;
};


export type QueryRootModelsByPkArgs = {
  id: Scalars['uuid']['input'];
};


export type QueryRootOrganizationFeaturesArgs = {
  distinct_on?: InputMaybe<Array<OrganizationFeaturesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<OrganizationFeaturesOrderBy>>;
  where?: InputMaybe<OrganizationFeaturesBoolExp>;
};


export type QueryRootOrganizationFeaturesAggregateArgs = {
  distinct_on?: InputMaybe<Array<OrganizationFeaturesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<OrganizationFeaturesOrderBy>>;
  where?: InputMaybe<OrganizationFeaturesBoolExp>;
};


export type QueryRootOrganizationFeaturesByPkArgs = {
  organizationId: Scalars['uuid']['input'];
};


export type QueryRootOrganizationsArgs = {
  distinct_on?: InputMaybe<Array<OrganizationsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<OrganizationsOrderBy>>;
  where?: InputMaybe<OrganizationsBoolExp>;
};


export type QueryRootOrganizationsAggregateArgs = {
  distinct_on?: InputMaybe<Array<OrganizationsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<OrganizationsOrderBy>>;
  where?: InputMaybe<OrganizationsBoolExp>;
};


export type QueryRootOrganizationsByPkArgs = {
  id: Scalars['uuid']['input'];
};


export type QueryRootStatusArgs = {
  distinct_on?: InputMaybe<Array<StatusSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<StatusOrderBy>>;
  where?: InputMaybe<StatusBoolExp>;
};


export type QueryRootStatusAggregateArgs = {
  distinct_on?: InputMaybe<Array<StatusSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<StatusOrderBy>>;
  where?: InputMaybe<StatusBoolExp>;
};


export type QueryRootStatusByPkArgs = {
  name: Scalars['String']['input'];
};


export type QueryRootTransactionTypeArgs = {
  distinct_on?: InputMaybe<Array<TransactionTypeSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<TransactionTypeOrderBy>>;
  where?: InputMaybe<TransactionTypeBoolExp>;
};


export type QueryRootTransactionTypeAggregateArgs = {
  distinct_on?: InputMaybe<Array<TransactionTypeSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<TransactionTypeOrderBy>>;
  where?: InputMaybe<TransactionTypeBoolExp>;
};


export type QueryRootTransactionTypeByPkArgs = {
  type: Scalars['String']['input'];
};


export type QueryRootTransactionsArgs = {
  distinct_on?: InputMaybe<Array<TransactionsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<TransactionsOrderBy>>;
  where?: InputMaybe<TransactionsBoolExp>;
};


export type QueryRootTransactionsAggregateArgs = {
  distinct_on?: InputMaybe<Array<TransactionsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<TransactionsOrderBy>>;
  where?: InputMaybe<TransactionsBoolExp>;
};


export type QueryRootTransactionsByPkArgs = {
  id: Scalars['uuid']['input'];
};


export type QueryRootUserArgs = {
  id: Scalars['uuid']['input'];
};


export type QueryRootUserOrganizationsArgs = {
  distinct_on?: InputMaybe<Array<UserOrganizationsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<UserOrganizationsOrderBy>>;
  where?: InputMaybe<UserOrganizationsBoolExp>;
};


export type QueryRootUserOrganizationsAggregateArgs = {
  distinct_on?: InputMaybe<Array<UserOrganizationsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<UserOrganizationsOrderBy>>;
  where?: InputMaybe<UserOrganizationsBoolExp>;
};


export type QueryRootUserOrganizationsByPkArgs = {
  id: Scalars['uuid']['input'];
};


export type QueryRootUsersArgs = {
  distinct_on?: InputMaybe<Array<UsersSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<UsersOrderBy>>;
  where?: InputMaybe<UsersBoolExp>;
};


export type QueryRootUsersAggregateArgs = {
  distinct_on?: InputMaybe<Array<UsersSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<UsersOrderBy>>;
  where?: InputMaybe<UsersBoolExp>;
};


export type QueryRootVirusArgs = {
  id: Scalars['uuid']['input'];
};


export type QueryRootVirusesArgs = {
  distinct_on?: InputMaybe<Array<VirusSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<VirusOrderBy>>;
  where?: InputMaybe<VirusBoolExp>;
};


export type QueryRootVirusesAggregateArgs = {
  distinct_on?: InputMaybe<Array<VirusSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<VirusOrderBy>>;
  where?: InputMaybe<VirusBoolExp>;
};

/** Enum table for holding statuses */
export type Status = {
  __typename?: 'status';
  name: Scalars['String']['output'];
};

/** aggregated selection of "status" */
export type StatusAggregate = {
  __typename?: 'status_aggregate';
  aggregate?: Maybe<StatusAggregateFields>;
  nodes: Array<Status>;
};

/** aggregate fields of "status" */
export type StatusAggregateFields = {
  __typename?: 'status_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<StatusMaxFields>;
  min?: Maybe<StatusMinFields>;
};


/** aggregate fields of "status" */
export type StatusAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<StatusSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "status". All fields are combined with a logical 'AND'. */
export type StatusBoolExp = {
  _and?: InputMaybe<Array<StatusBoolExp>>;
  _not?: InputMaybe<StatusBoolExp>;
  _or?: InputMaybe<Array<StatusBoolExp>>;
  name?: InputMaybe<StringComparisonExp>;
};

/** unique or primary key constraints on table "status" */
export enum StatusConstraint {
  /** unique or primary key constraint on columns "name" */
  STATUS_PKEY = 'status_pkey'
}

export enum StatusEnum {
  COMPLETED = 'COMPLETED',
  FAILED = 'FAILED',
  PENDING = 'PENDING',
  PROCESSING = 'PROCESSING',
  REJECTED = 'REJECTED'
}

/** Boolean expression to compare columns of type "status_enum". All fields are combined with logical 'AND'. */
export type StatusEnumComparisonExp = {
  _eq?: InputMaybe<StatusEnum>;
  _in?: InputMaybe<Array<StatusEnum>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _neq?: InputMaybe<StatusEnum>;
  _nin?: InputMaybe<Array<StatusEnum>>;
};

/** input type for inserting data into table "status" */
export type StatusInsertInput = {
  name?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type StatusMaxFields = {
  __typename?: 'status_max_fields';
  name?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type StatusMinFields = {
  __typename?: 'status_min_fields';
  name?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "status" */
export type StatusMutationResponse = {
  __typename?: 'status_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Status>;
};

/** on_conflict condition type for table "status" */
export type StatusOnConflict = {
  constraint: StatusConstraint;
  update_columns?: Array<StatusUpdateColumn>;
  where?: InputMaybe<StatusBoolExp>;
};

/** Ordering options when selecting data from "status". */
export type StatusOrderBy = {
  name?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: status */
export type StatusPkColumnsInput = {
  name: Scalars['String']['input'];
};

/** select columns of table "status" */
export enum StatusSelectColumn {
  /** column name */
  NAME = 'name'
}

/** input type for updating data in table "status" */
export type StatusSetInput = {
  name?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "status" */
export type StatusStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: StatusStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type StatusStreamCursorValueInput = {
  name?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "status" */
export enum StatusUpdateColumn {
  /** column name */
  NAME = 'name'
}

export type StatusUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<StatusSetInput>;
  /** filter the rows which have to be updated */
  where: StatusBoolExp;
};

export type SubscriptionRoot = {
  __typename?: 'subscription_root';
  /** fetch data from the table: "api_keys" */
  api_keys: Array<ApiKeys>;
  /** fetch aggregated fields from the table: "api_keys" */
  api_keys_aggregate: ApiKeysAggregate;
  /** fetch data from the table: "api_keys" using primary key columns */
  api_keys_by_pk?: Maybe<ApiKeys>;
  /** fetch data from the table in a streaming manner: "api_keys" */
  api_keys_stream: Array<ApiKeys>;
  /** fetch data from the table: "auth.providers" using primary key columns */
  authProvider?: Maybe<AuthProviders>;
  /** fetch data from the table: "auth.provider_requests" using primary key columns */
  authProviderRequest?: Maybe<AuthProviderRequests>;
  /** fetch data from the table: "auth.provider_requests" */
  authProviderRequests: Array<AuthProviderRequests>;
  /** fetch aggregated fields from the table: "auth.provider_requests" */
  authProviderRequestsAggregate: AuthProviderRequestsAggregate;
  /** fetch data from the table in a streaming manner: "auth.provider_requests" */
  authProviderRequests_stream: Array<AuthProviderRequests>;
  /** fetch data from the table: "auth.providers" */
  authProviders: Array<AuthProviders>;
  /** fetch aggregated fields from the table: "auth.providers" */
  authProvidersAggregate: AuthProvidersAggregate;
  /** fetch data from the table in a streaming manner: "auth.providers" */
  authProviders_stream: Array<AuthProviders>;
  /** fetch data from the table: "auth.refresh_tokens" using primary key columns */
  authRefreshToken?: Maybe<AuthRefreshTokens>;
  /** fetch data from the table: "auth.refresh_token_types" using primary key columns */
  authRefreshTokenType?: Maybe<AuthRefreshTokenTypes>;
  /** fetch data from the table: "auth.refresh_token_types" */
  authRefreshTokenTypes: Array<AuthRefreshTokenTypes>;
  /** fetch aggregated fields from the table: "auth.refresh_token_types" */
  authRefreshTokenTypesAggregate: AuthRefreshTokenTypesAggregate;
  /** fetch data from the table in a streaming manner: "auth.refresh_token_types" */
  authRefreshTokenTypes_stream: Array<AuthRefreshTokenTypes>;
  /** fetch data from the table: "auth.refresh_tokens" */
  authRefreshTokens: Array<AuthRefreshTokens>;
  /** fetch aggregated fields from the table: "auth.refresh_tokens" */
  authRefreshTokensAggregate: AuthRefreshTokensAggregate;
  /** fetch data from the table in a streaming manner: "auth.refresh_tokens" */
  authRefreshTokens_stream: Array<AuthRefreshTokens>;
  /** fetch data from the table: "auth.roles" using primary key columns */
  authRole?: Maybe<AuthRoles>;
  /** fetch data from the table: "auth.roles" */
  authRoles: Array<AuthRoles>;
  /** fetch aggregated fields from the table: "auth.roles" */
  authRolesAggregate: AuthRolesAggregate;
  /** fetch data from the table in a streaming manner: "auth.roles" */
  authRoles_stream: Array<AuthRoles>;
  /** fetch data from the table: "auth.user_providers" using primary key columns */
  authUserProvider?: Maybe<AuthUserProviders>;
  /** fetch data from the table: "auth.user_providers" */
  authUserProviders: Array<AuthUserProviders>;
  /** fetch aggregated fields from the table: "auth.user_providers" */
  authUserProvidersAggregate: AuthUserProvidersAggregate;
  /** fetch data from the table in a streaming manner: "auth.user_providers" */
  authUserProviders_stream: Array<AuthUserProviders>;
  /** fetch data from the table: "auth.user_roles" using primary key columns */
  authUserRole?: Maybe<AuthUserRoles>;
  /** fetch data from the table: "auth.user_roles" */
  authUserRoles: Array<AuthUserRoles>;
  /** fetch aggregated fields from the table: "auth.user_roles" */
  authUserRolesAggregate: AuthUserRolesAggregate;
  /** fetch data from the table in a streaming manner: "auth.user_roles" */
  authUserRoles_stream: Array<AuthUserRoles>;
  /** fetch data from the table: "auth.user_security_keys" using primary key columns */
  authUserSecurityKey?: Maybe<AuthUserSecurityKeys>;
  /** fetch data from the table: "auth.user_security_keys" */
  authUserSecurityKeys: Array<AuthUserSecurityKeys>;
  /** fetch aggregated fields from the table: "auth.user_security_keys" */
  authUserSecurityKeysAggregate: AuthUserSecurityKeysAggregate;
  /** fetch data from the table in a streaming manner: "auth.user_security_keys" */
  authUserSecurityKeys_stream: Array<AuthUserSecurityKeys>;
  /** fetch data from the table: "storage.buckets" using primary key columns */
  bucket?: Maybe<Buckets>;
  /** fetch data from the table: "storage.buckets" */
  buckets: Array<Buckets>;
  /** fetch aggregated fields from the table: "storage.buckets" */
  bucketsAggregate: BucketsAggregate;
  /** fetch data from the table in a streaming manner: "storage.buckets" */
  buckets_stream: Array<Buckets>;
  /** fetch data from the table: "storage.files" using primary key columns */
  file?: Maybe<Files>;
  /** An array relationship */
  files: Array<Files>;
  /** fetch aggregated fields from the table: "storage.files" */
  filesAggregate: FilesAggregate;
  /** fetch data from the table in a streaming manner: "storage.files" */
  files_stream: Array<Files>;
  /** fetch data from the table: "generation_presets" */
  generation_presets: Array<GenerationPresets>;
  /** fetch aggregated fields from the table: "generation_presets" */
  generation_presets_aggregate: GenerationPresetsAggregate;
  /** fetch data from the table: "generation_presets" using primary key columns */
  generation_presets_by_pk?: Maybe<GenerationPresets>;
  /** fetch data from the table in a streaming manner: "generation_presets" */
  generation_presets_stream: Array<GenerationPresets>;
  /** fetch data from the table: "generations" */
  generations: Array<Generations>;
  /** fetch aggregated fields from the table: "generations" */
  generations_aggregate: GenerationsAggregate;
  /** fetch data from the table: "generations" using primary key columns */
  generations_by_pk?: Maybe<Generations>;
  /** fetch data from the table in a streaming manner: "generations" */
  generations_stream: Array<Generations>;
  /** fetch data from the table: "model_input_type" */
  model_input_type: Array<ModelInputType>;
  /** fetch aggregated fields from the table: "model_input_type" */
  model_input_type_aggregate: ModelInputTypeAggregate;
  /** fetch data from the table: "model_input_type" using primary key columns */
  model_input_type_by_pk?: Maybe<ModelInputType>;
  /** fetch data from the table in a streaming manner: "model_input_type" */
  model_input_type_stream: Array<ModelInputType>;
  /** fetch data from the table: "model_type" */
  model_type: Array<ModelType>;
  /** fetch aggregated fields from the table: "model_type" */
  model_type_aggregate: ModelTypeAggregate;
  /** fetch data from the table: "model_type" using primary key columns */
  model_type_by_pk?: Maybe<ModelType>;
  /** fetch data from the table in a streaming manner: "model_type" */
  model_type_stream: Array<ModelType>;
  /** fetch data from the table: "models" */
  models: Array<Models>;
  /** fetch aggregated fields from the table: "models" */
  models_aggregate: ModelsAggregate;
  /** fetch data from the table: "models" using primary key columns */
  models_by_pk?: Maybe<Models>;
  /** fetch data from the table in a streaming manner: "models" */
  models_stream: Array<Models>;
  /** fetch data from the table: "organization_features" */
  organization_features: Array<OrganizationFeatures>;
  /** fetch aggregated fields from the table: "organization_features" */
  organization_features_aggregate: OrganizationFeaturesAggregate;
  /** fetch data from the table: "organization_features" using primary key columns */
  organization_features_by_pk?: Maybe<OrganizationFeatures>;
  /** fetch data from the table in a streaming manner: "organization_features" */
  organization_features_stream: Array<OrganizationFeatures>;
  /** fetch data from the table: "organizations" */
  organizations: Array<Organizations>;
  /** fetch aggregated fields from the table: "organizations" */
  organizations_aggregate: OrganizationsAggregate;
  /** fetch data from the table: "organizations" using primary key columns */
  organizations_by_pk?: Maybe<Organizations>;
  /** fetch data from the table in a streaming manner: "organizations" */
  organizations_stream: Array<Organizations>;
  /** fetch data from the table: "status" */
  status: Array<Status>;
  /** fetch aggregated fields from the table: "status" */
  status_aggregate: StatusAggregate;
  /** fetch data from the table: "status" using primary key columns */
  status_by_pk?: Maybe<Status>;
  /** fetch data from the table in a streaming manner: "status" */
  status_stream: Array<Status>;
  /** fetch data from the table: "transaction_type" */
  transaction_type: Array<TransactionType>;
  /** fetch aggregated fields from the table: "transaction_type" */
  transaction_type_aggregate: TransactionTypeAggregate;
  /** fetch data from the table: "transaction_type" using primary key columns */
  transaction_type_by_pk?: Maybe<TransactionType>;
  /** fetch data from the table in a streaming manner: "transaction_type" */
  transaction_type_stream: Array<TransactionType>;
  /** fetch data from the table: "transactions" */
  transactions: Array<Transactions>;
  /** fetch aggregated fields from the table: "transactions" */
  transactions_aggregate: TransactionsAggregate;
  /** fetch data from the table: "transactions" using primary key columns */
  transactions_by_pk?: Maybe<Transactions>;
  /** fetch data from the table in a streaming manner: "transactions" */
  transactions_stream: Array<Transactions>;
  /** fetch data from the table: "auth.users" using primary key columns */
  user?: Maybe<Users>;
  /** fetch data from the table: "user_organizations" */
  user_organizations: Array<UserOrganizations>;
  /** fetch aggregated fields from the table: "user_organizations" */
  user_organizations_aggregate: UserOrganizationsAggregate;
  /** fetch data from the table: "user_organizations" using primary key columns */
  user_organizations_by_pk?: Maybe<UserOrganizations>;
  /** fetch data from the table in a streaming manner: "user_organizations" */
  user_organizations_stream: Array<UserOrganizations>;
  /** fetch data from the table: "auth.users" */
  users: Array<Users>;
  /** fetch aggregated fields from the table: "auth.users" */
  usersAggregate: UsersAggregate;
  /** fetch data from the table in a streaming manner: "auth.users" */
  users_stream: Array<Users>;
  /** fetch data from the table: "storage.virus" using primary key columns */
  virus?: Maybe<Virus>;
  /** fetch data from the table in a streaming manner: "storage.virus" */
  virus_stream: Array<Virus>;
  /** fetch data from the table: "storage.virus" */
  viruses: Array<Virus>;
  /** fetch aggregated fields from the table: "storage.virus" */
  virusesAggregate: VirusAggregate;
};


export type SubscriptionRootApiKeysArgs = {
  distinct_on?: InputMaybe<Array<ApiKeysSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ApiKeysOrderBy>>;
  where?: InputMaybe<ApiKeysBoolExp>;
};


export type SubscriptionRootApiKeysAggregateArgs = {
  distinct_on?: InputMaybe<Array<ApiKeysSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ApiKeysOrderBy>>;
  where?: InputMaybe<ApiKeysBoolExp>;
};


export type SubscriptionRootApiKeysByPkArgs = {
  id: Scalars['uuid']['input'];
};


export type SubscriptionRootApiKeysStreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<ApiKeysStreamCursorInput>>;
  where?: InputMaybe<ApiKeysBoolExp>;
};


export type SubscriptionRootAuthProviderArgs = {
  id: Scalars['String']['input'];
};


export type SubscriptionRootAuthProviderRequestArgs = {
  id: Scalars['uuid']['input'];
};


export type SubscriptionRootAuthProviderRequestsArgs = {
  distinct_on?: InputMaybe<Array<AuthProviderRequestsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<AuthProviderRequestsOrderBy>>;
  where?: InputMaybe<AuthProviderRequestsBoolExp>;
};


export type SubscriptionRootAuthProviderRequestsAggregateArgs = {
  distinct_on?: InputMaybe<Array<AuthProviderRequestsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<AuthProviderRequestsOrderBy>>;
  where?: InputMaybe<AuthProviderRequestsBoolExp>;
};


export type SubscriptionRootAuthProviderRequestsStreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<AuthProviderRequestsStreamCursorInput>>;
  where?: InputMaybe<AuthProviderRequestsBoolExp>;
};


export type SubscriptionRootAuthProvidersArgs = {
  distinct_on?: InputMaybe<Array<AuthProvidersSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<AuthProvidersOrderBy>>;
  where?: InputMaybe<AuthProvidersBoolExp>;
};


export type SubscriptionRootAuthProvidersAggregateArgs = {
  distinct_on?: InputMaybe<Array<AuthProvidersSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<AuthProvidersOrderBy>>;
  where?: InputMaybe<AuthProvidersBoolExp>;
};


export type SubscriptionRootAuthProvidersStreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<AuthProvidersStreamCursorInput>>;
  where?: InputMaybe<AuthProvidersBoolExp>;
};


export type SubscriptionRootAuthRefreshTokenArgs = {
  id: Scalars['uuid']['input'];
};


export type SubscriptionRootAuthRefreshTokenTypeArgs = {
  value: Scalars['String']['input'];
};


export type SubscriptionRootAuthRefreshTokenTypesArgs = {
  distinct_on?: InputMaybe<Array<AuthRefreshTokenTypesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<AuthRefreshTokenTypesOrderBy>>;
  where?: InputMaybe<AuthRefreshTokenTypesBoolExp>;
};


export type SubscriptionRootAuthRefreshTokenTypesAggregateArgs = {
  distinct_on?: InputMaybe<Array<AuthRefreshTokenTypesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<AuthRefreshTokenTypesOrderBy>>;
  where?: InputMaybe<AuthRefreshTokenTypesBoolExp>;
};


export type SubscriptionRootAuthRefreshTokenTypesStreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<AuthRefreshTokenTypesStreamCursorInput>>;
  where?: InputMaybe<AuthRefreshTokenTypesBoolExp>;
};


export type SubscriptionRootAuthRefreshTokensArgs = {
  distinct_on?: InputMaybe<Array<AuthRefreshTokensSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<AuthRefreshTokensOrderBy>>;
  where?: InputMaybe<AuthRefreshTokensBoolExp>;
};


export type SubscriptionRootAuthRefreshTokensAggregateArgs = {
  distinct_on?: InputMaybe<Array<AuthRefreshTokensSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<AuthRefreshTokensOrderBy>>;
  where?: InputMaybe<AuthRefreshTokensBoolExp>;
};


export type SubscriptionRootAuthRefreshTokensStreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<AuthRefreshTokensStreamCursorInput>>;
  where?: InputMaybe<AuthRefreshTokensBoolExp>;
};


export type SubscriptionRootAuthRoleArgs = {
  role: Scalars['String']['input'];
};


export type SubscriptionRootAuthRolesArgs = {
  distinct_on?: InputMaybe<Array<AuthRolesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<AuthRolesOrderBy>>;
  where?: InputMaybe<AuthRolesBoolExp>;
};


export type SubscriptionRootAuthRolesAggregateArgs = {
  distinct_on?: InputMaybe<Array<AuthRolesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<AuthRolesOrderBy>>;
  where?: InputMaybe<AuthRolesBoolExp>;
};


export type SubscriptionRootAuthRolesStreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<AuthRolesStreamCursorInput>>;
  where?: InputMaybe<AuthRolesBoolExp>;
};


export type SubscriptionRootAuthUserProviderArgs = {
  id: Scalars['uuid']['input'];
};


export type SubscriptionRootAuthUserProvidersArgs = {
  distinct_on?: InputMaybe<Array<AuthUserProvidersSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<AuthUserProvidersOrderBy>>;
  where?: InputMaybe<AuthUserProvidersBoolExp>;
};


export type SubscriptionRootAuthUserProvidersAggregateArgs = {
  distinct_on?: InputMaybe<Array<AuthUserProvidersSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<AuthUserProvidersOrderBy>>;
  where?: InputMaybe<AuthUserProvidersBoolExp>;
};


export type SubscriptionRootAuthUserProvidersStreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<AuthUserProvidersStreamCursorInput>>;
  where?: InputMaybe<AuthUserProvidersBoolExp>;
};


export type SubscriptionRootAuthUserRoleArgs = {
  id: Scalars['uuid']['input'];
};


export type SubscriptionRootAuthUserRolesArgs = {
  distinct_on?: InputMaybe<Array<AuthUserRolesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<AuthUserRolesOrderBy>>;
  where?: InputMaybe<AuthUserRolesBoolExp>;
};


export type SubscriptionRootAuthUserRolesAggregateArgs = {
  distinct_on?: InputMaybe<Array<AuthUserRolesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<AuthUserRolesOrderBy>>;
  where?: InputMaybe<AuthUserRolesBoolExp>;
};


export type SubscriptionRootAuthUserRolesStreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<AuthUserRolesStreamCursorInput>>;
  where?: InputMaybe<AuthUserRolesBoolExp>;
};


export type SubscriptionRootAuthUserSecurityKeyArgs = {
  id: Scalars['uuid']['input'];
};


export type SubscriptionRootAuthUserSecurityKeysArgs = {
  distinct_on?: InputMaybe<Array<AuthUserSecurityKeysSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<AuthUserSecurityKeysOrderBy>>;
  where?: InputMaybe<AuthUserSecurityKeysBoolExp>;
};


export type SubscriptionRootAuthUserSecurityKeysAggregateArgs = {
  distinct_on?: InputMaybe<Array<AuthUserSecurityKeysSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<AuthUserSecurityKeysOrderBy>>;
  where?: InputMaybe<AuthUserSecurityKeysBoolExp>;
};


export type SubscriptionRootAuthUserSecurityKeysStreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<AuthUserSecurityKeysStreamCursorInput>>;
  where?: InputMaybe<AuthUserSecurityKeysBoolExp>;
};


export type SubscriptionRootBucketArgs = {
  id: Scalars['String']['input'];
};


export type SubscriptionRootBucketsArgs = {
  distinct_on?: InputMaybe<Array<BucketsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<BucketsOrderBy>>;
  where?: InputMaybe<BucketsBoolExp>;
};


export type SubscriptionRootBucketsAggregateArgs = {
  distinct_on?: InputMaybe<Array<BucketsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<BucketsOrderBy>>;
  where?: InputMaybe<BucketsBoolExp>;
};


export type SubscriptionRootBucketsStreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<BucketsStreamCursorInput>>;
  where?: InputMaybe<BucketsBoolExp>;
};


export type SubscriptionRootFileArgs = {
  id: Scalars['uuid']['input'];
};


export type SubscriptionRootFilesArgs = {
  distinct_on?: InputMaybe<Array<FilesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<FilesOrderBy>>;
  where?: InputMaybe<FilesBoolExp>;
};


export type SubscriptionRootFilesAggregateArgs = {
  distinct_on?: InputMaybe<Array<FilesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<FilesOrderBy>>;
  where?: InputMaybe<FilesBoolExp>;
};


export type SubscriptionRootFilesStreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<FilesStreamCursorInput>>;
  where?: InputMaybe<FilesBoolExp>;
};


export type SubscriptionRootGenerationPresetsArgs = {
  distinct_on?: InputMaybe<Array<GenerationPresetsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<GenerationPresetsOrderBy>>;
  where?: InputMaybe<GenerationPresetsBoolExp>;
};


export type SubscriptionRootGenerationPresetsAggregateArgs = {
  distinct_on?: InputMaybe<Array<GenerationPresetsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<GenerationPresetsOrderBy>>;
  where?: InputMaybe<GenerationPresetsBoolExp>;
};


export type SubscriptionRootGenerationPresetsByPkArgs = {
  id: Scalars['uuid']['input'];
};


export type SubscriptionRootGenerationPresetsStreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<GenerationPresetsStreamCursorInput>>;
  where?: InputMaybe<GenerationPresetsBoolExp>;
};


export type SubscriptionRootGenerationsArgs = {
  distinct_on?: InputMaybe<Array<GenerationsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<GenerationsOrderBy>>;
  where?: InputMaybe<GenerationsBoolExp>;
};


export type SubscriptionRootGenerationsAggregateArgs = {
  distinct_on?: InputMaybe<Array<GenerationsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<GenerationsOrderBy>>;
  where?: InputMaybe<GenerationsBoolExp>;
};


export type SubscriptionRootGenerationsByPkArgs = {
  id: Scalars['uuid']['input'];
};


export type SubscriptionRootGenerationsStreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<GenerationsStreamCursorInput>>;
  where?: InputMaybe<GenerationsBoolExp>;
};


export type SubscriptionRootModelInputTypeArgs = {
  distinct_on?: InputMaybe<Array<ModelInputTypeSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ModelInputTypeOrderBy>>;
  where?: InputMaybe<ModelInputTypeBoolExp>;
};


export type SubscriptionRootModelInputTypeAggregateArgs = {
  distinct_on?: InputMaybe<Array<ModelInputTypeSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ModelInputTypeOrderBy>>;
  where?: InputMaybe<ModelInputTypeBoolExp>;
};


export type SubscriptionRootModelInputTypeByPkArgs = {
  type: Scalars['String']['input'];
};


export type SubscriptionRootModelInputTypeStreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<ModelInputTypeStreamCursorInput>>;
  where?: InputMaybe<ModelInputTypeBoolExp>;
};


export type SubscriptionRootModelTypeArgs = {
  distinct_on?: InputMaybe<Array<ModelTypeSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ModelTypeOrderBy>>;
  where?: InputMaybe<ModelTypeBoolExp>;
};


export type SubscriptionRootModelTypeAggregateArgs = {
  distinct_on?: InputMaybe<Array<ModelTypeSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ModelTypeOrderBy>>;
  where?: InputMaybe<ModelTypeBoolExp>;
};


export type SubscriptionRootModelTypeByPkArgs = {
  type: Scalars['String']['input'];
};


export type SubscriptionRootModelTypeStreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<ModelTypeStreamCursorInput>>;
  where?: InputMaybe<ModelTypeBoolExp>;
};


export type SubscriptionRootModelsArgs = {
  distinct_on?: InputMaybe<Array<ModelsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ModelsOrderBy>>;
  where?: InputMaybe<ModelsBoolExp>;
};


export type SubscriptionRootModelsAggregateArgs = {
  distinct_on?: InputMaybe<Array<ModelsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ModelsOrderBy>>;
  where?: InputMaybe<ModelsBoolExp>;
};


export type SubscriptionRootModelsByPkArgs = {
  id: Scalars['uuid']['input'];
};


export type SubscriptionRootModelsStreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<ModelsStreamCursorInput>>;
  where?: InputMaybe<ModelsBoolExp>;
};


export type SubscriptionRootOrganizationFeaturesArgs = {
  distinct_on?: InputMaybe<Array<OrganizationFeaturesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<OrganizationFeaturesOrderBy>>;
  where?: InputMaybe<OrganizationFeaturesBoolExp>;
};


export type SubscriptionRootOrganizationFeaturesAggregateArgs = {
  distinct_on?: InputMaybe<Array<OrganizationFeaturesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<OrganizationFeaturesOrderBy>>;
  where?: InputMaybe<OrganizationFeaturesBoolExp>;
};


export type SubscriptionRootOrganizationFeaturesByPkArgs = {
  organizationId: Scalars['uuid']['input'];
};


export type SubscriptionRootOrganizationFeaturesStreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<OrganizationFeaturesStreamCursorInput>>;
  where?: InputMaybe<OrganizationFeaturesBoolExp>;
};


export type SubscriptionRootOrganizationsArgs = {
  distinct_on?: InputMaybe<Array<OrganizationsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<OrganizationsOrderBy>>;
  where?: InputMaybe<OrganizationsBoolExp>;
};


export type SubscriptionRootOrganizationsAggregateArgs = {
  distinct_on?: InputMaybe<Array<OrganizationsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<OrganizationsOrderBy>>;
  where?: InputMaybe<OrganizationsBoolExp>;
};


export type SubscriptionRootOrganizationsByPkArgs = {
  id: Scalars['uuid']['input'];
};


export type SubscriptionRootOrganizationsStreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<OrganizationsStreamCursorInput>>;
  where?: InputMaybe<OrganizationsBoolExp>;
};


export type SubscriptionRootStatusArgs = {
  distinct_on?: InputMaybe<Array<StatusSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<StatusOrderBy>>;
  where?: InputMaybe<StatusBoolExp>;
};


export type SubscriptionRootStatusAggregateArgs = {
  distinct_on?: InputMaybe<Array<StatusSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<StatusOrderBy>>;
  where?: InputMaybe<StatusBoolExp>;
};


export type SubscriptionRootStatusByPkArgs = {
  name: Scalars['String']['input'];
};


export type SubscriptionRootStatusStreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<StatusStreamCursorInput>>;
  where?: InputMaybe<StatusBoolExp>;
};


export type SubscriptionRootTransactionTypeArgs = {
  distinct_on?: InputMaybe<Array<TransactionTypeSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<TransactionTypeOrderBy>>;
  where?: InputMaybe<TransactionTypeBoolExp>;
};


export type SubscriptionRootTransactionTypeAggregateArgs = {
  distinct_on?: InputMaybe<Array<TransactionTypeSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<TransactionTypeOrderBy>>;
  where?: InputMaybe<TransactionTypeBoolExp>;
};


export type SubscriptionRootTransactionTypeByPkArgs = {
  type: Scalars['String']['input'];
};


export type SubscriptionRootTransactionTypeStreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<TransactionTypeStreamCursorInput>>;
  where?: InputMaybe<TransactionTypeBoolExp>;
};


export type SubscriptionRootTransactionsArgs = {
  distinct_on?: InputMaybe<Array<TransactionsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<TransactionsOrderBy>>;
  where?: InputMaybe<TransactionsBoolExp>;
};


export type SubscriptionRootTransactionsAggregateArgs = {
  distinct_on?: InputMaybe<Array<TransactionsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<TransactionsOrderBy>>;
  where?: InputMaybe<TransactionsBoolExp>;
};


export type SubscriptionRootTransactionsByPkArgs = {
  id: Scalars['uuid']['input'];
};


export type SubscriptionRootTransactionsStreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<TransactionsStreamCursorInput>>;
  where?: InputMaybe<TransactionsBoolExp>;
};


export type SubscriptionRootUserArgs = {
  id: Scalars['uuid']['input'];
};


export type SubscriptionRootUserOrganizationsArgs = {
  distinct_on?: InputMaybe<Array<UserOrganizationsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<UserOrganizationsOrderBy>>;
  where?: InputMaybe<UserOrganizationsBoolExp>;
};


export type SubscriptionRootUserOrganizationsAggregateArgs = {
  distinct_on?: InputMaybe<Array<UserOrganizationsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<UserOrganizationsOrderBy>>;
  where?: InputMaybe<UserOrganizationsBoolExp>;
};


export type SubscriptionRootUserOrganizationsByPkArgs = {
  id: Scalars['uuid']['input'];
};


export type SubscriptionRootUserOrganizationsStreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<UserOrganizationsStreamCursorInput>>;
  where?: InputMaybe<UserOrganizationsBoolExp>;
};


export type SubscriptionRootUsersArgs = {
  distinct_on?: InputMaybe<Array<UsersSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<UsersOrderBy>>;
  where?: InputMaybe<UsersBoolExp>;
};


export type SubscriptionRootUsersAggregateArgs = {
  distinct_on?: InputMaybe<Array<UsersSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<UsersOrderBy>>;
  where?: InputMaybe<UsersBoolExp>;
};


export type SubscriptionRootUsersStreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<UsersStreamCursorInput>>;
  where?: InputMaybe<UsersBoolExp>;
};


export type SubscriptionRootVirusArgs = {
  id: Scalars['uuid']['input'];
};


export type SubscriptionRootVirusStreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<VirusStreamCursorInput>>;
  where?: InputMaybe<VirusBoolExp>;
};


export type SubscriptionRootVirusesArgs = {
  distinct_on?: InputMaybe<Array<VirusSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<VirusOrderBy>>;
  where?: InputMaybe<VirusBoolExp>;
};


export type SubscriptionRootVirusesAggregateArgs = {
  distinct_on?: InputMaybe<Array<VirusSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<VirusOrderBy>>;
  where?: InputMaybe<VirusBoolExp>;
};

/** Boolean expression to compare columns of type "timestamptz". All fields are combined with logical 'AND'. */
export type TimestamptzComparisonExp = {
  _eq?: InputMaybe<Scalars['timestamptz']['input']>;
  _gt?: InputMaybe<Scalars['timestamptz']['input']>;
  _gte?: InputMaybe<Scalars['timestamptz']['input']>;
  _in?: InputMaybe<Array<Scalars['timestamptz']['input']>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['timestamptz']['input']>;
  _lte?: InputMaybe<Scalars['timestamptz']['input']>;
  _neq?: InputMaybe<Scalars['timestamptz']['input']>;
  _nin?: InputMaybe<Array<Scalars['timestamptz']['input']>>;
};

/** Enum table for holding transaction types */
export type TransactionType = {
  __typename?: 'transaction_type';
  type: Scalars['String']['output'];
};

/** aggregated selection of "transaction_type" */
export type TransactionTypeAggregate = {
  __typename?: 'transaction_type_aggregate';
  aggregate?: Maybe<TransactionTypeAggregateFields>;
  nodes: Array<TransactionType>;
};

/** aggregate fields of "transaction_type" */
export type TransactionTypeAggregateFields = {
  __typename?: 'transaction_type_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<TransactionTypeMaxFields>;
  min?: Maybe<TransactionTypeMinFields>;
};


/** aggregate fields of "transaction_type" */
export type TransactionTypeAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<TransactionTypeSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "transaction_type". All fields are combined with a logical 'AND'. */
export type TransactionTypeBoolExp = {
  _and?: InputMaybe<Array<TransactionTypeBoolExp>>;
  _not?: InputMaybe<TransactionTypeBoolExp>;
  _or?: InputMaybe<Array<TransactionTypeBoolExp>>;
  type?: InputMaybe<StringComparisonExp>;
};

/** unique or primary key constraints on table "transaction_type" */
export enum TransactionTypeConstraint {
  /** unique or primary key constraint on columns "type" */
  TRANSACTION_TYPE_PKEY = 'transaction_type_pkey'
}

export enum TransactionTypeEnum {
  DEPOSIT = 'DEPOSIT',
  GENERATION = 'GENERATION'
}

/** Boolean expression to compare columns of type "transaction_type_enum". All fields are combined with logical 'AND'. */
export type TransactionTypeEnumComparisonExp = {
  _eq?: InputMaybe<TransactionTypeEnum>;
  _in?: InputMaybe<Array<TransactionTypeEnum>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _neq?: InputMaybe<TransactionTypeEnum>;
  _nin?: InputMaybe<Array<TransactionTypeEnum>>;
};

/** input type for inserting data into table "transaction_type" */
export type TransactionTypeInsertInput = {
  type?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type TransactionTypeMaxFields = {
  __typename?: 'transaction_type_max_fields';
  type?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type TransactionTypeMinFields = {
  __typename?: 'transaction_type_min_fields';
  type?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "transaction_type" */
export type TransactionTypeMutationResponse = {
  __typename?: 'transaction_type_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<TransactionType>;
};

/** on_conflict condition type for table "transaction_type" */
export type TransactionTypeOnConflict = {
  constraint: TransactionTypeConstraint;
  update_columns?: Array<TransactionTypeUpdateColumn>;
  where?: InputMaybe<TransactionTypeBoolExp>;
};

/** Ordering options when selecting data from "transaction_type". */
export type TransactionTypeOrderBy = {
  type?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: transaction_type */
export type TransactionTypePkColumnsInput = {
  type: Scalars['String']['input'];
};

/** select columns of table "transaction_type" */
export enum TransactionTypeSelectColumn {
  /** column name */
  TYPE = 'type'
}

/** input type for updating data in table "transaction_type" */
export type TransactionTypeSetInput = {
  type?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "transaction_type" */
export type TransactionTypeStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: TransactionTypeStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type TransactionTypeStreamCursorValueInput = {
  type?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "transaction_type" */
export enum TransactionTypeUpdateColumn {
  /** column name */
  TYPE = 'type'
}

export type TransactionTypeUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<TransactionTypeSetInput>;
  /** filter the rows which have to be updated */
  where: TransactionTypeBoolExp;
};

/** Stores information about transactions */
export type Transactions = {
  __typename?: 'transactions';
  amount?: Maybe<Scalars['Int']['output']>;
  createdAt: Scalars['timestamptz']['output'];
  framesGenerated?: Maybe<Scalars['Int']['output']>;
  /** An object relationship */
  generation?: Maybe<Generations>;
  generationId?: Maybe<Scalars['uuid']['output']>;
  id: Scalars['uuid']['output'];
  /** An object relationship */
  organization: Organizations;
  organizationId: Scalars['uuid']['output'];
  status: StatusEnum;
  stripeTransactionId?: Maybe<Scalars['String']['output']>;
  type: TransactionTypeEnum;
  updatedAt: Scalars['timestamptz']['output'];
};

/** aggregated selection of "transactions" */
export type TransactionsAggregate = {
  __typename?: 'transactions_aggregate';
  aggregate?: Maybe<TransactionsAggregateFields>;
  nodes: Array<Transactions>;
};

/** aggregate fields of "transactions" */
export type TransactionsAggregateFields = {
  __typename?: 'transactions_aggregate_fields';
  avg?: Maybe<TransactionsAvgFields>;
  count: Scalars['Int']['output'];
  max?: Maybe<TransactionsMaxFields>;
  min?: Maybe<TransactionsMinFields>;
  stddev?: Maybe<TransactionsStddevFields>;
  stddev_pop?: Maybe<TransactionsStddevPopFields>;
  stddev_samp?: Maybe<TransactionsStddevSampFields>;
  sum?: Maybe<TransactionsSumFields>;
  var_pop?: Maybe<TransactionsVarPopFields>;
  var_samp?: Maybe<TransactionsVarSampFields>;
  variance?: Maybe<TransactionsVarianceFields>;
};


/** aggregate fields of "transactions" */
export type TransactionsAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<TransactionsSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type TransactionsAvgFields = {
  __typename?: 'transactions_avg_fields';
  amount?: Maybe<Scalars['Float']['output']>;
  framesGenerated?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "transactions". All fields are combined with a logical 'AND'. */
export type TransactionsBoolExp = {
  _and?: InputMaybe<Array<TransactionsBoolExp>>;
  _not?: InputMaybe<TransactionsBoolExp>;
  _or?: InputMaybe<Array<TransactionsBoolExp>>;
  amount?: InputMaybe<IntComparisonExp>;
  createdAt?: InputMaybe<TimestamptzComparisonExp>;
  framesGenerated?: InputMaybe<IntComparisonExp>;
  generation?: InputMaybe<GenerationsBoolExp>;
  generationId?: InputMaybe<UuidComparisonExp>;
  id?: InputMaybe<UuidComparisonExp>;
  organization?: InputMaybe<OrganizationsBoolExp>;
  organizationId?: InputMaybe<UuidComparisonExp>;
  status?: InputMaybe<StatusEnumComparisonExp>;
  stripeTransactionId?: InputMaybe<StringComparisonExp>;
  type?: InputMaybe<TransactionTypeEnumComparisonExp>;
  updatedAt?: InputMaybe<TimestamptzComparisonExp>;
};

/** unique or primary key constraints on table "transactions" */
export enum TransactionsConstraint {
  /** unique or primary key constraint on columns "id" */
  TRANSACTIONS_PKEY = 'transactions_pkey'
}

/** input type for incrementing numeric columns in table "transactions" */
export type TransactionsIncInput = {
  amount?: InputMaybe<Scalars['Int']['input']>;
  framesGenerated?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "transactions" */
export type TransactionsInsertInput = {
  amount?: InputMaybe<Scalars['Int']['input']>;
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  framesGenerated?: InputMaybe<Scalars['Int']['input']>;
  generation?: InputMaybe<GenerationsObjRelInsertInput>;
  generationId?: InputMaybe<Scalars['uuid']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  organization?: InputMaybe<OrganizationsObjRelInsertInput>;
  organizationId?: InputMaybe<Scalars['uuid']['input']>;
  status?: InputMaybe<StatusEnum>;
  stripeTransactionId?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<TransactionTypeEnum>;
  updatedAt?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate max on columns */
export type TransactionsMaxFields = {
  __typename?: 'transactions_max_fields';
  amount?: Maybe<Scalars['Int']['output']>;
  createdAt?: Maybe<Scalars['timestamptz']['output']>;
  framesGenerated?: Maybe<Scalars['Int']['output']>;
  generationId?: Maybe<Scalars['uuid']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  organizationId?: Maybe<Scalars['uuid']['output']>;
  stripeTransactionId?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['timestamptz']['output']>;
};

/** aggregate min on columns */
export type TransactionsMinFields = {
  __typename?: 'transactions_min_fields';
  amount?: Maybe<Scalars['Int']['output']>;
  createdAt?: Maybe<Scalars['timestamptz']['output']>;
  framesGenerated?: Maybe<Scalars['Int']['output']>;
  generationId?: Maybe<Scalars['uuid']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  organizationId?: Maybe<Scalars['uuid']['output']>;
  stripeTransactionId?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['timestamptz']['output']>;
};

/** response of any mutation on the table "transactions" */
export type TransactionsMutationResponse = {
  __typename?: 'transactions_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Transactions>;
};

/** input type for inserting object relation for remote table "transactions" */
export type TransactionsObjRelInsertInput = {
  data: TransactionsInsertInput;
  /** upsert condition */
  on_conflict?: InputMaybe<TransactionsOnConflict>;
};

/** on_conflict condition type for table "transactions" */
export type TransactionsOnConflict = {
  constraint: TransactionsConstraint;
  update_columns?: Array<TransactionsUpdateColumn>;
  where?: InputMaybe<TransactionsBoolExp>;
};

/** Ordering options when selecting data from "transactions". */
export type TransactionsOrderBy = {
  amount?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  framesGenerated?: InputMaybe<OrderBy>;
  generation?: InputMaybe<GenerationsOrderBy>;
  generationId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  organization?: InputMaybe<OrganizationsOrderBy>;
  organizationId?: InputMaybe<OrderBy>;
  status?: InputMaybe<OrderBy>;
  stripeTransactionId?: InputMaybe<OrderBy>;
  type?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: transactions */
export type TransactionsPkColumnsInput = {
  id: Scalars['uuid']['input'];
};

/** select columns of table "transactions" */
export enum TransactionsSelectColumn {
  /** column name */
  AMOUNT = 'amount',
  /** column name */
  CREATEDAT = 'createdAt',
  /** column name */
  FRAMESGENERATED = 'framesGenerated',
  /** column name */
  GENERATIONID = 'generationId',
  /** column name */
  ID = 'id',
  /** column name */
  ORGANIZATIONID = 'organizationId',
  /** column name */
  STATUS = 'status',
  /** column name */
  STRIPETRANSACTIONID = 'stripeTransactionId',
  /** column name */
  TYPE = 'type',
  /** column name */
  UPDATEDAT = 'updatedAt'
}

/** input type for updating data in table "transactions" */
export type TransactionsSetInput = {
  amount?: InputMaybe<Scalars['Int']['input']>;
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  framesGenerated?: InputMaybe<Scalars['Int']['input']>;
  generationId?: InputMaybe<Scalars['uuid']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  organizationId?: InputMaybe<Scalars['uuid']['input']>;
  status?: InputMaybe<StatusEnum>;
  stripeTransactionId?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<TransactionTypeEnum>;
  updatedAt?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate stddev on columns */
export type TransactionsStddevFields = {
  __typename?: 'transactions_stddev_fields';
  amount?: Maybe<Scalars['Float']['output']>;
  framesGenerated?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type TransactionsStddevPopFields = {
  __typename?: 'transactions_stddev_pop_fields';
  amount?: Maybe<Scalars['Float']['output']>;
  framesGenerated?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type TransactionsStddevSampFields = {
  __typename?: 'transactions_stddev_samp_fields';
  amount?: Maybe<Scalars['Float']['output']>;
  framesGenerated?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "transactions" */
export type TransactionsStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: TransactionsStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type TransactionsStreamCursorValueInput = {
  amount?: InputMaybe<Scalars['Int']['input']>;
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  framesGenerated?: InputMaybe<Scalars['Int']['input']>;
  generationId?: InputMaybe<Scalars['uuid']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  organizationId?: InputMaybe<Scalars['uuid']['input']>;
  status?: InputMaybe<StatusEnum>;
  stripeTransactionId?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<TransactionTypeEnum>;
  updatedAt?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate sum on columns */
export type TransactionsSumFields = {
  __typename?: 'transactions_sum_fields';
  amount?: Maybe<Scalars['Int']['output']>;
  framesGenerated?: Maybe<Scalars['Int']['output']>;
};

/** update columns of table "transactions" */
export enum TransactionsUpdateColumn {
  /** column name */
  AMOUNT = 'amount',
  /** column name */
  CREATEDAT = 'createdAt',
  /** column name */
  FRAMESGENERATED = 'framesGenerated',
  /** column name */
  GENERATIONID = 'generationId',
  /** column name */
  ID = 'id',
  /** column name */
  ORGANIZATIONID = 'organizationId',
  /** column name */
  STATUS = 'status',
  /** column name */
  STRIPETRANSACTIONID = 'stripeTransactionId',
  /** column name */
  TYPE = 'type',
  /** column name */
  UPDATEDAT = 'updatedAt'
}

export type TransactionsUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<TransactionsIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<TransactionsSetInput>;
  /** filter the rows which have to be updated */
  where: TransactionsBoolExp;
};

/** aggregate var_pop on columns */
export type TransactionsVarPopFields = {
  __typename?: 'transactions_var_pop_fields';
  amount?: Maybe<Scalars['Float']['output']>;
  framesGenerated?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type TransactionsVarSampFields = {
  __typename?: 'transactions_var_samp_fields';
  amount?: Maybe<Scalars['Float']['output']>;
  framesGenerated?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type TransactionsVarianceFields = {
  __typename?: 'transactions_variance_fields';
  amount?: Maybe<Scalars['Float']['output']>;
  framesGenerated?: Maybe<Scalars['Float']['output']>;
};

/** Links users to organizations, establishing many-to-many relationships between users and the organizations they belong to. */
export type UserOrganizations = {
  __typename?: 'user_organizations';
  createdAt: Scalars['timestamptz']['output'];
  id: Scalars['uuid']['output'];
  /** An object relationship */
  organization?: Maybe<Organizations>;
  organizationId: Scalars['uuid']['output'];
  updatedAt: Scalars['timestamptz']['output'];
  /** An object relationship */
  user?: Maybe<Users>;
  userId: Scalars['uuid']['output'];
};

/** aggregated selection of "user_organizations" */
export type UserOrganizationsAggregate = {
  __typename?: 'user_organizations_aggregate';
  aggregate?: Maybe<UserOrganizationsAggregateFields>;
  nodes: Array<UserOrganizations>;
};

/** aggregate fields of "user_organizations" */
export type UserOrganizationsAggregateFields = {
  __typename?: 'user_organizations_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<UserOrganizationsMaxFields>;
  min?: Maybe<UserOrganizationsMinFields>;
};


/** aggregate fields of "user_organizations" */
export type UserOrganizationsAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<UserOrganizationsSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "user_organizations". All fields are combined with a logical 'AND'. */
export type UserOrganizationsBoolExp = {
  _and?: InputMaybe<Array<UserOrganizationsBoolExp>>;
  _not?: InputMaybe<UserOrganizationsBoolExp>;
  _or?: InputMaybe<Array<UserOrganizationsBoolExp>>;
  createdAt?: InputMaybe<TimestamptzComparisonExp>;
  id?: InputMaybe<UuidComparisonExp>;
  organization?: InputMaybe<OrganizationsBoolExp>;
  organizationId?: InputMaybe<UuidComparisonExp>;
  updatedAt?: InputMaybe<TimestamptzComparisonExp>;
  user?: InputMaybe<UsersBoolExp>;
  userId?: InputMaybe<UuidComparisonExp>;
};

/** unique or primary key constraints on table "user_organizations" */
export enum UserOrganizationsConstraint {
  /** unique or primary key constraint on columns "id" */
  USER_ORGANIZATIONS_PKEY = 'user_organizations_pkey'
}

/** input type for inserting data into table "user_organizations" */
export type UserOrganizationsInsertInput = {
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  organization?: InputMaybe<OrganizationsObjRelInsertInput>;
  organizationId?: InputMaybe<Scalars['uuid']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']['input']>;
  user?: InputMaybe<UsersObjRelInsertInput>;
  userId?: InputMaybe<Scalars['uuid']['input']>;
};

/** aggregate max on columns */
export type UserOrganizationsMaxFields = {
  __typename?: 'user_organizations_max_fields';
  createdAt?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  organizationId?: Maybe<Scalars['uuid']['output']>;
  updatedAt?: Maybe<Scalars['timestamptz']['output']>;
  userId?: Maybe<Scalars['uuid']['output']>;
};

/** aggregate min on columns */
export type UserOrganizationsMinFields = {
  __typename?: 'user_organizations_min_fields';
  createdAt?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  organizationId?: Maybe<Scalars['uuid']['output']>;
  updatedAt?: Maybe<Scalars['timestamptz']['output']>;
  userId?: Maybe<Scalars['uuid']['output']>;
};

/** response of any mutation on the table "user_organizations" */
export type UserOrganizationsMutationResponse = {
  __typename?: 'user_organizations_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<UserOrganizations>;
};

/** input type for inserting object relation for remote table "user_organizations" */
export type UserOrganizationsObjRelInsertInput = {
  data: UserOrganizationsInsertInput;
  /** upsert condition */
  on_conflict?: InputMaybe<UserOrganizationsOnConflict>;
};

/** on_conflict condition type for table "user_organizations" */
export type UserOrganizationsOnConflict = {
  constraint: UserOrganizationsConstraint;
  update_columns?: Array<UserOrganizationsUpdateColumn>;
  where?: InputMaybe<UserOrganizationsBoolExp>;
};

/** Ordering options when selecting data from "user_organizations". */
export type UserOrganizationsOrderBy = {
  createdAt?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  organization?: InputMaybe<OrganizationsOrderBy>;
  organizationId?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
  user?: InputMaybe<UsersOrderBy>;
  userId?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: user_organizations */
export type UserOrganizationsPkColumnsInput = {
  id: Scalars['uuid']['input'];
};

/** select columns of table "user_organizations" */
export enum UserOrganizationsSelectColumn {
  /** column name */
  CREATEDAT = 'createdAt',
  /** column name */
  ID = 'id',
  /** column name */
  ORGANIZATIONID = 'organizationId',
  /** column name */
  UPDATEDAT = 'updatedAt',
  /** column name */
  USERID = 'userId'
}

/** input type for updating data in table "user_organizations" */
export type UserOrganizationsSetInput = {
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  organizationId?: InputMaybe<Scalars['uuid']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']['input']>;
  userId?: InputMaybe<Scalars['uuid']['input']>;
};

/** Streaming cursor of the table "user_organizations" */
export type UserOrganizationsStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: UserOrganizationsStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type UserOrganizationsStreamCursorValueInput = {
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  organizationId?: InputMaybe<Scalars['uuid']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']['input']>;
  userId?: InputMaybe<Scalars['uuid']['input']>;
};

/** update columns of table "user_organizations" */
export enum UserOrganizationsUpdateColumn {
  /** column name */
  CREATEDAT = 'createdAt',
  /** column name */
  ID = 'id',
  /** column name */
  ORGANIZATIONID = 'organizationId',
  /** column name */
  UPDATEDAT = 'updatedAt',
  /** column name */
  USERID = 'userId'
}

export type UserOrganizationsUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<UserOrganizationsSetInput>;
  /** filter the rows which have to be updated */
  where: UserOrganizationsBoolExp;
};

/** User account information. Don't modify its structure as Hasura Auth relies on it to function properly. */
export type Users = {
  __typename?: 'users';
  activeMfaType?: Maybe<Scalars['String']['output']>;
  avatarUrl: Scalars['String']['output'];
  createdAt: Scalars['timestamptz']['output'];
  currentChallenge?: Maybe<Scalars['String']['output']>;
  defaultRole: Scalars['String']['output'];
  /** An object relationship */
  defaultRoleByRole: AuthRoles;
  disabled: Scalars['Boolean']['output'];
  displayName: Scalars['String']['output'];
  email?: Maybe<Scalars['citext']['output']>;
  emailVerified: Scalars['Boolean']['output'];
  id: Scalars['uuid']['output'];
  isAnonymous: Scalars['Boolean']['output'];
  lastSeen?: Maybe<Scalars['timestamptz']['output']>;
  locale: Scalars['String']['output'];
  metadata?: Maybe<Scalars['jsonb']['output']>;
  newEmail?: Maybe<Scalars['citext']['output']>;
  otpHash?: Maybe<Scalars['String']['output']>;
  otpHashExpiresAt: Scalars['timestamptz']['output'];
  otpMethodLastUsed?: Maybe<Scalars['String']['output']>;
  passwordHash?: Maybe<Scalars['String']['output']>;
  phoneNumber?: Maybe<Scalars['String']['output']>;
  phoneNumberVerified: Scalars['Boolean']['output'];
  /** An array relationship */
  refreshTokens: Array<AuthRefreshTokens>;
  /** An aggregate relationship */
  refreshTokens_aggregate: AuthRefreshTokensAggregate;
  /** An array relationship */
  roles: Array<AuthUserRoles>;
  /** An aggregate relationship */
  roles_aggregate: AuthUserRolesAggregate;
  /** An array relationship */
  securityKeys: Array<AuthUserSecurityKeys>;
  /** An aggregate relationship */
  securityKeys_aggregate: AuthUserSecurityKeysAggregate;
  ticket?: Maybe<Scalars['String']['output']>;
  ticketExpiresAt: Scalars['timestamptz']['output'];
  totpSecret?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['timestamptz']['output'];
  /** An array relationship */
  userProviders: Array<AuthUserProviders>;
  /** An aggregate relationship */
  userProviders_aggregate: AuthUserProvidersAggregate;
};


/** User account information. Don't modify its structure as Hasura Auth relies on it to function properly. */
export type UsersMetadataArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};


/** User account information. Don't modify its structure as Hasura Auth relies on it to function properly. */
export type UsersRefreshTokensArgs = {
  distinct_on?: InputMaybe<Array<AuthRefreshTokensSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<AuthRefreshTokensOrderBy>>;
  where?: InputMaybe<AuthRefreshTokensBoolExp>;
};


/** User account information. Don't modify its structure as Hasura Auth relies on it to function properly. */
export type UsersRefreshTokensAggregateArgs = {
  distinct_on?: InputMaybe<Array<AuthRefreshTokensSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<AuthRefreshTokensOrderBy>>;
  where?: InputMaybe<AuthRefreshTokensBoolExp>;
};


/** User account information. Don't modify its structure as Hasura Auth relies on it to function properly. */
export type UsersRolesArgs = {
  distinct_on?: InputMaybe<Array<AuthUserRolesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<AuthUserRolesOrderBy>>;
  where?: InputMaybe<AuthUserRolesBoolExp>;
};


/** User account information. Don't modify its structure as Hasura Auth relies on it to function properly. */
export type UsersRolesAggregateArgs = {
  distinct_on?: InputMaybe<Array<AuthUserRolesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<AuthUserRolesOrderBy>>;
  where?: InputMaybe<AuthUserRolesBoolExp>;
};


/** User account information. Don't modify its structure as Hasura Auth relies on it to function properly. */
export type UsersSecurityKeysArgs = {
  distinct_on?: InputMaybe<Array<AuthUserSecurityKeysSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<AuthUserSecurityKeysOrderBy>>;
  where?: InputMaybe<AuthUserSecurityKeysBoolExp>;
};


/** User account information. Don't modify its structure as Hasura Auth relies on it to function properly. */
export type UsersSecurityKeysAggregateArgs = {
  distinct_on?: InputMaybe<Array<AuthUserSecurityKeysSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<AuthUserSecurityKeysOrderBy>>;
  where?: InputMaybe<AuthUserSecurityKeysBoolExp>;
};


/** User account information. Don't modify its structure as Hasura Auth relies on it to function properly. */
export type UsersUserProvidersArgs = {
  distinct_on?: InputMaybe<Array<AuthUserProvidersSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<AuthUserProvidersOrderBy>>;
  where?: InputMaybe<AuthUserProvidersBoolExp>;
};


/** User account information. Don't modify its structure as Hasura Auth relies on it to function properly. */
export type UsersUserProvidersAggregateArgs = {
  distinct_on?: InputMaybe<Array<AuthUserProvidersSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<AuthUserProvidersOrderBy>>;
  where?: InputMaybe<AuthUserProvidersBoolExp>;
};

/** aggregated selection of "auth.users" */
export type UsersAggregate = {
  __typename?: 'users_aggregate';
  aggregate?: Maybe<UsersAggregateFields>;
  nodes: Array<Users>;
};

export type UsersAggregateBoolExp = {
  bool_and?: InputMaybe<UsersAggregateBoolExpBoolAnd>;
  bool_or?: InputMaybe<UsersAggregateBoolExpBoolOr>;
  count?: InputMaybe<UsersAggregateBoolExpCount>;
};

export type UsersAggregateBoolExpBoolAnd = {
  arguments: UsersSelectColumnUsersAggregateBoolExpBoolAndArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<UsersBoolExp>;
  predicate: BooleanComparisonExp;
};

export type UsersAggregateBoolExpBoolOr = {
  arguments: UsersSelectColumnUsersAggregateBoolExpBoolOrArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<UsersBoolExp>;
  predicate: BooleanComparisonExp;
};

export type UsersAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<UsersSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<UsersBoolExp>;
  predicate: IntComparisonExp;
};

/** aggregate fields of "auth.users" */
export type UsersAggregateFields = {
  __typename?: 'users_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<UsersMaxFields>;
  min?: Maybe<UsersMinFields>;
};


/** aggregate fields of "auth.users" */
export type UsersAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<UsersSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "auth.users" */
export type UsersAggregateOrderBy = {
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<UsersMaxOrderBy>;
  min?: InputMaybe<UsersMinOrderBy>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type UsersAppendInput = {
  metadata?: InputMaybe<Scalars['jsonb']['input']>;
};

/** input type for inserting array relation for remote table "auth.users" */
export type UsersArrRelInsertInput = {
  data: Array<UsersInsertInput>;
  /** upsert condition */
  on_conflict?: InputMaybe<UsersOnConflict>;
};

/** Boolean expression to filter rows from the table "auth.users". All fields are combined with a logical 'AND'. */
export type UsersBoolExp = {
  _and?: InputMaybe<Array<UsersBoolExp>>;
  _not?: InputMaybe<UsersBoolExp>;
  _or?: InputMaybe<Array<UsersBoolExp>>;
  activeMfaType?: InputMaybe<StringComparisonExp>;
  avatarUrl?: InputMaybe<StringComparisonExp>;
  createdAt?: InputMaybe<TimestamptzComparisonExp>;
  currentChallenge?: InputMaybe<StringComparisonExp>;
  defaultRole?: InputMaybe<StringComparisonExp>;
  defaultRoleByRole?: InputMaybe<AuthRolesBoolExp>;
  disabled?: InputMaybe<BooleanComparisonExp>;
  displayName?: InputMaybe<StringComparisonExp>;
  email?: InputMaybe<CitextComparisonExp>;
  emailVerified?: InputMaybe<BooleanComparisonExp>;
  id?: InputMaybe<UuidComparisonExp>;
  isAnonymous?: InputMaybe<BooleanComparisonExp>;
  lastSeen?: InputMaybe<TimestamptzComparisonExp>;
  locale?: InputMaybe<StringComparisonExp>;
  metadata?: InputMaybe<JsonbComparisonExp>;
  newEmail?: InputMaybe<CitextComparisonExp>;
  otpHash?: InputMaybe<StringComparisonExp>;
  otpHashExpiresAt?: InputMaybe<TimestamptzComparisonExp>;
  otpMethodLastUsed?: InputMaybe<StringComparisonExp>;
  passwordHash?: InputMaybe<StringComparisonExp>;
  phoneNumber?: InputMaybe<StringComparisonExp>;
  phoneNumberVerified?: InputMaybe<BooleanComparisonExp>;
  refreshTokens?: InputMaybe<AuthRefreshTokensBoolExp>;
  refreshTokens_aggregate?: InputMaybe<AuthRefreshTokensAggregateBoolExp>;
  roles?: InputMaybe<AuthUserRolesBoolExp>;
  roles_aggregate?: InputMaybe<AuthUserRolesAggregateBoolExp>;
  securityKeys?: InputMaybe<AuthUserSecurityKeysBoolExp>;
  securityKeys_aggregate?: InputMaybe<AuthUserSecurityKeysAggregateBoolExp>;
  ticket?: InputMaybe<StringComparisonExp>;
  ticketExpiresAt?: InputMaybe<TimestamptzComparisonExp>;
  totpSecret?: InputMaybe<StringComparisonExp>;
  updatedAt?: InputMaybe<TimestamptzComparisonExp>;
  userProviders?: InputMaybe<AuthUserProvidersBoolExp>;
  userProviders_aggregate?: InputMaybe<AuthUserProvidersAggregateBoolExp>;
};

/** unique or primary key constraints on table "auth.users" */
export enum UsersConstraint {
  /** unique or primary key constraint on columns "email" */
  USERS_EMAIL_KEY = 'users_email_key',
  /** unique or primary key constraint on columns "phone_number" */
  USERS_PHONE_NUMBER_KEY = 'users_phone_number_key',
  /** unique or primary key constraint on columns "id" */
  USERS_PKEY = 'users_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type UsersDeleteAtPathInput = {
  metadata?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type UsersDeleteElemInput = {
  metadata?: InputMaybe<Scalars['Int']['input']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type UsersDeleteKeyInput = {
  metadata?: InputMaybe<Scalars['String']['input']>;
};

/** input type for inserting data into table "auth.users" */
export type UsersInsertInput = {
  activeMfaType?: InputMaybe<Scalars['String']['input']>;
  avatarUrl?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  currentChallenge?: InputMaybe<Scalars['String']['input']>;
  defaultRole?: InputMaybe<Scalars['String']['input']>;
  defaultRoleByRole?: InputMaybe<AuthRolesObjRelInsertInput>;
  disabled?: InputMaybe<Scalars['Boolean']['input']>;
  displayName?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['citext']['input']>;
  emailVerified?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  isAnonymous?: InputMaybe<Scalars['Boolean']['input']>;
  lastSeen?: InputMaybe<Scalars['timestamptz']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  metadata?: InputMaybe<Scalars['jsonb']['input']>;
  newEmail?: InputMaybe<Scalars['citext']['input']>;
  otpHash?: InputMaybe<Scalars['String']['input']>;
  otpHashExpiresAt?: InputMaybe<Scalars['timestamptz']['input']>;
  otpMethodLastUsed?: InputMaybe<Scalars['String']['input']>;
  passwordHash?: InputMaybe<Scalars['String']['input']>;
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
  phoneNumberVerified?: InputMaybe<Scalars['Boolean']['input']>;
  refreshTokens?: InputMaybe<AuthRefreshTokensArrRelInsertInput>;
  roles?: InputMaybe<AuthUserRolesArrRelInsertInput>;
  securityKeys?: InputMaybe<AuthUserSecurityKeysArrRelInsertInput>;
  ticket?: InputMaybe<Scalars['String']['input']>;
  ticketExpiresAt?: InputMaybe<Scalars['timestamptz']['input']>;
  totpSecret?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']['input']>;
  userProviders?: InputMaybe<AuthUserProvidersArrRelInsertInput>;
};

/** aggregate max on columns */
export type UsersMaxFields = {
  __typename?: 'users_max_fields';
  activeMfaType?: Maybe<Scalars['String']['output']>;
  avatarUrl?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['timestamptz']['output']>;
  currentChallenge?: Maybe<Scalars['String']['output']>;
  defaultRole?: Maybe<Scalars['String']['output']>;
  displayName?: Maybe<Scalars['String']['output']>;
  email?: Maybe<Scalars['citext']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  lastSeen?: Maybe<Scalars['timestamptz']['output']>;
  locale?: Maybe<Scalars['String']['output']>;
  newEmail?: Maybe<Scalars['citext']['output']>;
  otpHash?: Maybe<Scalars['String']['output']>;
  otpHashExpiresAt?: Maybe<Scalars['timestamptz']['output']>;
  otpMethodLastUsed?: Maybe<Scalars['String']['output']>;
  passwordHash?: Maybe<Scalars['String']['output']>;
  phoneNumber?: Maybe<Scalars['String']['output']>;
  ticket?: Maybe<Scalars['String']['output']>;
  ticketExpiresAt?: Maybe<Scalars['timestamptz']['output']>;
  totpSecret?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['timestamptz']['output']>;
};

/** order by max() on columns of table "auth.users" */
export type UsersMaxOrderBy = {
  activeMfaType?: InputMaybe<OrderBy>;
  avatarUrl?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  currentChallenge?: InputMaybe<OrderBy>;
  defaultRole?: InputMaybe<OrderBy>;
  displayName?: InputMaybe<OrderBy>;
  email?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  lastSeen?: InputMaybe<OrderBy>;
  locale?: InputMaybe<OrderBy>;
  newEmail?: InputMaybe<OrderBy>;
  otpHash?: InputMaybe<OrderBy>;
  otpHashExpiresAt?: InputMaybe<OrderBy>;
  otpMethodLastUsed?: InputMaybe<OrderBy>;
  passwordHash?: InputMaybe<OrderBy>;
  phoneNumber?: InputMaybe<OrderBy>;
  ticket?: InputMaybe<OrderBy>;
  ticketExpiresAt?: InputMaybe<OrderBy>;
  totpSecret?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type UsersMinFields = {
  __typename?: 'users_min_fields';
  activeMfaType?: Maybe<Scalars['String']['output']>;
  avatarUrl?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['timestamptz']['output']>;
  currentChallenge?: Maybe<Scalars['String']['output']>;
  defaultRole?: Maybe<Scalars['String']['output']>;
  displayName?: Maybe<Scalars['String']['output']>;
  email?: Maybe<Scalars['citext']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  lastSeen?: Maybe<Scalars['timestamptz']['output']>;
  locale?: Maybe<Scalars['String']['output']>;
  newEmail?: Maybe<Scalars['citext']['output']>;
  otpHash?: Maybe<Scalars['String']['output']>;
  otpHashExpiresAt?: Maybe<Scalars['timestamptz']['output']>;
  otpMethodLastUsed?: Maybe<Scalars['String']['output']>;
  passwordHash?: Maybe<Scalars['String']['output']>;
  phoneNumber?: Maybe<Scalars['String']['output']>;
  ticket?: Maybe<Scalars['String']['output']>;
  ticketExpiresAt?: Maybe<Scalars['timestamptz']['output']>;
  totpSecret?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['timestamptz']['output']>;
};

/** order by min() on columns of table "auth.users" */
export type UsersMinOrderBy = {
  activeMfaType?: InputMaybe<OrderBy>;
  avatarUrl?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  currentChallenge?: InputMaybe<OrderBy>;
  defaultRole?: InputMaybe<OrderBy>;
  displayName?: InputMaybe<OrderBy>;
  email?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  lastSeen?: InputMaybe<OrderBy>;
  locale?: InputMaybe<OrderBy>;
  newEmail?: InputMaybe<OrderBy>;
  otpHash?: InputMaybe<OrderBy>;
  otpHashExpiresAt?: InputMaybe<OrderBy>;
  otpMethodLastUsed?: InputMaybe<OrderBy>;
  passwordHash?: InputMaybe<OrderBy>;
  phoneNumber?: InputMaybe<OrderBy>;
  ticket?: InputMaybe<OrderBy>;
  ticketExpiresAt?: InputMaybe<OrderBy>;
  totpSecret?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "auth.users" */
export type UsersMutationResponse = {
  __typename?: 'users_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Users>;
};

/** input type for inserting object relation for remote table "auth.users" */
export type UsersObjRelInsertInput = {
  data: UsersInsertInput;
  /** upsert condition */
  on_conflict?: InputMaybe<UsersOnConflict>;
};

/** on_conflict condition type for table "auth.users" */
export type UsersOnConflict = {
  constraint: UsersConstraint;
  update_columns?: Array<UsersUpdateColumn>;
  where?: InputMaybe<UsersBoolExp>;
};

/** Ordering options when selecting data from "auth.users". */
export type UsersOrderBy = {
  activeMfaType?: InputMaybe<OrderBy>;
  avatarUrl?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  currentChallenge?: InputMaybe<OrderBy>;
  defaultRole?: InputMaybe<OrderBy>;
  defaultRoleByRole?: InputMaybe<AuthRolesOrderBy>;
  disabled?: InputMaybe<OrderBy>;
  displayName?: InputMaybe<OrderBy>;
  email?: InputMaybe<OrderBy>;
  emailVerified?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  isAnonymous?: InputMaybe<OrderBy>;
  lastSeen?: InputMaybe<OrderBy>;
  locale?: InputMaybe<OrderBy>;
  metadata?: InputMaybe<OrderBy>;
  newEmail?: InputMaybe<OrderBy>;
  otpHash?: InputMaybe<OrderBy>;
  otpHashExpiresAt?: InputMaybe<OrderBy>;
  otpMethodLastUsed?: InputMaybe<OrderBy>;
  passwordHash?: InputMaybe<OrderBy>;
  phoneNumber?: InputMaybe<OrderBy>;
  phoneNumberVerified?: InputMaybe<OrderBy>;
  refreshTokens_aggregate?: InputMaybe<AuthRefreshTokensAggregateOrderBy>;
  roles_aggregate?: InputMaybe<AuthUserRolesAggregateOrderBy>;
  securityKeys_aggregate?: InputMaybe<AuthUserSecurityKeysAggregateOrderBy>;
  ticket?: InputMaybe<OrderBy>;
  ticketExpiresAt?: InputMaybe<OrderBy>;
  totpSecret?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
  userProviders_aggregate?: InputMaybe<AuthUserProvidersAggregateOrderBy>;
};

/** primary key columns input for table: auth.users */
export type UsersPkColumnsInput = {
  id: Scalars['uuid']['input'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type UsersPrependInput = {
  metadata?: InputMaybe<Scalars['jsonb']['input']>;
};

/** select columns of table "auth.users" */
export enum UsersSelectColumn {
  /** column name */
  ACTIVEMFATYPE = 'activeMfaType',
  /** column name */
  AVATARURL = 'avatarUrl',
  /** column name */
  CREATEDAT = 'createdAt',
  /** column name */
  CURRENTCHALLENGE = 'currentChallenge',
  /** column name */
  DEFAULTROLE = 'defaultRole',
  /** column name */
  DISABLED = 'disabled',
  /** column name */
  DISPLAYNAME = 'displayName',
  /** column name */
  EMAIL = 'email',
  /** column name */
  EMAILVERIFIED = 'emailVerified',
  /** column name */
  ID = 'id',
  /** column name */
  ISANONYMOUS = 'isAnonymous',
  /** column name */
  LASTSEEN = 'lastSeen',
  /** column name */
  LOCALE = 'locale',
  /** column name */
  METADATA = 'metadata',
  /** column name */
  NEWEMAIL = 'newEmail',
  /** column name */
  OTPHASH = 'otpHash',
  /** column name */
  OTPHASHEXPIRESAT = 'otpHashExpiresAt',
  /** column name */
  OTPMETHODLASTUSED = 'otpMethodLastUsed',
  /** column name */
  PASSWORDHASH = 'passwordHash',
  /** column name */
  PHONENUMBER = 'phoneNumber',
  /** column name */
  PHONENUMBERVERIFIED = 'phoneNumberVerified',
  /** column name */
  TICKET = 'ticket',
  /** column name */
  TICKETEXPIRESAT = 'ticketExpiresAt',
  /** column name */
  TOTPSECRET = 'totpSecret',
  /** column name */
  UPDATEDAT = 'updatedAt'
}

/** select "users_aggregate_bool_exp_bool_and_arguments_columns" columns of table "auth.users" */
export enum UsersSelectColumnUsersAggregateBoolExpBoolAndArgumentsColumns {
  /** column name */
  DISABLED = 'disabled',
  /** column name */
  EMAILVERIFIED = 'emailVerified',
  /** column name */
  ISANONYMOUS = 'isAnonymous',
  /** column name */
  PHONENUMBERVERIFIED = 'phoneNumberVerified'
}

/** select "users_aggregate_bool_exp_bool_or_arguments_columns" columns of table "auth.users" */
export enum UsersSelectColumnUsersAggregateBoolExpBoolOrArgumentsColumns {
  /** column name */
  DISABLED = 'disabled',
  /** column name */
  EMAILVERIFIED = 'emailVerified',
  /** column name */
  ISANONYMOUS = 'isAnonymous',
  /** column name */
  PHONENUMBERVERIFIED = 'phoneNumberVerified'
}

/** input type for updating data in table "auth.users" */
export type UsersSetInput = {
  activeMfaType?: InputMaybe<Scalars['String']['input']>;
  avatarUrl?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  currentChallenge?: InputMaybe<Scalars['String']['input']>;
  defaultRole?: InputMaybe<Scalars['String']['input']>;
  disabled?: InputMaybe<Scalars['Boolean']['input']>;
  displayName?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['citext']['input']>;
  emailVerified?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  isAnonymous?: InputMaybe<Scalars['Boolean']['input']>;
  lastSeen?: InputMaybe<Scalars['timestamptz']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  metadata?: InputMaybe<Scalars['jsonb']['input']>;
  newEmail?: InputMaybe<Scalars['citext']['input']>;
  otpHash?: InputMaybe<Scalars['String']['input']>;
  otpHashExpiresAt?: InputMaybe<Scalars['timestamptz']['input']>;
  otpMethodLastUsed?: InputMaybe<Scalars['String']['input']>;
  passwordHash?: InputMaybe<Scalars['String']['input']>;
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
  phoneNumberVerified?: InputMaybe<Scalars['Boolean']['input']>;
  ticket?: InputMaybe<Scalars['String']['input']>;
  ticketExpiresAt?: InputMaybe<Scalars['timestamptz']['input']>;
  totpSecret?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** Streaming cursor of the table "users" */
export type UsersStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: UsersStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type UsersStreamCursorValueInput = {
  activeMfaType?: InputMaybe<Scalars['String']['input']>;
  avatarUrl?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  currentChallenge?: InputMaybe<Scalars['String']['input']>;
  defaultRole?: InputMaybe<Scalars['String']['input']>;
  disabled?: InputMaybe<Scalars['Boolean']['input']>;
  displayName?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['citext']['input']>;
  emailVerified?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  isAnonymous?: InputMaybe<Scalars['Boolean']['input']>;
  lastSeen?: InputMaybe<Scalars['timestamptz']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  metadata?: InputMaybe<Scalars['jsonb']['input']>;
  newEmail?: InputMaybe<Scalars['citext']['input']>;
  otpHash?: InputMaybe<Scalars['String']['input']>;
  otpHashExpiresAt?: InputMaybe<Scalars['timestamptz']['input']>;
  otpMethodLastUsed?: InputMaybe<Scalars['String']['input']>;
  passwordHash?: InputMaybe<Scalars['String']['input']>;
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
  phoneNumberVerified?: InputMaybe<Scalars['Boolean']['input']>;
  ticket?: InputMaybe<Scalars['String']['input']>;
  ticketExpiresAt?: InputMaybe<Scalars['timestamptz']['input']>;
  totpSecret?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** update columns of table "auth.users" */
export enum UsersUpdateColumn {
  /** column name */
  ACTIVEMFATYPE = 'activeMfaType',
  /** column name */
  AVATARURL = 'avatarUrl',
  /** column name */
  CREATEDAT = 'createdAt',
  /** column name */
  CURRENTCHALLENGE = 'currentChallenge',
  /** column name */
  DEFAULTROLE = 'defaultRole',
  /** column name */
  DISABLED = 'disabled',
  /** column name */
  DISPLAYNAME = 'displayName',
  /** column name */
  EMAIL = 'email',
  /** column name */
  EMAILVERIFIED = 'emailVerified',
  /** column name */
  ID = 'id',
  /** column name */
  ISANONYMOUS = 'isAnonymous',
  /** column name */
  LASTSEEN = 'lastSeen',
  /** column name */
  LOCALE = 'locale',
  /** column name */
  METADATA = 'metadata',
  /** column name */
  NEWEMAIL = 'newEmail',
  /** column name */
  OTPHASH = 'otpHash',
  /** column name */
  OTPHASHEXPIRESAT = 'otpHashExpiresAt',
  /** column name */
  OTPMETHODLASTUSED = 'otpMethodLastUsed',
  /** column name */
  PASSWORDHASH = 'passwordHash',
  /** column name */
  PHONENUMBER = 'phoneNumber',
  /** column name */
  PHONENUMBERVERIFIED = 'phoneNumberVerified',
  /** column name */
  TICKET = 'ticket',
  /** column name */
  TICKETEXPIRESAT = 'ticketExpiresAt',
  /** column name */
  TOTPSECRET = 'totpSecret',
  /** column name */
  UPDATEDAT = 'updatedAt'
}

export type UsersUpdates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<UsersAppendInput>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<UsersDeleteAtPathInput>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<UsersDeleteElemInput>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<UsersDeleteKeyInput>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<UsersPrependInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<UsersSetInput>;
  /** filter the rows which have to be updated */
  where: UsersBoolExp;
};

/** Boolean expression to compare columns of type "uuid". All fields are combined with logical 'AND'. */
export type UuidComparisonExp = {
  _eq?: InputMaybe<Scalars['uuid']['input']>;
  _gt?: InputMaybe<Scalars['uuid']['input']>;
  _gte?: InputMaybe<Scalars['uuid']['input']>;
  _in?: InputMaybe<Array<Scalars['uuid']['input']>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['uuid']['input']>;
  _lte?: InputMaybe<Scalars['uuid']['input']>;
  _neq?: InputMaybe<Scalars['uuid']['input']>;
  _nin?: InputMaybe<Array<Scalars['uuid']['input']>>;
};

/** columns and relationships of "storage.virus" */
export type Virus = {
  __typename?: 'virus';
  createdAt: Scalars['timestamptz']['output'];
  /** An object relationship */
  file: Files;
  fileId: Scalars['uuid']['output'];
  filename: Scalars['String']['output'];
  id: Scalars['uuid']['output'];
  updatedAt: Scalars['timestamptz']['output'];
  userSession: Scalars['jsonb']['output'];
  virus: Scalars['String']['output'];
};


/** columns and relationships of "storage.virus" */
export type VirusUserSessionArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};

/** aggregated selection of "storage.virus" */
export type VirusAggregate = {
  __typename?: 'virus_aggregate';
  aggregate?: Maybe<VirusAggregateFields>;
  nodes: Array<Virus>;
};

/** aggregate fields of "storage.virus" */
export type VirusAggregateFields = {
  __typename?: 'virus_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<VirusMaxFields>;
  min?: Maybe<VirusMinFields>;
};


/** aggregate fields of "storage.virus" */
export type VirusAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<VirusSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type VirusAppendInput = {
  userSession?: InputMaybe<Scalars['jsonb']['input']>;
};

/** Boolean expression to filter rows from the table "storage.virus". All fields are combined with a logical 'AND'. */
export type VirusBoolExp = {
  _and?: InputMaybe<Array<VirusBoolExp>>;
  _not?: InputMaybe<VirusBoolExp>;
  _or?: InputMaybe<Array<VirusBoolExp>>;
  createdAt?: InputMaybe<TimestamptzComparisonExp>;
  file?: InputMaybe<FilesBoolExp>;
  fileId?: InputMaybe<UuidComparisonExp>;
  filename?: InputMaybe<StringComparisonExp>;
  id?: InputMaybe<UuidComparisonExp>;
  updatedAt?: InputMaybe<TimestamptzComparisonExp>;
  userSession?: InputMaybe<JsonbComparisonExp>;
  virus?: InputMaybe<StringComparisonExp>;
};

/** unique or primary key constraints on table "storage.virus" */
export enum VirusConstraint {
  /** unique or primary key constraint on columns "id" */
  VIRUS_PKEY = 'virus_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type VirusDeleteAtPathInput = {
  userSession?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type VirusDeleteElemInput = {
  userSession?: InputMaybe<Scalars['Int']['input']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type VirusDeleteKeyInput = {
  userSession?: InputMaybe<Scalars['String']['input']>;
};

/** input type for inserting data into table "storage.virus" */
export type VirusInsertInput = {
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  file?: InputMaybe<FilesObjRelInsertInput>;
  fileId?: InputMaybe<Scalars['uuid']['input']>;
  filename?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']['input']>;
  userSession?: InputMaybe<Scalars['jsonb']['input']>;
  virus?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type VirusMaxFields = {
  __typename?: 'virus_max_fields';
  createdAt?: Maybe<Scalars['timestamptz']['output']>;
  fileId?: Maybe<Scalars['uuid']['output']>;
  filename?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  updatedAt?: Maybe<Scalars['timestamptz']['output']>;
  virus?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type VirusMinFields = {
  __typename?: 'virus_min_fields';
  createdAt?: Maybe<Scalars['timestamptz']['output']>;
  fileId?: Maybe<Scalars['uuid']['output']>;
  filename?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  updatedAt?: Maybe<Scalars['timestamptz']['output']>;
  virus?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "storage.virus" */
export type VirusMutationResponse = {
  __typename?: 'virus_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Virus>;
};

/** on_conflict condition type for table "storage.virus" */
export type VirusOnConflict = {
  constraint: VirusConstraint;
  update_columns?: Array<VirusUpdateColumn>;
  where?: InputMaybe<VirusBoolExp>;
};

/** Ordering options when selecting data from "storage.virus". */
export type VirusOrderBy = {
  createdAt?: InputMaybe<OrderBy>;
  file?: InputMaybe<FilesOrderBy>;
  fileId?: InputMaybe<OrderBy>;
  filename?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
  userSession?: InputMaybe<OrderBy>;
  virus?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: storage.virus */
export type VirusPkColumnsInput = {
  id: Scalars['uuid']['input'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type VirusPrependInput = {
  userSession?: InputMaybe<Scalars['jsonb']['input']>;
};

/** select columns of table "storage.virus" */
export enum VirusSelectColumn {
  /** column name */
  CREATEDAT = 'createdAt',
  /** column name */
  FILEID = 'fileId',
  /** column name */
  FILENAME = 'filename',
  /** column name */
  ID = 'id',
  /** column name */
  UPDATEDAT = 'updatedAt',
  /** column name */
  USERSESSION = 'userSession',
  /** column name */
  VIRUS = 'virus'
}

/** input type for updating data in table "storage.virus" */
export type VirusSetInput = {
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  fileId?: InputMaybe<Scalars['uuid']['input']>;
  filename?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']['input']>;
  userSession?: InputMaybe<Scalars['jsonb']['input']>;
  virus?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "virus" */
export type VirusStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: VirusStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type VirusStreamCursorValueInput = {
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  fileId?: InputMaybe<Scalars['uuid']['input']>;
  filename?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']['input']>;
  userSession?: InputMaybe<Scalars['jsonb']['input']>;
  virus?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "storage.virus" */
export enum VirusUpdateColumn {
  /** column name */
  CREATEDAT = 'createdAt',
  /** column name */
  FILEID = 'fileId',
  /** column name */
  FILENAME = 'filename',
  /** column name */
  ID = 'id',
  /** column name */
  UPDATEDAT = 'updatedAt',
  /** column name */
  USERSESSION = 'userSession',
  /** column name */
  VIRUS = 'virus'
}

export type VirusUpdates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<VirusAppendInput>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<VirusDeleteAtPathInput>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<VirusDeleteElemInput>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<VirusDeleteKeyInput>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<VirusPrependInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<VirusSetInput>;
  /** filter the rows which have to be updated */
  where: VirusBoolExp;
};

export type ApiKeyFragment = { __typename?: 'api_keys', id: any, createdAt: any, name?: string | null, secretMask?: string | null, secretHash?: string | null, revokedAt?: any | null, revoked?: boolean | null, organizationId: any, lastAccessedAt?: any | null };

export type CreateApiKeyMutationVariables = Exact<{
  id: Scalars['uuid']['input'];
  organizationId: Scalars['uuid']['input'];
  secretHash: Scalars['String']['input'];
  secretMask: Scalars['String']['input'];
}>;


export type CreateApiKeyMutation = { __typename?: 'mutation_root', apiKey?: { __typename?: 'api_keys', id: any, createdAt: any, name?: string | null, secretMask?: string | null, secretHash?: string | null, revokedAt?: any | null, revoked?: boolean | null, organizationId: any, lastAccessedAt?: any | null } | null };

export type UpdateApiKeyMutationVariables = Exact<{
  id: Scalars['uuid']['input'];
  set: ApiKeysSetInput;
}>;


export type UpdateApiKeyMutation = { __typename?: 'mutation_root', apiKey?: { __typename?: 'api_keys', id: any, createdAt: any, name?: string | null, secretMask?: string | null, revokedAt?: any | null, revoked?: boolean | null, organizationId: any, lastAccessedAt?: any | null } | null };

export type GenerationPresetFragment = { __typename?: 'generation_presets', id: any, organizationId: any, userId: any, name: string, description?: string | null, options: any, isShared?: boolean | null, model: { __typename?: 'models', id: any, createdAt: any, updatedAt: any, name: string, description?: string | null, type: ModelTypeEnum, version: string, endpoint: string, requiredInputCombinations: any } };

export type CreateGenerationPresetMutationVariables = Exact<{
  userId: Scalars['uuid']['input'];
  organizationId: Scalars['uuid']['input'];
  name: Scalars['String']['input'];
  description?: InputMaybe<Scalars['String']['input']>;
  modelName?: InputMaybe<Scalars['String']['input']>;
  options?: InputMaybe<Scalars['jsonb']['input']>;
}>;


export type CreateGenerationPresetMutation = { __typename?: 'mutation_root', generationPreset?: { __typename?: 'generation_presets', id: any, organizationId: any, userId: any, name: string, description?: string | null, options: any, isShared?: boolean | null, model: { __typename?: 'models', id: any, createdAt: any, updatedAt: any, name: string, description?: string | null, type: ModelTypeEnum, version: string, endpoint: string, requiredInputCombinations: any } } | null };

export type UpdateGenerationPresetMutationVariables = Exact<{
  id: Scalars['uuid']['input'];
  set: GenerationPresetsSetInput;
}>;


export type UpdateGenerationPresetMutation = { __typename?: 'mutation_root', generationPreset?: { __typename?: 'generation_presets', id: any, organizationId: any, userId: any, name: string, description?: string | null, options: any, isShared?: boolean | null, model: { __typename?: 'models', id: any, createdAt: any, updatedAt: any, name: string, description?: string | null, type: ModelTypeEnum, version: string, endpoint: string, requiredInputCombinations: any } } | null };

export type GetOrganizationGenerationPresetsQueryVariables = Exact<{
  organizationId: Scalars['uuid']['input'];
}>;


export type GetOrganizationGenerationPresetsQuery = { __typename?: 'query_root', generationPresets: Array<{ __typename?: 'generation_presets', id: any, organizationId: any, userId: any, name: string, description?: string | null, options: any, isShared?: boolean | null, model: { __typename?: 'models', id: any, createdAt: any, updatedAt: any, name: string, description?: string | null, type: ModelTypeEnum, version: string, endpoint: string, requiredInputCombinations: any } }> };

export type GenerationFragment = { __typename?: 'generations', id: any, createdAt: any, updatedAt: any, startedAt?: any | null, finishedAt?: any | null, organizationId: any, transactionId: any, status: StatusEnum, modelName: string, inputs?: any | null, webhookUrl?: string | null, options?: any | null, stream?: boolean | null, outputMediaUrl?: string | null, outputMediaLength?: number | null, potentialError?: any | null };

export type CreateGenerationMutationVariables = Exact<{
  organizationId: Scalars['uuid']['input'];
  status: StatusEnum;
  modelName: Scalars['String']['input'];
  inputs?: InputMaybe<Scalars['jsonb']['input']>;
  webhookUrl?: InputMaybe<Scalars['String']['input']>;
  options?: InputMaybe<Scalars['jsonb']['input']>;
  stream?: InputMaybe<Scalars['Boolean']['input']>;
}>;


export type CreateGenerationMutation = { __typename?: 'mutation_root', generation?: { __typename?: 'generations', id: any, createdAt: any, updatedAt: any, startedAt?: any | null, finishedAt?: any | null, organizationId: any, transactionId: any, status: StatusEnum, modelName: string, inputs?: any | null, webhookUrl?: string | null, options?: any | null, stream?: boolean | null, outputMediaUrl?: string | null, outputMediaLength?: number | null, potentialError?: any | null } | null };

export type UpdateGenerationMutationVariables = Exact<{
  id: Scalars['uuid']['input'];
  set: GenerationsSetInput;
}>;


export type UpdateGenerationMutation = { __typename?: 'mutation_root', generation?: { __typename?: 'generations', id: any, createdAt: any, updatedAt: any, startedAt?: any | null, finishedAt?: any | null, organizationId: any, transactionId: any, status: StatusEnum, modelName: string, inputs?: any | null, webhookUrl?: string | null, options?: any | null, stream?: boolean | null, outputMediaUrl?: string | null, outputMediaLength?: number | null, potentialError?: any | null } | null };

export type GetGenerationByIdQueryVariables = Exact<{
  id: Scalars['uuid']['input'];
  organizationId: Scalars['uuid']['input'];
}>;


export type GetGenerationByIdQuery = { __typename?: 'query_root', generations: Array<{ __typename?: 'generations', id: any, createdAt: any, updatedAt: any, startedAt?: any | null, finishedAt?: any | null, organizationId: any, transactionId: any, status: StatusEnum, modelName: string, inputs?: any | null, webhookUrl?: string | null, options?: any | null, stream?: boolean | null, outputMediaUrl?: string | null, outputMediaLength?: number | null, potentialError?: any | null }> };

export type GenerationUpdatedSubscriptionVariables = Exact<{
  id: Scalars['uuid']['input'];
}>;


export type GenerationUpdatedSubscription = { __typename?: 'subscription_root', generation?: { __typename?: 'generations', id: any, createdAt: any, updatedAt: any, startedAt?: any | null, finishedAt?: any | null, organizationId: any, transactionId: any, status: StatusEnum, modelName: string, inputs?: any | null, webhookUrl?: string | null, options?: any | null, stream?: boolean | null, outputMediaUrl?: string | null, outputMediaLength?: number | null, potentialError?: any | null } | null };

export type OrganizationSessionGenerationsCountUpdatedSubscriptionVariables = Exact<{
  organizationId?: InputMaybe<Scalars['uuid']['input']>;
  activeGenerationIds: Array<Scalars['uuid']['input']> | Scalars['uuid']['input'];
}>;


export type OrganizationSessionGenerationsCountUpdatedSubscription = { __typename?: 'subscription_root', totalGenerations: { __typename?: 'generations_aggregate', aggregate?: { __typename?: 'generations_aggregate_fields', count: number } | null } };

export type OrganizationSessionGenerationsUpdatedSubscriptionVariables = Exact<{
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  organizationId?: InputMaybe<Scalars['uuid']['input']>;
  activeGenerationIds: Array<Scalars['uuid']['input']> | Scalars['uuid']['input'];
}>;


export type OrganizationSessionGenerationsUpdatedSubscription = { __typename?: 'subscription_root', generations: Array<{ __typename?: 'generations', id: any, createdAt: any, updatedAt: any, startedAt?: any | null, finishedAt?: any | null, organizationId: any, transactionId: any, status: StatusEnum, modelName: string, inputs?: any | null, webhookUrl?: string | null, options?: any | null, stream?: boolean | null, outputMediaUrl?: string | null, outputMediaLength?: number | null, potentialError?: any | null }> };

export type ModelFragment = { __typename?: 'models', id: any, createdAt: any, updatedAt: any, name: string, description?: string | null, type: ModelTypeEnum, version: string, endpoint: string, requiredInputCombinations: any };

export type GetModelByNameQueryVariables = Exact<{
  name: Scalars['String']['input'];
}>;


export type GetModelByNameQuery = { __typename?: 'query_root', models: Array<{ __typename?: 'models', id: any, createdAt: any, updatedAt: any, name: string, description?: string | null, type: ModelTypeEnum, version: string, endpoint: string, requiredInputCombinations: any }> };

export type GetModelNamesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetModelNamesQuery = { __typename?: 'query_root', models: Array<{ __typename?: 'models', name: string }> };

export type GetModelsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetModelsQuery = { __typename?: 'query_root', models: Array<{ __typename?: 'models', id: any, createdAt: any, updatedAt: any, name: string, description?: string | null, type: ModelTypeEnum, version: string, endpoint: string, requiredInputCombinations: any }> };

export type OrganizationFeaturesFragment = { __typename?: 'organization_features', concurrencyOverride?: number | null };

export type OrganizationFragment = { __typename?: 'organizations', id: any, name?: string | null, keys: Array<{ __typename?: 'api_keys', id: any, createdAt: any, name?: string | null, secretMask?: string | null, secretHash?: string | null, revokedAt?: any | null, revoked?: boolean | null, organizationId: any, lastAccessedAt?: any | null }> };

export type OrganizationDetailsFragment = { __typename?: 'organizations', id: any, name?: string | null, keys: Array<{ __typename?: 'api_keys', id: any, createdAt: any, name?: string | null, secretMask?: string | null, secretHash?: string | null, revokedAt?: any | null, revoked?: boolean | null, organizationId: any, lastAccessedAt?: any | null }>, features?: { __typename?: 'organization_features', concurrencyOverride?: number | null } | null };

export type OrganizationForWebAppFragment = { __typename?: 'organizations', id: any, name?: string | null, keys: Array<{ __typename?: 'api_keys', id: any, createdAt: any, name?: string | null, secretMask?: string | null, revokedAt?: any | null, revoked?: boolean | null, organizationId: any, lastAccessedAt?: any | null }> };

export type CreateOrganizationMutationVariables = Exact<{
  id: Scalars['uuid']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
}>;


export type CreateOrganizationMutation = { __typename?: 'mutation_root', organization?: { __typename?: 'organizations', id: any, createdAt: any, name?: string | null } | null, userOrganization?: { __typename?: 'user_organizations', id: any, createdAt: any } | null };

export type UpdateOrganizationMutationVariables = Exact<{
  id: Scalars['uuid']['input'];
  set: OrganizationsSetInput;
}>;


export type UpdateOrganizationMutation = { __typename?: 'mutation_root', organization?: { __typename?: 'organizations', id: any, name?: string | null, keys: Array<{ __typename?: 'api_keys', id: any, createdAt: any, name?: string | null, secretMask?: string | null, secretHash?: string | null, revokedAt?: any | null, revoked?: boolean | null, organizationId: any, lastAccessedAt?: any | null }> } | null };

export type GetOrganizationByApiKeyQueryVariables = Exact<{
  apiKeyId: Scalars['uuid']['input'];
}>;


export type GetOrganizationByApiKeyQuery = { __typename?: 'query_root', organizations: Array<{ __typename?: 'organizations', id: any, name?: string | null, keys: Array<{ __typename?: 'api_keys', id: any, createdAt: any, name?: string | null, secretMask?: string | null, secretHash?: string | null, revokedAt?: any | null, revoked?: boolean | null, organizationId: any, lastAccessedAt?: any | null }>, features?: { __typename?: 'organization_features', concurrencyOverride?: number | null } | null }> };

export type GetOrganizationByIdQueryVariables = Exact<{
  id: Scalars['uuid']['input'];
}>;


export type GetOrganizationByIdQuery = { __typename?: 'query_root', organization?: { __typename?: 'organizations', id: any, name?: string | null, keys: Array<{ __typename?: 'api_keys', id: any, createdAt: any, name?: string | null, secretMask?: string | null, secretHash?: string | null, revokedAt?: any | null, revoked?: boolean | null, organizationId: any, lastAccessedAt?: any | null }> } | null };

export type GetOrganizationByIdForWebAppQueryVariables = Exact<{
  id: Scalars['uuid']['input'];
}>;


export type GetOrganizationByIdForWebAppQuery = { __typename?: 'query_root', organization?: { __typename?: 'organizations', id: any, name?: string | null, keys: Array<{ __typename?: 'api_keys', id: any, createdAt: any, name?: string | null, secretMask?: string | null, revokedAt?: any | null, revoked?: boolean | null, organizationId: any, lastAccessedAt?: any | null }> } | null };

export type GetOrganizationByUserIdQueryVariables = Exact<{
  userId: Scalars['uuid']['input'];
}>;


export type GetOrganizationByUserIdQuery = { __typename?: 'query_root', organizations: Array<{ __typename?: 'organizations', id: any, name?: string | null, keys: Array<{ __typename?: 'api_keys', id: any, createdAt: any, name?: string | null, secretMask?: string | null, secretHash?: string | null, revokedAt?: any | null, revoked?: boolean | null, organizationId: any, lastAccessedAt?: any | null }>, features?: { __typename?: 'organization_features', concurrencyOverride?: number | null } | null }> };

export type GetUsersInOrganizationByIdQueryVariables = Exact<{
  id: Scalars['uuid']['input'];
}>;


export type GetUsersInOrganizationByIdQuery = { __typename?: 'query_root', organization?: { __typename?: 'organizations', users?: { __typename?: 'user_organizations', user?: { __typename?: 'users', id: any, createdAt: any, displayName: string, avatarUrl: string, locale: string, email?: any | null, isAnonymous: boolean, defaultRole: string, metadata?: any | null, emailVerified: boolean, phoneNumber?: string | null, phoneNumberVerified: boolean, activeMfaType?: string | null, roles: Array<{ __typename?: 'authUserRoles', role: string }>, userProviders: Array<{ __typename?: 'authUserProviders', providerId: string }> } | null } | null } | null };

export type TransactionFragment = { __typename?: 'transactions', id: any, createdAt: any, updatedAt: any, organizationId: any, generationId?: any | null, type: TransactionTypeEnum, status: StatusEnum, amount?: number | null, framesGenerated?: number | null, stripeTransactionId?: string | null };

export type UpdateTransactionMutationVariables = Exact<{
  id: Scalars['uuid']['input'];
  set: TransactionsSetInput;
}>;


export type UpdateTransactionMutation = { __typename?: 'mutation_root', transaction?: { __typename?: 'transactions', id: any, createdAt: any, updatedAt: any, organizationId: any, generationId?: any | null, type: TransactionTypeEnum, status: StatusEnum, amount?: number | null, framesGenerated?: number | null, stripeTransactionId?: string | null } | null };

export type GetTransactionByIdQueryVariables = Exact<{
  id: Scalars['uuid']['input'];
}>;


export type GetTransactionByIdQuery = { __typename?: 'query_root', transaction?: { __typename?: 'transactions', id: any, createdAt: any, updatedAt: any, organizationId: any, generationId?: any | null, type: TransactionTypeEnum, status: StatusEnum, amount?: number | null, framesGenerated?: number | null, stripeTransactionId?: string | null } | null };

export type GetUserOrganizationsByUserIdQueryVariables = Exact<{
  userId?: InputMaybe<Scalars['uuid']['input']>;
}>;


export type GetUserOrganizationsByUserIdQuery = { __typename?: 'query_root', user_organizations: Array<{ __typename?: 'user_organizations', id: any, organizationId: any }> };

export type UserFragment = { __typename?: 'users', id: any, createdAt: any, displayName: string, avatarUrl: string, locale: string, email?: any | null, isAnonymous: boolean, defaultRole: string, metadata?: any | null, emailVerified: boolean, phoneNumber?: string | null, phoneNumberVerified: boolean, activeMfaType?: string | null, roles: Array<{ __typename?: 'authUserRoles', role: string }>, userProviders: Array<{ __typename?: 'authUserProviders', providerId: string }> };

export type UserDetailsFragment = { __typename?: 'users', id: any, displayName: string, email?: any | null, createdAt: any };

export type GetUserQueryVariables = Exact<{
  id: Scalars['uuid']['input'];
}>;


export type GetUserQuery = { __typename?: 'query_root', users: Array<{ __typename?: 'users', id: any, displayName: string, email?: any | null }> };

export type GetUserProviderQueryVariables = Exact<{
  id: Scalars['uuid']['input'];
}>;


export type GetUserProviderQuery = { __typename?: 'query_root', users: Array<{ __typename?: 'users', userProviders: Array<{ __typename?: 'authUserProviders', providerId: string }> }> };

export type GetUsersQueryVariables = Exact<{ [key: string]: never; }>;


export type GetUsersQuery = { __typename?: 'query_root', users: Array<{ __typename?: 'users', id: any, displayName: string, email?: any | null, createdAt: any }> };



export type ResolverTypeWrapper<T> = Promise<T> | T;


export type ResolverWithResolve<TResult, TParent, TContext, TArgs> = {
  resolve: ResolverFn<TResult, TParent, TContext, TArgs>;
};
export type Resolver<TResult, TParent = {}, TContext = {}, TArgs = {}> = ResolverFn<TResult, TParent, TContext, TArgs> | ResolverWithResolve<TResult, TParent, TContext, TArgs>;

export type ResolverFn<TResult, TParent, TContext, TArgs> = (
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => Promise<TResult> | TResult;

export type SubscriptionSubscribeFn<TResult, TParent, TContext, TArgs> = (
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => AsyncIterable<TResult> | Promise<AsyncIterable<TResult>>;

export type SubscriptionResolveFn<TResult, TParent, TContext, TArgs> = (
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => TResult | Promise<TResult>;

export interface SubscriptionSubscriberObject<TResult, TKey extends string, TParent, TContext, TArgs> {
  subscribe: SubscriptionSubscribeFn<{ [key in TKey]: TResult }, TParent, TContext, TArgs>;
  resolve?: SubscriptionResolveFn<TResult, { [key in TKey]: TResult }, TContext, TArgs>;
}

export interface SubscriptionResolverObject<TResult, TParent, TContext, TArgs> {
  subscribe: SubscriptionSubscribeFn<any, TParent, TContext, TArgs>;
  resolve: SubscriptionResolveFn<TResult, any, TContext, TArgs>;
}

export type SubscriptionObject<TResult, TKey extends string, TParent, TContext, TArgs> =
  | SubscriptionSubscriberObject<TResult, TKey, TParent, TContext, TArgs>
  | SubscriptionResolverObject<TResult, TParent, TContext, TArgs>;

export type SubscriptionResolver<TResult, TKey extends string, TParent = {}, TContext = {}, TArgs = {}> =
  | ((...args: any[]) => SubscriptionObject<TResult, TKey, TParent, TContext, TArgs>)
  | SubscriptionObject<TResult, TKey, TParent, TContext, TArgs>;

export type TypeResolveFn<TTypes, TParent = {}, TContext = {}> = (
  parent: TParent,
  context: TContext,
  info: GraphQLResolveInfo
) => Maybe<TTypes> | Promise<Maybe<TTypes>>;

export type IsTypeOfResolverFn<T = {}, TContext = {}> = (obj: T, context: TContext, info: GraphQLResolveInfo) => boolean | Promise<boolean>;

export type NextResolverFn<T> = () => Promise<T>;

export type DirectiveResolverFn<TResult = {}, TParent = {}, TContext = {}, TArgs = {}> = (
  next: NextResolverFn<TResult>,
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => TResult | Promise<TResult>;



/** Mapping between all available schema types and the resolvers types */
export type ResolversTypes = {
  Boolean: ResolverTypeWrapper<Scalars['Boolean']['output']>;
  Boolean_comparison_exp: BooleanComparisonExp;
  Float: ResolverTypeWrapper<Scalars['Float']['output']>;
  Int: ResolverTypeWrapper<Scalars['Int']['output']>;
  Int_comparison_exp: IntComparisonExp;
  String: ResolverTypeWrapper<Scalars['String']['output']>;
  String_comparison_exp: StringComparisonExp;
  api_keys: ResolverTypeWrapper<ApiKeys>;
  api_keys_aggregate: ResolverTypeWrapper<ApiKeysAggregate>;
  api_keys_aggregate_bool_exp: ApiKeysAggregateBoolExp;
  api_keys_aggregate_bool_exp_bool_and: ApiKeysAggregateBoolExpBoolAnd;
  api_keys_aggregate_bool_exp_bool_or: ApiKeysAggregateBoolExpBoolOr;
  api_keys_aggregate_bool_exp_count: ApiKeysAggregateBoolExpCount;
  api_keys_aggregate_fields: ResolverTypeWrapper<ApiKeysAggregateFields>;
  api_keys_aggregate_order_by: ApiKeysAggregateOrderBy;
  api_keys_arr_rel_insert_input: ApiKeysArrRelInsertInput;
  api_keys_bool_exp: ApiKeysBoolExp;
  api_keys_constraint: ApiKeysConstraint;
  api_keys_insert_input: ApiKeysInsertInput;
  api_keys_max_fields: ResolverTypeWrapper<ApiKeysMaxFields>;
  api_keys_max_order_by: ApiKeysMaxOrderBy;
  api_keys_min_fields: ResolverTypeWrapper<ApiKeysMinFields>;
  api_keys_min_order_by: ApiKeysMinOrderBy;
  api_keys_mutation_response: ResolverTypeWrapper<ApiKeysMutationResponse>;
  api_keys_on_conflict: ApiKeysOnConflict;
  api_keys_order_by: ApiKeysOrderBy;
  api_keys_pk_columns_input: ApiKeysPkColumnsInput;
  api_keys_select_column: ApiKeysSelectColumn;
  api_keys_select_column_api_keys_aggregate_bool_exp_bool_and_arguments_columns: ApiKeysSelectColumnApiKeysAggregateBoolExpBoolAndArgumentsColumns;
  api_keys_select_column_api_keys_aggregate_bool_exp_bool_or_arguments_columns: ApiKeysSelectColumnApiKeysAggregateBoolExpBoolOrArgumentsColumns;
  api_keys_set_input: ApiKeysSetInput;
  api_keys_stream_cursor_input: ApiKeysStreamCursorInput;
  api_keys_stream_cursor_value_input: ApiKeysStreamCursorValueInput;
  api_keys_update_column: ApiKeysUpdateColumn;
  api_keys_updates: ApiKeysUpdates;
  authProviderRequests: ResolverTypeWrapper<AuthProviderRequests>;
  authProviderRequests_aggregate: ResolverTypeWrapper<AuthProviderRequestsAggregate>;
  authProviderRequests_aggregate_fields: ResolverTypeWrapper<AuthProviderRequestsAggregateFields>;
  authProviderRequests_append_input: AuthProviderRequestsAppendInput;
  authProviderRequests_bool_exp: AuthProviderRequestsBoolExp;
  authProviderRequests_constraint: AuthProviderRequestsConstraint;
  authProviderRequests_delete_at_path_input: AuthProviderRequestsDeleteAtPathInput;
  authProviderRequests_delete_elem_input: AuthProviderRequestsDeleteElemInput;
  authProviderRequests_delete_key_input: AuthProviderRequestsDeleteKeyInput;
  authProviderRequests_insert_input: AuthProviderRequestsInsertInput;
  authProviderRequests_max_fields: ResolverTypeWrapper<AuthProviderRequestsMaxFields>;
  authProviderRequests_min_fields: ResolverTypeWrapper<AuthProviderRequestsMinFields>;
  authProviderRequests_mutation_response: ResolverTypeWrapper<AuthProviderRequestsMutationResponse>;
  authProviderRequests_on_conflict: AuthProviderRequestsOnConflict;
  authProviderRequests_order_by: AuthProviderRequestsOrderBy;
  authProviderRequests_pk_columns_input: AuthProviderRequestsPkColumnsInput;
  authProviderRequests_prepend_input: AuthProviderRequestsPrependInput;
  authProviderRequests_select_column: AuthProviderRequestsSelectColumn;
  authProviderRequests_set_input: AuthProviderRequestsSetInput;
  authProviderRequests_stream_cursor_input: AuthProviderRequestsStreamCursorInput;
  authProviderRequests_stream_cursor_value_input: AuthProviderRequestsStreamCursorValueInput;
  authProviderRequests_update_column: AuthProviderRequestsUpdateColumn;
  authProviderRequests_updates: AuthProviderRequestsUpdates;
  authProviders: ResolverTypeWrapper<AuthProviders>;
  authProviders_aggregate: ResolverTypeWrapper<AuthProvidersAggregate>;
  authProviders_aggregate_fields: ResolverTypeWrapper<AuthProvidersAggregateFields>;
  authProviders_bool_exp: AuthProvidersBoolExp;
  authProviders_constraint: AuthProvidersConstraint;
  authProviders_insert_input: AuthProvidersInsertInput;
  authProviders_max_fields: ResolverTypeWrapper<AuthProvidersMaxFields>;
  authProviders_min_fields: ResolverTypeWrapper<AuthProvidersMinFields>;
  authProviders_mutation_response: ResolverTypeWrapper<AuthProvidersMutationResponse>;
  authProviders_obj_rel_insert_input: AuthProvidersObjRelInsertInput;
  authProviders_on_conflict: AuthProvidersOnConflict;
  authProviders_order_by: AuthProvidersOrderBy;
  authProviders_pk_columns_input: AuthProvidersPkColumnsInput;
  authProviders_select_column: AuthProvidersSelectColumn;
  authProviders_set_input: AuthProvidersSetInput;
  authProviders_stream_cursor_input: AuthProvidersStreamCursorInput;
  authProviders_stream_cursor_value_input: AuthProvidersStreamCursorValueInput;
  authProviders_update_column: AuthProvidersUpdateColumn;
  authProviders_updates: AuthProvidersUpdates;
  authRefreshTokenTypes: ResolverTypeWrapper<AuthRefreshTokenTypes>;
  authRefreshTokenTypes_aggregate: ResolverTypeWrapper<AuthRefreshTokenTypesAggregate>;
  authRefreshTokenTypes_aggregate_fields: ResolverTypeWrapper<AuthRefreshTokenTypesAggregateFields>;
  authRefreshTokenTypes_bool_exp: AuthRefreshTokenTypesBoolExp;
  authRefreshTokenTypes_constraint: AuthRefreshTokenTypesConstraint;
  authRefreshTokenTypes_enum: AuthRefreshTokenTypesEnum;
  authRefreshTokenTypes_enum_comparison_exp: AuthRefreshTokenTypesEnumComparisonExp;
  authRefreshTokenTypes_insert_input: AuthRefreshTokenTypesInsertInput;
  authRefreshTokenTypes_max_fields: ResolverTypeWrapper<AuthRefreshTokenTypesMaxFields>;
  authRefreshTokenTypes_min_fields: ResolverTypeWrapper<AuthRefreshTokenTypesMinFields>;
  authRefreshTokenTypes_mutation_response: ResolverTypeWrapper<AuthRefreshTokenTypesMutationResponse>;
  authRefreshTokenTypes_on_conflict: AuthRefreshTokenTypesOnConflict;
  authRefreshTokenTypes_order_by: AuthRefreshTokenTypesOrderBy;
  authRefreshTokenTypes_pk_columns_input: AuthRefreshTokenTypesPkColumnsInput;
  authRefreshTokenTypes_select_column: AuthRefreshTokenTypesSelectColumn;
  authRefreshTokenTypes_set_input: AuthRefreshTokenTypesSetInput;
  authRefreshTokenTypes_stream_cursor_input: AuthRefreshTokenTypesStreamCursorInput;
  authRefreshTokenTypes_stream_cursor_value_input: AuthRefreshTokenTypesStreamCursorValueInput;
  authRefreshTokenTypes_update_column: AuthRefreshTokenTypesUpdateColumn;
  authRefreshTokenTypes_updates: AuthRefreshTokenTypesUpdates;
  authRefreshTokens: ResolverTypeWrapper<AuthRefreshTokens>;
  authRefreshTokens_aggregate: ResolverTypeWrapper<AuthRefreshTokensAggregate>;
  authRefreshTokens_aggregate_bool_exp: AuthRefreshTokensAggregateBoolExp;
  authRefreshTokens_aggregate_bool_exp_count: AuthRefreshTokensAggregateBoolExpCount;
  authRefreshTokens_aggregate_fields: ResolverTypeWrapper<AuthRefreshTokensAggregateFields>;
  authRefreshTokens_aggregate_order_by: AuthRefreshTokensAggregateOrderBy;
  authRefreshTokens_append_input: AuthRefreshTokensAppendInput;
  authRefreshTokens_arr_rel_insert_input: AuthRefreshTokensArrRelInsertInput;
  authRefreshTokens_bool_exp: AuthRefreshTokensBoolExp;
  authRefreshTokens_constraint: AuthRefreshTokensConstraint;
  authRefreshTokens_delete_at_path_input: AuthRefreshTokensDeleteAtPathInput;
  authRefreshTokens_delete_elem_input: AuthRefreshTokensDeleteElemInput;
  authRefreshTokens_delete_key_input: AuthRefreshTokensDeleteKeyInput;
  authRefreshTokens_insert_input: AuthRefreshTokensInsertInput;
  authRefreshTokens_max_fields: ResolverTypeWrapper<AuthRefreshTokensMaxFields>;
  authRefreshTokens_max_order_by: AuthRefreshTokensMaxOrderBy;
  authRefreshTokens_min_fields: ResolverTypeWrapper<AuthRefreshTokensMinFields>;
  authRefreshTokens_min_order_by: AuthRefreshTokensMinOrderBy;
  authRefreshTokens_mutation_response: ResolverTypeWrapper<AuthRefreshTokensMutationResponse>;
  authRefreshTokens_on_conflict: AuthRefreshTokensOnConflict;
  authRefreshTokens_order_by: AuthRefreshTokensOrderBy;
  authRefreshTokens_pk_columns_input: AuthRefreshTokensPkColumnsInput;
  authRefreshTokens_prepend_input: AuthRefreshTokensPrependInput;
  authRefreshTokens_select_column: AuthRefreshTokensSelectColumn;
  authRefreshTokens_set_input: AuthRefreshTokensSetInput;
  authRefreshTokens_stream_cursor_input: AuthRefreshTokensStreamCursorInput;
  authRefreshTokens_stream_cursor_value_input: AuthRefreshTokensStreamCursorValueInput;
  authRefreshTokens_update_column: AuthRefreshTokensUpdateColumn;
  authRefreshTokens_updates: AuthRefreshTokensUpdates;
  authRoles: ResolverTypeWrapper<AuthRoles>;
  authRoles_aggregate: ResolverTypeWrapper<AuthRolesAggregate>;
  authRoles_aggregate_fields: ResolverTypeWrapper<AuthRolesAggregateFields>;
  authRoles_bool_exp: AuthRolesBoolExp;
  authRoles_constraint: AuthRolesConstraint;
  authRoles_insert_input: AuthRolesInsertInput;
  authRoles_max_fields: ResolverTypeWrapper<AuthRolesMaxFields>;
  authRoles_min_fields: ResolverTypeWrapper<AuthRolesMinFields>;
  authRoles_mutation_response: ResolverTypeWrapper<AuthRolesMutationResponse>;
  authRoles_obj_rel_insert_input: AuthRolesObjRelInsertInput;
  authRoles_on_conflict: AuthRolesOnConflict;
  authRoles_order_by: AuthRolesOrderBy;
  authRoles_pk_columns_input: AuthRolesPkColumnsInput;
  authRoles_select_column: AuthRolesSelectColumn;
  authRoles_set_input: AuthRolesSetInput;
  authRoles_stream_cursor_input: AuthRolesStreamCursorInput;
  authRoles_stream_cursor_value_input: AuthRolesStreamCursorValueInput;
  authRoles_update_column: AuthRolesUpdateColumn;
  authRoles_updates: AuthRolesUpdates;
  authUserProviders: ResolverTypeWrapper<AuthUserProviders>;
  authUserProviders_aggregate: ResolverTypeWrapper<AuthUserProvidersAggregate>;
  authUserProviders_aggregate_bool_exp: AuthUserProvidersAggregateBoolExp;
  authUserProviders_aggregate_bool_exp_count: AuthUserProvidersAggregateBoolExpCount;
  authUserProviders_aggregate_fields: ResolverTypeWrapper<AuthUserProvidersAggregateFields>;
  authUserProviders_aggregate_order_by: AuthUserProvidersAggregateOrderBy;
  authUserProviders_arr_rel_insert_input: AuthUserProvidersArrRelInsertInput;
  authUserProviders_bool_exp: AuthUserProvidersBoolExp;
  authUserProviders_constraint: AuthUserProvidersConstraint;
  authUserProviders_insert_input: AuthUserProvidersInsertInput;
  authUserProviders_max_fields: ResolverTypeWrapper<AuthUserProvidersMaxFields>;
  authUserProviders_max_order_by: AuthUserProvidersMaxOrderBy;
  authUserProviders_min_fields: ResolverTypeWrapper<AuthUserProvidersMinFields>;
  authUserProviders_min_order_by: AuthUserProvidersMinOrderBy;
  authUserProviders_mutation_response: ResolverTypeWrapper<AuthUserProvidersMutationResponse>;
  authUserProviders_on_conflict: AuthUserProvidersOnConflict;
  authUserProviders_order_by: AuthUserProvidersOrderBy;
  authUserProviders_pk_columns_input: AuthUserProvidersPkColumnsInput;
  authUserProviders_select_column: AuthUserProvidersSelectColumn;
  authUserProviders_set_input: AuthUserProvidersSetInput;
  authUserProviders_stream_cursor_input: AuthUserProvidersStreamCursorInput;
  authUserProviders_stream_cursor_value_input: AuthUserProvidersStreamCursorValueInput;
  authUserProviders_update_column: AuthUserProvidersUpdateColumn;
  authUserProviders_updates: AuthUserProvidersUpdates;
  authUserRoles: ResolverTypeWrapper<AuthUserRoles>;
  authUserRoles_aggregate: ResolverTypeWrapper<AuthUserRolesAggregate>;
  authUserRoles_aggregate_bool_exp: AuthUserRolesAggregateBoolExp;
  authUserRoles_aggregate_bool_exp_count: AuthUserRolesAggregateBoolExpCount;
  authUserRoles_aggregate_fields: ResolverTypeWrapper<AuthUserRolesAggregateFields>;
  authUserRoles_aggregate_order_by: AuthUserRolesAggregateOrderBy;
  authUserRoles_arr_rel_insert_input: AuthUserRolesArrRelInsertInput;
  authUserRoles_bool_exp: AuthUserRolesBoolExp;
  authUserRoles_constraint: AuthUserRolesConstraint;
  authUserRoles_insert_input: AuthUserRolesInsertInput;
  authUserRoles_max_fields: ResolverTypeWrapper<AuthUserRolesMaxFields>;
  authUserRoles_max_order_by: AuthUserRolesMaxOrderBy;
  authUserRoles_min_fields: ResolverTypeWrapper<AuthUserRolesMinFields>;
  authUserRoles_min_order_by: AuthUserRolesMinOrderBy;
  authUserRoles_mutation_response: ResolverTypeWrapper<AuthUserRolesMutationResponse>;
  authUserRoles_on_conflict: AuthUserRolesOnConflict;
  authUserRoles_order_by: AuthUserRolesOrderBy;
  authUserRoles_pk_columns_input: AuthUserRolesPkColumnsInput;
  authUserRoles_select_column: AuthUserRolesSelectColumn;
  authUserRoles_set_input: AuthUserRolesSetInput;
  authUserRoles_stream_cursor_input: AuthUserRolesStreamCursorInput;
  authUserRoles_stream_cursor_value_input: AuthUserRolesStreamCursorValueInput;
  authUserRoles_update_column: AuthUserRolesUpdateColumn;
  authUserRoles_updates: AuthUserRolesUpdates;
  authUserSecurityKeys: ResolverTypeWrapper<AuthUserSecurityKeys>;
  authUserSecurityKeys_aggregate: ResolverTypeWrapper<AuthUserSecurityKeysAggregate>;
  authUserSecurityKeys_aggregate_bool_exp: AuthUserSecurityKeysAggregateBoolExp;
  authUserSecurityKeys_aggregate_bool_exp_count: AuthUserSecurityKeysAggregateBoolExpCount;
  authUserSecurityKeys_aggregate_fields: ResolverTypeWrapper<AuthUserSecurityKeysAggregateFields>;
  authUserSecurityKeys_aggregate_order_by: AuthUserSecurityKeysAggregateOrderBy;
  authUserSecurityKeys_arr_rel_insert_input: AuthUserSecurityKeysArrRelInsertInput;
  authUserSecurityKeys_avg_fields: ResolverTypeWrapper<AuthUserSecurityKeysAvgFields>;
  authUserSecurityKeys_avg_order_by: AuthUserSecurityKeysAvgOrderBy;
  authUserSecurityKeys_bool_exp: AuthUserSecurityKeysBoolExp;
  authUserSecurityKeys_constraint: AuthUserSecurityKeysConstraint;
  authUserSecurityKeys_inc_input: AuthUserSecurityKeysIncInput;
  authUserSecurityKeys_insert_input: AuthUserSecurityKeysInsertInput;
  authUserSecurityKeys_max_fields: ResolverTypeWrapper<AuthUserSecurityKeysMaxFields>;
  authUserSecurityKeys_max_order_by: AuthUserSecurityKeysMaxOrderBy;
  authUserSecurityKeys_min_fields: ResolverTypeWrapper<AuthUserSecurityKeysMinFields>;
  authUserSecurityKeys_min_order_by: AuthUserSecurityKeysMinOrderBy;
  authUserSecurityKeys_mutation_response: ResolverTypeWrapper<AuthUserSecurityKeysMutationResponse>;
  authUserSecurityKeys_on_conflict: AuthUserSecurityKeysOnConflict;
  authUserSecurityKeys_order_by: AuthUserSecurityKeysOrderBy;
  authUserSecurityKeys_pk_columns_input: AuthUserSecurityKeysPkColumnsInput;
  authUserSecurityKeys_select_column: AuthUserSecurityKeysSelectColumn;
  authUserSecurityKeys_set_input: AuthUserSecurityKeysSetInput;
  authUserSecurityKeys_stddev_fields: ResolverTypeWrapper<AuthUserSecurityKeysStddevFields>;
  authUserSecurityKeys_stddev_order_by: AuthUserSecurityKeysStddevOrderBy;
  authUserSecurityKeys_stddev_pop_fields: ResolverTypeWrapper<AuthUserSecurityKeysStddevPopFields>;
  authUserSecurityKeys_stddev_pop_order_by: AuthUserSecurityKeysStddevPopOrderBy;
  authUserSecurityKeys_stddev_samp_fields: ResolverTypeWrapper<AuthUserSecurityKeysStddevSampFields>;
  authUserSecurityKeys_stddev_samp_order_by: AuthUserSecurityKeysStddevSampOrderBy;
  authUserSecurityKeys_stream_cursor_input: AuthUserSecurityKeysStreamCursorInput;
  authUserSecurityKeys_stream_cursor_value_input: AuthUserSecurityKeysStreamCursorValueInput;
  authUserSecurityKeys_sum_fields: ResolverTypeWrapper<AuthUserSecurityKeysSumFields>;
  authUserSecurityKeys_sum_order_by: AuthUserSecurityKeysSumOrderBy;
  authUserSecurityKeys_update_column: AuthUserSecurityKeysUpdateColumn;
  authUserSecurityKeys_updates: AuthUserSecurityKeysUpdates;
  authUserSecurityKeys_var_pop_fields: ResolverTypeWrapper<AuthUserSecurityKeysVarPopFields>;
  authUserSecurityKeys_var_pop_order_by: AuthUserSecurityKeysVarPopOrderBy;
  authUserSecurityKeys_var_samp_fields: ResolverTypeWrapper<AuthUserSecurityKeysVarSampFields>;
  authUserSecurityKeys_var_samp_order_by: AuthUserSecurityKeysVarSampOrderBy;
  authUserSecurityKeys_variance_fields: ResolverTypeWrapper<AuthUserSecurityKeysVarianceFields>;
  authUserSecurityKeys_variance_order_by: AuthUserSecurityKeysVarianceOrderBy;
  bigint: ResolverTypeWrapper<Scalars['bigint']['output']>;
  bigint_comparison_exp: BigintComparisonExp;
  buckets: ResolverTypeWrapper<Buckets>;
  buckets_aggregate: ResolverTypeWrapper<BucketsAggregate>;
  buckets_aggregate_fields: ResolverTypeWrapper<BucketsAggregateFields>;
  buckets_avg_fields: ResolverTypeWrapper<BucketsAvgFields>;
  buckets_bool_exp: BucketsBoolExp;
  buckets_constraint: BucketsConstraint;
  buckets_inc_input: BucketsIncInput;
  buckets_insert_input: BucketsInsertInput;
  buckets_max_fields: ResolverTypeWrapper<BucketsMaxFields>;
  buckets_min_fields: ResolverTypeWrapper<BucketsMinFields>;
  buckets_mutation_response: ResolverTypeWrapper<BucketsMutationResponse>;
  buckets_obj_rel_insert_input: BucketsObjRelInsertInput;
  buckets_on_conflict: BucketsOnConflict;
  buckets_order_by: BucketsOrderBy;
  buckets_pk_columns_input: BucketsPkColumnsInput;
  buckets_select_column: BucketsSelectColumn;
  buckets_set_input: BucketsSetInput;
  buckets_stddev_fields: ResolverTypeWrapper<BucketsStddevFields>;
  buckets_stddev_pop_fields: ResolverTypeWrapper<BucketsStddevPopFields>;
  buckets_stddev_samp_fields: ResolverTypeWrapper<BucketsStddevSampFields>;
  buckets_stream_cursor_input: BucketsStreamCursorInput;
  buckets_stream_cursor_value_input: BucketsStreamCursorValueInput;
  buckets_sum_fields: ResolverTypeWrapper<BucketsSumFields>;
  buckets_update_column: BucketsUpdateColumn;
  buckets_updates: BucketsUpdates;
  buckets_var_pop_fields: ResolverTypeWrapper<BucketsVarPopFields>;
  buckets_var_samp_fields: ResolverTypeWrapper<BucketsVarSampFields>;
  buckets_variance_fields: ResolverTypeWrapper<BucketsVarianceFields>;
  bytea: ResolverTypeWrapper<Scalars['bytea']['output']>;
  bytea_comparison_exp: ByteaComparisonExp;
  citext: ResolverTypeWrapper<Scalars['citext']['output']>;
  citext_comparison_exp: CitextComparisonExp;
  cursor_ordering: CursorOrdering;
  files: ResolverTypeWrapper<Files>;
  files_aggregate: ResolverTypeWrapper<FilesAggregate>;
  files_aggregate_bool_exp: FilesAggregateBoolExp;
  files_aggregate_bool_exp_bool_and: FilesAggregateBoolExpBoolAnd;
  files_aggregate_bool_exp_bool_or: FilesAggregateBoolExpBoolOr;
  files_aggregate_bool_exp_count: FilesAggregateBoolExpCount;
  files_aggregate_fields: ResolverTypeWrapper<FilesAggregateFields>;
  files_aggregate_order_by: FilesAggregateOrderBy;
  files_append_input: FilesAppendInput;
  files_arr_rel_insert_input: FilesArrRelInsertInput;
  files_avg_fields: ResolverTypeWrapper<FilesAvgFields>;
  files_avg_order_by: FilesAvgOrderBy;
  files_bool_exp: FilesBoolExp;
  files_constraint: FilesConstraint;
  files_delete_at_path_input: FilesDeleteAtPathInput;
  files_delete_elem_input: FilesDeleteElemInput;
  files_delete_key_input: FilesDeleteKeyInput;
  files_inc_input: FilesIncInput;
  files_insert_input: FilesInsertInput;
  files_max_fields: ResolverTypeWrapper<FilesMaxFields>;
  files_max_order_by: FilesMaxOrderBy;
  files_min_fields: ResolverTypeWrapper<FilesMinFields>;
  files_min_order_by: FilesMinOrderBy;
  files_mutation_response: ResolverTypeWrapper<FilesMutationResponse>;
  files_obj_rel_insert_input: FilesObjRelInsertInput;
  files_on_conflict: FilesOnConflict;
  files_order_by: FilesOrderBy;
  files_pk_columns_input: FilesPkColumnsInput;
  files_prepend_input: FilesPrependInput;
  files_select_column: FilesSelectColumn;
  files_select_column_files_aggregate_bool_exp_bool_and_arguments_columns: FilesSelectColumnFilesAggregateBoolExpBoolAndArgumentsColumns;
  files_select_column_files_aggregate_bool_exp_bool_or_arguments_columns: FilesSelectColumnFilesAggregateBoolExpBoolOrArgumentsColumns;
  files_set_input: FilesSetInput;
  files_stddev_fields: ResolverTypeWrapper<FilesStddevFields>;
  files_stddev_order_by: FilesStddevOrderBy;
  files_stddev_pop_fields: ResolverTypeWrapper<FilesStddevPopFields>;
  files_stddev_pop_order_by: FilesStddevPopOrderBy;
  files_stddev_samp_fields: ResolverTypeWrapper<FilesStddevSampFields>;
  files_stddev_samp_order_by: FilesStddevSampOrderBy;
  files_stream_cursor_input: FilesStreamCursorInput;
  files_stream_cursor_value_input: FilesStreamCursorValueInput;
  files_sum_fields: ResolverTypeWrapper<FilesSumFields>;
  files_sum_order_by: FilesSumOrderBy;
  files_update_column: FilesUpdateColumn;
  files_updates: FilesUpdates;
  files_var_pop_fields: ResolverTypeWrapper<FilesVarPopFields>;
  files_var_pop_order_by: FilesVarPopOrderBy;
  files_var_samp_fields: ResolverTypeWrapper<FilesVarSampFields>;
  files_var_samp_order_by: FilesVarSampOrderBy;
  files_variance_fields: ResolverTypeWrapper<FilesVarianceFields>;
  files_variance_order_by: FilesVarianceOrderBy;
  generation_presets: ResolverTypeWrapper<GenerationPresets>;
  generation_presets_aggregate: ResolverTypeWrapper<GenerationPresetsAggregate>;
  generation_presets_aggregate_fields: ResolverTypeWrapper<GenerationPresetsAggregateFields>;
  generation_presets_append_input: GenerationPresetsAppendInput;
  generation_presets_bool_exp: GenerationPresetsBoolExp;
  generation_presets_constraint: GenerationPresetsConstraint;
  generation_presets_delete_at_path_input: GenerationPresetsDeleteAtPathInput;
  generation_presets_delete_elem_input: GenerationPresetsDeleteElemInput;
  generation_presets_delete_key_input: GenerationPresetsDeleteKeyInput;
  generation_presets_insert_input: GenerationPresetsInsertInput;
  generation_presets_max_fields: ResolverTypeWrapper<GenerationPresetsMaxFields>;
  generation_presets_min_fields: ResolverTypeWrapper<GenerationPresetsMinFields>;
  generation_presets_mutation_response: ResolverTypeWrapper<GenerationPresetsMutationResponse>;
  generation_presets_on_conflict: GenerationPresetsOnConflict;
  generation_presets_order_by: GenerationPresetsOrderBy;
  generation_presets_pk_columns_input: GenerationPresetsPkColumnsInput;
  generation_presets_prepend_input: GenerationPresetsPrependInput;
  generation_presets_select_column: GenerationPresetsSelectColumn;
  generation_presets_set_input: GenerationPresetsSetInput;
  generation_presets_stream_cursor_input: GenerationPresetsStreamCursorInput;
  generation_presets_stream_cursor_value_input: GenerationPresetsStreamCursorValueInput;
  generation_presets_update_column: GenerationPresetsUpdateColumn;
  generation_presets_updates: GenerationPresetsUpdates;
  generations: ResolverTypeWrapper<Generations>;
  generations_aggregate: ResolverTypeWrapper<GenerationsAggregate>;
  generations_aggregate_fields: ResolverTypeWrapper<GenerationsAggregateFields>;
  generations_append_input: GenerationsAppendInput;
  generations_avg_fields: ResolverTypeWrapper<GenerationsAvgFields>;
  generations_bool_exp: GenerationsBoolExp;
  generations_constraint: GenerationsConstraint;
  generations_delete_at_path_input: GenerationsDeleteAtPathInput;
  generations_delete_elem_input: GenerationsDeleteElemInput;
  generations_delete_key_input: GenerationsDeleteKeyInput;
  generations_inc_input: GenerationsIncInput;
  generations_insert_input: GenerationsInsertInput;
  generations_max_fields: ResolverTypeWrapper<GenerationsMaxFields>;
  generations_min_fields: ResolverTypeWrapper<GenerationsMinFields>;
  generations_mutation_response: ResolverTypeWrapper<GenerationsMutationResponse>;
  generations_obj_rel_insert_input: GenerationsObjRelInsertInput;
  generations_on_conflict: GenerationsOnConflict;
  generations_order_by: GenerationsOrderBy;
  generations_pk_columns_input: GenerationsPkColumnsInput;
  generations_prepend_input: GenerationsPrependInput;
  generations_select_column: GenerationsSelectColumn;
  generations_set_input: GenerationsSetInput;
  generations_stddev_fields: ResolverTypeWrapper<GenerationsStddevFields>;
  generations_stddev_pop_fields: ResolverTypeWrapper<GenerationsStddevPopFields>;
  generations_stddev_samp_fields: ResolverTypeWrapper<GenerationsStddevSampFields>;
  generations_stream_cursor_input: GenerationsStreamCursorInput;
  generations_stream_cursor_value_input: GenerationsStreamCursorValueInput;
  generations_sum_fields: ResolverTypeWrapper<GenerationsSumFields>;
  generations_update_column: GenerationsUpdateColumn;
  generations_updates: GenerationsUpdates;
  generations_var_pop_fields: ResolverTypeWrapper<GenerationsVarPopFields>;
  generations_var_samp_fields: ResolverTypeWrapper<GenerationsVarSampFields>;
  generations_variance_fields: ResolverTypeWrapper<GenerationsVarianceFields>;
  jsonb: ResolverTypeWrapper<Scalars['jsonb']['output']>;
  jsonb_cast_exp: JsonbCastExp;
  jsonb_comparison_exp: JsonbComparisonExp;
  model_input_type: ResolverTypeWrapper<ModelInputType>;
  model_input_type_aggregate: ResolverTypeWrapper<ModelInputTypeAggregate>;
  model_input_type_aggregate_fields: ResolverTypeWrapper<ModelInputTypeAggregateFields>;
  model_input_type_bool_exp: ModelInputTypeBoolExp;
  model_input_type_constraint: ModelInputTypeConstraint;
  model_input_type_insert_input: ModelInputTypeInsertInput;
  model_input_type_max_fields: ResolverTypeWrapper<ModelInputTypeMaxFields>;
  model_input_type_min_fields: ResolverTypeWrapper<ModelInputTypeMinFields>;
  model_input_type_mutation_response: ResolverTypeWrapper<ModelInputTypeMutationResponse>;
  model_input_type_on_conflict: ModelInputTypeOnConflict;
  model_input_type_order_by: ModelInputTypeOrderBy;
  model_input_type_pk_columns_input: ModelInputTypePkColumnsInput;
  model_input_type_select_column: ModelInputTypeSelectColumn;
  model_input_type_set_input: ModelInputTypeSetInput;
  model_input_type_stream_cursor_input: ModelInputTypeStreamCursorInput;
  model_input_type_stream_cursor_value_input: ModelInputTypeStreamCursorValueInput;
  model_input_type_update_column: ModelInputTypeUpdateColumn;
  model_input_type_updates: ModelInputTypeUpdates;
  model_type: ResolverTypeWrapper<ModelType>;
  model_type_aggregate: ResolverTypeWrapper<ModelTypeAggregate>;
  model_type_aggregate_fields: ResolverTypeWrapper<ModelTypeAggregateFields>;
  model_type_bool_exp: ModelTypeBoolExp;
  model_type_constraint: ModelTypeConstraint;
  model_type_enum: ModelTypeEnum;
  model_type_enum_comparison_exp: ModelTypeEnumComparisonExp;
  model_type_insert_input: ModelTypeInsertInput;
  model_type_max_fields: ResolverTypeWrapper<ModelTypeMaxFields>;
  model_type_min_fields: ResolverTypeWrapper<ModelTypeMinFields>;
  model_type_mutation_response: ResolverTypeWrapper<ModelTypeMutationResponse>;
  model_type_on_conflict: ModelTypeOnConflict;
  model_type_order_by: ModelTypeOrderBy;
  model_type_pk_columns_input: ModelTypePkColumnsInput;
  model_type_select_column: ModelTypeSelectColumn;
  model_type_set_input: ModelTypeSetInput;
  model_type_stream_cursor_input: ModelTypeStreamCursorInput;
  model_type_stream_cursor_value_input: ModelTypeStreamCursorValueInput;
  model_type_update_column: ModelTypeUpdateColumn;
  model_type_updates: ModelTypeUpdates;
  models: ResolverTypeWrapper<Models>;
  models_aggregate: ResolverTypeWrapper<ModelsAggregate>;
  models_aggregate_fields: ResolverTypeWrapper<ModelsAggregateFields>;
  models_append_input: ModelsAppendInput;
  models_bool_exp: ModelsBoolExp;
  models_constraint: ModelsConstraint;
  models_delete_at_path_input: ModelsDeleteAtPathInput;
  models_delete_elem_input: ModelsDeleteElemInput;
  models_delete_key_input: ModelsDeleteKeyInput;
  models_insert_input: ModelsInsertInput;
  models_max_fields: ResolverTypeWrapper<ModelsMaxFields>;
  models_min_fields: ResolverTypeWrapper<ModelsMinFields>;
  models_mutation_response: ResolverTypeWrapper<ModelsMutationResponse>;
  models_obj_rel_insert_input: ModelsObjRelInsertInput;
  models_on_conflict: ModelsOnConflict;
  models_order_by: ModelsOrderBy;
  models_pk_columns_input: ModelsPkColumnsInput;
  models_prepend_input: ModelsPrependInput;
  models_select_column: ModelsSelectColumn;
  models_set_input: ModelsSetInput;
  models_stream_cursor_input: ModelsStreamCursorInput;
  models_stream_cursor_value_input: ModelsStreamCursorValueInput;
  models_update_column: ModelsUpdateColumn;
  models_updates: ModelsUpdates;
  mutation_root: ResolverTypeWrapper<{}>;
  order_by: OrderBy;
  organization_features: ResolverTypeWrapper<OrganizationFeatures>;
  organization_features_aggregate: ResolverTypeWrapper<OrganizationFeaturesAggregate>;
  organization_features_aggregate_fields: ResolverTypeWrapper<OrganizationFeaturesAggregateFields>;
  organization_features_avg_fields: ResolverTypeWrapper<OrganizationFeaturesAvgFields>;
  organization_features_bool_exp: OrganizationFeaturesBoolExp;
  organization_features_constraint: OrganizationFeaturesConstraint;
  organization_features_inc_input: OrganizationFeaturesIncInput;
  organization_features_insert_input: OrganizationFeaturesInsertInput;
  organization_features_max_fields: ResolverTypeWrapper<OrganizationFeaturesMaxFields>;
  organization_features_min_fields: ResolverTypeWrapper<OrganizationFeaturesMinFields>;
  organization_features_mutation_response: ResolverTypeWrapper<OrganizationFeaturesMutationResponse>;
  organization_features_obj_rel_insert_input: OrganizationFeaturesObjRelInsertInput;
  organization_features_on_conflict: OrganizationFeaturesOnConflict;
  organization_features_order_by: OrganizationFeaturesOrderBy;
  organization_features_pk_columns_input: OrganizationFeaturesPkColumnsInput;
  organization_features_select_column: OrganizationFeaturesSelectColumn;
  organization_features_set_input: OrganizationFeaturesSetInput;
  organization_features_stddev_fields: ResolverTypeWrapper<OrganizationFeaturesStddevFields>;
  organization_features_stddev_pop_fields: ResolverTypeWrapper<OrganizationFeaturesStddevPopFields>;
  organization_features_stddev_samp_fields: ResolverTypeWrapper<OrganizationFeaturesStddevSampFields>;
  organization_features_stream_cursor_input: OrganizationFeaturesStreamCursorInput;
  organization_features_stream_cursor_value_input: OrganizationFeaturesStreamCursorValueInput;
  organization_features_sum_fields: ResolverTypeWrapper<OrganizationFeaturesSumFields>;
  organization_features_update_column: OrganizationFeaturesUpdateColumn;
  organization_features_updates: OrganizationFeaturesUpdates;
  organization_features_var_pop_fields: ResolverTypeWrapper<OrganizationFeaturesVarPopFields>;
  organization_features_var_samp_fields: ResolverTypeWrapper<OrganizationFeaturesVarSampFields>;
  organization_features_variance_fields: ResolverTypeWrapper<OrganizationFeaturesVarianceFields>;
  organizations: ResolverTypeWrapper<Organizations>;
  organizations_aggregate: ResolverTypeWrapper<OrganizationsAggregate>;
  organizations_aggregate_fields: ResolverTypeWrapper<OrganizationsAggregateFields>;
  organizations_bool_exp: OrganizationsBoolExp;
  organizations_constraint: OrganizationsConstraint;
  organizations_insert_input: OrganizationsInsertInput;
  organizations_max_fields: ResolverTypeWrapper<OrganizationsMaxFields>;
  organizations_min_fields: ResolverTypeWrapper<OrganizationsMinFields>;
  organizations_mutation_response: ResolverTypeWrapper<OrganizationsMutationResponse>;
  organizations_obj_rel_insert_input: OrganizationsObjRelInsertInput;
  organizations_on_conflict: OrganizationsOnConflict;
  organizations_order_by: OrganizationsOrderBy;
  organizations_pk_columns_input: OrganizationsPkColumnsInput;
  organizations_select_column: OrganizationsSelectColumn;
  organizations_set_input: OrganizationsSetInput;
  organizations_stream_cursor_input: OrganizationsStreamCursorInput;
  organizations_stream_cursor_value_input: OrganizationsStreamCursorValueInput;
  organizations_update_column: OrganizationsUpdateColumn;
  organizations_updates: OrganizationsUpdates;
  query_root: ResolverTypeWrapper<{}>;
  status: ResolverTypeWrapper<Status>;
  status_aggregate: ResolverTypeWrapper<StatusAggregate>;
  status_aggregate_fields: ResolverTypeWrapper<StatusAggregateFields>;
  status_bool_exp: StatusBoolExp;
  status_constraint: StatusConstraint;
  status_enum: StatusEnum;
  status_enum_comparison_exp: StatusEnumComparisonExp;
  status_insert_input: StatusInsertInput;
  status_max_fields: ResolverTypeWrapper<StatusMaxFields>;
  status_min_fields: ResolverTypeWrapper<StatusMinFields>;
  status_mutation_response: ResolverTypeWrapper<StatusMutationResponse>;
  status_on_conflict: StatusOnConflict;
  status_order_by: StatusOrderBy;
  status_pk_columns_input: StatusPkColumnsInput;
  status_select_column: StatusSelectColumn;
  status_set_input: StatusSetInput;
  status_stream_cursor_input: StatusStreamCursorInput;
  status_stream_cursor_value_input: StatusStreamCursorValueInput;
  status_update_column: StatusUpdateColumn;
  status_updates: StatusUpdates;
  subscription_root: ResolverTypeWrapper<{}>;
  timestamptz: ResolverTypeWrapper<Scalars['timestamptz']['output']>;
  timestamptz_comparison_exp: TimestamptzComparisonExp;
  transaction_type: ResolverTypeWrapper<TransactionType>;
  transaction_type_aggregate: ResolverTypeWrapper<TransactionTypeAggregate>;
  transaction_type_aggregate_fields: ResolverTypeWrapper<TransactionTypeAggregateFields>;
  transaction_type_bool_exp: TransactionTypeBoolExp;
  transaction_type_constraint: TransactionTypeConstraint;
  transaction_type_enum: TransactionTypeEnum;
  transaction_type_enum_comparison_exp: TransactionTypeEnumComparisonExp;
  transaction_type_insert_input: TransactionTypeInsertInput;
  transaction_type_max_fields: ResolverTypeWrapper<TransactionTypeMaxFields>;
  transaction_type_min_fields: ResolverTypeWrapper<TransactionTypeMinFields>;
  transaction_type_mutation_response: ResolverTypeWrapper<TransactionTypeMutationResponse>;
  transaction_type_on_conflict: TransactionTypeOnConflict;
  transaction_type_order_by: TransactionTypeOrderBy;
  transaction_type_pk_columns_input: TransactionTypePkColumnsInput;
  transaction_type_select_column: TransactionTypeSelectColumn;
  transaction_type_set_input: TransactionTypeSetInput;
  transaction_type_stream_cursor_input: TransactionTypeStreamCursorInput;
  transaction_type_stream_cursor_value_input: TransactionTypeStreamCursorValueInput;
  transaction_type_update_column: TransactionTypeUpdateColumn;
  transaction_type_updates: TransactionTypeUpdates;
  transactions: ResolverTypeWrapper<Transactions>;
  transactions_aggregate: ResolverTypeWrapper<TransactionsAggregate>;
  transactions_aggregate_fields: ResolverTypeWrapper<TransactionsAggregateFields>;
  transactions_avg_fields: ResolverTypeWrapper<TransactionsAvgFields>;
  transactions_bool_exp: TransactionsBoolExp;
  transactions_constraint: TransactionsConstraint;
  transactions_inc_input: TransactionsIncInput;
  transactions_insert_input: TransactionsInsertInput;
  transactions_max_fields: ResolverTypeWrapper<TransactionsMaxFields>;
  transactions_min_fields: ResolverTypeWrapper<TransactionsMinFields>;
  transactions_mutation_response: ResolverTypeWrapper<TransactionsMutationResponse>;
  transactions_obj_rel_insert_input: TransactionsObjRelInsertInput;
  transactions_on_conflict: TransactionsOnConflict;
  transactions_order_by: TransactionsOrderBy;
  transactions_pk_columns_input: TransactionsPkColumnsInput;
  transactions_select_column: TransactionsSelectColumn;
  transactions_set_input: TransactionsSetInput;
  transactions_stddev_fields: ResolverTypeWrapper<TransactionsStddevFields>;
  transactions_stddev_pop_fields: ResolverTypeWrapper<TransactionsStddevPopFields>;
  transactions_stddev_samp_fields: ResolverTypeWrapper<TransactionsStddevSampFields>;
  transactions_stream_cursor_input: TransactionsStreamCursorInput;
  transactions_stream_cursor_value_input: TransactionsStreamCursorValueInput;
  transactions_sum_fields: ResolverTypeWrapper<TransactionsSumFields>;
  transactions_update_column: TransactionsUpdateColumn;
  transactions_updates: TransactionsUpdates;
  transactions_var_pop_fields: ResolverTypeWrapper<TransactionsVarPopFields>;
  transactions_var_samp_fields: ResolverTypeWrapper<TransactionsVarSampFields>;
  transactions_variance_fields: ResolverTypeWrapper<TransactionsVarianceFields>;
  user_organizations: ResolverTypeWrapper<UserOrganizations>;
  user_organizations_aggregate: ResolverTypeWrapper<UserOrganizationsAggregate>;
  user_organizations_aggregate_fields: ResolverTypeWrapper<UserOrganizationsAggregateFields>;
  user_organizations_bool_exp: UserOrganizationsBoolExp;
  user_organizations_constraint: UserOrganizationsConstraint;
  user_organizations_insert_input: UserOrganizationsInsertInput;
  user_organizations_max_fields: ResolverTypeWrapper<UserOrganizationsMaxFields>;
  user_organizations_min_fields: ResolverTypeWrapper<UserOrganizationsMinFields>;
  user_organizations_mutation_response: ResolverTypeWrapper<UserOrganizationsMutationResponse>;
  user_organizations_obj_rel_insert_input: UserOrganizationsObjRelInsertInput;
  user_organizations_on_conflict: UserOrganizationsOnConflict;
  user_organizations_order_by: UserOrganizationsOrderBy;
  user_organizations_pk_columns_input: UserOrganizationsPkColumnsInput;
  user_organizations_select_column: UserOrganizationsSelectColumn;
  user_organizations_set_input: UserOrganizationsSetInput;
  user_organizations_stream_cursor_input: UserOrganizationsStreamCursorInput;
  user_organizations_stream_cursor_value_input: UserOrganizationsStreamCursorValueInput;
  user_organizations_update_column: UserOrganizationsUpdateColumn;
  user_organizations_updates: UserOrganizationsUpdates;
  users: ResolverTypeWrapper<Users>;
  users_aggregate: ResolverTypeWrapper<UsersAggregate>;
  users_aggregate_bool_exp: UsersAggregateBoolExp;
  users_aggregate_bool_exp_bool_and: UsersAggregateBoolExpBoolAnd;
  users_aggregate_bool_exp_bool_or: UsersAggregateBoolExpBoolOr;
  users_aggregate_bool_exp_count: UsersAggregateBoolExpCount;
  users_aggregate_fields: ResolverTypeWrapper<UsersAggregateFields>;
  users_aggregate_order_by: UsersAggregateOrderBy;
  users_append_input: UsersAppendInput;
  users_arr_rel_insert_input: UsersArrRelInsertInput;
  users_bool_exp: UsersBoolExp;
  users_constraint: UsersConstraint;
  users_delete_at_path_input: UsersDeleteAtPathInput;
  users_delete_elem_input: UsersDeleteElemInput;
  users_delete_key_input: UsersDeleteKeyInput;
  users_insert_input: UsersInsertInput;
  users_max_fields: ResolverTypeWrapper<UsersMaxFields>;
  users_max_order_by: UsersMaxOrderBy;
  users_min_fields: ResolverTypeWrapper<UsersMinFields>;
  users_min_order_by: UsersMinOrderBy;
  users_mutation_response: ResolverTypeWrapper<UsersMutationResponse>;
  users_obj_rel_insert_input: UsersObjRelInsertInput;
  users_on_conflict: UsersOnConflict;
  users_order_by: UsersOrderBy;
  users_pk_columns_input: UsersPkColumnsInput;
  users_prepend_input: UsersPrependInput;
  users_select_column: UsersSelectColumn;
  users_select_column_users_aggregate_bool_exp_bool_and_arguments_columns: UsersSelectColumnUsersAggregateBoolExpBoolAndArgumentsColumns;
  users_select_column_users_aggregate_bool_exp_bool_or_arguments_columns: UsersSelectColumnUsersAggregateBoolExpBoolOrArgumentsColumns;
  users_set_input: UsersSetInput;
  users_stream_cursor_input: UsersStreamCursorInput;
  users_stream_cursor_value_input: UsersStreamCursorValueInput;
  users_update_column: UsersUpdateColumn;
  users_updates: UsersUpdates;
  uuid: ResolverTypeWrapper<Scalars['uuid']['output']>;
  uuid_comparison_exp: UuidComparisonExp;
  virus: ResolverTypeWrapper<Virus>;
  virus_aggregate: ResolverTypeWrapper<VirusAggregate>;
  virus_aggregate_fields: ResolverTypeWrapper<VirusAggregateFields>;
  virus_append_input: VirusAppendInput;
  virus_bool_exp: VirusBoolExp;
  virus_constraint: VirusConstraint;
  virus_delete_at_path_input: VirusDeleteAtPathInput;
  virus_delete_elem_input: VirusDeleteElemInput;
  virus_delete_key_input: VirusDeleteKeyInput;
  virus_insert_input: VirusInsertInput;
  virus_max_fields: ResolverTypeWrapper<VirusMaxFields>;
  virus_min_fields: ResolverTypeWrapper<VirusMinFields>;
  virus_mutation_response: ResolverTypeWrapper<VirusMutationResponse>;
  virus_on_conflict: VirusOnConflict;
  virus_order_by: VirusOrderBy;
  virus_pk_columns_input: VirusPkColumnsInput;
  virus_prepend_input: VirusPrependInput;
  virus_select_column: VirusSelectColumn;
  virus_set_input: VirusSetInput;
  virus_stream_cursor_input: VirusStreamCursorInput;
  virus_stream_cursor_value_input: VirusStreamCursorValueInput;
  virus_update_column: VirusUpdateColumn;
  virus_updates: VirusUpdates;
};

/** Mapping between all available schema types and the resolvers parents */
export type ResolversParentTypes = {
  Boolean: Scalars['Boolean']['output'];
  Boolean_comparison_exp: BooleanComparisonExp;
  Float: Scalars['Float']['output'];
  Int: Scalars['Int']['output'];
  Int_comparison_exp: IntComparisonExp;
  String: Scalars['String']['output'];
  String_comparison_exp: StringComparisonExp;
  api_keys: ApiKeys;
  api_keys_aggregate: ApiKeysAggregate;
  api_keys_aggregate_bool_exp: ApiKeysAggregateBoolExp;
  api_keys_aggregate_bool_exp_bool_and: ApiKeysAggregateBoolExpBoolAnd;
  api_keys_aggregate_bool_exp_bool_or: ApiKeysAggregateBoolExpBoolOr;
  api_keys_aggregate_bool_exp_count: ApiKeysAggregateBoolExpCount;
  api_keys_aggregate_fields: ApiKeysAggregateFields;
  api_keys_aggregate_order_by: ApiKeysAggregateOrderBy;
  api_keys_arr_rel_insert_input: ApiKeysArrRelInsertInput;
  api_keys_bool_exp: ApiKeysBoolExp;
  api_keys_insert_input: ApiKeysInsertInput;
  api_keys_max_fields: ApiKeysMaxFields;
  api_keys_max_order_by: ApiKeysMaxOrderBy;
  api_keys_min_fields: ApiKeysMinFields;
  api_keys_min_order_by: ApiKeysMinOrderBy;
  api_keys_mutation_response: ApiKeysMutationResponse;
  api_keys_on_conflict: ApiKeysOnConflict;
  api_keys_order_by: ApiKeysOrderBy;
  api_keys_pk_columns_input: ApiKeysPkColumnsInput;
  api_keys_set_input: ApiKeysSetInput;
  api_keys_stream_cursor_input: ApiKeysStreamCursorInput;
  api_keys_stream_cursor_value_input: ApiKeysStreamCursorValueInput;
  api_keys_updates: ApiKeysUpdates;
  authProviderRequests: AuthProviderRequests;
  authProviderRequests_aggregate: AuthProviderRequestsAggregate;
  authProviderRequests_aggregate_fields: AuthProviderRequestsAggregateFields;
  authProviderRequests_append_input: AuthProviderRequestsAppendInput;
  authProviderRequests_bool_exp: AuthProviderRequestsBoolExp;
  authProviderRequests_delete_at_path_input: AuthProviderRequestsDeleteAtPathInput;
  authProviderRequests_delete_elem_input: AuthProviderRequestsDeleteElemInput;
  authProviderRequests_delete_key_input: AuthProviderRequestsDeleteKeyInput;
  authProviderRequests_insert_input: AuthProviderRequestsInsertInput;
  authProviderRequests_max_fields: AuthProviderRequestsMaxFields;
  authProviderRequests_min_fields: AuthProviderRequestsMinFields;
  authProviderRequests_mutation_response: AuthProviderRequestsMutationResponse;
  authProviderRequests_on_conflict: AuthProviderRequestsOnConflict;
  authProviderRequests_order_by: AuthProviderRequestsOrderBy;
  authProviderRequests_pk_columns_input: AuthProviderRequestsPkColumnsInput;
  authProviderRequests_prepend_input: AuthProviderRequestsPrependInput;
  authProviderRequests_set_input: AuthProviderRequestsSetInput;
  authProviderRequests_stream_cursor_input: AuthProviderRequestsStreamCursorInput;
  authProviderRequests_stream_cursor_value_input: AuthProviderRequestsStreamCursorValueInput;
  authProviderRequests_updates: AuthProviderRequestsUpdates;
  authProviders: AuthProviders;
  authProviders_aggregate: AuthProvidersAggregate;
  authProviders_aggregate_fields: AuthProvidersAggregateFields;
  authProviders_bool_exp: AuthProvidersBoolExp;
  authProviders_insert_input: AuthProvidersInsertInput;
  authProviders_max_fields: AuthProvidersMaxFields;
  authProviders_min_fields: AuthProvidersMinFields;
  authProviders_mutation_response: AuthProvidersMutationResponse;
  authProviders_obj_rel_insert_input: AuthProvidersObjRelInsertInput;
  authProviders_on_conflict: AuthProvidersOnConflict;
  authProviders_order_by: AuthProvidersOrderBy;
  authProviders_pk_columns_input: AuthProvidersPkColumnsInput;
  authProviders_set_input: AuthProvidersSetInput;
  authProviders_stream_cursor_input: AuthProvidersStreamCursorInput;
  authProviders_stream_cursor_value_input: AuthProvidersStreamCursorValueInput;
  authProviders_updates: AuthProvidersUpdates;
  authRefreshTokenTypes: AuthRefreshTokenTypes;
  authRefreshTokenTypes_aggregate: AuthRefreshTokenTypesAggregate;
  authRefreshTokenTypes_aggregate_fields: AuthRefreshTokenTypesAggregateFields;
  authRefreshTokenTypes_bool_exp: AuthRefreshTokenTypesBoolExp;
  authRefreshTokenTypes_enum_comparison_exp: AuthRefreshTokenTypesEnumComparisonExp;
  authRefreshTokenTypes_insert_input: AuthRefreshTokenTypesInsertInput;
  authRefreshTokenTypes_max_fields: AuthRefreshTokenTypesMaxFields;
  authRefreshTokenTypes_min_fields: AuthRefreshTokenTypesMinFields;
  authRefreshTokenTypes_mutation_response: AuthRefreshTokenTypesMutationResponse;
  authRefreshTokenTypes_on_conflict: AuthRefreshTokenTypesOnConflict;
  authRefreshTokenTypes_order_by: AuthRefreshTokenTypesOrderBy;
  authRefreshTokenTypes_pk_columns_input: AuthRefreshTokenTypesPkColumnsInput;
  authRefreshTokenTypes_set_input: AuthRefreshTokenTypesSetInput;
  authRefreshTokenTypes_stream_cursor_input: AuthRefreshTokenTypesStreamCursorInput;
  authRefreshTokenTypes_stream_cursor_value_input: AuthRefreshTokenTypesStreamCursorValueInput;
  authRefreshTokenTypes_updates: AuthRefreshTokenTypesUpdates;
  authRefreshTokens: AuthRefreshTokens;
  authRefreshTokens_aggregate: AuthRefreshTokensAggregate;
  authRefreshTokens_aggregate_bool_exp: AuthRefreshTokensAggregateBoolExp;
  authRefreshTokens_aggregate_bool_exp_count: AuthRefreshTokensAggregateBoolExpCount;
  authRefreshTokens_aggregate_fields: AuthRefreshTokensAggregateFields;
  authRefreshTokens_aggregate_order_by: AuthRefreshTokensAggregateOrderBy;
  authRefreshTokens_append_input: AuthRefreshTokensAppendInput;
  authRefreshTokens_arr_rel_insert_input: AuthRefreshTokensArrRelInsertInput;
  authRefreshTokens_bool_exp: AuthRefreshTokensBoolExp;
  authRefreshTokens_delete_at_path_input: AuthRefreshTokensDeleteAtPathInput;
  authRefreshTokens_delete_elem_input: AuthRefreshTokensDeleteElemInput;
  authRefreshTokens_delete_key_input: AuthRefreshTokensDeleteKeyInput;
  authRefreshTokens_insert_input: AuthRefreshTokensInsertInput;
  authRefreshTokens_max_fields: AuthRefreshTokensMaxFields;
  authRefreshTokens_max_order_by: AuthRefreshTokensMaxOrderBy;
  authRefreshTokens_min_fields: AuthRefreshTokensMinFields;
  authRefreshTokens_min_order_by: AuthRefreshTokensMinOrderBy;
  authRefreshTokens_mutation_response: AuthRefreshTokensMutationResponse;
  authRefreshTokens_on_conflict: AuthRefreshTokensOnConflict;
  authRefreshTokens_order_by: AuthRefreshTokensOrderBy;
  authRefreshTokens_pk_columns_input: AuthRefreshTokensPkColumnsInput;
  authRefreshTokens_prepend_input: AuthRefreshTokensPrependInput;
  authRefreshTokens_set_input: AuthRefreshTokensSetInput;
  authRefreshTokens_stream_cursor_input: AuthRefreshTokensStreamCursorInput;
  authRefreshTokens_stream_cursor_value_input: AuthRefreshTokensStreamCursorValueInput;
  authRefreshTokens_updates: AuthRefreshTokensUpdates;
  authRoles: AuthRoles;
  authRoles_aggregate: AuthRolesAggregate;
  authRoles_aggregate_fields: AuthRolesAggregateFields;
  authRoles_bool_exp: AuthRolesBoolExp;
  authRoles_insert_input: AuthRolesInsertInput;
  authRoles_max_fields: AuthRolesMaxFields;
  authRoles_min_fields: AuthRolesMinFields;
  authRoles_mutation_response: AuthRolesMutationResponse;
  authRoles_obj_rel_insert_input: AuthRolesObjRelInsertInput;
  authRoles_on_conflict: AuthRolesOnConflict;
  authRoles_order_by: AuthRolesOrderBy;
  authRoles_pk_columns_input: AuthRolesPkColumnsInput;
  authRoles_set_input: AuthRolesSetInput;
  authRoles_stream_cursor_input: AuthRolesStreamCursorInput;
  authRoles_stream_cursor_value_input: AuthRolesStreamCursorValueInput;
  authRoles_updates: AuthRolesUpdates;
  authUserProviders: AuthUserProviders;
  authUserProviders_aggregate: AuthUserProvidersAggregate;
  authUserProviders_aggregate_bool_exp: AuthUserProvidersAggregateBoolExp;
  authUserProviders_aggregate_bool_exp_count: AuthUserProvidersAggregateBoolExpCount;
  authUserProviders_aggregate_fields: AuthUserProvidersAggregateFields;
  authUserProviders_aggregate_order_by: AuthUserProvidersAggregateOrderBy;
  authUserProviders_arr_rel_insert_input: AuthUserProvidersArrRelInsertInput;
  authUserProviders_bool_exp: AuthUserProvidersBoolExp;
  authUserProviders_insert_input: AuthUserProvidersInsertInput;
  authUserProviders_max_fields: AuthUserProvidersMaxFields;
  authUserProviders_max_order_by: AuthUserProvidersMaxOrderBy;
  authUserProviders_min_fields: AuthUserProvidersMinFields;
  authUserProviders_min_order_by: AuthUserProvidersMinOrderBy;
  authUserProviders_mutation_response: AuthUserProvidersMutationResponse;
  authUserProviders_on_conflict: AuthUserProvidersOnConflict;
  authUserProviders_order_by: AuthUserProvidersOrderBy;
  authUserProviders_pk_columns_input: AuthUserProvidersPkColumnsInput;
  authUserProviders_set_input: AuthUserProvidersSetInput;
  authUserProviders_stream_cursor_input: AuthUserProvidersStreamCursorInput;
  authUserProviders_stream_cursor_value_input: AuthUserProvidersStreamCursorValueInput;
  authUserProviders_updates: AuthUserProvidersUpdates;
  authUserRoles: AuthUserRoles;
  authUserRoles_aggregate: AuthUserRolesAggregate;
  authUserRoles_aggregate_bool_exp: AuthUserRolesAggregateBoolExp;
  authUserRoles_aggregate_bool_exp_count: AuthUserRolesAggregateBoolExpCount;
  authUserRoles_aggregate_fields: AuthUserRolesAggregateFields;
  authUserRoles_aggregate_order_by: AuthUserRolesAggregateOrderBy;
  authUserRoles_arr_rel_insert_input: AuthUserRolesArrRelInsertInput;
  authUserRoles_bool_exp: AuthUserRolesBoolExp;
  authUserRoles_insert_input: AuthUserRolesInsertInput;
  authUserRoles_max_fields: AuthUserRolesMaxFields;
  authUserRoles_max_order_by: AuthUserRolesMaxOrderBy;
  authUserRoles_min_fields: AuthUserRolesMinFields;
  authUserRoles_min_order_by: AuthUserRolesMinOrderBy;
  authUserRoles_mutation_response: AuthUserRolesMutationResponse;
  authUserRoles_on_conflict: AuthUserRolesOnConflict;
  authUserRoles_order_by: AuthUserRolesOrderBy;
  authUserRoles_pk_columns_input: AuthUserRolesPkColumnsInput;
  authUserRoles_set_input: AuthUserRolesSetInput;
  authUserRoles_stream_cursor_input: AuthUserRolesStreamCursorInput;
  authUserRoles_stream_cursor_value_input: AuthUserRolesStreamCursorValueInput;
  authUserRoles_updates: AuthUserRolesUpdates;
  authUserSecurityKeys: AuthUserSecurityKeys;
  authUserSecurityKeys_aggregate: AuthUserSecurityKeysAggregate;
  authUserSecurityKeys_aggregate_bool_exp: AuthUserSecurityKeysAggregateBoolExp;
  authUserSecurityKeys_aggregate_bool_exp_count: AuthUserSecurityKeysAggregateBoolExpCount;
  authUserSecurityKeys_aggregate_fields: AuthUserSecurityKeysAggregateFields;
  authUserSecurityKeys_aggregate_order_by: AuthUserSecurityKeysAggregateOrderBy;
  authUserSecurityKeys_arr_rel_insert_input: AuthUserSecurityKeysArrRelInsertInput;
  authUserSecurityKeys_avg_fields: AuthUserSecurityKeysAvgFields;
  authUserSecurityKeys_avg_order_by: AuthUserSecurityKeysAvgOrderBy;
  authUserSecurityKeys_bool_exp: AuthUserSecurityKeysBoolExp;
  authUserSecurityKeys_inc_input: AuthUserSecurityKeysIncInput;
  authUserSecurityKeys_insert_input: AuthUserSecurityKeysInsertInput;
  authUserSecurityKeys_max_fields: AuthUserSecurityKeysMaxFields;
  authUserSecurityKeys_max_order_by: AuthUserSecurityKeysMaxOrderBy;
  authUserSecurityKeys_min_fields: AuthUserSecurityKeysMinFields;
  authUserSecurityKeys_min_order_by: AuthUserSecurityKeysMinOrderBy;
  authUserSecurityKeys_mutation_response: AuthUserSecurityKeysMutationResponse;
  authUserSecurityKeys_on_conflict: AuthUserSecurityKeysOnConflict;
  authUserSecurityKeys_order_by: AuthUserSecurityKeysOrderBy;
  authUserSecurityKeys_pk_columns_input: AuthUserSecurityKeysPkColumnsInput;
  authUserSecurityKeys_set_input: AuthUserSecurityKeysSetInput;
  authUserSecurityKeys_stddev_fields: AuthUserSecurityKeysStddevFields;
  authUserSecurityKeys_stddev_order_by: AuthUserSecurityKeysStddevOrderBy;
  authUserSecurityKeys_stddev_pop_fields: AuthUserSecurityKeysStddevPopFields;
  authUserSecurityKeys_stddev_pop_order_by: AuthUserSecurityKeysStddevPopOrderBy;
  authUserSecurityKeys_stddev_samp_fields: AuthUserSecurityKeysStddevSampFields;
  authUserSecurityKeys_stddev_samp_order_by: AuthUserSecurityKeysStddevSampOrderBy;
  authUserSecurityKeys_stream_cursor_input: AuthUserSecurityKeysStreamCursorInput;
  authUserSecurityKeys_stream_cursor_value_input: AuthUserSecurityKeysStreamCursorValueInput;
  authUserSecurityKeys_sum_fields: AuthUserSecurityKeysSumFields;
  authUserSecurityKeys_sum_order_by: AuthUserSecurityKeysSumOrderBy;
  authUserSecurityKeys_updates: AuthUserSecurityKeysUpdates;
  authUserSecurityKeys_var_pop_fields: AuthUserSecurityKeysVarPopFields;
  authUserSecurityKeys_var_pop_order_by: AuthUserSecurityKeysVarPopOrderBy;
  authUserSecurityKeys_var_samp_fields: AuthUserSecurityKeysVarSampFields;
  authUserSecurityKeys_var_samp_order_by: AuthUserSecurityKeysVarSampOrderBy;
  authUserSecurityKeys_variance_fields: AuthUserSecurityKeysVarianceFields;
  authUserSecurityKeys_variance_order_by: AuthUserSecurityKeysVarianceOrderBy;
  bigint: Scalars['bigint']['output'];
  bigint_comparison_exp: BigintComparisonExp;
  buckets: Buckets;
  buckets_aggregate: BucketsAggregate;
  buckets_aggregate_fields: BucketsAggregateFields;
  buckets_avg_fields: BucketsAvgFields;
  buckets_bool_exp: BucketsBoolExp;
  buckets_inc_input: BucketsIncInput;
  buckets_insert_input: BucketsInsertInput;
  buckets_max_fields: BucketsMaxFields;
  buckets_min_fields: BucketsMinFields;
  buckets_mutation_response: BucketsMutationResponse;
  buckets_obj_rel_insert_input: BucketsObjRelInsertInput;
  buckets_on_conflict: BucketsOnConflict;
  buckets_order_by: BucketsOrderBy;
  buckets_pk_columns_input: BucketsPkColumnsInput;
  buckets_set_input: BucketsSetInput;
  buckets_stddev_fields: BucketsStddevFields;
  buckets_stddev_pop_fields: BucketsStddevPopFields;
  buckets_stddev_samp_fields: BucketsStddevSampFields;
  buckets_stream_cursor_input: BucketsStreamCursorInput;
  buckets_stream_cursor_value_input: BucketsStreamCursorValueInput;
  buckets_sum_fields: BucketsSumFields;
  buckets_updates: BucketsUpdates;
  buckets_var_pop_fields: BucketsVarPopFields;
  buckets_var_samp_fields: BucketsVarSampFields;
  buckets_variance_fields: BucketsVarianceFields;
  bytea: Scalars['bytea']['output'];
  bytea_comparison_exp: ByteaComparisonExp;
  citext: Scalars['citext']['output'];
  citext_comparison_exp: CitextComparisonExp;
  files: Files;
  files_aggregate: FilesAggregate;
  files_aggregate_bool_exp: FilesAggregateBoolExp;
  files_aggregate_bool_exp_bool_and: FilesAggregateBoolExpBoolAnd;
  files_aggregate_bool_exp_bool_or: FilesAggregateBoolExpBoolOr;
  files_aggregate_bool_exp_count: FilesAggregateBoolExpCount;
  files_aggregate_fields: FilesAggregateFields;
  files_aggregate_order_by: FilesAggregateOrderBy;
  files_append_input: FilesAppendInput;
  files_arr_rel_insert_input: FilesArrRelInsertInput;
  files_avg_fields: FilesAvgFields;
  files_avg_order_by: FilesAvgOrderBy;
  files_bool_exp: FilesBoolExp;
  files_delete_at_path_input: FilesDeleteAtPathInput;
  files_delete_elem_input: FilesDeleteElemInput;
  files_delete_key_input: FilesDeleteKeyInput;
  files_inc_input: FilesIncInput;
  files_insert_input: FilesInsertInput;
  files_max_fields: FilesMaxFields;
  files_max_order_by: FilesMaxOrderBy;
  files_min_fields: FilesMinFields;
  files_min_order_by: FilesMinOrderBy;
  files_mutation_response: FilesMutationResponse;
  files_obj_rel_insert_input: FilesObjRelInsertInput;
  files_on_conflict: FilesOnConflict;
  files_order_by: FilesOrderBy;
  files_pk_columns_input: FilesPkColumnsInput;
  files_prepend_input: FilesPrependInput;
  files_set_input: FilesSetInput;
  files_stddev_fields: FilesStddevFields;
  files_stddev_order_by: FilesStddevOrderBy;
  files_stddev_pop_fields: FilesStddevPopFields;
  files_stddev_pop_order_by: FilesStddevPopOrderBy;
  files_stddev_samp_fields: FilesStddevSampFields;
  files_stddev_samp_order_by: FilesStddevSampOrderBy;
  files_stream_cursor_input: FilesStreamCursorInput;
  files_stream_cursor_value_input: FilesStreamCursorValueInput;
  files_sum_fields: FilesSumFields;
  files_sum_order_by: FilesSumOrderBy;
  files_updates: FilesUpdates;
  files_var_pop_fields: FilesVarPopFields;
  files_var_pop_order_by: FilesVarPopOrderBy;
  files_var_samp_fields: FilesVarSampFields;
  files_var_samp_order_by: FilesVarSampOrderBy;
  files_variance_fields: FilesVarianceFields;
  files_variance_order_by: FilesVarianceOrderBy;
  generation_presets: GenerationPresets;
  generation_presets_aggregate: GenerationPresetsAggregate;
  generation_presets_aggregate_fields: GenerationPresetsAggregateFields;
  generation_presets_append_input: GenerationPresetsAppendInput;
  generation_presets_bool_exp: GenerationPresetsBoolExp;
  generation_presets_delete_at_path_input: GenerationPresetsDeleteAtPathInput;
  generation_presets_delete_elem_input: GenerationPresetsDeleteElemInput;
  generation_presets_delete_key_input: GenerationPresetsDeleteKeyInput;
  generation_presets_insert_input: GenerationPresetsInsertInput;
  generation_presets_max_fields: GenerationPresetsMaxFields;
  generation_presets_min_fields: GenerationPresetsMinFields;
  generation_presets_mutation_response: GenerationPresetsMutationResponse;
  generation_presets_on_conflict: GenerationPresetsOnConflict;
  generation_presets_order_by: GenerationPresetsOrderBy;
  generation_presets_pk_columns_input: GenerationPresetsPkColumnsInput;
  generation_presets_prepend_input: GenerationPresetsPrependInput;
  generation_presets_set_input: GenerationPresetsSetInput;
  generation_presets_stream_cursor_input: GenerationPresetsStreamCursorInput;
  generation_presets_stream_cursor_value_input: GenerationPresetsStreamCursorValueInput;
  generation_presets_updates: GenerationPresetsUpdates;
  generations: Generations;
  generations_aggregate: GenerationsAggregate;
  generations_aggregate_fields: GenerationsAggregateFields;
  generations_append_input: GenerationsAppendInput;
  generations_avg_fields: GenerationsAvgFields;
  generations_bool_exp: GenerationsBoolExp;
  generations_delete_at_path_input: GenerationsDeleteAtPathInput;
  generations_delete_elem_input: GenerationsDeleteElemInput;
  generations_delete_key_input: GenerationsDeleteKeyInput;
  generations_inc_input: GenerationsIncInput;
  generations_insert_input: GenerationsInsertInput;
  generations_max_fields: GenerationsMaxFields;
  generations_min_fields: GenerationsMinFields;
  generations_mutation_response: GenerationsMutationResponse;
  generations_obj_rel_insert_input: GenerationsObjRelInsertInput;
  generations_on_conflict: GenerationsOnConflict;
  generations_order_by: GenerationsOrderBy;
  generations_pk_columns_input: GenerationsPkColumnsInput;
  generations_prepend_input: GenerationsPrependInput;
  generations_set_input: GenerationsSetInput;
  generations_stddev_fields: GenerationsStddevFields;
  generations_stddev_pop_fields: GenerationsStddevPopFields;
  generations_stddev_samp_fields: GenerationsStddevSampFields;
  generations_stream_cursor_input: GenerationsStreamCursorInput;
  generations_stream_cursor_value_input: GenerationsStreamCursorValueInput;
  generations_sum_fields: GenerationsSumFields;
  generations_updates: GenerationsUpdates;
  generations_var_pop_fields: GenerationsVarPopFields;
  generations_var_samp_fields: GenerationsVarSampFields;
  generations_variance_fields: GenerationsVarianceFields;
  jsonb: Scalars['jsonb']['output'];
  jsonb_cast_exp: JsonbCastExp;
  jsonb_comparison_exp: JsonbComparisonExp;
  model_input_type: ModelInputType;
  model_input_type_aggregate: ModelInputTypeAggregate;
  model_input_type_aggregate_fields: ModelInputTypeAggregateFields;
  model_input_type_bool_exp: ModelInputTypeBoolExp;
  model_input_type_insert_input: ModelInputTypeInsertInput;
  model_input_type_max_fields: ModelInputTypeMaxFields;
  model_input_type_min_fields: ModelInputTypeMinFields;
  model_input_type_mutation_response: ModelInputTypeMutationResponse;
  model_input_type_on_conflict: ModelInputTypeOnConflict;
  model_input_type_order_by: ModelInputTypeOrderBy;
  model_input_type_pk_columns_input: ModelInputTypePkColumnsInput;
  model_input_type_set_input: ModelInputTypeSetInput;
  model_input_type_stream_cursor_input: ModelInputTypeStreamCursorInput;
  model_input_type_stream_cursor_value_input: ModelInputTypeStreamCursorValueInput;
  model_input_type_updates: ModelInputTypeUpdates;
  model_type: ModelType;
  model_type_aggregate: ModelTypeAggregate;
  model_type_aggregate_fields: ModelTypeAggregateFields;
  model_type_bool_exp: ModelTypeBoolExp;
  model_type_enum_comparison_exp: ModelTypeEnumComparisonExp;
  model_type_insert_input: ModelTypeInsertInput;
  model_type_max_fields: ModelTypeMaxFields;
  model_type_min_fields: ModelTypeMinFields;
  model_type_mutation_response: ModelTypeMutationResponse;
  model_type_on_conflict: ModelTypeOnConflict;
  model_type_order_by: ModelTypeOrderBy;
  model_type_pk_columns_input: ModelTypePkColumnsInput;
  model_type_set_input: ModelTypeSetInput;
  model_type_stream_cursor_input: ModelTypeStreamCursorInput;
  model_type_stream_cursor_value_input: ModelTypeStreamCursorValueInput;
  model_type_updates: ModelTypeUpdates;
  models: Models;
  models_aggregate: ModelsAggregate;
  models_aggregate_fields: ModelsAggregateFields;
  models_append_input: ModelsAppendInput;
  models_bool_exp: ModelsBoolExp;
  models_delete_at_path_input: ModelsDeleteAtPathInput;
  models_delete_elem_input: ModelsDeleteElemInput;
  models_delete_key_input: ModelsDeleteKeyInput;
  models_insert_input: ModelsInsertInput;
  models_max_fields: ModelsMaxFields;
  models_min_fields: ModelsMinFields;
  models_mutation_response: ModelsMutationResponse;
  models_obj_rel_insert_input: ModelsObjRelInsertInput;
  models_on_conflict: ModelsOnConflict;
  models_order_by: ModelsOrderBy;
  models_pk_columns_input: ModelsPkColumnsInput;
  models_prepend_input: ModelsPrependInput;
  models_set_input: ModelsSetInput;
  models_stream_cursor_input: ModelsStreamCursorInput;
  models_stream_cursor_value_input: ModelsStreamCursorValueInput;
  models_updates: ModelsUpdates;
  mutation_root: {};
  organization_features: OrganizationFeatures;
  organization_features_aggregate: OrganizationFeaturesAggregate;
  organization_features_aggregate_fields: OrganizationFeaturesAggregateFields;
  organization_features_avg_fields: OrganizationFeaturesAvgFields;
  organization_features_bool_exp: OrganizationFeaturesBoolExp;
  organization_features_inc_input: OrganizationFeaturesIncInput;
  organization_features_insert_input: OrganizationFeaturesInsertInput;
  organization_features_max_fields: OrganizationFeaturesMaxFields;
  organization_features_min_fields: OrganizationFeaturesMinFields;
  organization_features_mutation_response: OrganizationFeaturesMutationResponse;
  organization_features_obj_rel_insert_input: OrganizationFeaturesObjRelInsertInput;
  organization_features_on_conflict: OrganizationFeaturesOnConflict;
  organization_features_order_by: OrganizationFeaturesOrderBy;
  organization_features_pk_columns_input: OrganizationFeaturesPkColumnsInput;
  organization_features_set_input: OrganizationFeaturesSetInput;
  organization_features_stddev_fields: OrganizationFeaturesStddevFields;
  organization_features_stddev_pop_fields: OrganizationFeaturesStddevPopFields;
  organization_features_stddev_samp_fields: OrganizationFeaturesStddevSampFields;
  organization_features_stream_cursor_input: OrganizationFeaturesStreamCursorInput;
  organization_features_stream_cursor_value_input: OrganizationFeaturesStreamCursorValueInput;
  organization_features_sum_fields: OrganizationFeaturesSumFields;
  organization_features_updates: OrganizationFeaturesUpdates;
  organization_features_var_pop_fields: OrganizationFeaturesVarPopFields;
  organization_features_var_samp_fields: OrganizationFeaturesVarSampFields;
  organization_features_variance_fields: OrganizationFeaturesVarianceFields;
  organizations: Organizations;
  organizations_aggregate: OrganizationsAggregate;
  organizations_aggregate_fields: OrganizationsAggregateFields;
  organizations_bool_exp: OrganizationsBoolExp;
  organizations_insert_input: OrganizationsInsertInput;
  organizations_max_fields: OrganizationsMaxFields;
  organizations_min_fields: OrganizationsMinFields;
  organizations_mutation_response: OrganizationsMutationResponse;
  organizations_obj_rel_insert_input: OrganizationsObjRelInsertInput;
  organizations_on_conflict: OrganizationsOnConflict;
  organizations_order_by: OrganizationsOrderBy;
  organizations_pk_columns_input: OrganizationsPkColumnsInput;
  organizations_set_input: OrganizationsSetInput;
  organizations_stream_cursor_input: OrganizationsStreamCursorInput;
  organizations_stream_cursor_value_input: OrganizationsStreamCursorValueInput;
  organizations_updates: OrganizationsUpdates;
  query_root: {};
  status: Status;
  status_aggregate: StatusAggregate;
  status_aggregate_fields: StatusAggregateFields;
  status_bool_exp: StatusBoolExp;
  status_enum_comparison_exp: StatusEnumComparisonExp;
  status_insert_input: StatusInsertInput;
  status_max_fields: StatusMaxFields;
  status_min_fields: StatusMinFields;
  status_mutation_response: StatusMutationResponse;
  status_on_conflict: StatusOnConflict;
  status_order_by: StatusOrderBy;
  status_pk_columns_input: StatusPkColumnsInput;
  status_set_input: StatusSetInput;
  status_stream_cursor_input: StatusStreamCursorInput;
  status_stream_cursor_value_input: StatusStreamCursorValueInput;
  status_updates: StatusUpdates;
  subscription_root: {};
  timestamptz: Scalars['timestamptz']['output'];
  timestamptz_comparison_exp: TimestamptzComparisonExp;
  transaction_type: TransactionType;
  transaction_type_aggregate: TransactionTypeAggregate;
  transaction_type_aggregate_fields: TransactionTypeAggregateFields;
  transaction_type_bool_exp: TransactionTypeBoolExp;
  transaction_type_enum_comparison_exp: TransactionTypeEnumComparisonExp;
  transaction_type_insert_input: TransactionTypeInsertInput;
  transaction_type_max_fields: TransactionTypeMaxFields;
  transaction_type_min_fields: TransactionTypeMinFields;
  transaction_type_mutation_response: TransactionTypeMutationResponse;
  transaction_type_on_conflict: TransactionTypeOnConflict;
  transaction_type_order_by: TransactionTypeOrderBy;
  transaction_type_pk_columns_input: TransactionTypePkColumnsInput;
  transaction_type_set_input: TransactionTypeSetInput;
  transaction_type_stream_cursor_input: TransactionTypeStreamCursorInput;
  transaction_type_stream_cursor_value_input: TransactionTypeStreamCursorValueInput;
  transaction_type_updates: TransactionTypeUpdates;
  transactions: Transactions;
  transactions_aggregate: TransactionsAggregate;
  transactions_aggregate_fields: TransactionsAggregateFields;
  transactions_avg_fields: TransactionsAvgFields;
  transactions_bool_exp: TransactionsBoolExp;
  transactions_inc_input: TransactionsIncInput;
  transactions_insert_input: TransactionsInsertInput;
  transactions_max_fields: TransactionsMaxFields;
  transactions_min_fields: TransactionsMinFields;
  transactions_mutation_response: TransactionsMutationResponse;
  transactions_obj_rel_insert_input: TransactionsObjRelInsertInput;
  transactions_on_conflict: TransactionsOnConflict;
  transactions_order_by: TransactionsOrderBy;
  transactions_pk_columns_input: TransactionsPkColumnsInput;
  transactions_set_input: TransactionsSetInput;
  transactions_stddev_fields: TransactionsStddevFields;
  transactions_stddev_pop_fields: TransactionsStddevPopFields;
  transactions_stddev_samp_fields: TransactionsStddevSampFields;
  transactions_stream_cursor_input: TransactionsStreamCursorInput;
  transactions_stream_cursor_value_input: TransactionsStreamCursorValueInput;
  transactions_sum_fields: TransactionsSumFields;
  transactions_updates: TransactionsUpdates;
  transactions_var_pop_fields: TransactionsVarPopFields;
  transactions_var_samp_fields: TransactionsVarSampFields;
  transactions_variance_fields: TransactionsVarianceFields;
  user_organizations: UserOrganizations;
  user_organizations_aggregate: UserOrganizationsAggregate;
  user_organizations_aggregate_fields: UserOrganizationsAggregateFields;
  user_organizations_bool_exp: UserOrganizationsBoolExp;
  user_organizations_insert_input: UserOrganizationsInsertInput;
  user_organizations_max_fields: UserOrganizationsMaxFields;
  user_organizations_min_fields: UserOrganizationsMinFields;
  user_organizations_mutation_response: UserOrganizationsMutationResponse;
  user_organizations_obj_rel_insert_input: UserOrganizationsObjRelInsertInput;
  user_organizations_on_conflict: UserOrganizationsOnConflict;
  user_organizations_order_by: UserOrganizationsOrderBy;
  user_organizations_pk_columns_input: UserOrganizationsPkColumnsInput;
  user_organizations_set_input: UserOrganizationsSetInput;
  user_organizations_stream_cursor_input: UserOrganizationsStreamCursorInput;
  user_organizations_stream_cursor_value_input: UserOrganizationsStreamCursorValueInput;
  user_organizations_updates: UserOrganizationsUpdates;
  users: Users;
  users_aggregate: UsersAggregate;
  users_aggregate_bool_exp: UsersAggregateBoolExp;
  users_aggregate_bool_exp_bool_and: UsersAggregateBoolExpBoolAnd;
  users_aggregate_bool_exp_bool_or: UsersAggregateBoolExpBoolOr;
  users_aggregate_bool_exp_count: UsersAggregateBoolExpCount;
  users_aggregate_fields: UsersAggregateFields;
  users_aggregate_order_by: UsersAggregateOrderBy;
  users_append_input: UsersAppendInput;
  users_arr_rel_insert_input: UsersArrRelInsertInput;
  users_bool_exp: UsersBoolExp;
  users_delete_at_path_input: UsersDeleteAtPathInput;
  users_delete_elem_input: UsersDeleteElemInput;
  users_delete_key_input: UsersDeleteKeyInput;
  users_insert_input: UsersInsertInput;
  users_max_fields: UsersMaxFields;
  users_max_order_by: UsersMaxOrderBy;
  users_min_fields: UsersMinFields;
  users_min_order_by: UsersMinOrderBy;
  users_mutation_response: UsersMutationResponse;
  users_obj_rel_insert_input: UsersObjRelInsertInput;
  users_on_conflict: UsersOnConflict;
  users_order_by: UsersOrderBy;
  users_pk_columns_input: UsersPkColumnsInput;
  users_prepend_input: UsersPrependInput;
  users_set_input: UsersSetInput;
  users_stream_cursor_input: UsersStreamCursorInput;
  users_stream_cursor_value_input: UsersStreamCursorValueInput;
  users_updates: UsersUpdates;
  uuid: Scalars['uuid']['output'];
  uuid_comparison_exp: UuidComparisonExp;
  virus: Virus;
  virus_aggregate: VirusAggregate;
  virus_aggregate_fields: VirusAggregateFields;
  virus_append_input: VirusAppendInput;
  virus_bool_exp: VirusBoolExp;
  virus_delete_at_path_input: VirusDeleteAtPathInput;
  virus_delete_elem_input: VirusDeleteElemInput;
  virus_delete_key_input: VirusDeleteKeyInput;
  virus_insert_input: VirusInsertInput;
  virus_max_fields: VirusMaxFields;
  virus_min_fields: VirusMinFields;
  virus_mutation_response: VirusMutationResponse;
  virus_on_conflict: VirusOnConflict;
  virus_order_by: VirusOrderBy;
  virus_pk_columns_input: VirusPkColumnsInput;
  virus_prepend_input: VirusPrependInput;
  virus_set_input: VirusSetInput;
  virus_stream_cursor_input: VirusStreamCursorInput;
  virus_stream_cursor_value_input: VirusStreamCursorValueInput;
  virus_updates: VirusUpdates;
};

export type CachedDirectiveArgs = {
  refresh?: Scalars['Boolean']['input'];
  ttl?: Scalars['Int']['input'];
};

export type CachedDirectiveResolver<Result, Parent, ContextType = any, Args = CachedDirectiveArgs> = DirectiveResolverFn<Result, Parent, ContextType, Args>;

export type ApiKeysResolvers<ContextType = any, ParentType extends ResolversParentTypes['api_keys'] = ResolversParentTypes['api_keys']> = {
  createdAt?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  lastAccessedAt?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  organization?: Resolver<Maybe<ResolversTypes['organizations']>, ParentType, ContextType>;
  organizationId?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  revoked?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  revokedAt?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  secretHash?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  secretMask?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updatedAt?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ApiKeysAggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['api_keys_aggregate'] = ResolversParentTypes['api_keys_aggregate']> = {
  aggregate?: Resolver<Maybe<ResolversTypes['api_keys_aggregate_fields']>, ParentType, ContextType>;
  nodes?: Resolver<Array<ResolversTypes['api_keys']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ApiKeysAggregateFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['api_keys_aggregate_fields'] = ResolversParentTypes['api_keys_aggregate_fields']> = {
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType, Partial<ApiKeysAggregateFieldsCountArgs>>;
  max?: Resolver<Maybe<ResolversTypes['api_keys_max_fields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['api_keys_min_fields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ApiKeysMaxFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['api_keys_max_fields'] = ResolversParentTypes['api_keys_max_fields']> = {
  createdAt?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  lastAccessedAt?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  organizationId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  revokedAt?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  secretHash?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  secretMask?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ApiKeysMinFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['api_keys_min_fields'] = ResolversParentTypes['api_keys_min_fields']> = {
  createdAt?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  lastAccessedAt?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  organizationId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  revokedAt?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  secretHash?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  secretMask?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ApiKeysMutationResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['api_keys_mutation_response'] = ResolversParentTypes['api_keys_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['api_keys']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AuthProviderRequestsResolvers<ContextType = any, ParentType extends ResolversParentTypes['authProviderRequests'] = ResolversParentTypes['authProviderRequests']> = {
  id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  options?: Resolver<Maybe<ResolversTypes['jsonb']>, ParentType, ContextType, Partial<AuthProviderRequestsOptionsArgs>>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AuthProviderRequestsAggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['authProviderRequests_aggregate'] = ResolversParentTypes['authProviderRequests_aggregate']> = {
  aggregate?: Resolver<Maybe<ResolversTypes['authProviderRequests_aggregate_fields']>, ParentType, ContextType>;
  nodes?: Resolver<Array<ResolversTypes['authProviderRequests']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AuthProviderRequestsAggregateFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['authProviderRequests_aggregate_fields'] = ResolversParentTypes['authProviderRequests_aggregate_fields']> = {
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType, Partial<AuthProviderRequestsAggregateFieldsCountArgs>>;
  max?: Resolver<Maybe<ResolversTypes['authProviderRequests_max_fields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['authProviderRequests_min_fields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AuthProviderRequestsMaxFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['authProviderRequests_max_fields'] = ResolversParentTypes['authProviderRequests_max_fields']> = {
  id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AuthProviderRequestsMinFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['authProviderRequests_min_fields'] = ResolversParentTypes['authProviderRequests_min_fields']> = {
  id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AuthProviderRequestsMutationResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['authProviderRequests_mutation_response'] = ResolversParentTypes['authProviderRequests_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['authProviderRequests']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AuthProvidersResolvers<ContextType = any, ParentType extends ResolversParentTypes['authProviders'] = ResolversParentTypes['authProviders']> = {
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  userProviders?: Resolver<Array<ResolversTypes['authUserProviders']>, ParentType, ContextType, Partial<AuthProvidersUserProvidersArgs>>;
  userProviders_aggregate?: Resolver<ResolversTypes['authUserProviders_aggregate'], ParentType, ContextType, Partial<AuthProvidersUserProvidersAggregateArgs>>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AuthProvidersAggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['authProviders_aggregate'] = ResolversParentTypes['authProviders_aggregate']> = {
  aggregate?: Resolver<Maybe<ResolversTypes['authProviders_aggregate_fields']>, ParentType, ContextType>;
  nodes?: Resolver<Array<ResolversTypes['authProviders']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AuthProvidersAggregateFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['authProviders_aggregate_fields'] = ResolversParentTypes['authProviders_aggregate_fields']> = {
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType, Partial<AuthProvidersAggregateFieldsCountArgs>>;
  max?: Resolver<Maybe<ResolversTypes['authProviders_max_fields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['authProviders_min_fields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AuthProvidersMaxFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['authProviders_max_fields'] = ResolversParentTypes['authProviders_max_fields']> = {
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AuthProvidersMinFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['authProviders_min_fields'] = ResolversParentTypes['authProviders_min_fields']> = {
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AuthProvidersMutationResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['authProviders_mutation_response'] = ResolversParentTypes['authProviders_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['authProviders']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AuthRefreshTokenTypesResolvers<ContextType = any, ParentType extends ResolversParentTypes['authRefreshTokenTypes'] = ResolversParentTypes['authRefreshTokenTypes']> = {
  comment?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  refreshTokens?: Resolver<Array<ResolversTypes['authRefreshTokens']>, ParentType, ContextType, Partial<AuthRefreshTokenTypesRefreshTokensArgs>>;
  refreshTokens_aggregate?: Resolver<ResolversTypes['authRefreshTokens_aggregate'], ParentType, ContextType, Partial<AuthRefreshTokenTypesRefreshTokensAggregateArgs>>;
  value?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AuthRefreshTokenTypesAggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['authRefreshTokenTypes_aggregate'] = ResolversParentTypes['authRefreshTokenTypes_aggregate']> = {
  aggregate?: Resolver<Maybe<ResolversTypes['authRefreshTokenTypes_aggregate_fields']>, ParentType, ContextType>;
  nodes?: Resolver<Array<ResolversTypes['authRefreshTokenTypes']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AuthRefreshTokenTypesAggregateFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['authRefreshTokenTypes_aggregate_fields'] = ResolversParentTypes['authRefreshTokenTypes_aggregate_fields']> = {
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType, Partial<AuthRefreshTokenTypesAggregateFieldsCountArgs>>;
  max?: Resolver<Maybe<ResolversTypes['authRefreshTokenTypes_max_fields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['authRefreshTokenTypes_min_fields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AuthRefreshTokenTypesMaxFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['authRefreshTokenTypes_max_fields'] = ResolversParentTypes['authRefreshTokenTypes_max_fields']> = {
  comment?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  value?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AuthRefreshTokenTypesMinFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['authRefreshTokenTypes_min_fields'] = ResolversParentTypes['authRefreshTokenTypes_min_fields']> = {
  comment?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  value?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AuthRefreshTokenTypesMutationResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['authRefreshTokenTypes_mutation_response'] = ResolversParentTypes['authRefreshTokenTypes_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['authRefreshTokenTypes']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AuthRefreshTokensResolvers<ContextType = any, ParentType extends ResolversParentTypes['authRefreshTokens'] = ResolversParentTypes['authRefreshTokens']> = {
  createdAt?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  expiresAt?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  metadata?: Resolver<Maybe<ResolversTypes['jsonb']>, ParentType, ContextType, Partial<AuthRefreshTokensMetadataArgs>>;
  refreshTokenHash?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  type?: Resolver<ResolversTypes['authRefreshTokenTypes_enum'], ParentType, ContextType>;
  user?: Resolver<ResolversTypes['users'], ParentType, ContextType>;
  userId?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AuthRefreshTokensAggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['authRefreshTokens_aggregate'] = ResolversParentTypes['authRefreshTokens_aggregate']> = {
  aggregate?: Resolver<Maybe<ResolversTypes['authRefreshTokens_aggregate_fields']>, ParentType, ContextType>;
  nodes?: Resolver<Array<ResolversTypes['authRefreshTokens']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AuthRefreshTokensAggregateFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['authRefreshTokens_aggregate_fields'] = ResolversParentTypes['authRefreshTokens_aggregate_fields']> = {
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType, Partial<AuthRefreshTokensAggregateFieldsCountArgs>>;
  max?: Resolver<Maybe<ResolversTypes['authRefreshTokens_max_fields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['authRefreshTokens_min_fields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AuthRefreshTokensMaxFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['authRefreshTokens_max_fields'] = ResolversParentTypes['authRefreshTokens_max_fields']> = {
  createdAt?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  expiresAt?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  refreshTokenHash?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  userId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AuthRefreshTokensMinFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['authRefreshTokens_min_fields'] = ResolversParentTypes['authRefreshTokens_min_fields']> = {
  createdAt?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  expiresAt?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  refreshTokenHash?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  userId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AuthRefreshTokensMutationResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['authRefreshTokens_mutation_response'] = ResolversParentTypes['authRefreshTokens_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['authRefreshTokens']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AuthRolesResolvers<ContextType = any, ParentType extends ResolversParentTypes['authRoles'] = ResolversParentTypes['authRoles']> = {
  role?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  userRoles?: Resolver<Array<ResolversTypes['authUserRoles']>, ParentType, ContextType, Partial<AuthRolesUserRolesArgs>>;
  userRoles_aggregate?: Resolver<ResolversTypes['authUserRoles_aggregate'], ParentType, ContextType, Partial<AuthRolesUserRolesAggregateArgs>>;
  usersByDefaultRole?: Resolver<Array<ResolversTypes['users']>, ParentType, ContextType, Partial<AuthRolesUsersByDefaultRoleArgs>>;
  usersByDefaultRole_aggregate?: Resolver<ResolversTypes['users_aggregate'], ParentType, ContextType, Partial<AuthRolesUsersByDefaultRoleAggregateArgs>>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AuthRolesAggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['authRoles_aggregate'] = ResolversParentTypes['authRoles_aggregate']> = {
  aggregate?: Resolver<Maybe<ResolversTypes['authRoles_aggregate_fields']>, ParentType, ContextType>;
  nodes?: Resolver<Array<ResolversTypes['authRoles']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AuthRolesAggregateFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['authRoles_aggregate_fields'] = ResolversParentTypes['authRoles_aggregate_fields']> = {
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType, Partial<AuthRolesAggregateFieldsCountArgs>>;
  max?: Resolver<Maybe<ResolversTypes['authRoles_max_fields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['authRoles_min_fields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AuthRolesMaxFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['authRoles_max_fields'] = ResolversParentTypes['authRoles_max_fields']> = {
  role?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AuthRolesMinFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['authRoles_min_fields'] = ResolversParentTypes['authRoles_min_fields']> = {
  role?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AuthRolesMutationResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['authRoles_mutation_response'] = ResolversParentTypes['authRoles_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['authRoles']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AuthUserProvidersResolvers<ContextType = any, ParentType extends ResolversParentTypes['authUserProviders'] = ResolversParentTypes['authUserProviders']> = {
  accessToken?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  createdAt?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  provider?: Resolver<ResolversTypes['authProviders'], ParentType, ContextType>;
  providerId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  providerUserId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  refreshToken?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updatedAt?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  user?: Resolver<ResolversTypes['users'], ParentType, ContextType>;
  userId?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AuthUserProvidersAggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['authUserProviders_aggregate'] = ResolversParentTypes['authUserProviders_aggregate']> = {
  aggregate?: Resolver<Maybe<ResolversTypes['authUserProviders_aggregate_fields']>, ParentType, ContextType>;
  nodes?: Resolver<Array<ResolversTypes['authUserProviders']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AuthUserProvidersAggregateFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['authUserProviders_aggregate_fields'] = ResolversParentTypes['authUserProviders_aggregate_fields']> = {
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType, Partial<AuthUserProvidersAggregateFieldsCountArgs>>;
  max?: Resolver<Maybe<ResolversTypes['authUserProviders_max_fields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['authUserProviders_min_fields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AuthUserProvidersMaxFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['authUserProviders_max_fields'] = ResolversParentTypes['authUserProviders_max_fields']> = {
  accessToken?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  createdAt?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  providerId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  providerUserId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  refreshToken?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  userId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AuthUserProvidersMinFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['authUserProviders_min_fields'] = ResolversParentTypes['authUserProviders_min_fields']> = {
  accessToken?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  createdAt?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  providerId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  providerUserId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  refreshToken?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  userId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AuthUserProvidersMutationResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['authUserProviders_mutation_response'] = ResolversParentTypes['authUserProviders_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['authUserProviders']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AuthUserRolesResolvers<ContextType = any, ParentType extends ResolversParentTypes['authUserRoles'] = ResolversParentTypes['authUserRoles']> = {
  createdAt?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  role?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  roleByRole?: Resolver<ResolversTypes['authRoles'], ParentType, ContextType>;
  user?: Resolver<ResolversTypes['users'], ParentType, ContextType>;
  userId?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AuthUserRolesAggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['authUserRoles_aggregate'] = ResolversParentTypes['authUserRoles_aggregate']> = {
  aggregate?: Resolver<Maybe<ResolversTypes['authUserRoles_aggregate_fields']>, ParentType, ContextType>;
  nodes?: Resolver<Array<ResolversTypes['authUserRoles']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AuthUserRolesAggregateFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['authUserRoles_aggregate_fields'] = ResolversParentTypes['authUserRoles_aggregate_fields']> = {
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType, Partial<AuthUserRolesAggregateFieldsCountArgs>>;
  max?: Resolver<Maybe<ResolversTypes['authUserRoles_max_fields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['authUserRoles_min_fields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AuthUserRolesMaxFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['authUserRoles_max_fields'] = ResolversParentTypes['authUserRoles_max_fields']> = {
  createdAt?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  role?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  userId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AuthUserRolesMinFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['authUserRoles_min_fields'] = ResolversParentTypes['authUserRoles_min_fields']> = {
  createdAt?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  role?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  userId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AuthUserRolesMutationResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['authUserRoles_mutation_response'] = ResolversParentTypes['authUserRoles_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['authUserRoles']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AuthUserSecurityKeysResolvers<ContextType = any, ParentType extends ResolversParentTypes['authUserSecurityKeys'] = ResolversParentTypes['authUserSecurityKeys']> = {
  counter?: Resolver<ResolversTypes['bigint'], ParentType, ContextType>;
  credentialId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  credentialPublicKey?: Resolver<Maybe<ResolversTypes['bytea']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  nickname?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  transports?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  user?: Resolver<ResolversTypes['users'], ParentType, ContextType>;
  userId?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AuthUserSecurityKeysAggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['authUserSecurityKeys_aggregate'] = ResolversParentTypes['authUserSecurityKeys_aggregate']> = {
  aggregate?: Resolver<Maybe<ResolversTypes['authUserSecurityKeys_aggregate_fields']>, ParentType, ContextType>;
  nodes?: Resolver<Array<ResolversTypes['authUserSecurityKeys']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AuthUserSecurityKeysAggregateFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['authUserSecurityKeys_aggregate_fields'] = ResolversParentTypes['authUserSecurityKeys_aggregate_fields']> = {
  avg?: Resolver<Maybe<ResolversTypes['authUserSecurityKeys_avg_fields']>, ParentType, ContextType>;
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType, Partial<AuthUserSecurityKeysAggregateFieldsCountArgs>>;
  max?: Resolver<Maybe<ResolversTypes['authUserSecurityKeys_max_fields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['authUserSecurityKeys_min_fields']>, ParentType, ContextType>;
  stddev?: Resolver<Maybe<ResolversTypes['authUserSecurityKeys_stddev_fields']>, ParentType, ContextType>;
  stddev_pop?: Resolver<Maybe<ResolversTypes['authUserSecurityKeys_stddev_pop_fields']>, ParentType, ContextType>;
  stddev_samp?: Resolver<Maybe<ResolversTypes['authUserSecurityKeys_stddev_samp_fields']>, ParentType, ContextType>;
  sum?: Resolver<Maybe<ResolversTypes['authUserSecurityKeys_sum_fields']>, ParentType, ContextType>;
  var_pop?: Resolver<Maybe<ResolversTypes['authUserSecurityKeys_var_pop_fields']>, ParentType, ContextType>;
  var_samp?: Resolver<Maybe<ResolversTypes['authUserSecurityKeys_var_samp_fields']>, ParentType, ContextType>;
  variance?: Resolver<Maybe<ResolversTypes['authUserSecurityKeys_variance_fields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AuthUserSecurityKeysAvgFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['authUserSecurityKeys_avg_fields'] = ResolversParentTypes['authUserSecurityKeys_avg_fields']> = {
  counter?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AuthUserSecurityKeysMaxFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['authUserSecurityKeys_max_fields'] = ResolversParentTypes['authUserSecurityKeys_max_fields']> = {
  counter?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  credentialId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  nickname?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  transports?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  userId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AuthUserSecurityKeysMinFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['authUserSecurityKeys_min_fields'] = ResolversParentTypes['authUserSecurityKeys_min_fields']> = {
  counter?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  credentialId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  nickname?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  transports?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  userId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AuthUserSecurityKeysMutationResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['authUserSecurityKeys_mutation_response'] = ResolversParentTypes['authUserSecurityKeys_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['authUserSecurityKeys']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AuthUserSecurityKeysStddevFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['authUserSecurityKeys_stddev_fields'] = ResolversParentTypes['authUserSecurityKeys_stddev_fields']> = {
  counter?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AuthUserSecurityKeysStddevPopFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['authUserSecurityKeys_stddev_pop_fields'] = ResolversParentTypes['authUserSecurityKeys_stddev_pop_fields']> = {
  counter?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AuthUserSecurityKeysStddevSampFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['authUserSecurityKeys_stddev_samp_fields'] = ResolversParentTypes['authUserSecurityKeys_stddev_samp_fields']> = {
  counter?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AuthUserSecurityKeysSumFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['authUserSecurityKeys_sum_fields'] = ResolversParentTypes['authUserSecurityKeys_sum_fields']> = {
  counter?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AuthUserSecurityKeysVarPopFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['authUserSecurityKeys_var_pop_fields'] = ResolversParentTypes['authUserSecurityKeys_var_pop_fields']> = {
  counter?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AuthUserSecurityKeysVarSampFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['authUserSecurityKeys_var_samp_fields'] = ResolversParentTypes['authUserSecurityKeys_var_samp_fields']> = {
  counter?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AuthUserSecurityKeysVarianceFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['authUserSecurityKeys_variance_fields'] = ResolversParentTypes['authUserSecurityKeys_variance_fields']> = {
  counter?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export interface BigintScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['bigint'], any> {
  name: 'bigint';
}

export type BucketsResolvers<ContextType = any, ParentType extends ResolversParentTypes['buckets'] = ResolversParentTypes['buckets']> = {
  cacheControl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  createdAt?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  downloadExpiration?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  files?: Resolver<Array<ResolversTypes['files']>, ParentType, ContextType, Partial<BucketsFilesArgs>>;
  files_aggregate?: Resolver<ResolversTypes['files_aggregate'], ParentType, ContextType, Partial<BucketsFilesAggregateArgs>>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  maxUploadFileSize?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  minUploadFileSize?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  presignedUrlsEnabled?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  updatedAt?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type BucketsAggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['buckets_aggregate'] = ResolversParentTypes['buckets_aggregate']> = {
  aggregate?: Resolver<Maybe<ResolversTypes['buckets_aggregate_fields']>, ParentType, ContextType>;
  nodes?: Resolver<Array<ResolversTypes['buckets']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type BucketsAggregateFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['buckets_aggregate_fields'] = ResolversParentTypes['buckets_aggregate_fields']> = {
  avg?: Resolver<Maybe<ResolversTypes['buckets_avg_fields']>, ParentType, ContextType>;
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType, Partial<BucketsAggregateFieldsCountArgs>>;
  max?: Resolver<Maybe<ResolversTypes['buckets_max_fields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['buckets_min_fields']>, ParentType, ContextType>;
  stddev?: Resolver<Maybe<ResolversTypes['buckets_stddev_fields']>, ParentType, ContextType>;
  stddev_pop?: Resolver<Maybe<ResolversTypes['buckets_stddev_pop_fields']>, ParentType, ContextType>;
  stddev_samp?: Resolver<Maybe<ResolversTypes['buckets_stddev_samp_fields']>, ParentType, ContextType>;
  sum?: Resolver<Maybe<ResolversTypes['buckets_sum_fields']>, ParentType, ContextType>;
  var_pop?: Resolver<Maybe<ResolversTypes['buckets_var_pop_fields']>, ParentType, ContextType>;
  var_samp?: Resolver<Maybe<ResolversTypes['buckets_var_samp_fields']>, ParentType, ContextType>;
  variance?: Resolver<Maybe<ResolversTypes['buckets_variance_fields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type BucketsAvgFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['buckets_avg_fields'] = ResolversParentTypes['buckets_avg_fields']> = {
  downloadExpiration?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  maxUploadFileSize?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  minUploadFileSize?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type BucketsMaxFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['buckets_max_fields'] = ResolversParentTypes['buckets_max_fields']> = {
  cacheControl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  createdAt?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  downloadExpiration?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  maxUploadFileSize?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  minUploadFileSize?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type BucketsMinFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['buckets_min_fields'] = ResolversParentTypes['buckets_min_fields']> = {
  cacheControl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  createdAt?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  downloadExpiration?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  maxUploadFileSize?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  minUploadFileSize?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type BucketsMutationResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['buckets_mutation_response'] = ResolversParentTypes['buckets_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['buckets']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type BucketsStddevFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['buckets_stddev_fields'] = ResolversParentTypes['buckets_stddev_fields']> = {
  downloadExpiration?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  maxUploadFileSize?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  minUploadFileSize?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type BucketsStddevPopFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['buckets_stddev_pop_fields'] = ResolversParentTypes['buckets_stddev_pop_fields']> = {
  downloadExpiration?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  maxUploadFileSize?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  minUploadFileSize?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type BucketsStddevSampFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['buckets_stddev_samp_fields'] = ResolversParentTypes['buckets_stddev_samp_fields']> = {
  downloadExpiration?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  maxUploadFileSize?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  minUploadFileSize?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type BucketsSumFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['buckets_sum_fields'] = ResolversParentTypes['buckets_sum_fields']> = {
  downloadExpiration?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  maxUploadFileSize?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  minUploadFileSize?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type BucketsVarPopFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['buckets_var_pop_fields'] = ResolversParentTypes['buckets_var_pop_fields']> = {
  downloadExpiration?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  maxUploadFileSize?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  minUploadFileSize?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type BucketsVarSampFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['buckets_var_samp_fields'] = ResolversParentTypes['buckets_var_samp_fields']> = {
  downloadExpiration?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  maxUploadFileSize?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  minUploadFileSize?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type BucketsVarianceFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['buckets_variance_fields'] = ResolversParentTypes['buckets_variance_fields']> = {
  downloadExpiration?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  maxUploadFileSize?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  minUploadFileSize?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export interface ByteaScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['bytea'], any> {
  name: 'bytea';
}

export interface CitextScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['citext'], any> {
  name: 'citext';
}

export type FilesResolvers<ContextType = any, ParentType extends ResolversParentTypes['files'] = ResolversParentTypes['files']> = {
  bucket?: Resolver<ResolversTypes['buckets'], ParentType, ContextType>;
  bucketId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  createdAt?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  etag?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  isUploaded?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  metadata?: Resolver<Maybe<ResolversTypes['jsonb']>, ParentType, ContextType, Partial<FilesMetadataArgs>>;
  mimeType?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  size?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  updatedAt?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  uploadedByUserId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type FilesAggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['files_aggregate'] = ResolversParentTypes['files_aggregate']> = {
  aggregate?: Resolver<Maybe<ResolversTypes['files_aggregate_fields']>, ParentType, ContextType>;
  nodes?: Resolver<Array<ResolversTypes['files']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type FilesAggregateFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['files_aggregate_fields'] = ResolversParentTypes['files_aggregate_fields']> = {
  avg?: Resolver<Maybe<ResolversTypes['files_avg_fields']>, ParentType, ContextType>;
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType, Partial<FilesAggregateFieldsCountArgs>>;
  max?: Resolver<Maybe<ResolversTypes['files_max_fields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['files_min_fields']>, ParentType, ContextType>;
  stddev?: Resolver<Maybe<ResolversTypes['files_stddev_fields']>, ParentType, ContextType>;
  stddev_pop?: Resolver<Maybe<ResolversTypes['files_stddev_pop_fields']>, ParentType, ContextType>;
  stddev_samp?: Resolver<Maybe<ResolversTypes['files_stddev_samp_fields']>, ParentType, ContextType>;
  sum?: Resolver<Maybe<ResolversTypes['files_sum_fields']>, ParentType, ContextType>;
  var_pop?: Resolver<Maybe<ResolversTypes['files_var_pop_fields']>, ParentType, ContextType>;
  var_samp?: Resolver<Maybe<ResolversTypes['files_var_samp_fields']>, ParentType, ContextType>;
  variance?: Resolver<Maybe<ResolversTypes['files_variance_fields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type FilesAvgFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['files_avg_fields'] = ResolversParentTypes['files_avg_fields']> = {
  size?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type FilesMaxFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['files_max_fields'] = ResolversParentTypes['files_max_fields']> = {
  bucketId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  createdAt?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  etag?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  mimeType?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  size?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  uploadedByUserId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type FilesMinFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['files_min_fields'] = ResolversParentTypes['files_min_fields']> = {
  bucketId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  createdAt?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  etag?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  mimeType?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  size?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  uploadedByUserId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type FilesMutationResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['files_mutation_response'] = ResolversParentTypes['files_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['files']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type FilesStddevFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['files_stddev_fields'] = ResolversParentTypes['files_stddev_fields']> = {
  size?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type FilesStddevPopFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['files_stddev_pop_fields'] = ResolversParentTypes['files_stddev_pop_fields']> = {
  size?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type FilesStddevSampFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['files_stddev_samp_fields'] = ResolversParentTypes['files_stddev_samp_fields']> = {
  size?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type FilesSumFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['files_sum_fields'] = ResolversParentTypes['files_sum_fields']> = {
  size?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type FilesVarPopFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['files_var_pop_fields'] = ResolversParentTypes['files_var_pop_fields']> = {
  size?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type FilesVarSampFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['files_var_samp_fields'] = ResolversParentTypes['files_var_samp_fields']> = {
  size?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type FilesVarianceFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['files_variance_fields'] = ResolversParentTypes['files_variance_fields']> = {
  size?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GenerationPresetsResolvers<ContextType = any, ParentType extends ResolversParentTypes['generation_presets'] = ResolversParentTypes['generation_presets']> = {
  createdAt?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  isShared?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  model?: Resolver<ResolversTypes['models'], ParentType, ContextType>;
  modelName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  options?: Resolver<ResolversTypes['jsonb'], ParentType, ContextType, Partial<GenerationPresetsOptionsArgs>>;
  organization?: Resolver<ResolversTypes['organizations'], ParentType, ContextType>;
  organizationId?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  updatedAt?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  user?: Resolver<ResolversTypes['users'], ParentType, ContextType>;
  userId?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GenerationPresetsAggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['generation_presets_aggregate'] = ResolversParentTypes['generation_presets_aggregate']> = {
  aggregate?: Resolver<Maybe<ResolversTypes['generation_presets_aggregate_fields']>, ParentType, ContextType>;
  nodes?: Resolver<Array<ResolversTypes['generation_presets']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GenerationPresetsAggregateFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['generation_presets_aggregate_fields'] = ResolversParentTypes['generation_presets_aggregate_fields']> = {
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType, Partial<GenerationPresetsAggregateFieldsCountArgs>>;
  max?: Resolver<Maybe<ResolversTypes['generation_presets_max_fields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['generation_presets_min_fields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GenerationPresetsMaxFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['generation_presets_max_fields'] = ResolversParentTypes['generation_presets_max_fields']> = {
  createdAt?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  modelName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  organizationId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  userId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GenerationPresetsMinFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['generation_presets_min_fields'] = ResolversParentTypes['generation_presets_min_fields']> = {
  createdAt?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  modelName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  organizationId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  userId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GenerationPresetsMutationResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['generation_presets_mutation_response'] = ResolversParentTypes['generation_presets_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['generation_presets']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GenerationsResolvers<ContextType = any, ParentType extends ResolversParentTypes['generations'] = ResolversParentTypes['generations']> = {
  createdAt?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  finishedAt?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  inputs?: Resolver<Maybe<ResolversTypes['jsonb']>, ParentType, ContextType, Partial<GenerationsInputsArgs>>;
  model?: Resolver<ResolversTypes['models'], ParentType, ContextType>;
  modelName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  options?: Resolver<Maybe<ResolversTypes['jsonb']>, ParentType, ContextType, Partial<GenerationsOptionsArgs>>;
  organization?: Resolver<ResolversTypes['organizations'], ParentType, ContextType>;
  organizationId?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  outputMediaLength?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  outputMediaUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  potentialError?: Resolver<Maybe<ResolversTypes['jsonb']>, ParentType, ContextType, Partial<GenerationsPotentialErrorArgs>>;
  startedAt?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  status?: Resolver<ResolversTypes['status_enum'], ParentType, ContextType>;
  stream?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  transaction?: Resolver<ResolversTypes['transactions'], ParentType, ContextType>;
  transactionId?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  updatedAt?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  webhookUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GenerationsAggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['generations_aggregate'] = ResolversParentTypes['generations_aggregate']> = {
  aggregate?: Resolver<Maybe<ResolversTypes['generations_aggregate_fields']>, ParentType, ContextType>;
  nodes?: Resolver<Array<ResolversTypes['generations']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GenerationsAggregateFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['generations_aggregate_fields'] = ResolversParentTypes['generations_aggregate_fields']> = {
  avg?: Resolver<Maybe<ResolversTypes['generations_avg_fields']>, ParentType, ContextType>;
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType, Partial<GenerationsAggregateFieldsCountArgs>>;
  max?: Resolver<Maybe<ResolversTypes['generations_max_fields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['generations_min_fields']>, ParentType, ContextType>;
  stddev?: Resolver<Maybe<ResolversTypes['generations_stddev_fields']>, ParentType, ContextType>;
  stddev_pop?: Resolver<Maybe<ResolversTypes['generations_stddev_pop_fields']>, ParentType, ContextType>;
  stddev_samp?: Resolver<Maybe<ResolversTypes['generations_stddev_samp_fields']>, ParentType, ContextType>;
  sum?: Resolver<Maybe<ResolversTypes['generations_sum_fields']>, ParentType, ContextType>;
  var_pop?: Resolver<Maybe<ResolversTypes['generations_var_pop_fields']>, ParentType, ContextType>;
  var_samp?: Resolver<Maybe<ResolversTypes['generations_var_samp_fields']>, ParentType, ContextType>;
  variance?: Resolver<Maybe<ResolversTypes['generations_variance_fields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GenerationsAvgFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['generations_avg_fields'] = ResolversParentTypes['generations_avg_fields']> = {
  outputMediaLength?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GenerationsMaxFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['generations_max_fields'] = ResolversParentTypes['generations_max_fields']> = {
  createdAt?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  finishedAt?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  modelName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  organizationId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  outputMediaLength?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  outputMediaUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  startedAt?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  transactionId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  webhookUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GenerationsMinFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['generations_min_fields'] = ResolversParentTypes['generations_min_fields']> = {
  createdAt?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  finishedAt?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  modelName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  organizationId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  outputMediaLength?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  outputMediaUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  startedAt?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  transactionId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  webhookUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GenerationsMutationResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['generations_mutation_response'] = ResolversParentTypes['generations_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['generations']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GenerationsStddevFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['generations_stddev_fields'] = ResolversParentTypes['generations_stddev_fields']> = {
  outputMediaLength?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GenerationsStddevPopFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['generations_stddev_pop_fields'] = ResolversParentTypes['generations_stddev_pop_fields']> = {
  outputMediaLength?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GenerationsStddevSampFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['generations_stddev_samp_fields'] = ResolversParentTypes['generations_stddev_samp_fields']> = {
  outputMediaLength?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GenerationsSumFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['generations_sum_fields'] = ResolversParentTypes['generations_sum_fields']> = {
  outputMediaLength?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GenerationsVarPopFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['generations_var_pop_fields'] = ResolversParentTypes['generations_var_pop_fields']> = {
  outputMediaLength?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GenerationsVarSampFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['generations_var_samp_fields'] = ResolversParentTypes['generations_var_samp_fields']> = {
  outputMediaLength?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GenerationsVarianceFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['generations_variance_fields'] = ResolversParentTypes['generations_variance_fields']> = {
  outputMediaLength?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export interface JsonbScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['jsonb'], any> {
  name: 'jsonb';
}

export type ModelInputTypeResolvers<ContextType = any, ParentType extends ResolversParentTypes['model_input_type'] = ResolversParentTypes['model_input_type']> = {
  type?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ModelInputTypeAggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['model_input_type_aggregate'] = ResolversParentTypes['model_input_type_aggregate']> = {
  aggregate?: Resolver<Maybe<ResolversTypes['model_input_type_aggregate_fields']>, ParentType, ContextType>;
  nodes?: Resolver<Array<ResolversTypes['model_input_type']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ModelInputTypeAggregateFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['model_input_type_aggregate_fields'] = ResolversParentTypes['model_input_type_aggregate_fields']> = {
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType, Partial<ModelInputTypeAggregateFieldsCountArgs>>;
  max?: Resolver<Maybe<ResolversTypes['model_input_type_max_fields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['model_input_type_min_fields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ModelInputTypeMaxFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['model_input_type_max_fields'] = ResolversParentTypes['model_input_type_max_fields']> = {
  type?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ModelInputTypeMinFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['model_input_type_min_fields'] = ResolversParentTypes['model_input_type_min_fields']> = {
  type?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ModelInputTypeMutationResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['model_input_type_mutation_response'] = ResolversParentTypes['model_input_type_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['model_input_type']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ModelTypeResolvers<ContextType = any, ParentType extends ResolversParentTypes['model_type'] = ResolversParentTypes['model_type']> = {
  type?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ModelTypeAggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['model_type_aggregate'] = ResolversParentTypes['model_type_aggregate']> = {
  aggregate?: Resolver<Maybe<ResolversTypes['model_type_aggregate_fields']>, ParentType, ContextType>;
  nodes?: Resolver<Array<ResolversTypes['model_type']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ModelTypeAggregateFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['model_type_aggregate_fields'] = ResolversParentTypes['model_type_aggregate_fields']> = {
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType, Partial<ModelTypeAggregateFieldsCountArgs>>;
  max?: Resolver<Maybe<ResolversTypes['model_type_max_fields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['model_type_min_fields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ModelTypeMaxFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['model_type_max_fields'] = ResolversParentTypes['model_type_max_fields']> = {
  type?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ModelTypeMinFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['model_type_min_fields'] = ResolversParentTypes['model_type_min_fields']> = {
  type?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ModelTypeMutationResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['model_type_mutation_response'] = ResolversParentTypes['model_type_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['model_type']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ModelsResolvers<ContextType = any, ParentType extends ResolversParentTypes['models'] = ResolversParentTypes['models']> = {
  createdAt?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  endpoint?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  requiredInputCombinations?: Resolver<ResolversTypes['jsonb'], ParentType, ContextType, Partial<ModelsRequiredInputCombinationsArgs>>;
  type?: Resolver<ResolversTypes['model_type_enum'], ParentType, ContextType>;
  updatedAt?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  version?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ModelsAggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['models_aggregate'] = ResolversParentTypes['models_aggregate']> = {
  aggregate?: Resolver<Maybe<ResolversTypes['models_aggregate_fields']>, ParentType, ContextType>;
  nodes?: Resolver<Array<ResolversTypes['models']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ModelsAggregateFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['models_aggregate_fields'] = ResolversParentTypes['models_aggregate_fields']> = {
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType, Partial<ModelsAggregateFieldsCountArgs>>;
  max?: Resolver<Maybe<ResolversTypes['models_max_fields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['models_min_fields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ModelsMaxFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['models_max_fields'] = ResolversParentTypes['models_max_fields']> = {
  createdAt?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  endpoint?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  version?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ModelsMinFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['models_min_fields'] = ResolversParentTypes['models_min_fields']> = {
  createdAt?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  endpoint?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  version?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ModelsMutationResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['models_mutation_response'] = ResolversParentTypes['models_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['models']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MutationRootResolvers<ContextType = any, ParentType extends ResolversParentTypes['mutation_root'] = ResolversParentTypes['mutation_root']> = {
  deleteAuthProvider?: Resolver<Maybe<ResolversTypes['authProviders']>, ParentType, ContextType, RequireFields<MutationRootDeleteAuthProviderArgs, 'id'>>;
  deleteAuthProviderRequest?: Resolver<Maybe<ResolversTypes['authProviderRequests']>, ParentType, ContextType, RequireFields<MutationRootDeleteAuthProviderRequestArgs, 'id'>>;
  deleteAuthProviderRequests?: Resolver<Maybe<ResolversTypes['authProviderRequests_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootDeleteAuthProviderRequestsArgs, 'where'>>;
  deleteAuthProviders?: Resolver<Maybe<ResolversTypes['authProviders_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootDeleteAuthProvidersArgs, 'where'>>;
  deleteAuthRefreshToken?: Resolver<Maybe<ResolversTypes['authRefreshTokens']>, ParentType, ContextType, RequireFields<MutationRootDeleteAuthRefreshTokenArgs, 'id'>>;
  deleteAuthRefreshTokenType?: Resolver<Maybe<ResolversTypes['authRefreshTokenTypes']>, ParentType, ContextType, RequireFields<MutationRootDeleteAuthRefreshTokenTypeArgs, 'value'>>;
  deleteAuthRefreshTokenTypes?: Resolver<Maybe<ResolversTypes['authRefreshTokenTypes_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootDeleteAuthRefreshTokenTypesArgs, 'where'>>;
  deleteAuthRefreshTokens?: Resolver<Maybe<ResolversTypes['authRefreshTokens_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootDeleteAuthRefreshTokensArgs, 'where'>>;
  deleteAuthRole?: Resolver<Maybe<ResolversTypes['authRoles']>, ParentType, ContextType, RequireFields<MutationRootDeleteAuthRoleArgs, 'role'>>;
  deleteAuthRoles?: Resolver<Maybe<ResolversTypes['authRoles_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootDeleteAuthRolesArgs, 'where'>>;
  deleteAuthUserProvider?: Resolver<Maybe<ResolversTypes['authUserProviders']>, ParentType, ContextType, RequireFields<MutationRootDeleteAuthUserProviderArgs, 'id'>>;
  deleteAuthUserProviders?: Resolver<Maybe<ResolversTypes['authUserProviders_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootDeleteAuthUserProvidersArgs, 'where'>>;
  deleteAuthUserRole?: Resolver<Maybe<ResolversTypes['authUserRoles']>, ParentType, ContextType, RequireFields<MutationRootDeleteAuthUserRoleArgs, 'id'>>;
  deleteAuthUserRoles?: Resolver<Maybe<ResolversTypes['authUserRoles_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootDeleteAuthUserRolesArgs, 'where'>>;
  deleteAuthUserSecurityKey?: Resolver<Maybe<ResolversTypes['authUserSecurityKeys']>, ParentType, ContextType, RequireFields<MutationRootDeleteAuthUserSecurityKeyArgs, 'id'>>;
  deleteAuthUserSecurityKeys?: Resolver<Maybe<ResolversTypes['authUserSecurityKeys_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootDeleteAuthUserSecurityKeysArgs, 'where'>>;
  deleteBucket?: Resolver<Maybe<ResolversTypes['buckets']>, ParentType, ContextType, RequireFields<MutationRootDeleteBucketArgs, 'id'>>;
  deleteBuckets?: Resolver<Maybe<ResolversTypes['buckets_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootDeleteBucketsArgs, 'where'>>;
  deleteFile?: Resolver<Maybe<ResolversTypes['files']>, ParentType, ContextType, RequireFields<MutationRootDeleteFileArgs, 'id'>>;
  deleteFiles?: Resolver<Maybe<ResolversTypes['files_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootDeleteFilesArgs, 'where'>>;
  deleteUser?: Resolver<Maybe<ResolversTypes['users']>, ParentType, ContextType, RequireFields<MutationRootDeleteUserArgs, 'id'>>;
  deleteUsers?: Resolver<Maybe<ResolversTypes['users_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootDeleteUsersArgs, 'where'>>;
  deleteVirus?: Resolver<Maybe<ResolversTypes['virus']>, ParentType, ContextType, RequireFields<MutationRootDeleteVirusArgs, 'id'>>;
  deleteViruses?: Resolver<Maybe<ResolversTypes['virus_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootDeleteVirusesArgs, 'where'>>;
  delete_api_keys?: Resolver<Maybe<ResolversTypes['api_keys_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootDeleteApiKeysArgs, 'where'>>;
  delete_api_keys_by_pk?: Resolver<Maybe<ResolversTypes['api_keys']>, ParentType, ContextType, RequireFields<MutationRootDeleteApiKeysByPkArgs, 'id'>>;
  delete_generation_presets?: Resolver<Maybe<ResolversTypes['generation_presets_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootDeleteGenerationPresetsArgs, 'where'>>;
  delete_generation_presets_by_pk?: Resolver<Maybe<ResolversTypes['generation_presets']>, ParentType, ContextType, RequireFields<MutationRootDeleteGenerationPresetsByPkArgs, 'id'>>;
  delete_generations?: Resolver<Maybe<ResolversTypes['generations_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootDeleteGenerationsArgs, 'where'>>;
  delete_generations_by_pk?: Resolver<Maybe<ResolversTypes['generations']>, ParentType, ContextType, RequireFields<MutationRootDeleteGenerationsByPkArgs, 'id'>>;
  delete_model_input_type?: Resolver<Maybe<ResolversTypes['model_input_type_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootDeleteModelInputTypeArgs, 'where'>>;
  delete_model_input_type_by_pk?: Resolver<Maybe<ResolversTypes['model_input_type']>, ParentType, ContextType, RequireFields<MutationRootDeleteModelInputTypeByPkArgs, 'type'>>;
  delete_model_type?: Resolver<Maybe<ResolversTypes['model_type_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootDeleteModelTypeArgs, 'where'>>;
  delete_model_type_by_pk?: Resolver<Maybe<ResolversTypes['model_type']>, ParentType, ContextType, RequireFields<MutationRootDeleteModelTypeByPkArgs, 'type'>>;
  delete_models?: Resolver<Maybe<ResolversTypes['models_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootDeleteModelsArgs, 'where'>>;
  delete_models_by_pk?: Resolver<Maybe<ResolversTypes['models']>, ParentType, ContextType, RequireFields<MutationRootDeleteModelsByPkArgs, 'id'>>;
  delete_organization_features?: Resolver<Maybe<ResolversTypes['organization_features_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootDeleteOrganizationFeaturesArgs, 'where'>>;
  delete_organization_features_by_pk?: Resolver<Maybe<ResolversTypes['organization_features']>, ParentType, ContextType, RequireFields<MutationRootDeleteOrganizationFeaturesByPkArgs, 'organizationId'>>;
  delete_organizations?: Resolver<Maybe<ResolversTypes['organizations_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootDeleteOrganizationsArgs, 'where'>>;
  delete_organizations_by_pk?: Resolver<Maybe<ResolversTypes['organizations']>, ParentType, ContextType, RequireFields<MutationRootDeleteOrganizationsByPkArgs, 'id'>>;
  delete_status?: Resolver<Maybe<ResolversTypes['status_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootDeleteStatusArgs, 'where'>>;
  delete_status_by_pk?: Resolver<Maybe<ResolversTypes['status']>, ParentType, ContextType, RequireFields<MutationRootDeleteStatusByPkArgs, 'name'>>;
  delete_transaction_type?: Resolver<Maybe<ResolversTypes['transaction_type_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootDeleteTransactionTypeArgs, 'where'>>;
  delete_transaction_type_by_pk?: Resolver<Maybe<ResolversTypes['transaction_type']>, ParentType, ContextType, RequireFields<MutationRootDeleteTransactionTypeByPkArgs, 'type'>>;
  delete_transactions?: Resolver<Maybe<ResolversTypes['transactions_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootDeleteTransactionsArgs, 'where'>>;
  delete_transactions_by_pk?: Resolver<Maybe<ResolversTypes['transactions']>, ParentType, ContextType, RequireFields<MutationRootDeleteTransactionsByPkArgs, 'id'>>;
  delete_user_organizations?: Resolver<Maybe<ResolversTypes['user_organizations_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootDeleteUserOrganizationsArgs, 'where'>>;
  delete_user_organizations_by_pk?: Resolver<Maybe<ResolversTypes['user_organizations']>, ParentType, ContextType, RequireFields<MutationRootDeleteUserOrganizationsByPkArgs, 'id'>>;
  insertAuthProvider?: Resolver<Maybe<ResolversTypes['authProviders']>, ParentType, ContextType, RequireFields<MutationRootInsertAuthProviderArgs, 'object'>>;
  insertAuthProviderRequest?: Resolver<Maybe<ResolversTypes['authProviderRequests']>, ParentType, ContextType, RequireFields<MutationRootInsertAuthProviderRequestArgs, 'object'>>;
  insertAuthProviderRequests?: Resolver<Maybe<ResolversTypes['authProviderRequests_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootInsertAuthProviderRequestsArgs, 'objects'>>;
  insertAuthProviders?: Resolver<Maybe<ResolversTypes['authProviders_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootInsertAuthProvidersArgs, 'objects'>>;
  insertAuthRefreshToken?: Resolver<Maybe<ResolversTypes['authRefreshTokens']>, ParentType, ContextType, RequireFields<MutationRootInsertAuthRefreshTokenArgs, 'object'>>;
  insertAuthRefreshTokenType?: Resolver<Maybe<ResolversTypes['authRefreshTokenTypes']>, ParentType, ContextType, RequireFields<MutationRootInsertAuthRefreshTokenTypeArgs, 'object'>>;
  insertAuthRefreshTokenTypes?: Resolver<Maybe<ResolversTypes['authRefreshTokenTypes_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootInsertAuthRefreshTokenTypesArgs, 'objects'>>;
  insertAuthRefreshTokens?: Resolver<Maybe<ResolversTypes['authRefreshTokens_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootInsertAuthRefreshTokensArgs, 'objects'>>;
  insertAuthRole?: Resolver<Maybe<ResolversTypes['authRoles']>, ParentType, ContextType, RequireFields<MutationRootInsertAuthRoleArgs, 'object'>>;
  insertAuthRoles?: Resolver<Maybe<ResolversTypes['authRoles_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootInsertAuthRolesArgs, 'objects'>>;
  insertAuthUserProvider?: Resolver<Maybe<ResolversTypes['authUserProviders']>, ParentType, ContextType, RequireFields<MutationRootInsertAuthUserProviderArgs, 'object'>>;
  insertAuthUserProviders?: Resolver<Maybe<ResolversTypes['authUserProviders_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootInsertAuthUserProvidersArgs, 'objects'>>;
  insertAuthUserRole?: Resolver<Maybe<ResolversTypes['authUserRoles']>, ParentType, ContextType, RequireFields<MutationRootInsertAuthUserRoleArgs, 'object'>>;
  insertAuthUserRoles?: Resolver<Maybe<ResolversTypes['authUserRoles_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootInsertAuthUserRolesArgs, 'objects'>>;
  insertAuthUserSecurityKey?: Resolver<Maybe<ResolversTypes['authUserSecurityKeys']>, ParentType, ContextType, RequireFields<MutationRootInsertAuthUserSecurityKeyArgs, 'object'>>;
  insertAuthUserSecurityKeys?: Resolver<Maybe<ResolversTypes['authUserSecurityKeys_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootInsertAuthUserSecurityKeysArgs, 'objects'>>;
  insertBucket?: Resolver<Maybe<ResolversTypes['buckets']>, ParentType, ContextType, RequireFields<MutationRootInsertBucketArgs, 'object'>>;
  insertBuckets?: Resolver<Maybe<ResolversTypes['buckets_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootInsertBucketsArgs, 'objects'>>;
  insertFile?: Resolver<Maybe<ResolversTypes['files']>, ParentType, ContextType, RequireFields<MutationRootInsertFileArgs, 'object'>>;
  insertFiles?: Resolver<Maybe<ResolversTypes['files_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootInsertFilesArgs, 'objects'>>;
  insertUser?: Resolver<Maybe<ResolversTypes['users']>, ParentType, ContextType, RequireFields<MutationRootInsertUserArgs, 'object'>>;
  insertUsers?: Resolver<Maybe<ResolversTypes['users_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootInsertUsersArgs, 'objects'>>;
  insertVirus?: Resolver<Maybe<ResolversTypes['virus']>, ParentType, ContextType, RequireFields<MutationRootInsertVirusArgs, 'object'>>;
  insertViruses?: Resolver<Maybe<ResolversTypes['virus_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootInsertVirusesArgs, 'objects'>>;
  insert_api_keys?: Resolver<Maybe<ResolversTypes['api_keys_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootInsertApiKeysArgs, 'objects'>>;
  insert_api_keys_one?: Resolver<Maybe<ResolversTypes['api_keys']>, ParentType, ContextType, RequireFields<MutationRootInsertApiKeysOneArgs, 'object'>>;
  insert_generation_presets?: Resolver<Maybe<ResolversTypes['generation_presets_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootInsertGenerationPresetsArgs, 'objects'>>;
  insert_generation_presets_one?: Resolver<Maybe<ResolversTypes['generation_presets']>, ParentType, ContextType, RequireFields<MutationRootInsertGenerationPresetsOneArgs, 'object'>>;
  insert_generations?: Resolver<Maybe<ResolversTypes['generations_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootInsertGenerationsArgs, 'objects'>>;
  insert_generations_one?: Resolver<Maybe<ResolversTypes['generations']>, ParentType, ContextType, RequireFields<MutationRootInsertGenerationsOneArgs, 'object'>>;
  insert_model_input_type?: Resolver<Maybe<ResolversTypes['model_input_type_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootInsertModelInputTypeArgs, 'objects'>>;
  insert_model_input_type_one?: Resolver<Maybe<ResolversTypes['model_input_type']>, ParentType, ContextType, RequireFields<MutationRootInsertModelInputTypeOneArgs, 'object'>>;
  insert_model_type?: Resolver<Maybe<ResolversTypes['model_type_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootInsertModelTypeArgs, 'objects'>>;
  insert_model_type_one?: Resolver<Maybe<ResolversTypes['model_type']>, ParentType, ContextType, RequireFields<MutationRootInsertModelTypeOneArgs, 'object'>>;
  insert_models?: Resolver<Maybe<ResolversTypes['models_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootInsertModelsArgs, 'objects'>>;
  insert_models_one?: Resolver<Maybe<ResolversTypes['models']>, ParentType, ContextType, RequireFields<MutationRootInsertModelsOneArgs, 'object'>>;
  insert_organization_features?: Resolver<Maybe<ResolversTypes['organization_features_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootInsertOrganizationFeaturesArgs, 'objects'>>;
  insert_organization_features_one?: Resolver<Maybe<ResolversTypes['organization_features']>, ParentType, ContextType, RequireFields<MutationRootInsertOrganizationFeaturesOneArgs, 'object'>>;
  insert_organizations?: Resolver<Maybe<ResolversTypes['organizations_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootInsertOrganizationsArgs, 'objects'>>;
  insert_organizations_one?: Resolver<Maybe<ResolversTypes['organizations']>, ParentType, ContextType, RequireFields<MutationRootInsertOrganizationsOneArgs, 'object'>>;
  insert_status?: Resolver<Maybe<ResolversTypes['status_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootInsertStatusArgs, 'objects'>>;
  insert_status_one?: Resolver<Maybe<ResolversTypes['status']>, ParentType, ContextType, RequireFields<MutationRootInsertStatusOneArgs, 'object'>>;
  insert_transaction_type?: Resolver<Maybe<ResolversTypes['transaction_type_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootInsertTransactionTypeArgs, 'objects'>>;
  insert_transaction_type_one?: Resolver<Maybe<ResolversTypes['transaction_type']>, ParentType, ContextType, RequireFields<MutationRootInsertTransactionTypeOneArgs, 'object'>>;
  insert_transactions?: Resolver<Maybe<ResolversTypes['transactions_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootInsertTransactionsArgs, 'objects'>>;
  insert_transactions_one?: Resolver<Maybe<ResolversTypes['transactions']>, ParentType, ContextType, RequireFields<MutationRootInsertTransactionsOneArgs, 'object'>>;
  insert_user_organizations?: Resolver<Maybe<ResolversTypes['user_organizations_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootInsertUserOrganizationsArgs, 'objects'>>;
  insert_user_organizations_one?: Resolver<Maybe<ResolversTypes['user_organizations']>, ParentType, ContextType, RequireFields<MutationRootInsertUserOrganizationsOneArgs, 'object'>>;
  updateAuthProvider?: Resolver<Maybe<ResolversTypes['authProviders']>, ParentType, ContextType, RequireFields<MutationRootUpdateAuthProviderArgs, 'pk_columns'>>;
  updateAuthProviderRequest?: Resolver<Maybe<ResolversTypes['authProviderRequests']>, ParentType, ContextType, RequireFields<MutationRootUpdateAuthProviderRequestArgs, 'pk_columns'>>;
  updateAuthProviderRequests?: Resolver<Maybe<ResolversTypes['authProviderRequests_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootUpdateAuthProviderRequestsArgs, 'where'>>;
  updateAuthProviders?: Resolver<Maybe<ResolversTypes['authProviders_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootUpdateAuthProvidersArgs, 'where'>>;
  updateAuthRefreshToken?: Resolver<Maybe<ResolversTypes['authRefreshTokens']>, ParentType, ContextType, RequireFields<MutationRootUpdateAuthRefreshTokenArgs, 'pk_columns'>>;
  updateAuthRefreshTokenType?: Resolver<Maybe<ResolversTypes['authRefreshTokenTypes']>, ParentType, ContextType, RequireFields<MutationRootUpdateAuthRefreshTokenTypeArgs, 'pk_columns'>>;
  updateAuthRefreshTokenTypes?: Resolver<Maybe<ResolversTypes['authRefreshTokenTypes_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootUpdateAuthRefreshTokenTypesArgs, 'where'>>;
  updateAuthRefreshTokens?: Resolver<Maybe<ResolversTypes['authRefreshTokens_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootUpdateAuthRefreshTokensArgs, 'where'>>;
  updateAuthRole?: Resolver<Maybe<ResolversTypes['authRoles']>, ParentType, ContextType, RequireFields<MutationRootUpdateAuthRoleArgs, 'pk_columns'>>;
  updateAuthRoles?: Resolver<Maybe<ResolversTypes['authRoles_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootUpdateAuthRolesArgs, 'where'>>;
  updateAuthUserProvider?: Resolver<Maybe<ResolversTypes['authUserProviders']>, ParentType, ContextType, RequireFields<MutationRootUpdateAuthUserProviderArgs, 'pk_columns'>>;
  updateAuthUserProviders?: Resolver<Maybe<ResolversTypes['authUserProviders_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootUpdateAuthUserProvidersArgs, 'where'>>;
  updateAuthUserRole?: Resolver<Maybe<ResolversTypes['authUserRoles']>, ParentType, ContextType, RequireFields<MutationRootUpdateAuthUserRoleArgs, 'pk_columns'>>;
  updateAuthUserRoles?: Resolver<Maybe<ResolversTypes['authUserRoles_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootUpdateAuthUserRolesArgs, 'where'>>;
  updateAuthUserSecurityKey?: Resolver<Maybe<ResolversTypes['authUserSecurityKeys']>, ParentType, ContextType, RequireFields<MutationRootUpdateAuthUserSecurityKeyArgs, 'pk_columns'>>;
  updateAuthUserSecurityKeys?: Resolver<Maybe<ResolversTypes['authUserSecurityKeys_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootUpdateAuthUserSecurityKeysArgs, 'where'>>;
  updateBucket?: Resolver<Maybe<ResolversTypes['buckets']>, ParentType, ContextType, RequireFields<MutationRootUpdateBucketArgs, 'pk_columns'>>;
  updateBuckets?: Resolver<Maybe<ResolversTypes['buckets_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootUpdateBucketsArgs, 'where'>>;
  updateFile?: Resolver<Maybe<ResolversTypes['files']>, ParentType, ContextType, RequireFields<MutationRootUpdateFileArgs, 'pk_columns'>>;
  updateFiles?: Resolver<Maybe<ResolversTypes['files_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootUpdateFilesArgs, 'where'>>;
  updateUser?: Resolver<Maybe<ResolversTypes['users']>, ParentType, ContextType, RequireFields<MutationRootUpdateUserArgs, 'pk_columns'>>;
  updateUsers?: Resolver<Maybe<ResolversTypes['users_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootUpdateUsersArgs, 'where'>>;
  updateVirus?: Resolver<Maybe<ResolversTypes['virus']>, ParentType, ContextType, RequireFields<MutationRootUpdateVirusArgs, 'pk_columns'>>;
  updateViruses?: Resolver<Maybe<ResolversTypes['virus_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootUpdateVirusesArgs, 'where'>>;
  update_api_keys?: Resolver<Maybe<ResolversTypes['api_keys_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootUpdateApiKeysArgs, 'where'>>;
  update_api_keys_by_pk?: Resolver<Maybe<ResolversTypes['api_keys']>, ParentType, ContextType, RequireFields<MutationRootUpdateApiKeysByPkArgs, 'pk_columns'>>;
  update_api_keys_many?: Resolver<Maybe<Array<Maybe<ResolversTypes['api_keys_mutation_response']>>>, ParentType, ContextType, RequireFields<MutationRootUpdateApiKeysManyArgs, 'updates'>>;
  update_authProviderRequests_many?: Resolver<Maybe<Array<Maybe<ResolversTypes['authProviderRequests_mutation_response']>>>, ParentType, ContextType, RequireFields<MutationRootUpdateAuthProviderRequestsManyArgs, 'updates'>>;
  update_authProviders_many?: Resolver<Maybe<Array<Maybe<ResolversTypes['authProviders_mutation_response']>>>, ParentType, ContextType, RequireFields<MutationRootUpdateAuthProvidersManyArgs, 'updates'>>;
  update_authRefreshTokenTypes_many?: Resolver<Maybe<Array<Maybe<ResolversTypes['authRefreshTokenTypes_mutation_response']>>>, ParentType, ContextType, RequireFields<MutationRootUpdateAuthRefreshTokenTypesManyArgs, 'updates'>>;
  update_authRefreshTokens_many?: Resolver<Maybe<Array<Maybe<ResolversTypes['authRefreshTokens_mutation_response']>>>, ParentType, ContextType, RequireFields<MutationRootUpdateAuthRefreshTokensManyArgs, 'updates'>>;
  update_authRoles_many?: Resolver<Maybe<Array<Maybe<ResolversTypes['authRoles_mutation_response']>>>, ParentType, ContextType, RequireFields<MutationRootUpdateAuthRolesManyArgs, 'updates'>>;
  update_authUserProviders_many?: Resolver<Maybe<Array<Maybe<ResolversTypes['authUserProviders_mutation_response']>>>, ParentType, ContextType, RequireFields<MutationRootUpdateAuthUserProvidersManyArgs, 'updates'>>;
  update_authUserRoles_many?: Resolver<Maybe<Array<Maybe<ResolversTypes['authUserRoles_mutation_response']>>>, ParentType, ContextType, RequireFields<MutationRootUpdateAuthUserRolesManyArgs, 'updates'>>;
  update_authUserSecurityKeys_many?: Resolver<Maybe<Array<Maybe<ResolversTypes['authUserSecurityKeys_mutation_response']>>>, ParentType, ContextType, RequireFields<MutationRootUpdateAuthUserSecurityKeysManyArgs, 'updates'>>;
  update_buckets_many?: Resolver<Maybe<Array<Maybe<ResolversTypes['buckets_mutation_response']>>>, ParentType, ContextType, RequireFields<MutationRootUpdateBucketsManyArgs, 'updates'>>;
  update_files_many?: Resolver<Maybe<Array<Maybe<ResolversTypes['files_mutation_response']>>>, ParentType, ContextType, RequireFields<MutationRootUpdateFilesManyArgs, 'updates'>>;
  update_generation_presets?: Resolver<Maybe<ResolversTypes['generation_presets_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootUpdateGenerationPresetsArgs, 'where'>>;
  update_generation_presets_by_pk?: Resolver<Maybe<ResolversTypes['generation_presets']>, ParentType, ContextType, RequireFields<MutationRootUpdateGenerationPresetsByPkArgs, 'pk_columns'>>;
  update_generation_presets_many?: Resolver<Maybe<Array<Maybe<ResolversTypes['generation_presets_mutation_response']>>>, ParentType, ContextType, RequireFields<MutationRootUpdateGenerationPresetsManyArgs, 'updates'>>;
  update_generations?: Resolver<Maybe<ResolversTypes['generations_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootUpdateGenerationsArgs, 'where'>>;
  update_generations_by_pk?: Resolver<Maybe<ResolversTypes['generations']>, ParentType, ContextType, RequireFields<MutationRootUpdateGenerationsByPkArgs, 'pk_columns'>>;
  update_generations_many?: Resolver<Maybe<Array<Maybe<ResolversTypes['generations_mutation_response']>>>, ParentType, ContextType, RequireFields<MutationRootUpdateGenerationsManyArgs, 'updates'>>;
  update_model_input_type?: Resolver<Maybe<ResolversTypes['model_input_type_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootUpdateModelInputTypeArgs, 'where'>>;
  update_model_input_type_by_pk?: Resolver<Maybe<ResolversTypes['model_input_type']>, ParentType, ContextType, RequireFields<MutationRootUpdateModelInputTypeByPkArgs, 'pk_columns'>>;
  update_model_input_type_many?: Resolver<Maybe<Array<Maybe<ResolversTypes['model_input_type_mutation_response']>>>, ParentType, ContextType, RequireFields<MutationRootUpdateModelInputTypeManyArgs, 'updates'>>;
  update_model_type?: Resolver<Maybe<ResolversTypes['model_type_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootUpdateModelTypeArgs, 'where'>>;
  update_model_type_by_pk?: Resolver<Maybe<ResolversTypes['model_type']>, ParentType, ContextType, RequireFields<MutationRootUpdateModelTypeByPkArgs, 'pk_columns'>>;
  update_model_type_many?: Resolver<Maybe<Array<Maybe<ResolversTypes['model_type_mutation_response']>>>, ParentType, ContextType, RequireFields<MutationRootUpdateModelTypeManyArgs, 'updates'>>;
  update_models?: Resolver<Maybe<ResolversTypes['models_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootUpdateModelsArgs, 'where'>>;
  update_models_by_pk?: Resolver<Maybe<ResolversTypes['models']>, ParentType, ContextType, RequireFields<MutationRootUpdateModelsByPkArgs, 'pk_columns'>>;
  update_models_many?: Resolver<Maybe<Array<Maybe<ResolversTypes['models_mutation_response']>>>, ParentType, ContextType, RequireFields<MutationRootUpdateModelsManyArgs, 'updates'>>;
  update_organization_features?: Resolver<Maybe<ResolversTypes['organization_features_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootUpdateOrganizationFeaturesArgs, 'where'>>;
  update_organization_features_by_pk?: Resolver<Maybe<ResolversTypes['organization_features']>, ParentType, ContextType, RequireFields<MutationRootUpdateOrganizationFeaturesByPkArgs, 'pk_columns'>>;
  update_organization_features_many?: Resolver<Maybe<Array<Maybe<ResolversTypes['organization_features_mutation_response']>>>, ParentType, ContextType, RequireFields<MutationRootUpdateOrganizationFeaturesManyArgs, 'updates'>>;
  update_organizations?: Resolver<Maybe<ResolversTypes['organizations_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootUpdateOrganizationsArgs, 'where'>>;
  update_organizations_by_pk?: Resolver<Maybe<ResolversTypes['organizations']>, ParentType, ContextType, RequireFields<MutationRootUpdateOrganizationsByPkArgs, 'pk_columns'>>;
  update_organizations_many?: Resolver<Maybe<Array<Maybe<ResolversTypes['organizations_mutation_response']>>>, ParentType, ContextType, RequireFields<MutationRootUpdateOrganizationsManyArgs, 'updates'>>;
  update_status?: Resolver<Maybe<ResolversTypes['status_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootUpdateStatusArgs, 'where'>>;
  update_status_by_pk?: Resolver<Maybe<ResolversTypes['status']>, ParentType, ContextType, RequireFields<MutationRootUpdateStatusByPkArgs, 'pk_columns'>>;
  update_status_many?: Resolver<Maybe<Array<Maybe<ResolversTypes['status_mutation_response']>>>, ParentType, ContextType, RequireFields<MutationRootUpdateStatusManyArgs, 'updates'>>;
  update_transaction_type?: Resolver<Maybe<ResolversTypes['transaction_type_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootUpdateTransactionTypeArgs, 'where'>>;
  update_transaction_type_by_pk?: Resolver<Maybe<ResolversTypes['transaction_type']>, ParentType, ContextType, RequireFields<MutationRootUpdateTransactionTypeByPkArgs, 'pk_columns'>>;
  update_transaction_type_many?: Resolver<Maybe<Array<Maybe<ResolversTypes['transaction_type_mutation_response']>>>, ParentType, ContextType, RequireFields<MutationRootUpdateTransactionTypeManyArgs, 'updates'>>;
  update_transactions?: Resolver<Maybe<ResolversTypes['transactions_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootUpdateTransactionsArgs, 'where'>>;
  update_transactions_by_pk?: Resolver<Maybe<ResolversTypes['transactions']>, ParentType, ContextType, RequireFields<MutationRootUpdateTransactionsByPkArgs, 'pk_columns'>>;
  update_transactions_many?: Resolver<Maybe<Array<Maybe<ResolversTypes['transactions_mutation_response']>>>, ParentType, ContextType, RequireFields<MutationRootUpdateTransactionsManyArgs, 'updates'>>;
  update_user_organizations?: Resolver<Maybe<ResolversTypes['user_organizations_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootUpdateUserOrganizationsArgs, 'where'>>;
  update_user_organizations_by_pk?: Resolver<Maybe<ResolversTypes['user_organizations']>, ParentType, ContextType, RequireFields<MutationRootUpdateUserOrganizationsByPkArgs, 'pk_columns'>>;
  update_user_organizations_many?: Resolver<Maybe<Array<Maybe<ResolversTypes['user_organizations_mutation_response']>>>, ParentType, ContextType, RequireFields<MutationRootUpdateUserOrganizationsManyArgs, 'updates'>>;
  update_users_many?: Resolver<Maybe<Array<Maybe<ResolversTypes['users_mutation_response']>>>, ParentType, ContextType, RequireFields<MutationRootUpdateUsersManyArgs, 'updates'>>;
  update_virus_many?: Resolver<Maybe<Array<Maybe<ResolversTypes['virus_mutation_response']>>>, ParentType, ContextType, RequireFields<MutationRootUpdateVirusManyArgs, 'updates'>>;
};

export type OrganizationFeaturesResolvers<ContextType = any, ParentType extends ResolversParentTypes['organization_features'] = ResolversParentTypes['organization_features']> = {
  concurrencyOverride?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  createdAt?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  organizationId?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  updatedAt?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OrganizationFeaturesAggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['organization_features_aggregate'] = ResolversParentTypes['organization_features_aggregate']> = {
  aggregate?: Resolver<Maybe<ResolversTypes['organization_features_aggregate_fields']>, ParentType, ContextType>;
  nodes?: Resolver<Array<ResolversTypes['organization_features']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OrganizationFeaturesAggregateFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['organization_features_aggregate_fields'] = ResolversParentTypes['organization_features_aggregate_fields']> = {
  avg?: Resolver<Maybe<ResolversTypes['organization_features_avg_fields']>, ParentType, ContextType>;
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType, Partial<OrganizationFeaturesAggregateFieldsCountArgs>>;
  max?: Resolver<Maybe<ResolversTypes['organization_features_max_fields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['organization_features_min_fields']>, ParentType, ContextType>;
  stddev?: Resolver<Maybe<ResolversTypes['organization_features_stddev_fields']>, ParentType, ContextType>;
  stddev_pop?: Resolver<Maybe<ResolversTypes['organization_features_stddev_pop_fields']>, ParentType, ContextType>;
  stddev_samp?: Resolver<Maybe<ResolversTypes['organization_features_stddev_samp_fields']>, ParentType, ContextType>;
  sum?: Resolver<Maybe<ResolversTypes['organization_features_sum_fields']>, ParentType, ContextType>;
  var_pop?: Resolver<Maybe<ResolversTypes['organization_features_var_pop_fields']>, ParentType, ContextType>;
  var_samp?: Resolver<Maybe<ResolversTypes['organization_features_var_samp_fields']>, ParentType, ContextType>;
  variance?: Resolver<Maybe<ResolversTypes['organization_features_variance_fields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OrganizationFeaturesAvgFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['organization_features_avg_fields'] = ResolversParentTypes['organization_features_avg_fields']> = {
  concurrencyOverride?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OrganizationFeaturesMaxFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['organization_features_max_fields'] = ResolversParentTypes['organization_features_max_fields']> = {
  concurrencyOverride?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  createdAt?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  organizationId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OrganizationFeaturesMinFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['organization_features_min_fields'] = ResolversParentTypes['organization_features_min_fields']> = {
  concurrencyOverride?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  createdAt?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  organizationId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OrganizationFeaturesMutationResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['organization_features_mutation_response'] = ResolversParentTypes['organization_features_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['organization_features']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OrganizationFeaturesStddevFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['organization_features_stddev_fields'] = ResolversParentTypes['organization_features_stddev_fields']> = {
  concurrencyOverride?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OrganizationFeaturesStddevPopFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['organization_features_stddev_pop_fields'] = ResolversParentTypes['organization_features_stddev_pop_fields']> = {
  concurrencyOverride?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OrganizationFeaturesStddevSampFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['organization_features_stddev_samp_fields'] = ResolversParentTypes['organization_features_stddev_samp_fields']> = {
  concurrencyOverride?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OrganizationFeaturesSumFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['organization_features_sum_fields'] = ResolversParentTypes['organization_features_sum_fields']> = {
  concurrencyOverride?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OrganizationFeaturesVarPopFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['organization_features_var_pop_fields'] = ResolversParentTypes['organization_features_var_pop_fields']> = {
  concurrencyOverride?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OrganizationFeaturesVarSampFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['organization_features_var_samp_fields'] = ResolversParentTypes['organization_features_var_samp_fields']> = {
  concurrencyOverride?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OrganizationFeaturesVarianceFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['organization_features_variance_fields'] = ResolversParentTypes['organization_features_variance_fields']> = {
  concurrencyOverride?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OrganizationsResolvers<ContextType = any, ParentType extends ResolversParentTypes['organizations'] = ResolversParentTypes['organizations']> = {
  createdAt?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  keys?: Resolver<Array<ResolversTypes['api_keys']>, ParentType, ContextType, Partial<OrganizationsKeysArgs>>;
  keys_aggregate?: Resolver<ResolversTypes['api_keys_aggregate'], ParentType, ContextType, Partial<OrganizationsKeysAggregateArgs>>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  organization_features?: Resolver<Maybe<ResolversTypes['organization_features']>, ParentType, ContextType>;
  updatedAt?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  users?: Resolver<Maybe<ResolversTypes['user_organizations']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OrganizationsAggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['organizations_aggregate'] = ResolversParentTypes['organizations_aggregate']> = {
  aggregate?: Resolver<Maybe<ResolversTypes['organizations_aggregate_fields']>, ParentType, ContextType>;
  nodes?: Resolver<Array<ResolversTypes['organizations']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OrganizationsAggregateFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['organizations_aggregate_fields'] = ResolversParentTypes['organizations_aggregate_fields']> = {
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType, Partial<OrganizationsAggregateFieldsCountArgs>>;
  max?: Resolver<Maybe<ResolversTypes['organizations_max_fields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['organizations_min_fields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OrganizationsMaxFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['organizations_max_fields'] = ResolversParentTypes['organizations_max_fields']> = {
  createdAt?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OrganizationsMinFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['organizations_min_fields'] = ResolversParentTypes['organizations_min_fields']> = {
  createdAt?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OrganizationsMutationResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['organizations_mutation_response'] = ResolversParentTypes['organizations_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['organizations']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type QueryRootResolvers<ContextType = any, ParentType extends ResolversParentTypes['query_root'] = ResolversParentTypes['query_root']> = {
  api_keys?: Resolver<Array<ResolversTypes['api_keys']>, ParentType, ContextType, Partial<QueryRootApiKeysArgs>>;
  api_keys_aggregate?: Resolver<ResolversTypes['api_keys_aggregate'], ParentType, ContextType, Partial<QueryRootApiKeysAggregateArgs>>;
  api_keys_by_pk?: Resolver<Maybe<ResolversTypes['api_keys']>, ParentType, ContextType, RequireFields<QueryRootApiKeysByPkArgs, 'id'>>;
  authProvider?: Resolver<Maybe<ResolversTypes['authProviders']>, ParentType, ContextType, RequireFields<QueryRootAuthProviderArgs, 'id'>>;
  authProviderRequest?: Resolver<Maybe<ResolversTypes['authProviderRequests']>, ParentType, ContextType, RequireFields<QueryRootAuthProviderRequestArgs, 'id'>>;
  authProviderRequests?: Resolver<Array<ResolversTypes['authProviderRequests']>, ParentType, ContextType, Partial<QueryRootAuthProviderRequestsArgs>>;
  authProviderRequestsAggregate?: Resolver<ResolversTypes['authProviderRequests_aggregate'], ParentType, ContextType, Partial<QueryRootAuthProviderRequestsAggregateArgs>>;
  authProviders?: Resolver<Array<ResolversTypes['authProviders']>, ParentType, ContextType, Partial<QueryRootAuthProvidersArgs>>;
  authProvidersAggregate?: Resolver<ResolversTypes['authProviders_aggregate'], ParentType, ContextType, Partial<QueryRootAuthProvidersAggregateArgs>>;
  authRefreshToken?: Resolver<Maybe<ResolversTypes['authRefreshTokens']>, ParentType, ContextType, RequireFields<QueryRootAuthRefreshTokenArgs, 'id'>>;
  authRefreshTokenType?: Resolver<Maybe<ResolversTypes['authRefreshTokenTypes']>, ParentType, ContextType, RequireFields<QueryRootAuthRefreshTokenTypeArgs, 'value'>>;
  authRefreshTokenTypes?: Resolver<Array<ResolversTypes['authRefreshTokenTypes']>, ParentType, ContextType, Partial<QueryRootAuthRefreshTokenTypesArgs>>;
  authRefreshTokenTypesAggregate?: Resolver<ResolversTypes['authRefreshTokenTypes_aggregate'], ParentType, ContextType, Partial<QueryRootAuthRefreshTokenTypesAggregateArgs>>;
  authRefreshTokens?: Resolver<Array<ResolversTypes['authRefreshTokens']>, ParentType, ContextType, Partial<QueryRootAuthRefreshTokensArgs>>;
  authRefreshTokensAggregate?: Resolver<ResolversTypes['authRefreshTokens_aggregate'], ParentType, ContextType, Partial<QueryRootAuthRefreshTokensAggregateArgs>>;
  authRole?: Resolver<Maybe<ResolversTypes['authRoles']>, ParentType, ContextType, RequireFields<QueryRootAuthRoleArgs, 'role'>>;
  authRoles?: Resolver<Array<ResolversTypes['authRoles']>, ParentType, ContextType, Partial<QueryRootAuthRolesArgs>>;
  authRolesAggregate?: Resolver<ResolversTypes['authRoles_aggregate'], ParentType, ContextType, Partial<QueryRootAuthRolesAggregateArgs>>;
  authUserProvider?: Resolver<Maybe<ResolversTypes['authUserProviders']>, ParentType, ContextType, RequireFields<QueryRootAuthUserProviderArgs, 'id'>>;
  authUserProviders?: Resolver<Array<ResolversTypes['authUserProviders']>, ParentType, ContextType, Partial<QueryRootAuthUserProvidersArgs>>;
  authUserProvidersAggregate?: Resolver<ResolversTypes['authUserProviders_aggregate'], ParentType, ContextType, Partial<QueryRootAuthUserProvidersAggregateArgs>>;
  authUserRole?: Resolver<Maybe<ResolversTypes['authUserRoles']>, ParentType, ContextType, RequireFields<QueryRootAuthUserRoleArgs, 'id'>>;
  authUserRoles?: Resolver<Array<ResolversTypes['authUserRoles']>, ParentType, ContextType, Partial<QueryRootAuthUserRolesArgs>>;
  authUserRolesAggregate?: Resolver<ResolversTypes['authUserRoles_aggregate'], ParentType, ContextType, Partial<QueryRootAuthUserRolesAggregateArgs>>;
  authUserSecurityKey?: Resolver<Maybe<ResolversTypes['authUserSecurityKeys']>, ParentType, ContextType, RequireFields<QueryRootAuthUserSecurityKeyArgs, 'id'>>;
  authUserSecurityKeys?: Resolver<Array<ResolversTypes['authUserSecurityKeys']>, ParentType, ContextType, Partial<QueryRootAuthUserSecurityKeysArgs>>;
  authUserSecurityKeysAggregate?: Resolver<ResolversTypes['authUserSecurityKeys_aggregate'], ParentType, ContextType, Partial<QueryRootAuthUserSecurityKeysAggregateArgs>>;
  bucket?: Resolver<Maybe<ResolversTypes['buckets']>, ParentType, ContextType, RequireFields<QueryRootBucketArgs, 'id'>>;
  buckets?: Resolver<Array<ResolversTypes['buckets']>, ParentType, ContextType, Partial<QueryRootBucketsArgs>>;
  bucketsAggregate?: Resolver<ResolversTypes['buckets_aggregate'], ParentType, ContextType, Partial<QueryRootBucketsAggregateArgs>>;
  file?: Resolver<Maybe<ResolversTypes['files']>, ParentType, ContextType, RequireFields<QueryRootFileArgs, 'id'>>;
  files?: Resolver<Array<ResolversTypes['files']>, ParentType, ContextType, Partial<QueryRootFilesArgs>>;
  filesAggregate?: Resolver<ResolversTypes['files_aggregate'], ParentType, ContextType, Partial<QueryRootFilesAggregateArgs>>;
  generation_presets?: Resolver<Array<ResolversTypes['generation_presets']>, ParentType, ContextType, Partial<QueryRootGenerationPresetsArgs>>;
  generation_presets_aggregate?: Resolver<ResolversTypes['generation_presets_aggregate'], ParentType, ContextType, Partial<QueryRootGenerationPresetsAggregateArgs>>;
  generation_presets_by_pk?: Resolver<Maybe<ResolversTypes['generation_presets']>, ParentType, ContextType, RequireFields<QueryRootGenerationPresetsByPkArgs, 'id'>>;
  generations?: Resolver<Array<ResolversTypes['generations']>, ParentType, ContextType, Partial<QueryRootGenerationsArgs>>;
  generations_aggregate?: Resolver<ResolversTypes['generations_aggregate'], ParentType, ContextType, Partial<QueryRootGenerationsAggregateArgs>>;
  generations_by_pk?: Resolver<Maybe<ResolversTypes['generations']>, ParentType, ContextType, RequireFields<QueryRootGenerationsByPkArgs, 'id'>>;
  model_input_type?: Resolver<Array<ResolversTypes['model_input_type']>, ParentType, ContextType, Partial<QueryRootModelInputTypeArgs>>;
  model_input_type_aggregate?: Resolver<ResolversTypes['model_input_type_aggregate'], ParentType, ContextType, Partial<QueryRootModelInputTypeAggregateArgs>>;
  model_input_type_by_pk?: Resolver<Maybe<ResolversTypes['model_input_type']>, ParentType, ContextType, RequireFields<QueryRootModelInputTypeByPkArgs, 'type'>>;
  model_type?: Resolver<Array<ResolversTypes['model_type']>, ParentType, ContextType, Partial<QueryRootModelTypeArgs>>;
  model_type_aggregate?: Resolver<ResolversTypes['model_type_aggregate'], ParentType, ContextType, Partial<QueryRootModelTypeAggregateArgs>>;
  model_type_by_pk?: Resolver<Maybe<ResolversTypes['model_type']>, ParentType, ContextType, RequireFields<QueryRootModelTypeByPkArgs, 'type'>>;
  models?: Resolver<Array<ResolversTypes['models']>, ParentType, ContextType, Partial<QueryRootModelsArgs>>;
  models_aggregate?: Resolver<ResolversTypes['models_aggregate'], ParentType, ContextType, Partial<QueryRootModelsAggregateArgs>>;
  models_by_pk?: Resolver<Maybe<ResolversTypes['models']>, ParentType, ContextType, RequireFields<QueryRootModelsByPkArgs, 'id'>>;
  organization_features?: Resolver<Array<ResolversTypes['organization_features']>, ParentType, ContextType, Partial<QueryRootOrganizationFeaturesArgs>>;
  organization_features_aggregate?: Resolver<ResolversTypes['organization_features_aggregate'], ParentType, ContextType, Partial<QueryRootOrganizationFeaturesAggregateArgs>>;
  organization_features_by_pk?: Resolver<Maybe<ResolversTypes['organization_features']>, ParentType, ContextType, RequireFields<QueryRootOrganizationFeaturesByPkArgs, 'organizationId'>>;
  organizations?: Resolver<Array<ResolversTypes['organizations']>, ParentType, ContextType, Partial<QueryRootOrganizationsArgs>>;
  organizations_aggregate?: Resolver<ResolversTypes['organizations_aggregate'], ParentType, ContextType, Partial<QueryRootOrganizationsAggregateArgs>>;
  organizations_by_pk?: Resolver<Maybe<ResolversTypes['organizations']>, ParentType, ContextType, RequireFields<QueryRootOrganizationsByPkArgs, 'id'>>;
  status?: Resolver<Array<ResolversTypes['status']>, ParentType, ContextType, Partial<QueryRootStatusArgs>>;
  status_aggregate?: Resolver<ResolversTypes['status_aggregate'], ParentType, ContextType, Partial<QueryRootStatusAggregateArgs>>;
  status_by_pk?: Resolver<Maybe<ResolversTypes['status']>, ParentType, ContextType, RequireFields<QueryRootStatusByPkArgs, 'name'>>;
  transaction_type?: Resolver<Array<ResolversTypes['transaction_type']>, ParentType, ContextType, Partial<QueryRootTransactionTypeArgs>>;
  transaction_type_aggregate?: Resolver<ResolversTypes['transaction_type_aggregate'], ParentType, ContextType, Partial<QueryRootTransactionTypeAggregateArgs>>;
  transaction_type_by_pk?: Resolver<Maybe<ResolversTypes['transaction_type']>, ParentType, ContextType, RequireFields<QueryRootTransactionTypeByPkArgs, 'type'>>;
  transactions?: Resolver<Array<ResolversTypes['transactions']>, ParentType, ContextType, Partial<QueryRootTransactionsArgs>>;
  transactions_aggregate?: Resolver<ResolversTypes['transactions_aggregate'], ParentType, ContextType, Partial<QueryRootTransactionsAggregateArgs>>;
  transactions_by_pk?: Resolver<Maybe<ResolversTypes['transactions']>, ParentType, ContextType, RequireFields<QueryRootTransactionsByPkArgs, 'id'>>;
  user?: Resolver<Maybe<ResolversTypes['users']>, ParentType, ContextType, RequireFields<QueryRootUserArgs, 'id'>>;
  user_organizations?: Resolver<Array<ResolversTypes['user_organizations']>, ParentType, ContextType, Partial<QueryRootUserOrganizationsArgs>>;
  user_organizations_aggregate?: Resolver<ResolversTypes['user_organizations_aggregate'], ParentType, ContextType, Partial<QueryRootUserOrganizationsAggregateArgs>>;
  user_organizations_by_pk?: Resolver<Maybe<ResolversTypes['user_organizations']>, ParentType, ContextType, RequireFields<QueryRootUserOrganizationsByPkArgs, 'id'>>;
  users?: Resolver<Array<ResolversTypes['users']>, ParentType, ContextType, Partial<QueryRootUsersArgs>>;
  usersAggregate?: Resolver<ResolversTypes['users_aggregate'], ParentType, ContextType, Partial<QueryRootUsersAggregateArgs>>;
  virus?: Resolver<Maybe<ResolversTypes['virus']>, ParentType, ContextType, RequireFields<QueryRootVirusArgs, 'id'>>;
  viruses?: Resolver<Array<ResolversTypes['virus']>, ParentType, ContextType, Partial<QueryRootVirusesArgs>>;
  virusesAggregate?: Resolver<ResolversTypes['virus_aggregate'], ParentType, ContextType, Partial<QueryRootVirusesAggregateArgs>>;
};

export type StatusResolvers<ContextType = any, ParentType extends ResolversParentTypes['status'] = ResolversParentTypes['status']> = {
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type StatusAggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['status_aggregate'] = ResolversParentTypes['status_aggregate']> = {
  aggregate?: Resolver<Maybe<ResolversTypes['status_aggregate_fields']>, ParentType, ContextType>;
  nodes?: Resolver<Array<ResolversTypes['status']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type StatusAggregateFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['status_aggregate_fields'] = ResolversParentTypes['status_aggregate_fields']> = {
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType, Partial<StatusAggregateFieldsCountArgs>>;
  max?: Resolver<Maybe<ResolversTypes['status_max_fields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['status_min_fields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type StatusMaxFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['status_max_fields'] = ResolversParentTypes['status_max_fields']> = {
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type StatusMinFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['status_min_fields'] = ResolversParentTypes['status_min_fields']> = {
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type StatusMutationResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['status_mutation_response'] = ResolversParentTypes['status_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['status']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SubscriptionRootResolvers<ContextType = any, ParentType extends ResolversParentTypes['subscription_root'] = ResolversParentTypes['subscription_root']> = {
  api_keys?: SubscriptionResolver<Array<ResolversTypes['api_keys']>, "api_keys", ParentType, ContextType, Partial<SubscriptionRootApiKeysArgs>>;
  api_keys_aggregate?: SubscriptionResolver<ResolversTypes['api_keys_aggregate'], "api_keys_aggregate", ParentType, ContextType, Partial<SubscriptionRootApiKeysAggregateArgs>>;
  api_keys_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['api_keys']>, "api_keys_by_pk", ParentType, ContextType, RequireFields<SubscriptionRootApiKeysByPkArgs, 'id'>>;
  api_keys_stream?: SubscriptionResolver<Array<ResolversTypes['api_keys']>, "api_keys_stream", ParentType, ContextType, RequireFields<SubscriptionRootApiKeysStreamArgs, 'batch_size' | 'cursor'>>;
  authProvider?: SubscriptionResolver<Maybe<ResolversTypes['authProviders']>, "authProvider", ParentType, ContextType, RequireFields<SubscriptionRootAuthProviderArgs, 'id'>>;
  authProviderRequest?: SubscriptionResolver<Maybe<ResolversTypes['authProviderRequests']>, "authProviderRequest", ParentType, ContextType, RequireFields<SubscriptionRootAuthProviderRequestArgs, 'id'>>;
  authProviderRequests?: SubscriptionResolver<Array<ResolversTypes['authProviderRequests']>, "authProviderRequests", ParentType, ContextType, Partial<SubscriptionRootAuthProviderRequestsArgs>>;
  authProviderRequestsAggregate?: SubscriptionResolver<ResolversTypes['authProviderRequests_aggregate'], "authProviderRequestsAggregate", ParentType, ContextType, Partial<SubscriptionRootAuthProviderRequestsAggregateArgs>>;
  authProviderRequests_stream?: SubscriptionResolver<Array<ResolversTypes['authProviderRequests']>, "authProviderRequests_stream", ParentType, ContextType, RequireFields<SubscriptionRootAuthProviderRequestsStreamArgs, 'batch_size' | 'cursor'>>;
  authProviders?: SubscriptionResolver<Array<ResolversTypes['authProviders']>, "authProviders", ParentType, ContextType, Partial<SubscriptionRootAuthProvidersArgs>>;
  authProvidersAggregate?: SubscriptionResolver<ResolversTypes['authProviders_aggregate'], "authProvidersAggregate", ParentType, ContextType, Partial<SubscriptionRootAuthProvidersAggregateArgs>>;
  authProviders_stream?: SubscriptionResolver<Array<ResolversTypes['authProviders']>, "authProviders_stream", ParentType, ContextType, RequireFields<SubscriptionRootAuthProvidersStreamArgs, 'batch_size' | 'cursor'>>;
  authRefreshToken?: SubscriptionResolver<Maybe<ResolversTypes['authRefreshTokens']>, "authRefreshToken", ParentType, ContextType, RequireFields<SubscriptionRootAuthRefreshTokenArgs, 'id'>>;
  authRefreshTokenType?: SubscriptionResolver<Maybe<ResolversTypes['authRefreshTokenTypes']>, "authRefreshTokenType", ParentType, ContextType, RequireFields<SubscriptionRootAuthRefreshTokenTypeArgs, 'value'>>;
  authRefreshTokenTypes?: SubscriptionResolver<Array<ResolversTypes['authRefreshTokenTypes']>, "authRefreshTokenTypes", ParentType, ContextType, Partial<SubscriptionRootAuthRefreshTokenTypesArgs>>;
  authRefreshTokenTypesAggregate?: SubscriptionResolver<ResolversTypes['authRefreshTokenTypes_aggregate'], "authRefreshTokenTypesAggregate", ParentType, ContextType, Partial<SubscriptionRootAuthRefreshTokenTypesAggregateArgs>>;
  authRefreshTokenTypes_stream?: SubscriptionResolver<Array<ResolversTypes['authRefreshTokenTypes']>, "authRefreshTokenTypes_stream", ParentType, ContextType, RequireFields<SubscriptionRootAuthRefreshTokenTypesStreamArgs, 'batch_size' | 'cursor'>>;
  authRefreshTokens?: SubscriptionResolver<Array<ResolversTypes['authRefreshTokens']>, "authRefreshTokens", ParentType, ContextType, Partial<SubscriptionRootAuthRefreshTokensArgs>>;
  authRefreshTokensAggregate?: SubscriptionResolver<ResolversTypes['authRefreshTokens_aggregate'], "authRefreshTokensAggregate", ParentType, ContextType, Partial<SubscriptionRootAuthRefreshTokensAggregateArgs>>;
  authRefreshTokens_stream?: SubscriptionResolver<Array<ResolversTypes['authRefreshTokens']>, "authRefreshTokens_stream", ParentType, ContextType, RequireFields<SubscriptionRootAuthRefreshTokensStreamArgs, 'batch_size' | 'cursor'>>;
  authRole?: SubscriptionResolver<Maybe<ResolversTypes['authRoles']>, "authRole", ParentType, ContextType, RequireFields<SubscriptionRootAuthRoleArgs, 'role'>>;
  authRoles?: SubscriptionResolver<Array<ResolversTypes['authRoles']>, "authRoles", ParentType, ContextType, Partial<SubscriptionRootAuthRolesArgs>>;
  authRolesAggregate?: SubscriptionResolver<ResolversTypes['authRoles_aggregate'], "authRolesAggregate", ParentType, ContextType, Partial<SubscriptionRootAuthRolesAggregateArgs>>;
  authRoles_stream?: SubscriptionResolver<Array<ResolversTypes['authRoles']>, "authRoles_stream", ParentType, ContextType, RequireFields<SubscriptionRootAuthRolesStreamArgs, 'batch_size' | 'cursor'>>;
  authUserProvider?: SubscriptionResolver<Maybe<ResolversTypes['authUserProviders']>, "authUserProvider", ParentType, ContextType, RequireFields<SubscriptionRootAuthUserProviderArgs, 'id'>>;
  authUserProviders?: SubscriptionResolver<Array<ResolversTypes['authUserProviders']>, "authUserProviders", ParentType, ContextType, Partial<SubscriptionRootAuthUserProvidersArgs>>;
  authUserProvidersAggregate?: SubscriptionResolver<ResolversTypes['authUserProviders_aggregate'], "authUserProvidersAggregate", ParentType, ContextType, Partial<SubscriptionRootAuthUserProvidersAggregateArgs>>;
  authUserProviders_stream?: SubscriptionResolver<Array<ResolversTypes['authUserProviders']>, "authUserProviders_stream", ParentType, ContextType, RequireFields<SubscriptionRootAuthUserProvidersStreamArgs, 'batch_size' | 'cursor'>>;
  authUserRole?: SubscriptionResolver<Maybe<ResolversTypes['authUserRoles']>, "authUserRole", ParentType, ContextType, RequireFields<SubscriptionRootAuthUserRoleArgs, 'id'>>;
  authUserRoles?: SubscriptionResolver<Array<ResolversTypes['authUserRoles']>, "authUserRoles", ParentType, ContextType, Partial<SubscriptionRootAuthUserRolesArgs>>;
  authUserRolesAggregate?: SubscriptionResolver<ResolversTypes['authUserRoles_aggregate'], "authUserRolesAggregate", ParentType, ContextType, Partial<SubscriptionRootAuthUserRolesAggregateArgs>>;
  authUserRoles_stream?: SubscriptionResolver<Array<ResolversTypes['authUserRoles']>, "authUserRoles_stream", ParentType, ContextType, RequireFields<SubscriptionRootAuthUserRolesStreamArgs, 'batch_size' | 'cursor'>>;
  authUserSecurityKey?: SubscriptionResolver<Maybe<ResolversTypes['authUserSecurityKeys']>, "authUserSecurityKey", ParentType, ContextType, RequireFields<SubscriptionRootAuthUserSecurityKeyArgs, 'id'>>;
  authUserSecurityKeys?: SubscriptionResolver<Array<ResolversTypes['authUserSecurityKeys']>, "authUserSecurityKeys", ParentType, ContextType, Partial<SubscriptionRootAuthUserSecurityKeysArgs>>;
  authUserSecurityKeysAggregate?: SubscriptionResolver<ResolversTypes['authUserSecurityKeys_aggregate'], "authUserSecurityKeysAggregate", ParentType, ContextType, Partial<SubscriptionRootAuthUserSecurityKeysAggregateArgs>>;
  authUserSecurityKeys_stream?: SubscriptionResolver<Array<ResolversTypes['authUserSecurityKeys']>, "authUserSecurityKeys_stream", ParentType, ContextType, RequireFields<SubscriptionRootAuthUserSecurityKeysStreamArgs, 'batch_size' | 'cursor'>>;
  bucket?: SubscriptionResolver<Maybe<ResolversTypes['buckets']>, "bucket", ParentType, ContextType, RequireFields<SubscriptionRootBucketArgs, 'id'>>;
  buckets?: SubscriptionResolver<Array<ResolversTypes['buckets']>, "buckets", ParentType, ContextType, Partial<SubscriptionRootBucketsArgs>>;
  bucketsAggregate?: SubscriptionResolver<ResolversTypes['buckets_aggregate'], "bucketsAggregate", ParentType, ContextType, Partial<SubscriptionRootBucketsAggregateArgs>>;
  buckets_stream?: SubscriptionResolver<Array<ResolversTypes['buckets']>, "buckets_stream", ParentType, ContextType, RequireFields<SubscriptionRootBucketsStreamArgs, 'batch_size' | 'cursor'>>;
  file?: SubscriptionResolver<Maybe<ResolversTypes['files']>, "file", ParentType, ContextType, RequireFields<SubscriptionRootFileArgs, 'id'>>;
  files?: SubscriptionResolver<Array<ResolversTypes['files']>, "files", ParentType, ContextType, Partial<SubscriptionRootFilesArgs>>;
  filesAggregate?: SubscriptionResolver<ResolversTypes['files_aggregate'], "filesAggregate", ParentType, ContextType, Partial<SubscriptionRootFilesAggregateArgs>>;
  files_stream?: SubscriptionResolver<Array<ResolversTypes['files']>, "files_stream", ParentType, ContextType, RequireFields<SubscriptionRootFilesStreamArgs, 'batch_size' | 'cursor'>>;
  generation_presets?: SubscriptionResolver<Array<ResolversTypes['generation_presets']>, "generation_presets", ParentType, ContextType, Partial<SubscriptionRootGenerationPresetsArgs>>;
  generation_presets_aggregate?: SubscriptionResolver<ResolversTypes['generation_presets_aggregate'], "generation_presets_aggregate", ParentType, ContextType, Partial<SubscriptionRootGenerationPresetsAggregateArgs>>;
  generation_presets_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['generation_presets']>, "generation_presets_by_pk", ParentType, ContextType, RequireFields<SubscriptionRootGenerationPresetsByPkArgs, 'id'>>;
  generation_presets_stream?: SubscriptionResolver<Array<ResolversTypes['generation_presets']>, "generation_presets_stream", ParentType, ContextType, RequireFields<SubscriptionRootGenerationPresetsStreamArgs, 'batch_size' | 'cursor'>>;
  generations?: SubscriptionResolver<Array<ResolversTypes['generations']>, "generations", ParentType, ContextType, Partial<SubscriptionRootGenerationsArgs>>;
  generations_aggregate?: SubscriptionResolver<ResolversTypes['generations_aggregate'], "generations_aggregate", ParentType, ContextType, Partial<SubscriptionRootGenerationsAggregateArgs>>;
  generations_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['generations']>, "generations_by_pk", ParentType, ContextType, RequireFields<SubscriptionRootGenerationsByPkArgs, 'id'>>;
  generations_stream?: SubscriptionResolver<Array<ResolversTypes['generations']>, "generations_stream", ParentType, ContextType, RequireFields<SubscriptionRootGenerationsStreamArgs, 'batch_size' | 'cursor'>>;
  model_input_type?: SubscriptionResolver<Array<ResolversTypes['model_input_type']>, "model_input_type", ParentType, ContextType, Partial<SubscriptionRootModelInputTypeArgs>>;
  model_input_type_aggregate?: SubscriptionResolver<ResolversTypes['model_input_type_aggregate'], "model_input_type_aggregate", ParentType, ContextType, Partial<SubscriptionRootModelInputTypeAggregateArgs>>;
  model_input_type_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['model_input_type']>, "model_input_type_by_pk", ParentType, ContextType, RequireFields<SubscriptionRootModelInputTypeByPkArgs, 'type'>>;
  model_input_type_stream?: SubscriptionResolver<Array<ResolversTypes['model_input_type']>, "model_input_type_stream", ParentType, ContextType, RequireFields<SubscriptionRootModelInputTypeStreamArgs, 'batch_size' | 'cursor'>>;
  model_type?: SubscriptionResolver<Array<ResolversTypes['model_type']>, "model_type", ParentType, ContextType, Partial<SubscriptionRootModelTypeArgs>>;
  model_type_aggregate?: SubscriptionResolver<ResolversTypes['model_type_aggregate'], "model_type_aggregate", ParentType, ContextType, Partial<SubscriptionRootModelTypeAggregateArgs>>;
  model_type_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['model_type']>, "model_type_by_pk", ParentType, ContextType, RequireFields<SubscriptionRootModelTypeByPkArgs, 'type'>>;
  model_type_stream?: SubscriptionResolver<Array<ResolversTypes['model_type']>, "model_type_stream", ParentType, ContextType, RequireFields<SubscriptionRootModelTypeStreamArgs, 'batch_size' | 'cursor'>>;
  models?: SubscriptionResolver<Array<ResolversTypes['models']>, "models", ParentType, ContextType, Partial<SubscriptionRootModelsArgs>>;
  models_aggregate?: SubscriptionResolver<ResolversTypes['models_aggregate'], "models_aggregate", ParentType, ContextType, Partial<SubscriptionRootModelsAggregateArgs>>;
  models_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['models']>, "models_by_pk", ParentType, ContextType, RequireFields<SubscriptionRootModelsByPkArgs, 'id'>>;
  models_stream?: SubscriptionResolver<Array<ResolversTypes['models']>, "models_stream", ParentType, ContextType, RequireFields<SubscriptionRootModelsStreamArgs, 'batch_size' | 'cursor'>>;
  organization_features?: SubscriptionResolver<Array<ResolversTypes['organization_features']>, "organization_features", ParentType, ContextType, Partial<SubscriptionRootOrganizationFeaturesArgs>>;
  organization_features_aggregate?: SubscriptionResolver<ResolversTypes['organization_features_aggregate'], "organization_features_aggregate", ParentType, ContextType, Partial<SubscriptionRootOrganizationFeaturesAggregateArgs>>;
  organization_features_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['organization_features']>, "organization_features_by_pk", ParentType, ContextType, RequireFields<SubscriptionRootOrganizationFeaturesByPkArgs, 'organizationId'>>;
  organization_features_stream?: SubscriptionResolver<Array<ResolversTypes['organization_features']>, "organization_features_stream", ParentType, ContextType, RequireFields<SubscriptionRootOrganizationFeaturesStreamArgs, 'batch_size' | 'cursor'>>;
  organizations?: SubscriptionResolver<Array<ResolversTypes['organizations']>, "organizations", ParentType, ContextType, Partial<SubscriptionRootOrganizationsArgs>>;
  organizations_aggregate?: SubscriptionResolver<ResolversTypes['organizations_aggregate'], "organizations_aggregate", ParentType, ContextType, Partial<SubscriptionRootOrganizationsAggregateArgs>>;
  organizations_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['organizations']>, "organizations_by_pk", ParentType, ContextType, RequireFields<SubscriptionRootOrganizationsByPkArgs, 'id'>>;
  organizations_stream?: SubscriptionResolver<Array<ResolversTypes['organizations']>, "organizations_stream", ParentType, ContextType, RequireFields<SubscriptionRootOrganizationsStreamArgs, 'batch_size' | 'cursor'>>;
  status?: SubscriptionResolver<Array<ResolversTypes['status']>, "status", ParentType, ContextType, Partial<SubscriptionRootStatusArgs>>;
  status_aggregate?: SubscriptionResolver<ResolversTypes['status_aggregate'], "status_aggregate", ParentType, ContextType, Partial<SubscriptionRootStatusAggregateArgs>>;
  status_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['status']>, "status_by_pk", ParentType, ContextType, RequireFields<SubscriptionRootStatusByPkArgs, 'name'>>;
  status_stream?: SubscriptionResolver<Array<ResolversTypes['status']>, "status_stream", ParentType, ContextType, RequireFields<SubscriptionRootStatusStreamArgs, 'batch_size' | 'cursor'>>;
  transaction_type?: SubscriptionResolver<Array<ResolversTypes['transaction_type']>, "transaction_type", ParentType, ContextType, Partial<SubscriptionRootTransactionTypeArgs>>;
  transaction_type_aggregate?: SubscriptionResolver<ResolversTypes['transaction_type_aggregate'], "transaction_type_aggregate", ParentType, ContextType, Partial<SubscriptionRootTransactionTypeAggregateArgs>>;
  transaction_type_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['transaction_type']>, "transaction_type_by_pk", ParentType, ContextType, RequireFields<SubscriptionRootTransactionTypeByPkArgs, 'type'>>;
  transaction_type_stream?: SubscriptionResolver<Array<ResolversTypes['transaction_type']>, "transaction_type_stream", ParentType, ContextType, RequireFields<SubscriptionRootTransactionTypeStreamArgs, 'batch_size' | 'cursor'>>;
  transactions?: SubscriptionResolver<Array<ResolversTypes['transactions']>, "transactions", ParentType, ContextType, Partial<SubscriptionRootTransactionsArgs>>;
  transactions_aggregate?: SubscriptionResolver<ResolversTypes['transactions_aggregate'], "transactions_aggregate", ParentType, ContextType, Partial<SubscriptionRootTransactionsAggregateArgs>>;
  transactions_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['transactions']>, "transactions_by_pk", ParentType, ContextType, RequireFields<SubscriptionRootTransactionsByPkArgs, 'id'>>;
  transactions_stream?: SubscriptionResolver<Array<ResolversTypes['transactions']>, "transactions_stream", ParentType, ContextType, RequireFields<SubscriptionRootTransactionsStreamArgs, 'batch_size' | 'cursor'>>;
  user?: SubscriptionResolver<Maybe<ResolversTypes['users']>, "user", ParentType, ContextType, RequireFields<SubscriptionRootUserArgs, 'id'>>;
  user_organizations?: SubscriptionResolver<Array<ResolversTypes['user_organizations']>, "user_organizations", ParentType, ContextType, Partial<SubscriptionRootUserOrganizationsArgs>>;
  user_organizations_aggregate?: SubscriptionResolver<ResolversTypes['user_organizations_aggregate'], "user_organizations_aggregate", ParentType, ContextType, Partial<SubscriptionRootUserOrganizationsAggregateArgs>>;
  user_organizations_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['user_organizations']>, "user_organizations_by_pk", ParentType, ContextType, RequireFields<SubscriptionRootUserOrganizationsByPkArgs, 'id'>>;
  user_organizations_stream?: SubscriptionResolver<Array<ResolversTypes['user_organizations']>, "user_organizations_stream", ParentType, ContextType, RequireFields<SubscriptionRootUserOrganizationsStreamArgs, 'batch_size' | 'cursor'>>;
  users?: SubscriptionResolver<Array<ResolversTypes['users']>, "users", ParentType, ContextType, Partial<SubscriptionRootUsersArgs>>;
  usersAggregate?: SubscriptionResolver<ResolversTypes['users_aggregate'], "usersAggregate", ParentType, ContextType, Partial<SubscriptionRootUsersAggregateArgs>>;
  users_stream?: SubscriptionResolver<Array<ResolversTypes['users']>, "users_stream", ParentType, ContextType, RequireFields<SubscriptionRootUsersStreamArgs, 'batch_size' | 'cursor'>>;
  virus?: SubscriptionResolver<Maybe<ResolversTypes['virus']>, "virus", ParentType, ContextType, RequireFields<SubscriptionRootVirusArgs, 'id'>>;
  virus_stream?: SubscriptionResolver<Array<ResolversTypes['virus']>, "virus_stream", ParentType, ContextType, RequireFields<SubscriptionRootVirusStreamArgs, 'batch_size' | 'cursor'>>;
  viruses?: SubscriptionResolver<Array<ResolversTypes['virus']>, "viruses", ParentType, ContextType, Partial<SubscriptionRootVirusesArgs>>;
  virusesAggregate?: SubscriptionResolver<ResolversTypes['virus_aggregate'], "virusesAggregate", ParentType, ContextType, Partial<SubscriptionRootVirusesAggregateArgs>>;
};

export interface TimestamptzScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['timestamptz'], any> {
  name: 'timestamptz';
}

export type TransactionTypeResolvers<ContextType = any, ParentType extends ResolversParentTypes['transaction_type'] = ResolversParentTypes['transaction_type']> = {
  type?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TransactionTypeAggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['transaction_type_aggregate'] = ResolversParentTypes['transaction_type_aggregate']> = {
  aggregate?: Resolver<Maybe<ResolversTypes['transaction_type_aggregate_fields']>, ParentType, ContextType>;
  nodes?: Resolver<Array<ResolversTypes['transaction_type']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TransactionTypeAggregateFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['transaction_type_aggregate_fields'] = ResolversParentTypes['transaction_type_aggregate_fields']> = {
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType, Partial<TransactionTypeAggregateFieldsCountArgs>>;
  max?: Resolver<Maybe<ResolversTypes['transaction_type_max_fields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['transaction_type_min_fields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TransactionTypeMaxFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['transaction_type_max_fields'] = ResolversParentTypes['transaction_type_max_fields']> = {
  type?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TransactionTypeMinFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['transaction_type_min_fields'] = ResolversParentTypes['transaction_type_min_fields']> = {
  type?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TransactionTypeMutationResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['transaction_type_mutation_response'] = ResolversParentTypes['transaction_type_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['transaction_type']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TransactionsResolvers<ContextType = any, ParentType extends ResolversParentTypes['transactions'] = ResolversParentTypes['transactions']> = {
  amount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  createdAt?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  framesGenerated?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  generation?: Resolver<Maybe<ResolversTypes['generations']>, ParentType, ContextType>;
  generationId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  organization?: Resolver<ResolversTypes['organizations'], ParentType, ContextType>;
  organizationId?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  status?: Resolver<ResolversTypes['status_enum'], ParentType, ContextType>;
  stripeTransactionId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  type?: Resolver<ResolversTypes['transaction_type_enum'], ParentType, ContextType>;
  updatedAt?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TransactionsAggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['transactions_aggregate'] = ResolversParentTypes['transactions_aggregate']> = {
  aggregate?: Resolver<Maybe<ResolversTypes['transactions_aggregate_fields']>, ParentType, ContextType>;
  nodes?: Resolver<Array<ResolversTypes['transactions']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TransactionsAggregateFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['transactions_aggregate_fields'] = ResolversParentTypes['transactions_aggregate_fields']> = {
  avg?: Resolver<Maybe<ResolversTypes['transactions_avg_fields']>, ParentType, ContextType>;
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType, Partial<TransactionsAggregateFieldsCountArgs>>;
  max?: Resolver<Maybe<ResolversTypes['transactions_max_fields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['transactions_min_fields']>, ParentType, ContextType>;
  stddev?: Resolver<Maybe<ResolversTypes['transactions_stddev_fields']>, ParentType, ContextType>;
  stddev_pop?: Resolver<Maybe<ResolversTypes['transactions_stddev_pop_fields']>, ParentType, ContextType>;
  stddev_samp?: Resolver<Maybe<ResolversTypes['transactions_stddev_samp_fields']>, ParentType, ContextType>;
  sum?: Resolver<Maybe<ResolversTypes['transactions_sum_fields']>, ParentType, ContextType>;
  var_pop?: Resolver<Maybe<ResolversTypes['transactions_var_pop_fields']>, ParentType, ContextType>;
  var_samp?: Resolver<Maybe<ResolversTypes['transactions_var_samp_fields']>, ParentType, ContextType>;
  variance?: Resolver<Maybe<ResolversTypes['transactions_variance_fields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TransactionsAvgFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['transactions_avg_fields'] = ResolversParentTypes['transactions_avg_fields']> = {
  amount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  framesGenerated?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TransactionsMaxFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['transactions_max_fields'] = ResolversParentTypes['transactions_max_fields']> = {
  amount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  createdAt?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  framesGenerated?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  generationId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  organizationId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  stripeTransactionId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TransactionsMinFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['transactions_min_fields'] = ResolversParentTypes['transactions_min_fields']> = {
  amount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  createdAt?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  framesGenerated?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  generationId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  organizationId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  stripeTransactionId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TransactionsMutationResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['transactions_mutation_response'] = ResolversParentTypes['transactions_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['transactions']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TransactionsStddevFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['transactions_stddev_fields'] = ResolversParentTypes['transactions_stddev_fields']> = {
  amount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  framesGenerated?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TransactionsStddevPopFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['transactions_stddev_pop_fields'] = ResolversParentTypes['transactions_stddev_pop_fields']> = {
  amount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  framesGenerated?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TransactionsStddevSampFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['transactions_stddev_samp_fields'] = ResolversParentTypes['transactions_stddev_samp_fields']> = {
  amount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  framesGenerated?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TransactionsSumFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['transactions_sum_fields'] = ResolversParentTypes['transactions_sum_fields']> = {
  amount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  framesGenerated?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TransactionsVarPopFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['transactions_var_pop_fields'] = ResolversParentTypes['transactions_var_pop_fields']> = {
  amount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  framesGenerated?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TransactionsVarSampFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['transactions_var_samp_fields'] = ResolversParentTypes['transactions_var_samp_fields']> = {
  amount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  framesGenerated?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TransactionsVarianceFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['transactions_variance_fields'] = ResolversParentTypes['transactions_variance_fields']> = {
  amount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  framesGenerated?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UserOrganizationsResolvers<ContextType = any, ParentType extends ResolversParentTypes['user_organizations'] = ResolversParentTypes['user_organizations']> = {
  createdAt?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  organization?: Resolver<Maybe<ResolversTypes['organizations']>, ParentType, ContextType>;
  organizationId?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  updatedAt?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  user?: Resolver<Maybe<ResolversTypes['users']>, ParentType, ContextType>;
  userId?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UserOrganizationsAggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['user_organizations_aggregate'] = ResolversParentTypes['user_organizations_aggregate']> = {
  aggregate?: Resolver<Maybe<ResolversTypes['user_organizations_aggregate_fields']>, ParentType, ContextType>;
  nodes?: Resolver<Array<ResolversTypes['user_organizations']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UserOrganizationsAggregateFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['user_organizations_aggregate_fields'] = ResolversParentTypes['user_organizations_aggregate_fields']> = {
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType, Partial<UserOrganizationsAggregateFieldsCountArgs>>;
  max?: Resolver<Maybe<ResolversTypes['user_organizations_max_fields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['user_organizations_min_fields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UserOrganizationsMaxFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['user_organizations_max_fields'] = ResolversParentTypes['user_organizations_max_fields']> = {
  createdAt?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  organizationId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  userId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UserOrganizationsMinFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['user_organizations_min_fields'] = ResolversParentTypes['user_organizations_min_fields']> = {
  createdAt?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  organizationId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  userId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UserOrganizationsMutationResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['user_organizations_mutation_response'] = ResolversParentTypes['user_organizations_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['user_organizations']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UsersResolvers<ContextType = any, ParentType extends ResolversParentTypes['users'] = ResolversParentTypes['users']> = {
  activeMfaType?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  avatarUrl?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  createdAt?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  currentChallenge?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  defaultRole?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  defaultRoleByRole?: Resolver<ResolversTypes['authRoles'], ParentType, ContextType>;
  disabled?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  displayName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  email?: Resolver<Maybe<ResolversTypes['citext']>, ParentType, ContextType>;
  emailVerified?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  isAnonymous?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  lastSeen?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  locale?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  metadata?: Resolver<Maybe<ResolversTypes['jsonb']>, ParentType, ContextType, Partial<UsersMetadataArgs>>;
  newEmail?: Resolver<Maybe<ResolversTypes['citext']>, ParentType, ContextType>;
  otpHash?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  otpHashExpiresAt?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  otpMethodLastUsed?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  passwordHash?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  phoneNumber?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  phoneNumberVerified?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  refreshTokens?: Resolver<Array<ResolversTypes['authRefreshTokens']>, ParentType, ContextType, Partial<UsersRefreshTokensArgs>>;
  refreshTokens_aggregate?: Resolver<ResolversTypes['authRefreshTokens_aggregate'], ParentType, ContextType, Partial<UsersRefreshTokensAggregateArgs>>;
  roles?: Resolver<Array<ResolversTypes['authUserRoles']>, ParentType, ContextType, Partial<UsersRolesArgs>>;
  roles_aggregate?: Resolver<ResolversTypes['authUserRoles_aggregate'], ParentType, ContextType, Partial<UsersRolesAggregateArgs>>;
  securityKeys?: Resolver<Array<ResolversTypes['authUserSecurityKeys']>, ParentType, ContextType, Partial<UsersSecurityKeysArgs>>;
  securityKeys_aggregate?: Resolver<ResolversTypes['authUserSecurityKeys_aggregate'], ParentType, ContextType, Partial<UsersSecurityKeysAggregateArgs>>;
  ticket?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ticketExpiresAt?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  totpSecret?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updatedAt?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  userProviders?: Resolver<Array<ResolversTypes['authUserProviders']>, ParentType, ContextType, Partial<UsersUserProvidersArgs>>;
  userProviders_aggregate?: Resolver<ResolversTypes['authUserProviders_aggregate'], ParentType, ContextType, Partial<UsersUserProvidersAggregateArgs>>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UsersAggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['users_aggregate'] = ResolversParentTypes['users_aggregate']> = {
  aggregate?: Resolver<Maybe<ResolversTypes['users_aggregate_fields']>, ParentType, ContextType>;
  nodes?: Resolver<Array<ResolversTypes['users']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UsersAggregateFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['users_aggregate_fields'] = ResolversParentTypes['users_aggregate_fields']> = {
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType, Partial<UsersAggregateFieldsCountArgs>>;
  max?: Resolver<Maybe<ResolversTypes['users_max_fields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['users_min_fields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UsersMaxFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['users_max_fields'] = ResolversParentTypes['users_max_fields']> = {
  activeMfaType?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  avatarUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  createdAt?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  currentChallenge?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  defaultRole?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  displayName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  email?: Resolver<Maybe<ResolversTypes['citext']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  lastSeen?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  locale?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  newEmail?: Resolver<Maybe<ResolversTypes['citext']>, ParentType, ContextType>;
  otpHash?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  otpHashExpiresAt?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  otpMethodLastUsed?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  passwordHash?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  phoneNumber?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ticket?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ticketExpiresAt?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  totpSecret?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UsersMinFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['users_min_fields'] = ResolversParentTypes['users_min_fields']> = {
  activeMfaType?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  avatarUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  createdAt?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  currentChallenge?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  defaultRole?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  displayName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  email?: Resolver<Maybe<ResolversTypes['citext']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  lastSeen?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  locale?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  newEmail?: Resolver<Maybe<ResolversTypes['citext']>, ParentType, ContextType>;
  otpHash?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  otpHashExpiresAt?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  otpMethodLastUsed?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  passwordHash?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  phoneNumber?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ticket?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ticketExpiresAt?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  totpSecret?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UsersMutationResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['users_mutation_response'] = ResolversParentTypes['users_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['users']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export interface UuidScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['uuid'], any> {
  name: 'uuid';
}

export type VirusResolvers<ContextType = any, ParentType extends ResolversParentTypes['virus'] = ResolversParentTypes['virus']> = {
  createdAt?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  file?: Resolver<ResolversTypes['files'], ParentType, ContextType>;
  fileId?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  filename?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  updatedAt?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  userSession?: Resolver<ResolversTypes['jsonb'], ParentType, ContextType, Partial<VirusUserSessionArgs>>;
  virus?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type VirusAggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['virus_aggregate'] = ResolversParentTypes['virus_aggregate']> = {
  aggregate?: Resolver<Maybe<ResolversTypes['virus_aggregate_fields']>, ParentType, ContextType>;
  nodes?: Resolver<Array<ResolversTypes['virus']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type VirusAggregateFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['virus_aggregate_fields'] = ResolversParentTypes['virus_aggregate_fields']> = {
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType, Partial<VirusAggregateFieldsCountArgs>>;
  max?: Resolver<Maybe<ResolversTypes['virus_max_fields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['virus_min_fields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type VirusMaxFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['virus_max_fields'] = ResolversParentTypes['virus_max_fields']> = {
  createdAt?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  fileId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  filename?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  virus?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type VirusMinFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['virus_min_fields'] = ResolversParentTypes['virus_min_fields']> = {
  createdAt?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  fileId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  filename?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  virus?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type VirusMutationResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['virus_mutation_response'] = ResolversParentTypes['virus_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['virus']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Resolvers<ContextType = any> = {
  api_keys?: ApiKeysResolvers<ContextType>;
  api_keys_aggregate?: ApiKeysAggregateResolvers<ContextType>;
  api_keys_aggregate_fields?: ApiKeysAggregateFieldsResolvers<ContextType>;
  api_keys_max_fields?: ApiKeysMaxFieldsResolvers<ContextType>;
  api_keys_min_fields?: ApiKeysMinFieldsResolvers<ContextType>;
  api_keys_mutation_response?: ApiKeysMutationResponseResolvers<ContextType>;
  authProviderRequests?: AuthProviderRequestsResolvers<ContextType>;
  authProviderRequests_aggregate?: AuthProviderRequestsAggregateResolvers<ContextType>;
  authProviderRequests_aggregate_fields?: AuthProviderRequestsAggregateFieldsResolvers<ContextType>;
  authProviderRequests_max_fields?: AuthProviderRequestsMaxFieldsResolvers<ContextType>;
  authProviderRequests_min_fields?: AuthProviderRequestsMinFieldsResolvers<ContextType>;
  authProviderRequests_mutation_response?: AuthProviderRequestsMutationResponseResolvers<ContextType>;
  authProviders?: AuthProvidersResolvers<ContextType>;
  authProviders_aggregate?: AuthProvidersAggregateResolvers<ContextType>;
  authProviders_aggregate_fields?: AuthProvidersAggregateFieldsResolvers<ContextType>;
  authProviders_max_fields?: AuthProvidersMaxFieldsResolvers<ContextType>;
  authProviders_min_fields?: AuthProvidersMinFieldsResolvers<ContextType>;
  authProviders_mutation_response?: AuthProvidersMutationResponseResolvers<ContextType>;
  authRefreshTokenTypes?: AuthRefreshTokenTypesResolvers<ContextType>;
  authRefreshTokenTypes_aggregate?: AuthRefreshTokenTypesAggregateResolvers<ContextType>;
  authRefreshTokenTypes_aggregate_fields?: AuthRefreshTokenTypesAggregateFieldsResolvers<ContextType>;
  authRefreshTokenTypes_max_fields?: AuthRefreshTokenTypesMaxFieldsResolvers<ContextType>;
  authRefreshTokenTypes_min_fields?: AuthRefreshTokenTypesMinFieldsResolvers<ContextType>;
  authRefreshTokenTypes_mutation_response?: AuthRefreshTokenTypesMutationResponseResolvers<ContextType>;
  authRefreshTokens?: AuthRefreshTokensResolvers<ContextType>;
  authRefreshTokens_aggregate?: AuthRefreshTokensAggregateResolvers<ContextType>;
  authRefreshTokens_aggregate_fields?: AuthRefreshTokensAggregateFieldsResolvers<ContextType>;
  authRefreshTokens_max_fields?: AuthRefreshTokensMaxFieldsResolvers<ContextType>;
  authRefreshTokens_min_fields?: AuthRefreshTokensMinFieldsResolvers<ContextType>;
  authRefreshTokens_mutation_response?: AuthRefreshTokensMutationResponseResolvers<ContextType>;
  authRoles?: AuthRolesResolvers<ContextType>;
  authRoles_aggregate?: AuthRolesAggregateResolvers<ContextType>;
  authRoles_aggregate_fields?: AuthRolesAggregateFieldsResolvers<ContextType>;
  authRoles_max_fields?: AuthRolesMaxFieldsResolvers<ContextType>;
  authRoles_min_fields?: AuthRolesMinFieldsResolvers<ContextType>;
  authRoles_mutation_response?: AuthRolesMutationResponseResolvers<ContextType>;
  authUserProviders?: AuthUserProvidersResolvers<ContextType>;
  authUserProviders_aggregate?: AuthUserProvidersAggregateResolvers<ContextType>;
  authUserProviders_aggregate_fields?: AuthUserProvidersAggregateFieldsResolvers<ContextType>;
  authUserProviders_max_fields?: AuthUserProvidersMaxFieldsResolvers<ContextType>;
  authUserProviders_min_fields?: AuthUserProvidersMinFieldsResolvers<ContextType>;
  authUserProviders_mutation_response?: AuthUserProvidersMutationResponseResolvers<ContextType>;
  authUserRoles?: AuthUserRolesResolvers<ContextType>;
  authUserRoles_aggregate?: AuthUserRolesAggregateResolvers<ContextType>;
  authUserRoles_aggregate_fields?: AuthUserRolesAggregateFieldsResolvers<ContextType>;
  authUserRoles_max_fields?: AuthUserRolesMaxFieldsResolvers<ContextType>;
  authUserRoles_min_fields?: AuthUserRolesMinFieldsResolvers<ContextType>;
  authUserRoles_mutation_response?: AuthUserRolesMutationResponseResolvers<ContextType>;
  authUserSecurityKeys?: AuthUserSecurityKeysResolvers<ContextType>;
  authUserSecurityKeys_aggregate?: AuthUserSecurityKeysAggregateResolvers<ContextType>;
  authUserSecurityKeys_aggregate_fields?: AuthUserSecurityKeysAggregateFieldsResolvers<ContextType>;
  authUserSecurityKeys_avg_fields?: AuthUserSecurityKeysAvgFieldsResolvers<ContextType>;
  authUserSecurityKeys_max_fields?: AuthUserSecurityKeysMaxFieldsResolvers<ContextType>;
  authUserSecurityKeys_min_fields?: AuthUserSecurityKeysMinFieldsResolvers<ContextType>;
  authUserSecurityKeys_mutation_response?: AuthUserSecurityKeysMutationResponseResolvers<ContextType>;
  authUserSecurityKeys_stddev_fields?: AuthUserSecurityKeysStddevFieldsResolvers<ContextType>;
  authUserSecurityKeys_stddev_pop_fields?: AuthUserSecurityKeysStddevPopFieldsResolvers<ContextType>;
  authUserSecurityKeys_stddev_samp_fields?: AuthUserSecurityKeysStddevSampFieldsResolvers<ContextType>;
  authUserSecurityKeys_sum_fields?: AuthUserSecurityKeysSumFieldsResolvers<ContextType>;
  authUserSecurityKeys_var_pop_fields?: AuthUserSecurityKeysVarPopFieldsResolvers<ContextType>;
  authUserSecurityKeys_var_samp_fields?: AuthUserSecurityKeysVarSampFieldsResolvers<ContextType>;
  authUserSecurityKeys_variance_fields?: AuthUserSecurityKeysVarianceFieldsResolvers<ContextType>;
  bigint?: GraphQLScalarType;
  buckets?: BucketsResolvers<ContextType>;
  buckets_aggregate?: BucketsAggregateResolvers<ContextType>;
  buckets_aggregate_fields?: BucketsAggregateFieldsResolvers<ContextType>;
  buckets_avg_fields?: BucketsAvgFieldsResolvers<ContextType>;
  buckets_max_fields?: BucketsMaxFieldsResolvers<ContextType>;
  buckets_min_fields?: BucketsMinFieldsResolvers<ContextType>;
  buckets_mutation_response?: BucketsMutationResponseResolvers<ContextType>;
  buckets_stddev_fields?: BucketsStddevFieldsResolvers<ContextType>;
  buckets_stddev_pop_fields?: BucketsStddevPopFieldsResolvers<ContextType>;
  buckets_stddev_samp_fields?: BucketsStddevSampFieldsResolvers<ContextType>;
  buckets_sum_fields?: BucketsSumFieldsResolvers<ContextType>;
  buckets_var_pop_fields?: BucketsVarPopFieldsResolvers<ContextType>;
  buckets_var_samp_fields?: BucketsVarSampFieldsResolvers<ContextType>;
  buckets_variance_fields?: BucketsVarianceFieldsResolvers<ContextType>;
  bytea?: GraphQLScalarType;
  citext?: GraphQLScalarType;
  files?: FilesResolvers<ContextType>;
  files_aggregate?: FilesAggregateResolvers<ContextType>;
  files_aggregate_fields?: FilesAggregateFieldsResolvers<ContextType>;
  files_avg_fields?: FilesAvgFieldsResolvers<ContextType>;
  files_max_fields?: FilesMaxFieldsResolvers<ContextType>;
  files_min_fields?: FilesMinFieldsResolvers<ContextType>;
  files_mutation_response?: FilesMutationResponseResolvers<ContextType>;
  files_stddev_fields?: FilesStddevFieldsResolvers<ContextType>;
  files_stddev_pop_fields?: FilesStddevPopFieldsResolvers<ContextType>;
  files_stddev_samp_fields?: FilesStddevSampFieldsResolvers<ContextType>;
  files_sum_fields?: FilesSumFieldsResolvers<ContextType>;
  files_var_pop_fields?: FilesVarPopFieldsResolvers<ContextType>;
  files_var_samp_fields?: FilesVarSampFieldsResolvers<ContextType>;
  files_variance_fields?: FilesVarianceFieldsResolvers<ContextType>;
  generation_presets?: GenerationPresetsResolvers<ContextType>;
  generation_presets_aggregate?: GenerationPresetsAggregateResolvers<ContextType>;
  generation_presets_aggregate_fields?: GenerationPresetsAggregateFieldsResolvers<ContextType>;
  generation_presets_max_fields?: GenerationPresetsMaxFieldsResolvers<ContextType>;
  generation_presets_min_fields?: GenerationPresetsMinFieldsResolvers<ContextType>;
  generation_presets_mutation_response?: GenerationPresetsMutationResponseResolvers<ContextType>;
  generations?: GenerationsResolvers<ContextType>;
  generations_aggregate?: GenerationsAggregateResolvers<ContextType>;
  generations_aggregate_fields?: GenerationsAggregateFieldsResolvers<ContextType>;
  generations_avg_fields?: GenerationsAvgFieldsResolvers<ContextType>;
  generations_max_fields?: GenerationsMaxFieldsResolvers<ContextType>;
  generations_min_fields?: GenerationsMinFieldsResolvers<ContextType>;
  generations_mutation_response?: GenerationsMutationResponseResolvers<ContextType>;
  generations_stddev_fields?: GenerationsStddevFieldsResolvers<ContextType>;
  generations_stddev_pop_fields?: GenerationsStddevPopFieldsResolvers<ContextType>;
  generations_stddev_samp_fields?: GenerationsStddevSampFieldsResolvers<ContextType>;
  generations_sum_fields?: GenerationsSumFieldsResolvers<ContextType>;
  generations_var_pop_fields?: GenerationsVarPopFieldsResolvers<ContextType>;
  generations_var_samp_fields?: GenerationsVarSampFieldsResolvers<ContextType>;
  generations_variance_fields?: GenerationsVarianceFieldsResolvers<ContextType>;
  jsonb?: GraphQLScalarType;
  model_input_type?: ModelInputTypeResolvers<ContextType>;
  model_input_type_aggregate?: ModelInputTypeAggregateResolvers<ContextType>;
  model_input_type_aggregate_fields?: ModelInputTypeAggregateFieldsResolvers<ContextType>;
  model_input_type_max_fields?: ModelInputTypeMaxFieldsResolvers<ContextType>;
  model_input_type_min_fields?: ModelInputTypeMinFieldsResolvers<ContextType>;
  model_input_type_mutation_response?: ModelInputTypeMutationResponseResolvers<ContextType>;
  model_type?: ModelTypeResolvers<ContextType>;
  model_type_aggregate?: ModelTypeAggregateResolvers<ContextType>;
  model_type_aggregate_fields?: ModelTypeAggregateFieldsResolvers<ContextType>;
  model_type_max_fields?: ModelTypeMaxFieldsResolvers<ContextType>;
  model_type_min_fields?: ModelTypeMinFieldsResolvers<ContextType>;
  model_type_mutation_response?: ModelTypeMutationResponseResolvers<ContextType>;
  models?: ModelsResolvers<ContextType>;
  models_aggregate?: ModelsAggregateResolvers<ContextType>;
  models_aggregate_fields?: ModelsAggregateFieldsResolvers<ContextType>;
  models_max_fields?: ModelsMaxFieldsResolvers<ContextType>;
  models_min_fields?: ModelsMinFieldsResolvers<ContextType>;
  models_mutation_response?: ModelsMutationResponseResolvers<ContextType>;
  mutation_root?: MutationRootResolvers<ContextType>;
  organization_features?: OrganizationFeaturesResolvers<ContextType>;
  organization_features_aggregate?: OrganizationFeaturesAggregateResolvers<ContextType>;
  organization_features_aggregate_fields?: OrganizationFeaturesAggregateFieldsResolvers<ContextType>;
  organization_features_avg_fields?: OrganizationFeaturesAvgFieldsResolvers<ContextType>;
  organization_features_max_fields?: OrganizationFeaturesMaxFieldsResolvers<ContextType>;
  organization_features_min_fields?: OrganizationFeaturesMinFieldsResolvers<ContextType>;
  organization_features_mutation_response?: OrganizationFeaturesMutationResponseResolvers<ContextType>;
  organization_features_stddev_fields?: OrganizationFeaturesStddevFieldsResolvers<ContextType>;
  organization_features_stddev_pop_fields?: OrganizationFeaturesStddevPopFieldsResolvers<ContextType>;
  organization_features_stddev_samp_fields?: OrganizationFeaturesStddevSampFieldsResolvers<ContextType>;
  organization_features_sum_fields?: OrganizationFeaturesSumFieldsResolvers<ContextType>;
  organization_features_var_pop_fields?: OrganizationFeaturesVarPopFieldsResolvers<ContextType>;
  organization_features_var_samp_fields?: OrganizationFeaturesVarSampFieldsResolvers<ContextType>;
  organization_features_variance_fields?: OrganizationFeaturesVarianceFieldsResolvers<ContextType>;
  organizations?: OrganizationsResolvers<ContextType>;
  organizations_aggregate?: OrganizationsAggregateResolvers<ContextType>;
  organizations_aggregate_fields?: OrganizationsAggregateFieldsResolvers<ContextType>;
  organizations_max_fields?: OrganizationsMaxFieldsResolvers<ContextType>;
  organizations_min_fields?: OrganizationsMinFieldsResolvers<ContextType>;
  organizations_mutation_response?: OrganizationsMutationResponseResolvers<ContextType>;
  query_root?: QueryRootResolvers<ContextType>;
  status?: StatusResolvers<ContextType>;
  status_aggregate?: StatusAggregateResolvers<ContextType>;
  status_aggregate_fields?: StatusAggregateFieldsResolvers<ContextType>;
  status_max_fields?: StatusMaxFieldsResolvers<ContextType>;
  status_min_fields?: StatusMinFieldsResolvers<ContextType>;
  status_mutation_response?: StatusMutationResponseResolvers<ContextType>;
  subscription_root?: SubscriptionRootResolvers<ContextType>;
  timestamptz?: GraphQLScalarType;
  transaction_type?: TransactionTypeResolvers<ContextType>;
  transaction_type_aggregate?: TransactionTypeAggregateResolvers<ContextType>;
  transaction_type_aggregate_fields?: TransactionTypeAggregateFieldsResolvers<ContextType>;
  transaction_type_max_fields?: TransactionTypeMaxFieldsResolvers<ContextType>;
  transaction_type_min_fields?: TransactionTypeMinFieldsResolvers<ContextType>;
  transaction_type_mutation_response?: TransactionTypeMutationResponseResolvers<ContextType>;
  transactions?: TransactionsResolvers<ContextType>;
  transactions_aggregate?: TransactionsAggregateResolvers<ContextType>;
  transactions_aggregate_fields?: TransactionsAggregateFieldsResolvers<ContextType>;
  transactions_avg_fields?: TransactionsAvgFieldsResolvers<ContextType>;
  transactions_max_fields?: TransactionsMaxFieldsResolvers<ContextType>;
  transactions_min_fields?: TransactionsMinFieldsResolvers<ContextType>;
  transactions_mutation_response?: TransactionsMutationResponseResolvers<ContextType>;
  transactions_stddev_fields?: TransactionsStddevFieldsResolvers<ContextType>;
  transactions_stddev_pop_fields?: TransactionsStddevPopFieldsResolvers<ContextType>;
  transactions_stddev_samp_fields?: TransactionsStddevSampFieldsResolvers<ContextType>;
  transactions_sum_fields?: TransactionsSumFieldsResolvers<ContextType>;
  transactions_var_pop_fields?: TransactionsVarPopFieldsResolvers<ContextType>;
  transactions_var_samp_fields?: TransactionsVarSampFieldsResolvers<ContextType>;
  transactions_variance_fields?: TransactionsVarianceFieldsResolvers<ContextType>;
  user_organizations?: UserOrganizationsResolvers<ContextType>;
  user_organizations_aggregate?: UserOrganizationsAggregateResolvers<ContextType>;
  user_organizations_aggregate_fields?: UserOrganizationsAggregateFieldsResolvers<ContextType>;
  user_organizations_max_fields?: UserOrganizationsMaxFieldsResolvers<ContextType>;
  user_organizations_min_fields?: UserOrganizationsMinFieldsResolvers<ContextType>;
  user_organizations_mutation_response?: UserOrganizationsMutationResponseResolvers<ContextType>;
  users?: UsersResolvers<ContextType>;
  users_aggregate?: UsersAggregateResolvers<ContextType>;
  users_aggregate_fields?: UsersAggregateFieldsResolvers<ContextType>;
  users_max_fields?: UsersMaxFieldsResolvers<ContextType>;
  users_min_fields?: UsersMinFieldsResolvers<ContextType>;
  users_mutation_response?: UsersMutationResponseResolvers<ContextType>;
  uuid?: GraphQLScalarType;
  virus?: VirusResolvers<ContextType>;
  virus_aggregate?: VirusAggregateResolvers<ContextType>;
  virus_aggregate_fields?: VirusAggregateFieldsResolvers<ContextType>;
  virus_max_fields?: VirusMaxFieldsResolvers<ContextType>;
  virus_min_fields?: VirusMinFieldsResolvers<ContextType>;
  virus_mutation_response?: VirusMutationResponseResolvers<ContextType>;
};

export type DirectiveResolvers<ContextType = any> = {
  cached?: CachedDirectiveResolver<any, any, ContextType>;
};

export const ModelFragmentDoc = gql`
    fragment Model on models {
  id
  createdAt
  updatedAt
  name
  description
  type
  version
  endpoint
  requiredInputCombinations
}
    `;
export const GenerationPresetFragmentDoc = gql`
    fragment GenerationPreset on generation_presets {
  id
  organizationId
  userId
  name
  description
  options
  isShared
  model {
    ...Model
  }
}
    ${ModelFragmentDoc}`;
export const GenerationFragmentDoc = gql`
    fragment Generation on generations {
  id
  createdAt
  updatedAt
  startedAt
  finishedAt
  organizationId
  transactionId
  status
  modelName
  inputs
  webhookUrl
  options
  stream
  outputMediaUrl
  outputMediaLength
  potentialError
}
    `;
export const ApiKeyFragmentDoc = gql`
    fragment ApiKey on api_keys {
  id
  createdAt
  name
  secretMask
  secretHash
  revokedAt
  revoked
  organizationId
  lastAccessedAt
}
    `;
export const OrganizationFragmentDoc = gql`
    fragment Organization on organizations {
  id
  name
  keys(order_by: {createdAt: desc}) {
    ...ApiKey
  }
}
    ${ApiKeyFragmentDoc}`;
export const OrganizationFeaturesFragmentDoc = gql`
    fragment OrganizationFeatures on organization_features {
  concurrencyOverride
}
    `;
export const OrganizationDetailsFragmentDoc = gql`
    fragment OrganizationDetails on organizations {
  id
  name
  keys {
    ...ApiKey
  }
  features: organization_features {
    ...OrganizationFeatures
  }
}
    ${ApiKeyFragmentDoc}
${OrganizationFeaturesFragmentDoc}`;
export const OrganizationForWebAppFragmentDoc = gql`
    fragment OrganizationForWebApp on organizations {
  id
  name
  keys(order_by: {createdAt: desc}) {
    id
    createdAt
    name
    secretMask
    revokedAt
    revoked
    organizationId
    lastAccessedAt
  }
}
    `;
export const TransactionFragmentDoc = gql`
    fragment Transaction on transactions {
  id
  createdAt
  updatedAt
  organizationId
  generationId
  type
  status
  amount
  framesGenerated
  stripeTransactionId
}
    `;
export const UserFragmentDoc = gql`
    fragment User on users {
  id
  createdAt
  displayName
  avatarUrl
  locale
  email
  isAnonymous
  defaultRole
  roles {
    role
  }
  metadata
  emailVerified
  phoneNumber
  phoneNumberVerified
  activeMfaType
  userProviders {
    providerId
  }
}
    `;
export const UserDetailsFragmentDoc = gql`
    fragment UserDetails on users {
  id
  displayName
  email
  createdAt
}
    `;
export const CreateApiKeyDocument = gql`
    mutation CreateApiKey($id: uuid!, $organizationId: uuid!, $secretHash: String!, $secretMask: String!) {
  apiKey: insert_api_keys_one(
    object: {id: $id, organizationId: $organizationId, secretHash: $secretHash, secretMask: $secretMask}
  ) {
    ...ApiKey
  }
}
    ${ApiKeyFragmentDoc}`;
export type CreateApiKeyMutationFn = Apollo.MutationFunction<CreateApiKeyMutation, CreateApiKeyMutationVariables>;

/**
 * __useCreateApiKeyMutation__
 *
 * To run a mutation, you first call `useCreateApiKeyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateApiKeyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createApiKeyMutation, { data, loading, error }] = useCreateApiKeyMutation({
 *   variables: {
 *      id: // value for 'id'
 *      organizationId: // value for 'organizationId'
 *      secretHash: // value for 'secretHash'
 *      secretMask: // value for 'secretMask'
 *   },
 * });
 */
export function useCreateApiKeyMutation(baseOptions?: Apollo.MutationHookOptions<CreateApiKeyMutation, CreateApiKeyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateApiKeyMutation, CreateApiKeyMutationVariables>(CreateApiKeyDocument, options);
      }
export type CreateApiKeyMutationHookResult = ReturnType<typeof useCreateApiKeyMutation>;
export type CreateApiKeyMutationResult = Apollo.MutationResult<CreateApiKeyMutation>;
export type CreateApiKeyMutationOptions = Apollo.BaseMutationOptions<CreateApiKeyMutation, CreateApiKeyMutationVariables>;
export const UpdateApiKeyDocument = gql`
    mutation UpdateApiKey($id: uuid!, $set: api_keys_set_input!) {
  apiKey: update_api_keys_by_pk(pk_columns: {id: $id}, _set: $set) {
    id
    createdAt
    name
    secretMask
    revokedAt
    revoked
    organizationId
    lastAccessedAt
  }
}
    `;
export type UpdateApiKeyMutationFn = Apollo.MutationFunction<UpdateApiKeyMutation, UpdateApiKeyMutationVariables>;

/**
 * __useUpdateApiKeyMutation__
 *
 * To run a mutation, you first call `useUpdateApiKeyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateApiKeyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateApiKeyMutation, { data, loading, error }] = useUpdateApiKeyMutation({
 *   variables: {
 *      id: // value for 'id'
 *      set: // value for 'set'
 *   },
 * });
 */
export function useUpdateApiKeyMutation(baseOptions?: Apollo.MutationHookOptions<UpdateApiKeyMutation, UpdateApiKeyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateApiKeyMutation, UpdateApiKeyMutationVariables>(UpdateApiKeyDocument, options);
      }
export type UpdateApiKeyMutationHookResult = ReturnType<typeof useUpdateApiKeyMutation>;
export type UpdateApiKeyMutationResult = Apollo.MutationResult<UpdateApiKeyMutation>;
export type UpdateApiKeyMutationOptions = Apollo.BaseMutationOptions<UpdateApiKeyMutation, UpdateApiKeyMutationVariables>;
export const CreateGenerationPresetDocument = gql`
    mutation CreateGenerationPreset($userId: uuid!, $organizationId: uuid!, $name: String!, $description: String, $modelName: String, $options: jsonb) {
  generationPreset: insert_generation_presets_one(
    object: {userId: $userId, organizationId: $organizationId, name: $name, description: $description, modelName: $modelName, options: $options}
  ) {
    ...GenerationPreset
  }
}
    ${GenerationPresetFragmentDoc}`;
export type CreateGenerationPresetMutationFn = Apollo.MutationFunction<CreateGenerationPresetMutation, CreateGenerationPresetMutationVariables>;

/**
 * __useCreateGenerationPresetMutation__
 *
 * To run a mutation, you first call `useCreateGenerationPresetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateGenerationPresetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createGenerationPresetMutation, { data, loading, error }] = useCreateGenerationPresetMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      organizationId: // value for 'organizationId'
 *      name: // value for 'name'
 *      description: // value for 'description'
 *      modelName: // value for 'modelName'
 *      options: // value for 'options'
 *   },
 * });
 */
export function useCreateGenerationPresetMutation(baseOptions?: Apollo.MutationHookOptions<CreateGenerationPresetMutation, CreateGenerationPresetMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateGenerationPresetMutation, CreateGenerationPresetMutationVariables>(CreateGenerationPresetDocument, options);
      }
export type CreateGenerationPresetMutationHookResult = ReturnType<typeof useCreateGenerationPresetMutation>;
export type CreateGenerationPresetMutationResult = Apollo.MutationResult<CreateGenerationPresetMutation>;
export type CreateGenerationPresetMutationOptions = Apollo.BaseMutationOptions<CreateGenerationPresetMutation, CreateGenerationPresetMutationVariables>;
export const UpdateGenerationPresetDocument = gql`
    mutation UpdateGenerationPreset($id: uuid!, $set: generation_presets_set_input!) {
  generationPreset: update_generation_presets_by_pk(
    pk_columns: {id: $id}
    _set: $set
  ) {
    ...GenerationPreset
  }
}
    ${GenerationPresetFragmentDoc}`;
export type UpdateGenerationPresetMutationFn = Apollo.MutationFunction<UpdateGenerationPresetMutation, UpdateGenerationPresetMutationVariables>;

/**
 * __useUpdateGenerationPresetMutation__
 *
 * To run a mutation, you first call `useUpdateGenerationPresetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateGenerationPresetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateGenerationPresetMutation, { data, loading, error }] = useUpdateGenerationPresetMutation({
 *   variables: {
 *      id: // value for 'id'
 *      set: // value for 'set'
 *   },
 * });
 */
export function useUpdateGenerationPresetMutation(baseOptions?: Apollo.MutationHookOptions<UpdateGenerationPresetMutation, UpdateGenerationPresetMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateGenerationPresetMutation, UpdateGenerationPresetMutationVariables>(UpdateGenerationPresetDocument, options);
      }
export type UpdateGenerationPresetMutationHookResult = ReturnType<typeof useUpdateGenerationPresetMutation>;
export type UpdateGenerationPresetMutationResult = Apollo.MutationResult<UpdateGenerationPresetMutation>;
export type UpdateGenerationPresetMutationOptions = Apollo.BaseMutationOptions<UpdateGenerationPresetMutation, UpdateGenerationPresetMutationVariables>;
export const GetOrganizationGenerationPresetsDocument = gql`
    query GetOrganizationGenerationPresets($organizationId: uuid!) {
  generationPresets: generation_presets(
    where: {organizationId: {_eq: $organizationId}}
  ) {
    ...GenerationPreset
  }
}
    ${GenerationPresetFragmentDoc}`;

/**
 * __useGetOrganizationGenerationPresetsQuery__
 *
 * To run a query within a React component, call `useGetOrganizationGenerationPresetsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOrganizationGenerationPresetsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOrganizationGenerationPresetsQuery({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *   },
 * });
 */
export function useGetOrganizationGenerationPresetsQuery(baseOptions: Apollo.QueryHookOptions<GetOrganizationGenerationPresetsQuery, GetOrganizationGenerationPresetsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetOrganizationGenerationPresetsQuery, GetOrganizationGenerationPresetsQueryVariables>(GetOrganizationGenerationPresetsDocument, options);
      }
export function useGetOrganizationGenerationPresetsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetOrganizationGenerationPresetsQuery, GetOrganizationGenerationPresetsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetOrganizationGenerationPresetsQuery, GetOrganizationGenerationPresetsQueryVariables>(GetOrganizationGenerationPresetsDocument, options);
        }
export type GetOrganizationGenerationPresetsQueryHookResult = ReturnType<typeof useGetOrganizationGenerationPresetsQuery>;
export type GetOrganizationGenerationPresetsLazyQueryHookResult = ReturnType<typeof useGetOrganizationGenerationPresetsLazyQuery>;
export type GetOrganizationGenerationPresetsQueryResult = Apollo.QueryResult<GetOrganizationGenerationPresetsQuery, GetOrganizationGenerationPresetsQueryVariables>;
export const CreateGenerationDocument = gql`
    mutation CreateGeneration($organizationId: uuid!, $status: status_enum!, $modelName: String!, $inputs: jsonb, $webhookUrl: String, $options: jsonb, $stream: Boolean) {
  generation: insert_generations_one(
    object: {organizationId: $organizationId, status: $status, modelName: $modelName, inputs: $inputs, webhookUrl: $webhookUrl, options: $options, stream: $stream, transaction: {data: {organizationId: $organizationId, type: GENERATION, status: $status}}}
  ) {
    ...Generation
  }
}
    ${GenerationFragmentDoc}`;
export type CreateGenerationMutationFn = Apollo.MutationFunction<CreateGenerationMutation, CreateGenerationMutationVariables>;

/**
 * __useCreateGenerationMutation__
 *
 * To run a mutation, you first call `useCreateGenerationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateGenerationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createGenerationMutation, { data, loading, error }] = useCreateGenerationMutation({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *      status: // value for 'status'
 *      modelName: // value for 'modelName'
 *      inputs: // value for 'inputs'
 *      webhookUrl: // value for 'webhookUrl'
 *      options: // value for 'options'
 *      stream: // value for 'stream'
 *   },
 * });
 */
export function useCreateGenerationMutation(baseOptions?: Apollo.MutationHookOptions<CreateGenerationMutation, CreateGenerationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateGenerationMutation, CreateGenerationMutationVariables>(CreateGenerationDocument, options);
      }
export type CreateGenerationMutationHookResult = ReturnType<typeof useCreateGenerationMutation>;
export type CreateGenerationMutationResult = Apollo.MutationResult<CreateGenerationMutation>;
export type CreateGenerationMutationOptions = Apollo.BaseMutationOptions<CreateGenerationMutation, CreateGenerationMutationVariables>;
export const UpdateGenerationDocument = gql`
    mutation UpdateGeneration($id: uuid!, $set: generations_set_input!) {
  generation: update_generations_by_pk(pk_columns: {id: $id}, _set: $set) {
    ...Generation
  }
}
    ${GenerationFragmentDoc}`;
export type UpdateGenerationMutationFn = Apollo.MutationFunction<UpdateGenerationMutation, UpdateGenerationMutationVariables>;

/**
 * __useUpdateGenerationMutation__
 *
 * To run a mutation, you first call `useUpdateGenerationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateGenerationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateGenerationMutation, { data, loading, error }] = useUpdateGenerationMutation({
 *   variables: {
 *      id: // value for 'id'
 *      set: // value for 'set'
 *   },
 * });
 */
export function useUpdateGenerationMutation(baseOptions?: Apollo.MutationHookOptions<UpdateGenerationMutation, UpdateGenerationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateGenerationMutation, UpdateGenerationMutationVariables>(UpdateGenerationDocument, options);
      }
export type UpdateGenerationMutationHookResult = ReturnType<typeof useUpdateGenerationMutation>;
export type UpdateGenerationMutationResult = Apollo.MutationResult<UpdateGenerationMutation>;
export type UpdateGenerationMutationOptions = Apollo.BaseMutationOptions<UpdateGenerationMutation, UpdateGenerationMutationVariables>;
export const GetGenerationByIdDocument = gql`
    query GetGenerationById($id: uuid!, $organizationId: uuid!) {
  generations(where: {id: {_eq: $id}, organizationId: {_eq: $organizationId}}) {
    ...Generation
  }
}
    ${GenerationFragmentDoc}`;

/**
 * __useGetGenerationByIdQuery__
 *
 * To run a query within a React component, call `useGetGenerationByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetGenerationByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetGenerationByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *      organizationId: // value for 'organizationId'
 *   },
 * });
 */
export function useGetGenerationByIdQuery(baseOptions: Apollo.QueryHookOptions<GetGenerationByIdQuery, GetGenerationByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetGenerationByIdQuery, GetGenerationByIdQueryVariables>(GetGenerationByIdDocument, options);
      }
export function useGetGenerationByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetGenerationByIdQuery, GetGenerationByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetGenerationByIdQuery, GetGenerationByIdQueryVariables>(GetGenerationByIdDocument, options);
        }
export type GetGenerationByIdQueryHookResult = ReturnType<typeof useGetGenerationByIdQuery>;
export type GetGenerationByIdLazyQueryHookResult = ReturnType<typeof useGetGenerationByIdLazyQuery>;
export type GetGenerationByIdQueryResult = Apollo.QueryResult<GetGenerationByIdQuery, GetGenerationByIdQueryVariables>;
export const GenerationUpdatedDocument = gql`
    subscription GenerationUpdated($id: uuid!) {
  generation: generations_by_pk(id: $id) {
    ...Generation
  }
}
    ${GenerationFragmentDoc}`;

/**
 * __useGenerationUpdatedSubscription__
 *
 * To run a query within a React component, call `useGenerationUpdatedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useGenerationUpdatedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGenerationUpdatedSubscription({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGenerationUpdatedSubscription(baseOptions: Apollo.SubscriptionHookOptions<GenerationUpdatedSubscription, GenerationUpdatedSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<GenerationUpdatedSubscription, GenerationUpdatedSubscriptionVariables>(GenerationUpdatedDocument, options);
      }
export type GenerationUpdatedSubscriptionHookResult = ReturnType<typeof useGenerationUpdatedSubscription>;
export type GenerationUpdatedSubscriptionResult = Apollo.SubscriptionResult<GenerationUpdatedSubscription>;
export const OrganizationSessionGenerationsCountUpdatedDocument = gql`
    subscription OrganizationSessionGenerationsCountUpdated($organizationId: uuid, $activeGenerationIds: [uuid!]!) {
  totalGenerations: generations_aggregate(
    where: {organizationId: {_eq: $organizationId}, id: {_in: $activeGenerationIds}}
  ) {
    aggregate {
      count
    }
  }
}
    `;

/**
 * __useOrganizationSessionGenerationsCountUpdatedSubscription__
 *
 * To run a query within a React component, call `useOrganizationSessionGenerationsCountUpdatedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useOrganizationSessionGenerationsCountUpdatedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrganizationSessionGenerationsCountUpdatedSubscription({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *      activeGenerationIds: // value for 'activeGenerationIds'
 *   },
 * });
 */
export function useOrganizationSessionGenerationsCountUpdatedSubscription(baseOptions: Apollo.SubscriptionHookOptions<OrganizationSessionGenerationsCountUpdatedSubscription, OrganizationSessionGenerationsCountUpdatedSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<OrganizationSessionGenerationsCountUpdatedSubscription, OrganizationSessionGenerationsCountUpdatedSubscriptionVariables>(OrganizationSessionGenerationsCountUpdatedDocument, options);
      }
export type OrganizationSessionGenerationsCountUpdatedSubscriptionHookResult = ReturnType<typeof useOrganizationSessionGenerationsCountUpdatedSubscription>;
export type OrganizationSessionGenerationsCountUpdatedSubscriptionResult = Apollo.SubscriptionResult<OrganizationSessionGenerationsCountUpdatedSubscription>;
export const OrganizationSessionGenerationsUpdatedDocument = gql`
    subscription OrganizationSessionGenerationsUpdated($limit: Int, $offset: Int, $organizationId: uuid, $activeGenerationIds: [uuid!]!) {
  generations(
    limit: $limit
    offset: $offset
    where: {organizationId: {_eq: $organizationId}, id: {_in: $activeGenerationIds}}
    order_by: {createdAt: desc}
  ) {
    ...Generation
  }
}
    ${GenerationFragmentDoc}`;

/**
 * __useOrganizationSessionGenerationsUpdatedSubscription__
 *
 * To run a query within a React component, call `useOrganizationSessionGenerationsUpdatedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useOrganizationSessionGenerationsUpdatedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrganizationSessionGenerationsUpdatedSubscription({
 *   variables: {
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      organizationId: // value for 'organizationId'
 *      activeGenerationIds: // value for 'activeGenerationIds'
 *   },
 * });
 */
export function useOrganizationSessionGenerationsUpdatedSubscription(baseOptions: Apollo.SubscriptionHookOptions<OrganizationSessionGenerationsUpdatedSubscription, OrganizationSessionGenerationsUpdatedSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<OrganizationSessionGenerationsUpdatedSubscription, OrganizationSessionGenerationsUpdatedSubscriptionVariables>(OrganizationSessionGenerationsUpdatedDocument, options);
      }
export type OrganizationSessionGenerationsUpdatedSubscriptionHookResult = ReturnType<typeof useOrganizationSessionGenerationsUpdatedSubscription>;
export type OrganizationSessionGenerationsUpdatedSubscriptionResult = Apollo.SubscriptionResult<OrganizationSessionGenerationsUpdatedSubscription>;
export const GetModelByNameDocument = gql`
    query GetModelByName($name: String!) {
  models(where: {name: {_eq: $name}}, limit: 1) {
    ...Model
  }
}
    ${ModelFragmentDoc}`;

/**
 * __useGetModelByNameQuery__
 *
 * To run a query within a React component, call `useGetModelByNameQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetModelByNameQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetModelByNameQuery({
 *   variables: {
 *      name: // value for 'name'
 *   },
 * });
 */
export function useGetModelByNameQuery(baseOptions: Apollo.QueryHookOptions<GetModelByNameQuery, GetModelByNameQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetModelByNameQuery, GetModelByNameQueryVariables>(GetModelByNameDocument, options);
      }
export function useGetModelByNameLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetModelByNameQuery, GetModelByNameQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetModelByNameQuery, GetModelByNameQueryVariables>(GetModelByNameDocument, options);
        }
export type GetModelByNameQueryHookResult = ReturnType<typeof useGetModelByNameQuery>;
export type GetModelByNameLazyQueryHookResult = ReturnType<typeof useGetModelByNameLazyQuery>;
export type GetModelByNameQueryResult = Apollo.QueryResult<GetModelByNameQuery, GetModelByNameQueryVariables>;
export const GetModelNamesDocument = gql`
    query GetModelNames {
  models {
    name
  }
}
    `;

/**
 * __useGetModelNamesQuery__
 *
 * To run a query within a React component, call `useGetModelNamesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetModelNamesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetModelNamesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetModelNamesQuery(baseOptions?: Apollo.QueryHookOptions<GetModelNamesQuery, GetModelNamesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetModelNamesQuery, GetModelNamesQueryVariables>(GetModelNamesDocument, options);
      }
export function useGetModelNamesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetModelNamesQuery, GetModelNamesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetModelNamesQuery, GetModelNamesQueryVariables>(GetModelNamesDocument, options);
        }
export type GetModelNamesQueryHookResult = ReturnType<typeof useGetModelNamesQuery>;
export type GetModelNamesLazyQueryHookResult = ReturnType<typeof useGetModelNamesLazyQuery>;
export type GetModelNamesQueryResult = Apollo.QueryResult<GetModelNamesQuery, GetModelNamesQueryVariables>;
export const GetModelsDocument = gql`
    query GetModels {
  models {
    ...Model
  }
}
    ${ModelFragmentDoc}`;

/**
 * __useGetModelsQuery__
 *
 * To run a query within a React component, call `useGetModelsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetModelsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetModelsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetModelsQuery(baseOptions?: Apollo.QueryHookOptions<GetModelsQuery, GetModelsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetModelsQuery, GetModelsQueryVariables>(GetModelsDocument, options);
      }
export function useGetModelsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetModelsQuery, GetModelsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetModelsQuery, GetModelsQueryVariables>(GetModelsDocument, options);
        }
export type GetModelsQueryHookResult = ReturnType<typeof useGetModelsQuery>;
export type GetModelsLazyQueryHookResult = ReturnType<typeof useGetModelsLazyQuery>;
export type GetModelsQueryResult = Apollo.QueryResult<GetModelsQuery, GetModelsQueryVariables>;
export const CreateOrganizationDocument = gql`
    mutation CreateOrganization($id: uuid!, $name: String) {
  organization: insert_organizations_one(object: {id: $id, name: $name}) {
    id
    createdAt
    name
  }
  userOrganization: insert_user_organizations_one(
    object: {userId: $id, organizationId: $id, createdAt: "now()"}
  ) {
    id
    createdAt
  }
}
    `;
export type CreateOrganizationMutationFn = Apollo.MutationFunction<CreateOrganizationMutation, CreateOrganizationMutationVariables>;

/**
 * __useCreateOrganizationMutation__
 *
 * To run a mutation, you first call `useCreateOrganizationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOrganizationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOrganizationMutation, { data, loading, error }] = useCreateOrganizationMutation({
 *   variables: {
 *      id: // value for 'id'
 *      name: // value for 'name'
 *   },
 * });
 */
export function useCreateOrganizationMutation(baseOptions?: Apollo.MutationHookOptions<CreateOrganizationMutation, CreateOrganizationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateOrganizationMutation, CreateOrganizationMutationVariables>(CreateOrganizationDocument, options);
      }
export type CreateOrganizationMutationHookResult = ReturnType<typeof useCreateOrganizationMutation>;
export type CreateOrganizationMutationResult = Apollo.MutationResult<CreateOrganizationMutation>;
export type CreateOrganizationMutationOptions = Apollo.BaseMutationOptions<CreateOrganizationMutation, CreateOrganizationMutationVariables>;
export const UpdateOrganizationDocument = gql`
    mutation UpdateOrganization($id: uuid!, $set: organizations_set_input!) {
  organization: update_organizations_by_pk(pk_columns: {id: $id}, _set: $set) {
    ...Organization
  }
}
    ${OrganizationFragmentDoc}`;
export type UpdateOrganizationMutationFn = Apollo.MutationFunction<UpdateOrganizationMutation, UpdateOrganizationMutationVariables>;

/**
 * __useUpdateOrganizationMutation__
 *
 * To run a mutation, you first call `useUpdateOrganizationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOrganizationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOrganizationMutation, { data, loading, error }] = useUpdateOrganizationMutation({
 *   variables: {
 *      id: // value for 'id'
 *      set: // value for 'set'
 *   },
 * });
 */
export function useUpdateOrganizationMutation(baseOptions?: Apollo.MutationHookOptions<UpdateOrganizationMutation, UpdateOrganizationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateOrganizationMutation, UpdateOrganizationMutationVariables>(UpdateOrganizationDocument, options);
      }
export type UpdateOrganizationMutationHookResult = ReturnType<typeof useUpdateOrganizationMutation>;
export type UpdateOrganizationMutationResult = Apollo.MutationResult<UpdateOrganizationMutation>;
export type UpdateOrganizationMutationOptions = Apollo.BaseMutationOptions<UpdateOrganizationMutation, UpdateOrganizationMutationVariables>;
export const GetOrganizationByApiKeyDocument = gql`
    query GetOrganizationByApiKey($apiKeyId: uuid!) {
  organizations(where: {keys: {id: {_eq: $apiKeyId}}}, limit: 1) {
    ...OrganizationDetails
  }
}
    ${OrganizationDetailsFragmentDoc}`;

/**
 * __useGetOrganizationByApiKeyQuery__
 *
 * To run a query within a React component, call `useGetOrganizationByApiKeyQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOrganizationByApiKeyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOrganizationByApiKeyQuery({
 *   variables: {
 *      apiKeyId: // value for 'apiKeyId'
 *   },
 * });
 */
export function useGetOrganizationByApiKeyQuery(baseOptions: Apollo.QueryHookOptions<GetOrganizationByApiKeyQuery, GetOrganizationByApiKeyQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetOrganizationByApiKeyQuery, GetOrganizationByApiKeyQueryVariables>(GetOrganizationByApiKeyDocument, options);
      }
export function useGetOrganizationByApiKeyLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetOrganizationByApiKeyQuery, GetOrganizationByApiKeyQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetOrganizationByApiKeyQuery, GetOrganizationByApiKeyQueryVariables>(GetOrganizationByApiKeyDocument, options);
        }
export type GetOrganizationByApiKeyQueryHookResult = ReturnType<typeof useGetOrganizationByApiKeyQuery>;
export type GetOrganizationByApiKeyLazyQueryHookResult = ReturnType<typeof useGetOrganizationByApiKeyLazyQuery>;
export type GetOrganizationByApiKeyQueryResult = Apollo.QueryResult<GetOrganizationByApiKeyQuery, GetOrganizationByApiKeyQueryVariables>;
export const GetOrganizationByIdDocument = gql`
    query GetOrganizationById($id: uuid!) {
  organization: organizations_by_pk(id: $id) {
    ...Organization
  }
}
    ${OrganizationFragmentDoc}`;

/**
 * __useGetOrganizationByIdQuery__
 *
 * To run a query within a React component, call `useGetOrganizationByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOrganizationByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOrganizationByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetOrganizationByIdQuery(baseOptions: Apollo.QueryHookOptions<GetOrganizationByIdQuery, GetOrganizationByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetOrganizationByIdQuery, GetOrganizationByIdQueryVariables>(GetOrganizationByIdDocument, options);
      }
export function useGetOrganizationByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetOrganizationByIdQuery, GetOrganizationByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetOrganizationByIdQuery, GetOrganizationByIdQueryVariables>(GetOrganizationByIdDocument, options);
        }
export type GetOrganizationByIdQueryHookResult = ReturnType<typeof useGetOrganizationByIdQuery>;
export type GetOrganizationByIdLazyQueryHookResult = ReturnType<typeof useGetOrganizationByIdLazyQuery>;
export type GetOrganizationByIdQueryResult = Apollo.QueryResult<GetOrganizationByIdQuery, GetOrganizationByIdQueryVariables>;
export const GetOrganizationByIdForWebAppDocument = gql`
    query GetOrganizationByIdForWebApp($id: uuid!) {
  organization: organizations_by_pk(id: $id) {
    ...OrganizationForWebApp
  }
}
    ${OrganizationForWebAppFragmentDoc}`;

/**
 * __useGetOrganizationByIdForWebAppQuery__
 *
 * To run a query within a React component, call `useGetOrganizationByIdForWebAppQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOrganizationByIdForWebAppQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOrganizationByIdForWebAppQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetOrganizationByIdForWebAppQuery(baseOptions: Apollo.QueryHookOptions<GetOrganizationByIdForWebAppQuery, GetOrganizationByIdForWebAppQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetOrganizationByIdForWebAppQuery, GetOrganizationByIdForWebAppQueryVariables>(GetOrganizationByIdForWebAppDocument, options);
      }
export function useGetOrganizationByIdForWebAppLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetOrganizationByIdForWebAppQuery, GetOrganizationByIdForWebAppQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetOrganizationByIdForWebAppQuery, GetOrganizationByIdForWebAppQueryVariables>(GetOrganizationByIdForWebAppDocument, options);
        }
export type GetOrganizationByIdForWebAppQueryHookResult = ReturnType<typeof useGetOrganizationByIdForWebAppQuery>;
export type GetOrganizationByIdForWebAppLazyQueryHookResult = ReturnType<typeof useGetOrganizationByIdForWebAppLazyQuery>;
export type GetOrganizationByIdForWebAppQueryResult = Apollo.QueryResult<GetOrganizationByIdForWebAppQuery, GetOrganizationByIdForWebAppQueryVariables>;
export const GetOrganizationByUserIdDocument = gql`
    query GetOrganizationByUserId($userId: uuid!) {
  organizations(where: {users: {user: {id: {_eq: $userId}}}}, limit: 1) {
    ...OrganizationDetails
  }
}
    ${OrganizationDetailsFragmentDoc}`;

/**
 * __useGetOrganizationByUserIdQuery__
 *
 * To run a query within a React component, call `useGetOrganizationByUserIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOrganizationByUserIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOrganizationByUserIdQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useGetOrganizationByUserIdQuery(baseOptions: Apollo.QueryHookOptions<GetOrganizationByUserIdQuery, GetOrganizationByUserIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetOrganizationByUserIdQuery, GetOrganizationByUserIdQueryVariables>(GetOrganizationByUserIdDocument, options);
      }
export function useGetOrganizationByUserIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetOrganizationByUserIdQuery, GetOrganizationByUserIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetOrganizationByUserIdQuery, GetOrganizationByUserIdQueryVariables>(GetOrganizationByUserIdDocument, options);
        }
export type GetOrganizationByUserIdQueryHookResult = ReturnType<typeof useGetOrganizationByUserIdQuery>;
export type GetOrganizationByUserIdLazyQueryHookResult = ReturnType<typeof useGetOrganizationByUserIdLazyQuery>;
export type GetOrganizationByUserIdQueryResult = Apollo.QueryResult<GetOrganizationByUserIdQuery, GetOrganizationByUserIdQueryVariables>;
export const GetUsersInOrganizationByIdDocument = gql`
    query GetUsersInOrganizationById($id: uuid!) {
  organization: organizations_by_pk(id: $id) {
    users {
      user {
        ...User
      }
    }
  }
}
    ${UserFragmentDoc}`;

/**
 * __useGetUsersInOrganizationByIdQuery__
 *
 * To run a query within a React component, call `useGetUsersInOrganizationByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUsersInOrganizationByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUsersInOrganizationByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetUsersInOrganizationByIdQuery(baseOptions: Apollo.QueryHookOptions<GetUsersInOrganizationByIdQuery, GetUsersInOrganizationByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUsersInOrganizationByIdQuery, GetUsersInOrganizationByIdQueryVariables>(GetUsersInOrganizationByIdDocument, options);
      }
export function useGetUsersInOrganizationByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUsersInOrganizationByIdQuery, GetUsersInOrganizationByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUsersInOrganizationByIdQuery, GetUsersInOrganizationByIdQueryVariables>(GetUsersInOrganizationByIdDocument, options);
        }
export type GetUsersInOrganizationByIdQueryHookResult = ReturnType<typeof useGetUsersInOrganizationByIdQuery>;
export type GetUsersInOrganizationByIdLazyQueryHookResult = ReturnType<typeof useGetUsersInOrganizationByIdLazyQuery>;
export type GetUsersInOrganizationByIdQueryResult = Apollo.QueryResult<GetUsersInOrganizationByIdQuery, GetUsersInOrganizationByIdQueryVariables>;
export const UpdateTransactionDocument = gql`
    mutation UpdateTransaction($id: uuid!, $set: transactions_set_input!) {
  transaction: update_transactions_by_pk(pk_columns: {id: $id}, _set: $set) {
    ...Transaction
  }
}
    ${TransactionFragmentDoc}`;
export type UpdateTransactionMutationFn = Apollo.MutationFunction<UpdateTransactionMutation, UpdateTransactionMutationVariables>;

/**
 * __useUpdateTransactionMutation__
 *
 * To run a mutation, you first call `useUpdateTransactionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTransactionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTransactionMutation, { data, loading, error }] = useUpdateTransactionMutation({
 *   variables: {
 *      id: // value for 'id'
 *      set: // value for 'set'
 *   },
 * });
 */
export function useUpdateTransactionMutation(baseOptions?: Apollo.MutationHookOptions<UpdateTransactionMutation, UpdateTransactionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateTransactionMutation, UpdateTransactionMutationVariables>(UpdateTransactionDocument, options);
      }
export type UpdateTransactionMutationHookResult = ReturnType<typeof useUpdateTransactionMutation>;
export type UpdateTransactionMutationResult = Apollo.MutationResult<UpdateTransactionMutation>;
export type UpdateTransactionMutationOptions = Apollo.BaseMutationOptions<UpdateTransactionMutation, UpdateTransactionMutationVariables>;
export const GetTransactionByIdDocument = gql`
    query GetTransactionById($id: uuid!) {
  transaction: transactions_by_pk(id: $id) {
    ...Transaction
  }
}
    ${TransactionFragmentDoc}`;

/**
 * __useGetTransactionByIdQuery__
 *
 * To run a query within a React component, call `useGetTransactionByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTransactionByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTransactionByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetTransactionByIdQuery(baseOptions: Apollo.QueryHookOptions<GetTransactionByIdQuery, GetTransactionByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetTransactionByIdQuery, GetTransactionByIdQueryVariables>(GetTransactionByIdDocument, options);
      }
export function useGetTransactionByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTransactionByIdQuery, GetTransactionByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetTransactionByIdQuery, GetTransactionByIdQueryVariables>(GetTransactionByIdDocument, options);
        }
export type GetTransactionByIdQueryHookResult = ReturnType<typeof useGetTransactionByIdQuery>;
export type GetTransactionByIdLazyQueryHookResult = ReturnType<typeof useGetTransactionByIdLazyQuery>;
export type GetTransactionByIdQueryResult = Apollo.QueryResult<GetTransactionByIdQuery, GetTransactionByIdQueryVariables>;
export const GetUserOrganizationsByUserIdDocument = gql`
    query GetUserOrganizationsByUserId($userId: uuid) {
  user_organizations(where: {userId: {_eq: $userId}}) {
    id
    organizationId
  }
}
    `;

/**
 * __useGetUserOrganizationsByUserIdQuery__
 *
 * To run a query within a React component, call `useGetUserOrganizationsByUserIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserOrganizationsByUserIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserOrganizationsByUserIdQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useGetUserOrganizationsByUserIdQuery(baseOptions?: Apollo.QueryHookOptions<GetUserOrganizationsByUserIdQuery, GetUserOrganizationsByUserIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUserOrganizationsByUserIdQuery, GetUserOrganizationsByUserIdQueryVariables>(GetUserOrganizationsByUserIdDocument, options);
      }
export function useGetUserOrganizationsByUserIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUserOrganizationsByUserIdQuery, GetUserOrganizationsByUserIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUserOrganizationsByUserIdQuery, GetUserOrganizationsByUserIdQueryVariables>(GetUserOrganizationsByUserIdDocument, options);
        }
export type GetUserOrganizationsByUserIdQueryHookResult = ReturnType<typeof useGetUserOrganizationsByUserIdQuery>;
export type GetUserOrganizationsByUserIdLazyQueryHookResult = ReturnType<typeof useGetUserOrganizationsByUserIdLazyQuery>;
export type GetUserOrganizationsByUserIdQueryResult = Apollo.QueryResult<GetUserOrganizationsByUserIdQuery, GetUserOrganizationsByUserIdQueryVariables>;
export const GetUserDocument = gql`
    query GetUser($id: uuid!) {
  users(where: {id: {_eq: $id}}) {
    id
    displayName
    email
  }
}
    `;

/**
 * __useGetUserQuery__
 *
 * To run a query within a React component, call `useGetUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetUserQuery(baseOptions: Apollo.QueryHookOptions<GetUserQuery, GetUserQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUserQuery, GetUserQueryVariables>(GetUserDocument, options);
      }
export function useGetUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUserQuery, GetUserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUserQuery, GetUserQueryVariables>(GetUserDocument, options);
        }
export type GetUserQueryHookResult = ReturnType<typeof useGetUserQuery>;
export type GetUserLazyQueryHookResult = ReturnType<typeof useGetUserLazyQuery>;
export type GetUserQueryResult = Apollo.QueryResult<GetUserQuery, GetUserQueryVariables>;
export const GetUserProviderDocument = gql`
    query GetUserProvider($id: uuid!) {
  users(where: {id: {_eq: $id}}) {
    userProviders {
      providerId
    }
  }
}
    `;

/**
 * __useGetUserProviderQuery__
 *
 * To run a query within a React component, call `useGetUserProviderQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserProviderQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserProviderQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetUserProviderQuery(baseOptions: Apollo.QueryHookOptions<GetUserProviderQuery, GetUserProviderQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUserProviderQuery, GetUserProviderQueryVariables>(GetUserProviderDocument, options);
      }
export function useGetUserProviderLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUserProviderQuery, GetUserProviderQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUserProviderQuery, GetUserProviderQueryVariables>(GetUserProviderDocument, options);
        }
export type GetUserProviderQueryHookResult = ReturnType<typeof useGetUserProviderQuery>;
export type GetUserProviderLazyQueryHookResult = ReturnType<typeof useGetUserProviderLazyQuery>;
export type GetUserProviderQueryResult = Apollo.QueryResult<GetUserProviderQuery, GetUserProviderQueryVariables>;
export const GetUsersDocument = gql`
    query GetUsers {
  users {
    ...UserDetails
  }
}
    ${UserDetailsFragmentDoc}`;

/**
 * __useGetUsersQuery__
 *
 * To run a query within a React component, call `useGetUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUsersQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetUsersQuery(baseOptions?: Apollo.QueryHookOptions<GetUsersQuery, GetUsersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUsersQuery, GetUsersQueryVariables>(GetUsersDocument, options);
      }
export function useGetUsersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUsersQuery, GetUsersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUsersQuery, GetUsersQueryVariables>(GetUsersDocument, options);
        }
export type GetUsersQueryHookResult = ReturnType<typeof useGetUsersQuery>;
export type GetUsersLazyQueryHookResult = ReturnType<typeof useGetUsersLazyQuery>;
export type GetUsersQueryResult = Apollo.QueryResult<GetUsersQuery, GetUsersQueryVariables>;