import { FC } from "react";
import {
  Button,
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "@/components/ui";
import { CircleHelpIcon } from "lucide-react";
import Link from "next/link";

interface FooterProps {}

export const Footer: FC<FooterProps> = () => {
  return (
    <div className="fixed right-4 bottom-4">
      <Tooltip>
        <TooltipTrigger asChild>
          <Link href="mailto:hello@sync.so" target="_blank">
            <Button size="icon">
              <CircleHelpIcon className="size-6" />
            </Button>
          </Link>
        </TooltipTrigger>
        <TooltipContent sideOffset={20}>
          email us if you need help
        </TooltipContent>
      </Tooltip>
    </div>
  );
};
