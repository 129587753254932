"use client";

import { FC, useRef } from "react";
import { LottieRefCurrentProps } from "lottie-react";
import { UserDetails } from "@/hooks/use-user-details";
import ProfileMenu from "@/components/profile/profile-menu";
import { Logo } from "@/components/logo";
import { PlaygroundLogo } from "./playground-logo";
import Link from "next/link";

// Constants
const HEADER_LINKS = [
  { href: "https://docs.sync.so/api-reference/quickstart", label: "docs" },
  { href: "https://status.sync.so/", label: "status" },
  // Add more links as needed
];

interface HeaderProps {
  userDetails: UserDetails;
}

export const Header: FC<HeaderProps> = ({ userDetails }) => {
  const lottieRef = useRef<LottieRefCurrentProps>(null);

  return (
    <header className="px-6 border-b py-2 h-16">
      <div className="flex justify-between items-center h-full">
        <Link href="/">
          <Logo />
        </Link>

        <PlaygroundLogo lottieRef={lottieRef} />

        <div className="flex gap-8 items-center">
          {HEADER_LINKS.map((link) => (
            <Link
              key={link.href}
              href={link.href}
              target="_blank"
              rel="noopener noreferrer"
              className="hover:underline font-medium"
            >
              {link.label}
            </Link>
          ))}
          <ProfileMenu
            userId={userDetails.id}
            avatarUrl={userDetails.avatarUrl}
          />
        </div>
      </div>
    </header>
  );
};
