"use client";

import { useState, useEffect } from "react";
import { usePathname } from "next/navigation";
import Image from "next/image";
import { motion } from "framer-motion";
import { cn } from "@/lib/utils";
import { SideNavigationLink, sideNavigationLinks } from "./types";
import { SideNavItem } from "./item";
import { Button } from "@/components/ui";
import { PanelLeftCloseIcon, PanelLeftOpenIcon } from "lucide-react";

export const SIDENAV_EXPANDED_WIDTH = "200px";
export const SIDENAV_MINIMIZED_WIDTH = "72px";

const MotionDiv = motion.div;

export const SideNav = () => {
  const pathname = usePathname();
  const [expanded, setExpanded] = useState(() => {
    // Initialize state from localStorage, default to true if not set
    if (typeof window !== "undefined") {
      const saved = localStorage.getItem("sidenavExpanded");
      return saved !== null ? JSON.parse(saved) : true;
    }
    return true;
  });

  // Update localStorage when expanded state changes
  useEffect(() => {
    localStorage.setItem("sidenavExpanded", JSON.stringify(expanded));
  }, [expanded]);

  const width = expanded ? SIDENAV_EXPANDED_WIDTH : SIDENAV_MINIMIZED_WIDTH;

  const isActive = (link: SideNavigationLink) =>
    link.href === "/" ? pathname === link.href : pathname.startsWith(link.href);

  return (
    <MotionDiv
      className="h-full bg-background text-primary-foreground border-r flex flex-col px-4 py-6"
      style={{ width }}
      animate={{
        width: expanded ? SIDENAV_EXPANDED_WIDTH : SIDENAV_MINIMIZED_WIDTH,
      }}
      transition={{ duration: 0.3, ease: "easeInOut" }}
    >
      <div className="flex flex-col gap-6">
        {sideNavigationLinks.map((link) => (
          <SideNavItem
            key={link.href}
            link={link}
            isActive={isActive(link)}
            expanded={expanded}
          />
        ))}
      </div>

      <Button
        variant="ghost"
        size="icon"
        onClick={() => setExpanded((expanded: boolean) => !expanded)}
        className="mt-auto text-muted-foreground"
      >
        {!expanded ? (
          <PanelLeftOpenIcon className="size-4" />
        ) : (
          <PanelLeftCloseIcon className="size-4" />
        )}
      </Button>
    </MotionDiv>
  );
};
